import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./AuthReducer";
import bookingsReducer from "./BookingsReducer";
import boardReducer from "./BoardReducer";
import ratesReducer from "./RatesReducer";
import seasonsReducer from "./SeasonsReducer";
import BookingRequestsReducer from "./BookingRequestsReducer";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  bookings: bookingsReducer,
  board: boardReducer,
  rates: ratesReducer,
  seasons: seasonsReducer,
  requests: BookingRequestsReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
