import moment from "moment";
import { cloneDeep } from "lodash";

const initialState = {
  // Add bookings
  add_booking_expanded: false,
  add_booking_section: {
    client_name: "",
    client_email: "",
    client_phone: "",
    client_address: "",
    start_date: null,
    start_local: "",
    end_date: null,
    end_local: "",
    num_days: "",
    car_brand: "",
    car_model: "",
    car_plate: "",
    car_group: "",
    price: "",
    deposit: "",
    insurance: false,
    observations: "",
    added_date: "",
    modified_date: "",
    id: "",
  },
  add_booking_car_brand_options: [],
  add_booking_car_model_options: [],
  add_booking_car_plate_options: [],
  add_booking_tbd_dates_num: 0,
  add_booking_tbd_cars_num: 0,

  // Edit bookings
  edit_bookings: false,
  edit_booking_popup_open: -1,
  edit_booking_section: {
    client_name: "",
    client_email: "",
    client_phone: "",
    client_address: "",
    start_date: null,
    start_time: null,
    start_local: "",
    end_date: null,
    end_time: null,
    end_local: "",
    num_days: "",
    car_brand: "",
    car_model: "",
    car_plate: "",
    car_group: "",
    price: "",
    deposit: "",
    insurance: false,
    observations: "",
    added_date: "",
    modified_date: "",
    id: "",
  },
  edit_booking_car_brand_options: [],
  edit_booking_car_model_options: [],
  edit_booking_car_plate_options: [],
  edit_booking_tbd_dates_num: 0,
  edit_booking_tbd_cars_num: 0,

  // Delete bookings
  delete_booking_popup_open: -1,

  // Sort bookings
  sort_by: "ID",
  sort_direction: null,

  // Filter bookings
  filters: {
    client_info: "",
    start_date: null,
    start_time: null,
    start_local: "",
    end_date: null,
    end_time: null,
    end_local: "",
    num_days: "",
    car_info: "",
    id: "",
  },

  // General
  all_bookings: [],
  displayed_bookings: [],
  cars: [],
  overlapped_bookings_num: 0,
  gaps: {}
};

const updateGaps = (state) => {
  let groups = { A: [], B: [], C: [], M: [], D: [], E: [] };
  let margin = 90;
  let infinity = moment(1e15);

  // Check each group's cars
  for (var group in groups) {
    for (var car of state.cars) {
      if (car.Group.toUpperCase() === group) {
        // Get car bookings and gaps
        let car_bookings = state.all_bookings.filter((booking, index) => {
          if (booking.booking_info.Car_Plate === car.Plate) return true;
          else return false;
        });

        if (car_bookings.length <= 0)
          continue

        // Sort bookings by date
        car_bookings = car_bookings.sort((a, b) => {
          if (
            moment(
              a.booking_info.Start_Date + "T" + a.booking_info.Start_Time,
              "DD-MM-YYYYTHH:mm"
            ).isBefore(
              moment(
                b.booking_info.End_Date + "T" + b.booking_info.End_Time,
                "DD-MM-YYYYTHH:mm"
              )
            )
          )
            return -1;
          else return 1;
        });

        for (var j = 0; j < car_bookings.length - 1; j++) {
          let start_datetime = moment(
            car_bookings[j].booking_info.End_Date +
            "T" +
            car_bookings[j].booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );

          let end_datetime = moment(
            car_bookings[j + 1].booking_info.Start_Date +
            "T" +
            car_bookings[j + 1].booking_info.Start_Time,
            "DD-MM-YYYYTHH:mm"
          );

          if (
            end_datetime.diff(start_datetime, "minute") < 1 ||
            end_datetime.isSameOrBefore(moment(), "minute")
          )
            continue;

          groups[car.Group.toUpperCase()].push({
            start_time: start_datetime,
            end_time: end_datetime,
            range: moment.range(start_datetime, end_datetime),
            prev_booking: car_bookings[j],
            next_booking: car_bookings[j + 1],
          });
        }

        let start_datetime = moment(
          car_bookings[car_bookings.length - 1].booking_info.End_Date +
          "T" +
          car_bookings[car_bookings.length - 1].booking_info.End_Time,
          "DD-MM-YYYYTHH:mm"
        );

        groups[car.Group.toUpperCase()].push({
          start_time: start_datetime,
          end_time: infinity,
          range: moment.range(start_datetime, infinity),
          prev_booking: car_bookings[car_bookings.length - 1],
          next_booking: undefined,
        });
      }
    }
  }

  for (var group in groups) {
    for (var i = 0; i < groups[group].length; i++) {
      for (var j = 0; j < groups[group].length; j++) {
        if (i === j) continue;

        let gap_a = groups[group][i];
        let gap_b = groups[group][j];

        if (gap_a.range.overlaps(gap_b.range)) {
          // Remover buracos sobrepostos
          // Talvez sem isto para ordenar os carros
          if (
            gap_a.start_time.isSameOrBefore(gap_b.start_time) &&
            gap_a.end_time.isSameOrAfter(gap_b.end_time)
          ) {
            groups[group].splice(j, 1);

            if (j <= i && i > 0) i -= 1;
            if (j >= 0) j -= 1;
            continue;
          }
          if (
            gap_b.start_time.isSameOrBefore(gap_a.start_time) &&
            gap_b.end_time.isSameOrAfter(gap_a.end_time)
          ) {
            groups[group].splice(i, 1);
            if (i > 0) i -= 1;
            if (j >= 0) j -= 1;
            continue;
          }

          let gap = {};

          if (gap_a.start_time.isSameOrBefore(gap_b.start_time, "minute")) {
            gap.start_time = gap_a.start_time;
            gap.prev_booking = gap_a.prev_booking;
          } else {
            gap.start_time = gap_b.start_time;
            gap.prev_booking = gap_b.prev_booking;
          }

          if (gap_a.end_time.isSameOrAfter(gap_b.end_time, "minute")) {
            gap.end_time = gap_a.end_time;
            gap.next_booking = gap_a.next_booking;
          } else {
            gap.end_time = gap_b.end_time;
            gap.next_booking = gap_b.next_booking;
          }

          gap.range = moment.range(gap.start_time, gap.end_time);

          groups[group].splice(groups[group].indexOf(gap_a), 1);
          groups[group].splice(groups[group].indexOf(gap_b), 1);
          groups[group].unshift(gap);
          i = 0;
          j = -1;
        }
      }
    }

    groups[group] = groups[group].sort((a, b) => {
      if (a.start_time.isBefore(b.start_time)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  for (var group in groups) {
    if (groups[group].length <= 0) continue;

    for (var i = groups[group].length - 1; i >= 0; i--) {
      // Add margin to the beginning and end of the gap
      // If it's a final gap, just add to the beginning
      if (groups[group][i].end_time.isSame(infinity)) {
        groups[group][i].start_time = groups[group][i].start_time.add(
          margin,
          "minutes"
        );
        groups[group][i].range = moment.range(
          groups[group][i].start_time,
          groups[group][i].end_time
        );
        continue;
      }

      // If the gap started before the present moment, change the gap start to the present moment plus 90 minutes
      // Round it to the next tens and add the margins
      let addedMargin = false;

      if (groups[group][i].start_time.isBefore(moment(), "minute")) {
        groups[group][i].start_time = moment().add(margin, "minutes");
        groups[group][i].start_time.minute(
          Math.ceil((groups[group][i].start_time.minute() + 1) / 10) * 10
        );
        if (
          groups[group][i].end_time.diff(
            groups[group][i].start_time,
            "minutes"
          ) > margin
        ) {
          groups[group][i].end_time = groups[group][i].end_time.subtract(
            margin,
            "minutes"
          );
          groups[group][i].range = moment.range(
            groups[group][i].start_time,
            groups[group][i].end_time
          );
          addedMargin = true;
        } else {
          groups[group].splice(i, 1);
          continue;
        }
      }

      if (addedMargin === false) {
        if (
          groups[group][i].end_time.diff(
            groups[group][i].start_time,
            "minutes"
          ) <=
          margin * 2
        ) {
          groups[group].splice(i, 1);
          continue;
        }
        groups[group][i].start_time = groups[group][i].start_time.add(
          margin,
          "minutes"
        );
        groups[group][i].end_time = groups[group][i].end_time.subtract(
          margin,
          "minutes"
        );
        groups[group][i].range = moment.range(
          groups[group][i].start_time,
          groups[group][i].end_time
        );
      }

      // Remove gaps smaller than 5 hours
      if (
        groups[group][i].end_time.diff(
          groups[group][i].start_time,
          "minutes"
        ) <
        5 * 60
      ) {
        groups[group].splice(i, 1);
        continue;
      }

      // Remove gaps overnight or with not enough consecutive day hours
      if (
        groups[group][i].start_time.hour() >= 14 &&
        groups[group][i].end_time.diff(
          groups[group][i].start_time,
          "minutes"
        ) <=
        21 * 60
      ) {
        groups[group].splice(i, 1);
        continue;
      }
      if (
        groups[group][i].start_time.hour() >= 12 &&
        groups[group][i].end_time.diff(
          groups[group][i].start_time,
          "minutes"
        ) <=
        6 * 60
      ) {
        groups[group].splice(i, 1);
        continue;
      }
    }
  }

  return groups;
};

const updateBookings = (state, bookings) => {
  let expanded_ids = [];

  for (var i = 0; i < state.all_bookings.length; i++)
    if (state.all_bookings[i]["expanded"])
      expanded_ids.push(state.all_bookings[i]["booking_info"]["ID"]);

  state.all_bookings = bookings;

  for (var i = 0; i < state.all_bookings.length; i++) {
    state.all_bookings[i] = {
      booking_info: state.all_bookings[i],
      expanded: expanded_ids.includes(state.all_bookings[i].ID) ? true : false,
    };
  }

  let overlapped_bookings_num = 0

  for (var i = 0; i < state.cars.length; i++) {

    let car_bookings = state.all_bookings.filter((booking) => { return booking.booking_info.Car_Plate === state.cars[i].Plate })

    car_bookings.sort((a, b) => {
      let a_date_time = moment(a["booking_info"].Start_Date + " " + a["booking_info"].Start_Time, "DD-MM-YYYY HH:mm")
      let b_date_time = moment(b["booking_info"].Start_Date + " " + b["booking_info"].Start_Time, "DD-MM-YYYY HH:mm")

      if (!b_date_time.isValid())
        return -1;
      else if (!a_date_time.isValid())
        return 1;
      else if (a_date_time.isAfter(b_date_time, "minutes"))
        return 1;
      else return -1;

    });

    let now = moment()

    for (var j = 0; j < car_bookings.length - 1; j++) {
      let first_date_time = moment(car_bookings[j]["booking_info"].End_Date + " " + car_bookings[j]["booking_info"].End_Time, "DD-MM-YYYY HH:mm")
      let second_date_time = moment(car_bookings[j + 1]["booking_info"].Start_Date + " " + car_bookings[j + 1]["booking_info"].Start_Time, "DD-MM-YYYY HH:mm")

      if (first_date_time.isAfter(second_date_time, "minutes") && (first_date_time.isAfter(now) || second_date_time.isAfter(now))) {
        overlapped_bookings_num += 1
        console.log(car_bookings[j]["booking_info"])
        console.log(car_bookings[j + 1]["booking_info"])
      }
    }
  }

  state.overlapped_bookings_num = overlapped_bookings_num
};

const updateDisplayedBookings = (state) => {
  filterBookings(state, state.filters);
  applySorting(state, state.sort_by);
};

const updateAddBookingSection = (state, info = state.add_booking_section) => {
  let brands = [];
  let models = [];
  let plates = [];
  let cars = cloneDeep(state.cars);

  // Prepare
  let plate_changed =
    info["car_plate"] !== state.add_booking_section.car_plate ? true : false;
  state.add_booking_section = info;
  let current_start = moment(
    state.add_booking_section.start_date,
    "YYYY-MM-DDTHH:mm"
  );
  let current_end = moment(
    state.add_booking_section.end_date,
    "YYYY-MM-DDTHH:mm"
  );
  //let available_same_day = state.add_booking_section.available_same_day;
  let max_overlap = 0;

  // If dates are selected, find overlapped ones
  if (
    current_start.isValid() &&
    current_end.isValid() &&
    current_start.isBefore(current_end, "minute")
  ) {
    state.add_booking_tbd_dates_num = 0;

    let overlapped_bookings = state.all_bookings.filter((booking) => {
      let booking_start = moment(
        booking.booking_info.Start_Date + "T" + booking.booking_info.Start_Time,
        "DD-MM-YYYYTHH:mm"
      );
      let booking_end = moment(
        booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
        "DD-MM-YYYYTHH:mm"
      );

      if (!booking_start.isValid() || !booking_end.isValid()) {
        state.add_booking_tbd_dates_num += 1;
        return false;
      }

      let booking_range = moment.range(booking_start, booking_end);
      let current_range = moment.range(current_start, current_end);

      let gap_before = moment
        .duration(current_start.diff(booking_end))
        .asMinutes();
      let gap_after = moment
        .duration(booking_start.diff(current_end))
        .asMinutes();

      if (
        Math.abs(gap_before) <= max_overlap ||
        Math.abs(gap_after) <= max_overlap
      ) {
        return false;
      } else if (current_range.overlaps(booking_range)) return true;
      else return false;
    });

    // Remove unavailable cars
    //let num_tbd_cars = 0;
    for (var i = 0; i < overlapped_bookings.length; i++) {
      if (!overlapped_bookings[i].booking_info.Car_Plate) continue//num_tbd_cars += 1;
      else {
        for (var j = cars.length - 1; j > -1; j--) {
          if (cars[j].Plate === overlapped_bookings[i].booking_info.Car_Plate) {
            cars.splice(j, 1);
            break;
          }
        }
      }
    }
    //state.add_booking_tbd_cars_num = num_tbd_cars;
  } //else state.add_booking_tbd_cars_num = 0;

  // Count TBD dates
  state.add_booking_tbd_dates_num = 0;
  for (var i = 0; i < state.all_bookings.length; i++) {
    let booking = state.all_bookings[i];
    let booking_start = moment(
      booking.booking_info.Start_Date + "T" + booking.booking_info.Start_Time,
      "DD-MM-YYYYTHH:mm"
    );
    let booking_end = moment(
      booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
      "DD-MM-YYYYTHH:mm"
    );

    if (!booking_start.isValid() || !booking_end.isValid())
      state.add_booking_tbd_dates_num += 1;
  }

  // Count TBD cars
  state.add_booking_tbd_cars_num = 0
  for (var i = 0; i < state.all_bookings.length; i++) {
    if (!state.all_bookings[i].booking_info.Car_Plate) state.add_booking_tbd_cars_num += 1;
  }


  // Filter brands
  let current_brand = state.add_booking_section.car_brand;

  if (current_brand !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Brand !== current_brand) cars.splice(i, 1);
  }

  // Filter models
  let current_model = state.add_booking_section.car_model;

  if (current_model !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Model !== current_model) cars.splice(i, 1);
  }

  // Filter plates
  let current_plate = state.add_booking_section.car_plate;

  if (current_plate !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Plate !== current_plate) cars.splice(i, 1);
  }

  // If dates are selected, sort the plates by the gaps
  if (
    current_start.isValid() &&
    current_end.isValid() &&
    current_start.isBefore(current_end, "minute")
  ) {
    for (var i = 0; i < cars.length; i++) {
      if (!brands.includes(cars[i].Brand)) brands.push(cars[i].Brand);
      if (!models.includes(cars[i].Model)) models.push(cars[i].Model);
      if (!plates.includes(cars[i].Plate)) {
        let plate = cars[i].Plate;

        let closest_booking_before = null;
        let closest_booking_after = null;
        let closest_difference_before = Infinity;
        let closest_difference_after = Infinity;

        for (var j = 0; j < state.all_bookings.length; j++) {
          let booking = state.all_bookings[j];

          if (booking.booking_info.Car_Plate !== plate) continue;

          let booking_start = moment(
            booking.booking_info.Start_Date +
            "T" +
            booking.booking_info.Start_Time,
            "DD-MM-YYYYTHH:mm"
          );
          let booking_end = moment(
            booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );
          if (!booking_start.isValid() || !booking_end.isValid()) continue;

          if (booking_end.isBefore(current_start, "minute")) {
            let difference = moment
              .duration(current_start.diff(booking_end))
              .asMinutes();
            if (difference < closest_difference_before) {
              closest_difference_before = difference;
              closest_booking_before = booking;
            }
          } else if (booking_start.isAfter(current_end, "minute")) {
            let difference = moment
              .duration(booking_start.diff(current_end))
              .asMinutes();
            if (difference < closest_difference_after) {
              closest_difference_after = difference;
              closest_booking_after = booking;
            }
          } else {
            let gap_before = Math.abs(
              moment.duration(current_start.diff(booking_end)).asMinutes()
            );
            let gap_after = Math.abs(
              moment.duration(booking_start.diff(current_end)).asMinutes()
            );

            if (Math.abs(gap_before) <= max_overlap) {
              if (gap_before < closest_difference_before) {
                closest_difference_before = gap_before;
                closest_booking_before = booking;
              }
            } else if (Math.abs(gap_after) <= max_overlap) {
              if (gap_after < closest_difference_after) {
                closest_difference_after = gap_after;
                closest_booking_after = booking;
              }
            }
          }
        }

        if (closest_booking_before !== null) {
          let booking_end = moment(
            closest_booking_before.booking_info.End_Date +
            "T" +
            closest_booking_before.booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );
          closest_difference_before = moment
            .duration(current_start.diff(booking_end))
            .asMinutes();
        }

        if (closest_booking_after !== null) {
          let booking_start = moment(
            closest_booking_after.booking_info.Start_Date +
            "T" +
            closest_booking_after.booking_info.Start_Time,
            "DD-MM-YYYYTHH:mm"
          );
          closest_difference_after = moment
            .duration(booking_start.diff(current_end))
            .asMinutes();
        }

        let in_gap = "";
        let out_gap = "";

        if (closest_booking_before !== null)
          in_gap = `Entra ${closest_booking_before.booking_info.End_Date} ${closest_booking_before.booking_info.End_Time}`;

        if (closest_booking_after !== null)
          out_gap = `Sai ${closest_booking_after.booking_info.Start_Date} ${closest_booking_after.booking_info.Start_Time}`;

        plates.push({
          brand: cars[i].Brand,
          plate: plate,
          model: cars[i].Model,
          group: cars[i].Group,
          transmission: cars[i].Transmission,
          in_gap: in_gap,
          out_gap: out_gap,
          in_difference: closest_difference_before,
          out_difference: closest_difference_after,
        });
      }
    }

    plates.sort((a, b) => (a.plate > b.plate ? 1 : -1));
    plates.sort((a, b) => {
      let a_closest =
        a.in_difference < a.out_difference ? a.in_difference : a.out_difference;
      let b_closest =
        b.in_difference < b.out_difference ? b.in_difference : b.out_difference;

      if (b_closest < 0) return -1;

      if (a_closest < 0) return 1;

      if (b_closest === Infinity && a_closest !== Infinity) return -1;

      if (a_closest === Infinity && b_closest !== Infinity) return 1;

      if (a_closest <= b_closest && a_closest !== Infinity) return -1;

      if (a_closest > b_closest && b_closest !== Infinity) return 1;

      if (a.plate > b.plate) return 1;
      else return -1;
    });
  }
  // Add all the options
  else {
    for (var i = 0; i < cars.length; i++) {
      if (!brands.includes(cars[i].Brand)) brands.push(cars[i].Brand);
      if (!models.includes(cars[i].Model)) models.push(cars[i].Model);
      if (!plates.includes(cars[i].Plate))
        plates.push({
          brand: cars[i].Brand,
          plate: cars[i].Plate,
          model: cars[i].Model,
          group: cars[i].Group,
          transmission: cars[i].Transmission,
          in_gap: "",
          out_gap: "",
          in_difference: 0,
          out_difference: 0,
        });
    }

    plates.sort((a, b) => (a.plate > b.plate ? 1 : -1));
  }

  state.add_booking_car_brand_options = brands;
  state.add_booking_car_model_options = models;
  state.add_booking_car_plate_options = plates;

  if (
    plates.length === 1 &&
    plate_changed &&
    state.add_booking_section["car_plate"] !== ""
  ) {
    state.add_booking_section["car_brand"] = brands[0];
    state.add_booking_section["car_model"] = models[0];
    state.add_booking_section["car_plate"] = plates[0].plate;
  }
};

const updateEditBookingSection = (state, info = state.edit_booking_section) => {
  let brands = [];
  let models = [];
  let plates = [];
  let cars = cloneDeep(state.cars);

  // Prepare
  let plate_changed =
    info["car_plate"] !== state.edit_booking_section.car_plate ? true : false;
  state.edit_booking_section = info;

  let current_start = null;
  let current_end = null;

  if (
    state.edit_booking_section.start_date &&
    state.edit_booking_section.start_date.includes("T")
  )
    current_start = moment(
      state.edit_booking_section.start_date,
      "YYYY-MM-DDTHH:mm"
    );
  else if (
    state.edit_booking_section.start_date &&
    state.edit_booking_section.start_time
  ) {
    current_start = moment(
      state.edit_booking_section.start_date +
      "T" +
      state.edit_booking_section.start_time,
      "DD-MM-YYYYTHH:mm"
    );
  }

  if (
    state.edit_booking_section.end_date &&
    state.edit_booking_section.end_date.includes("T")
  )
    current_end = moment(
      state.edit_booking_section.end_date,
      "YYYY-MM-DDTHH:mm"
    );
  else if (
    state.edit_booking_section.end_date &&
    state.edit_booking_section.end_time
  ) {
    current_end = moment(
      state.edit_booking_section.end_date +
      "T" +
      state.edit_booking_section.end_time,
      "DD-MM-YYYYTHH:mm"
    );
  }

  //let available_same_day = state.edit_booking_section.available_same_day;
  let max_overlap = 0;

  // If dates are selected, find overlapped ones
  if (
    current_start &&
    current_end &&
    current_start.isValid() &&
    current_end.isValid() &&
    current_start.isBefore(current_end, "minute")
  ) {
    state.edit_booking_tbd_dates_num = 0;

    let overlapped_bookings = state.all_bookings.filter((booking) => {
      let booking_start = moment(
        booking.booking_info.Start_Date + "T" + booking.booking_info.Start_Time,
        "DD-MM-YYYYTHH:mm"
      );
      let booking_end = moment(
        booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
        "DD-MM-YYYYTHH:mm"
      );

      if (!booking_start.isValid() || !booking_end.isValid()) {
        state.edit_booking_tbd_dates_num += 1;
        return false;
      }

      let booking_range = moment.range(booking_start, booking_end);
      let current_range = moment.range(current_start, current_end);

      let gap_before = moment
        .duration(current_start.diff(booking_end))
        .asMinutes();
      let gap_after = moment
        .duration(booking_start.diff(current_end))
        .asMinutes();

      if (
        Math.abs(gap_before) <= max_overlap ||
        Math.abs(gap_after) <= max_overlap
      ) {
        return false;
      } else if (current_range.overlaps(booking_range)) {
        return true;
      } else return false;
    });

    // Remove unavailable cars
    for (var i = 0; i < overlapped_bookings.length; i++) {
      if (
        overlapped_bookings[i].booking_info.ID === state.edit_booking_section.id
      )
        continue;

      if (!overlapped_bookings[i].booking_info.Car_Plate) continue
      else {
        for (var j = cars.length - 1; j > -1; j--) {
          if (cars[j].Plate === overlapped_bookings[i].booking_info.Car_Plate) {
            cars.splice(j, 1);
            break;
          }
        }
      }
    }
    //state.edit_booking_tbd_cars_num = num_tbd_cars;
  } //else state.edit_booking_tbd_cars_num = 0;

  // Count TBD dates
  state.edit_booking_tbd_dates_num = 0;
  for (var i = 0; i < state.all_bookings.length; i++) {
    let booking = state.all_bookings[i];
    let booking_start = moment(
      booking.booking_info.Start_Date + "T" + booking.booking_info.Start_Time,
      "DD-MM-YYYYTHH:mm"
    );
    let booking_end = moment(
      booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
      "DD-MM-YYYYTHH:mm"
    );

    if (!booking_start.isValid() || !booking_end.isValid())
      state.edit_booking_tbd_dates_num += 1;
  }

  // Count TBD cars
  state.edit_booking_tbd_cars_num = 0
  for (var i = 0; i < state.all_bookings.length; i++) {
    if (!state.all_bookings[i].booking_info.Car_Plate) state.edit_booking_tbd_cars_num += 1;
  }

  // Filter brands
  let current_brand = state.edit_booking_section.car_brand;

  if (current_brand !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Brand !== current_brand) cars.splice(i, 1);
  }

  // Filter models
  let current_model = state.edit_booking_section.car_model;

  if (current_model !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Model !== current_model) cars.splice(i, 1);
  }

  // Filter plates
  let current_plate = state.edit_booking_section.car_plate;

  if (current_plate !== "") {
    for (var i = cars.length - 1; i >= 0; i--)
      if (cars[i].Plate !== current_plate) cars.splice(i, 1);
  }

  // If dates are selected, sort the plates by the gaps
  if (
    current_start &&
    current_end &&
    current_start.isValid() &&
    current_end.isValid() &&
    current_start.isBefore(current_end, "minute")
  ) {
    for (var i = 0; i < cars.length; i++) {
      if (!brands.includes(cars[i].Brand)) brands.push(cars[i].Brand);
      if (!models.includes(cars[i].Model)) models.push(cars[i].Model);
      if (!plates.includes(cars[i].Plate)) {
        let plate = cars[i].Plate;

        let closest_booking_before = null;
        let closest_booking_after = null;
        let closest_difference_before = Infinity;
        let closest_difference_after = Infinity;

        for (var j = 0; j < state.all_bookings.length; j++) {
          let booking = state.all_bookings[j];

          if (booking.booking_info.Car_Plate !== plate) continue;

          let booking_start = moment(
            booking.booking_info.Start_Date +
            "T" +
            booking.booking_info.Start_Time,
            "DD-MM-YYYYTHH:mm"
          );
          let booking_end = moment(
            booking.booking_info.End_Date + "T" + booking.booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );
          if (!booking_start.isValid() || !booking_end.isValid()) continue;

          if (booking_end.isBefore(current_start, "minute")) {
            let difference = moment
              .duration(current_start.diff(booking_end))
              .asMinutes();
            if (difference < closest_difference_before) {
              closest_difference_before = difference;
              closest_booking_before = booking;
            }
          } else if (booking_start.isAfter(current_end, "minute")) {
            let difference = moment
              .duration(booking_start.diff(current_end))
              .asMinutes();
            if (difference < closest_difference_after) {
              closest_difference_after = difference;
              closest_booking_after = booking;
            }
          } else {
            let gap_before = Math.abs(
              moment.duration(current_start.diff(booking_end)).asMinutes()
            );
            let gap_after = Math.abs(
              moment.duration(booking_start.diff(current_end)).asMinutes()
            );

            if (Math.abs(gap_before) <= max_overlap) {
              if (gap_before < closest_difference_before) {
                closest_difference_before = gap_before;
                closest_booking_before = booking;
              }
            } else if (Math.abs(gap_after) <= max_overlap) {
              if (gap_after < closest_difference_after) {
                closest_difference_after = gap_after;
                closest_booking_after = booking;
              }
            }
          }
        }

        if (closest_booking_before !== null) {
          let booking_end = moment(
            closest_booking_before.booking_info.End_Date +
            "T" +
            closest_booking_before.booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );
          closest_difference_before = moment
            .duration(current_start.diff(booking_end))
            .asMinutes();
        }

        if (closest_booking_after !== null) {
          let booking_start = moment(
            closest_booking_after.booking_info.Start_Date +
            "T" +
            closest_booking_after.booking_info.Start_Time,
            "DD-MM-YYYYTHH:mm"
          );
          closest_difference_after = moment
            .duration(booking_start.diff(current_end))
            .asMinutes();
        }

        let in_gap = "";
        let out_gap = "";

        if (closest_booking_before !== null)
          in_gap = `Entra ${closest_booking_before.booking_info.End_Date} ${closest_booking_before.booking_info.End_Time}`;

        if (closest_booking_after !== null)
          out_gap = `Sai ${closest_booking_after.booking_info.Start_Date} ${closest_booking_after.booking_info.Start_Time}`;

        plates.push({
          brand: cars[i].Brand,
          plate: plate,
          model: cars[i].Model,
          group: cars[i].Group,
          transmission: cars[i].Transmission,
          in_gap: in_gap,
          out_gap: out_gap,
          in_difference: closest_difference_before,
          out_difference: closest_difference_after,
        });
      }
    }

    plates.sort((a, b) => (a.plate > b.plate ? 1 : -1));
    plates.sort((a, b) => {
      let a_closest =
        a.in_difference < a.out_difference ? a.in_difference : a.out_difference;
      let b_closest =
        b.in_difference < b.out_difference ? b.in_difference : b.out_difference;

      if (b_closest < 0) return -1;

      if (a_closest < 0) return 1;

      if (b_closest === Infinity && a_closest !== Infinity) return -1;

      if (a_closest === Infinity && b_closest !== Infinity) return 1;

      if (a_closest <= b_closest && a_closest !== Infinity) return -1;

      if (a_closest > b_closest && b_closest !== Infinity) return 1;

      if (a.plate > b.plate) return 1;
      else return -1;
    });
  }
  // Add all the options
  else {
    for (var i = 0; i < cars.length; i++) {
      if (!brands.includes(cars[i].Brand)) brands.push(cars[i].Brand);
      if (!models.includes(cars[i].Model)) models.push(cars[i].Model);
      if (!plates.includes(cars[i].Plate))
        plates.push({
          brand: cars[i].Brand,
          plate: cars[i].Plate,
          model: cars[i].Model,
          group: cars[i].Group,
          transmission: cars[i].Transmission,
          in_gap: "",
          out_gap: "",
          in_difference: 0,
          out_difference: 0,
        });
    }

    plates.sort((a, b) => (a > b ? 1 : -1));
  }

  state.edit_booking_car_brand_options = brands;
  state.edit_booking_car_model_options = models;
  state.edit_booking_car_plate_options = plates;

  if (
    plates.length === 1 &&
    plate_changed &&
    state.edit_booking_section["car_plate"] !== ""
  ) {
    state.edit_booking_section["car_brand"] = brands[0];
    state.edit_booking_section["car_model"] = models[0];
    state.edit_booking_section["car_plate"] = plates[0].plate;
  }
};

const expand_booking = (state, ID) => {
  for (var i = 0; i < state["displayed_bookings"].length; i++) {
    if (state["displayed_bookings"][i]["booking_info"]["ID"] === ID) {
      state["displayed_bookings"][i]["expanded"] =
        !state["displayed_bookings"][i]["expanded"];
      break;
    }
  }

  for (var i = 0; i < state["all_bookings"].length; i++) {
    if (state["all_bookings"][i]["booking_info"]["ID"] === ID) {
      state["all_bookings"][i]["expanded"] =
        !state["all_bookings"][i]["expanded"];
      break;
    }
  }
};

const expand_all_bookings = (state) => {
  for (var i = 0; i < state["displayed_bookings"].length; i++)
    state["displayed_bookings"][i]["expanded"] = true;
  for (var i = 0; i < state["all_bookings"].length; i++)
    state["all_bookings"][i]["expanded"] = true;
};

const collapse_all_bookings = (state) => {
  for (var i = 0; i < state["displayed_bookings"].length; i++)
    state["displayed_bookings"][i]["expanded"] = false;
  for (var i = 0; i < state["all_bookings"].length; i++)
    state["all_bookings"][i]["expanded"] = false;
};

const filterBookings = (state, filters) => {
  state.filters = filters;

  let filtered_bookings = cloneDeep(state.all_bookings);

  // Client Info
  let content = filters["client_info"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (
        !booking["Client_Name"].toLowerCase().includes(content) &&
        !booking["Client_Email"].toLowerCase().includes(content) &&
        !booking["Client_Phone"].toLowerCase().includes(content) &&
        !booking["Client_Address"].toLowerCase().includes(content)
      )
        filtered_bookings.splice(i, 1);
    }
  }

  // Only Start Date
  if (filters["start_date"] !== "" && filters["end_date"] === null) {
    content = filters["start_date"];
    if (content !== "" && content !== null) {
      content = moment(content);
      for (var i = filtered_bookings.length - 1; i > -1; i--) {
        let booking = filtered_bookings[i]["booking_info"];
        let booking_start_date = moment(booking["Start_Date"], "DD/MM/YYYY");
        if (booking_start_date.isBefore(content, "days"))
          filtered_bookings.splice(i, 1);
      }
    }
  }
  // Only End Date
  else if (filters["end_date"] !== "" && filters["start_date"] === null) {
    content = filters["end_date"];
    if (content !== "" && content !== null) {
      content = moment(content);
      for (var i = filtered_bookings.length - 1; i > -1; i--) {
        let booking = filtered_bookings[i]["booking_info"];
        let booking_end_date = moment(booking["End_Date"], "DD/MM/YYYY");
        if (booking_end_date.isAfter(content, "days"))
          filtered_bookings.splice(i, 1);
      }
    }
  }
  // Between Start Date and End Date
  else {
    let start_date = moment(filters["start_date"]);
    let end_date = moment(filters["end_date"]);

    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      let booking_start_date = moment(booking["Start_Date"], "DD/MM/YYYY");
      let booking_end_date = moment(booking["End_Date"], "DD/MM/YYYY");
      if (
        !booking_start_date.isBetween(start_date, end_date, "days", "[]") ||
        !booking_end_date.isBetween(start_date, end_date, "days", "[]")
      )
        filtered_bookings.splice(i, 1);
    }
  }

  // Start Time
  content = filters["start_time"];
  if (content !== "" && content !== null) {
    content = content.toTimeString().split(" ")[0].split(":");
    content.pop();
    content = content.join(":");
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["Start_Time"].toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  // Start Local
  content = filters["start_local"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["Start_Local"].toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  // End Time
  content = filters["end_time"];
  if (content !== "" && content !== null) {
    content = content.toTimeString().split(" ")[0].split(":");
    content.pop();
    content = content.join(":");
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["End_Time"].toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  // End Local
  content = filters["end_local"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["End_Local"].toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  // Num Days
  content = filters["num_days"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["Num_Days"].toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  // Car Info
  content = filters["car_info"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (
        !booking["Car_Brand"].toLowerCase().includes(content) &&
        !booking["Car_Model"].toLowerCase().includes(content) &&
        !booking["Car_Plate"].toLowerCase().includes(content)
      )
        filtered_bookings.splice(i, 1);
    }
  }

  // ID
  content = filters["id"].toLowerCase();
  if (content !== "") {
    for (var i = filtered_bookings.length - 1; i > -1; i--) {
      let booking = filtered_bookings[i]["booking_info"];
      if (!booking["ID"].toString().toLowerCase().includes(content))
        filtered_bookings.splice(i, 1);
    }
  }

  state.displayed_bookings = filtered_bookings;
};

const sortBookings = (state, sort_by) => {
  if (sort_by === state.sort_by) {
    if (state.sort_direction === null) state.sort_direction = "ASCENDING";
    else if (state.sort_direction === "ASCENDING")
      state.sort_direction = "DESCENDING";
    else state.sort_direction = null;
  } else state.sort_direction = "ASCENDING";

  applySorting(state, sort_by);
};

const applySorting = (state, sort_by) => {
  if (sort_by !== null) state.sort_by = sort_by;

  if (state.sort_direction === null) {
    state.sort_by = "ID";
    state["displayed_bookings"].sort((a, b) =>
      a["booking_info"]["ID"] > b["booking_info"]["ID"] ? -1 : 1
    );
    return;
  }

  if (
    state.sort_by === "ID" ||
    state.sort_by === "Client_Name" ||
    state.sort_by === "Start_Local" ||
    state.sort_by === "End_Local" ||
    state.sort_by === "Car_Plate"
  ) {
    if (state.sort_direction === "ASCENDING")
      state["displayed_bookings"].sort((a, b) =>
        a["booking_info"][state.sort_by] > b["booking_info"][state.sort_by]
          ? 1
          : -1
      );
    else if (state.sort_direction === "DESCENDING")
      state["displayed_bookings"].sort((a, b) =>
        a["booking_info"][state.sort_by] > b["booking_info"][state.sort_by]
          ? -1
          : 1
      );
  } else if (state.sort_by === "Num_Days") {
    if (state.sort_direction === "ASCENDING")
      state["displayed_bookings"].sort((a, b) => {
        if (
          !isNaN(parseInt(a["booking_info"][state.sort_by])) &&
          !isNaN(parseInt(b["booking_info"][state.sort_by]))
        ) {
          if (
            parseInt(a["booking_info"][state.sort_by]) >
            parseInt(b["booking_info"][state.sort_by])
          )
            return 1;
          else return -1;
        } else if (
          a["booking_info"][state.sort_by] > b["booking_info"][state.sort_by]
        )
          return 1;
        else return -1;
      });
    else if (state.sort_direction === "DESCENDING")
      state["displayed_bookings"].sort((a, b) => {
        if (
          !isNaN(parseInt(a["booking_info"][state.sort_by])) &&
          !isNaN(parseInt(b["booking_info"][state.sort_by]))
        ) {
          if (
            parseInt(a["booking_info"][state.sort_by]) >
            parseInt(b["booking_info"][state.sort_by])
          )
            return -1;
          else return 1;
        } else if (
          a["booking_info"][state.sort_by] > b["booking_info"][state.sort_by]
        )
          return -1;
        else return 1;
      });
  } else if (state.sort_by === "Start_Date" || state.sort_by === "End_Date") {
    state["displayed_bookings"].sort((a, b) => {
      if (!moment(a["booking_info"][state.sort_by], "DD/MM/YYYY").isValid())
        return -1;
      else if (
        !moment(b["booking_info"][state.sort_by], "DD/MM/YYYY").isValid()
      )
        return 1;
      else {
        if (
          moment(a["booking_info"][state.sort_by], "DD/MM/YYYY").isAfter(
            moment(b["booking_info"][state.sort_by], "DD/MM/YYYY"),
            "days"
          )
        ) {
          if (state.sort_direction === "ASCENDING") return 1;
          else return -1;
        } else {
          if (state.sort_direction === "ASCENDING") return -1;
          else return 1;
        }
      }
    });
  } else if (state.sort_by === "Start_Time" || state.sort_by === "End_Time") {
    state["displayed_bookings"].sort((a, b) => {
      if (!moment(a["booking_info"][state.sort_by], "HH:mm").isValid())
        return -1;
      else if (!moment(b["booking_info"][state.sort_by], "HH:mm").isValid())
        return 1;
      else {
        if (
          moment(a["booking_info"][state.sort_by], "HH:mm").isAfter(
            moment(b["booking_info"][state.sort_by], "HH:mm"),
            "minutes"
          )
        ) {
          if (state.sort_direction === "ASCENDING") return 1;
          else return -1;
        } else {
          if (state.sort_direction === "ASCENDING") return -1;
          else return 1;
        }
      }
    });
  }
};

const BookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BOOKINGS":
      updateBookings(state, action.payload);
      updateDisplayedBookings(state);
      updateAddBookingSection(state);
      if (state.edit_booking_popup_open !== -1) updateEditBookingSection(state);
      return {
        ...state,
        all_bookings: state.all_bookings,
        displayed_bookings: state.displayed_bookings,
      };

    case "UPDATE_GAPS":
      let gaps = updateGaps(state);
      state.gaps = gaps
      return {
        ...state,
        gaps: state.gaps,
      };

    case "UPDATE_CARS":
      state.cars = action.payload;
      updateAddBookingSection(state);
      updateEditBookingSection(state);
      return { ...state, cars: state.cars };

    case "UPDATE_ADD_BOOKING_SECTION": {
      updateAddBookingSection(state, action.payload);
      return { ...state, add_booking_section: state.add_booking_section };
    }

    case "UPDATE_EDIT_BOOKING_SECTION": {
      updateEditBookingSection(state, action.payload);
      return { ...state, edit_booking_section: state.edit_booking_section };
    }

    case "EXPAND_ADD_BOOKING": {
      return { ...state, add_booking_expanded: !state.add_booking_expanded };
    }

    case "EXPAND_BOOKING": {
      expand_booking(state, action.payload);

      return { ...state };
    }

    case "EXPAND_ALL_BOOKINGS": {
      expand_all_bookings(state);
      return { ...state };
    }

    case "COLLAPSE_ALL_BOOKINGS": {
      collapse_all_bookings(state);
      return state;
    }

    case "SORT_BOOKINGS": {
      sortBookings(state, action.payload);
      return { ...state, displayed_bookings: state.displayed_bookings };
    }

    case "FILTER_BOOKINGS": {
      filterBookings(state, action.payload);
      applySorting(state, state.sort_by);
      return {
        ...state,
        filters: state.filters,
        displayed_bookings: state.displayed_bookings,
      };
    }

    case "OPEN_EDIT_BOOKING_POPUP": {
      return { ...state, edit_booking_popup_open: action.payload };
    }

    case "CLOSE_EDIT_BOOKING_POPUP": {
      let edit_booking_section = {
        client_name: "",
        client_email: "",
        client_phone: "",
        client_address: "",
        start_date: null,
        start_time: null,
        start_local: "",
        end_date: null,
        end_time: null,
        end_local: "",
        num_days: "",
        car_brand: "",
        car_model: "",
        car_plate: "",
        car_group: "",
        price: "",
        deposit: "",
        insurance: false,
        observations: "",
        added_date: "",
        modified_date: "",
        id: "",
      };
      updateEditBookingSection(state, edit_booking_section);
      return {
        ...state,
        edit_booking_popup_open: -1,
        edit_booking_section: state.edit_booking_section,
      };
    }

    case "OPEN_DELETE_BOOKING_POPUP": {
      return { ...state, delete_booking_popup_open: action.payload };
    }

    case "CLOSE_DELETE_BOOKING_POPUP": {
      return { ...state, delete_booking_popup_open: -1 };
    }

    case "TOGGLE_EDIT_BOOKINGS_MODE": {
      return { ...state, edit_bookings: !state.edit_bookings };
    }

    case "DISABLE_EDIT_BOOKINGS_MODE": {
      return { ...state, edit_bookings: false };
    }

    default:
      return state;
  }
};

export default BookingsReducer;
