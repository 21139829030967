const confirmation_email_en = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Booking Confirmation</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    @media screen and (max-width: 600px) {
      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
</head>
<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <center><h2>Booking Confirmation - Reservation ID-XXX</h2></center>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <p>
              Hello XXX,<br><br>
              Thank you for choosing us for your car rental needs in Madeira! We are pleased to inform you that your requested dates and vehicle are available. Your booking with Auto Rent-a-Car is confirmed, and its details are as follows:
            </p>
            <!-- Reservation Details -->
            <p>
              <strong>Reservation Details:</strong><br>
              - Pick-up date: XXX<br>
              - Pick-up place: XXX<br>
              - Return date: XXX<br>
              - Return place: XXX
            </p>
            <!-- Car details -->
            <p>
              <strong>Car details:</strong><br>
              - Number of cars: XXX<br>
              - Car type: Group XXX (XXX transmission - XXX)<br>
              - Number of extra drivers: XXX<br>
              - Toddler chair (0-3 years): XXX<br>
              - Infant chair (4-7 years): XXX<br>
              - Booster seat (8-12 years): XXX<br>
              - Insurance: XXX<br>
            </p>
            </p>
            <!-- Pricing and Deposit -->
            <p>
              <strong>Full Rental Price (Cash/Debit card/Credit card):</strong> XXX<br>
              <strong>Deposit (Cash/VISA Credit card):</strong> XXX
            </p>
            </p>
            <!-- Additional Information -->
            <br>
            <p style="text-align: justify">
              Regarding payment, it is only done at the time of pick-up. We accept payments in cash (Euro), and by major credit and debit cards (except American Express).
              We ask for the deposit in cash, as it is easier to return to you at the end of the rental period. As a last resort, it is possible with a VISA credit card.
              <br></br>
              We now kindly request some further information, such as flight number, the number of people arriving, and your accommodation details in Madeira. Thank you in advance for your cooperation!
              <br></br>
              We now kindly request you inform us of your cruise ship’s name. We will be ready to welcome you at the cruise ship terminal, identifiable by our logo and a board with your name. Thank you in advance for your cooperation!
              <br></br>
              We have all the required information on our end. Please confirm if everything looks good to you. If you have any further questions or need assistance, also feel free to contact us. Thank you, and we look forward to meeting you soon!
            </p>
            <br>
            <!-- Closing Part -->
            <p>
              Best regards,<br>
              XXX<br><br>
              Auto Rent-a-Car<br>
              +351 918888307 (also on WhatsApp)<br>
              www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. All rights reserved.
          </p>
        </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default confirmation_email_en;