export const locations = [
  { label: "29 Madeira Hostel" },
  { label: "A&M House II Luxury Holidays Home" },
  { label: "ASPA - Alegria Studios" },
  { label: "Adega da Quinta Restaurant" },
  { label: "Aeroporto" },
  { label: "Aeroporto/Escritório" },
  { label: "Albatroz Beach & Yacht Club" },
  { label: "Albergaria Dias" },
  { label: "Albergaria O Professor" },
  { label: "Allegro Madeira" },
  { label: "Alojamento Local" },
  { label: "Apart-Hotel Vale Mar" },
  { label: "Apartamentos Turísticos Paraíso Dourado" },
  { label: "Apartamentos do Mar" },
  { label: "Aparthotel Imperatriz" },
  { label: "Atlantic Gardens Apart Hotel" },
  { label: "Boutique Hotel" },
  { label: "CASAS MARE" },
  { label: "CBA suites Madeira" },
  { label: "Calheta Beach Resort" },
  { label: "Canico Bay apartments" },
  { label: "Caniço Bay Club" },
  { label: "Casa Branca Madeira" },
  { label: "Casa Cor de Rosa" },
  { label: "Casa Velha Do Palheiro" },
  { label: "Casa da Capelinha" },
  { label: "Casa da Piedade" },
  { label: "Casa da Tia Clementina" },
  { label: "Casa das Videiras" },
  { label: "Casa do Caseiro" },
  { label: "Casa do Ribeirinho" },
  { label: "Casa do Ribeiro Frio" },
  { label: "Casa do Til" },
  { label: "Castanheiro Boutique Hotel" },
  { label: "Colina da Fajã" },
  { label: "Divino's Hostel" },
  { label: "Dom Pedro Madeira Hotel" },
  { label: "Dorisol Buganvilia" },
  { label: "Dorisol Estrelicia Hotel" },
  { label: "Dorisol Florasol Hotel" },
  { label: "Dorisol Mimosa" },
  { label: "Enotel Quinta do Sol" },
  { label: "Escritório" },
  { label: "Escritório/Aeroporto" },
  { label: "Esmeralda Holidays Apartments" },
  { label: "Estalagem Corte do Norte" },
  { label: "Estalagem Eira do Serrado" },
  { label: "Estalagem Encumeada" },
  { label: "Estalagem da Ponta do Sol" },
  { label: "Estalagem do Mar" },
  { label: "Estalagem do Vale" },
  { label: "FX Hostel" },
  { label: "FX Hostel & Guest House Carvalhal" },
  { label: "Five Design Rooftop" },
  { label: "Four Views Monumental" },
  { label: "Four Views Oasis" },
  { label: "Funchal" },
  { label: "Funchal Design Hotel" },
  { label: "Funchal Village Studios" },
  { label: "Golden Residence Hotel" },
  { label: "Hospedagem Casa do Largo" },
  { label: "Hostel Casal São João" },
  { label: "Hostel Santa Maria Funchal" },
  { label: "Hotel" },
  { label: "Hotel Alto Lido" },
  { label: "Hotel Apartamento Da Sé" },
  { label: "Hotel Apartamentos Baia Brava" },
  { label: "Hotel Apartamentos Reno" },
  { label: "Hotel Aqua Natura Madeira" },
  { label: "Hotel Atrio" },
  { label: "Hotel Baía Azul" },
  { label: "Hotel Cais da Oliveira" },
  { label: "Hotel Caju" },
  { label: "Hotel Calamar" },
  { label: "Hotel Catedral" },
  { label: "Hotel Conde Carvalhal" },
  { label: "Hotel Costa Linda" },
  { label: "Hotel DFM Happy Days" },
  { label: "Hotel Da Vila" },
  { label: "Hotel Duas Torres" },
  { label: "Hotel Enotel Lido" },
  { label: "Hotel Escola" },
  { label: "Hotel Euro Moniz" },
  { label: "Hotel Girassol" },
  { label: "Hotel Gordon" },
  { label: "Hotel Inn Art Gallery" },
  { label: "Hotel Jardim do Mar" },
  { label: "Hotel Madeira" },
  { label: "Hotel Madeira Panorâmico" },
  { label: "Hotel Monte Carlo Madeira" },
  { label: "Hotel Ondamar" },
  { label: "Hotel Orca Praia" },
  { label: "Hotel Orquídea" },
  { label: "Hotel Porto Mare" },
  { label: "Hotel Porto Santa Maria" },
  { label: "Hotel PortoBay Serra Golf" },
  { label: "Hotel Praia Dourada" },
  { label: "Hotel Praia Formosa" },
  { label: "Hotel Quinta Alegre" },
  { label: "Hotel Quinta da Serra" },
  { label: "Hotel Quinta do Furão" },
  { label: "Hotel Riu Palace Madeira" },
  { label: "Hotel Salgueiro" },
  { label: "Hotel Solar Bom Jesus" },
  { label: "Hotel The Cliff Bay" },
  { label: "Hotel The Residence" },
  { label: "Hotel Torre Praia" },
  { label: "Hotel Vila Galé Santa Cruz" },
  { label: "Hotel Windsor" },
  { label: "Hotel do Carmo" },
  { label: "Hotel do Centro" },
  { label: "Hotel-Rural Estalagem A Quinta" },
  { label: "Inn & Art Madeira" },
  { label: "Jaca Hostel" },
  { label: "Luamar" },
  { label: "Madeira Bright Star" },
  { label: "Madeira Palacio Resort Hotel" },
  { label: "Madeira Regency Cliff" },
  { label: "Madeira Regency Club" },
  { label: "Meliá Madeira Mare" },
  { label: "Molhe Hotel - Conde Carvalhal" },
  { label: "Monte Mar Palace" },
  { label: "Muthu Raga Madeira Hotel" },
  { label: "NEXT Hotel" },
  { label: "North Coast Guest House" },
  { label: "Ocean Gardens Hotel" },
  { label: "Palace of Zinos" },
  { label: "Paul do Mar Aparthotel" },
  { label: "Pensão Astoria" },
  { label: "Pensão Residencial Mirasol" },
  { label: "Penthouse canico de baixo" },
  { label: "Perola Views Inn" },
  { label: "Pestana CR7 Funchal" },
  { label: "Pestana Carlton Madeira" },
  { label: "Pestana Casino Park" },
  { label: "Pestana Casino Studios" },
  { label: "Pestana Churchill Bay" },
  { label: "Pestana Colombos" },
  { label: "Pestana Gardens" },
  { label: "Pestana Grand" },
  { label: "Pestana Madeira Beach Club" },
  { label: "Pestana Miramar" },
  { label: "Pestana Ocean Bay" },
  { label: "Pestana Palms" },
  { label: "Pestana Promenade" },
  { label: "Pestana Quinta Do Arco" },
  { label: "Pestana Royal" },
  { label: "Pestana Village" },
  { label: "Pina Residencial" },
  { label: "Pontinha" },
  { label: "Porto Bay Hotels & Resorts" },
  { label: "Porto Mare Residence" },
  { label: "Quinta Bela Sao Tiago Hotel" },
  { label: "Quinta Da Cova Do Milho" },
  { label: "Quinta Das Vinhas" },
  { label: "Quinta Devónia" },
  { label: "Quinta Jardins do Lago" },
  { label: "Quinta Mirabela" },
  { label: "Quinta Mãe dos Homens" },
  { label: "Quinta Paços do Lago" },
  { label: "Quinta Perestrello" },
  { label: "Quinta Santo Antonio Da Serra" },
  { label: "Quinta Splendida" },
  { label: "Quinta da Bela Vista" },
  { label: "Quinta da Casa Branca" },
  { label: "Quinta da Penha" },
  { label: "Quinta da Penha de França" },
  { label: "Quinta da Saraiva" },
  { label: "Quinta das Colmeias" },
  { label: "Quinta das Vistas Palace Gardens" },
  { label: "Quinta do Alto de São João" },
  { label: "Quinta do Monte" },
  { label: "Quintinha São João Hotel & Spa" },
  { label: "Reid's Palace" },
  { label: "Residencial Amparo" },
  { label: "Residencial Calhau" },
  { label: "Residencial Colombo" },
  { label: "Residencial Família" },
  { label: "Residencial Funchal" },
  { label: "Residencial Greco" },
  { label: "Residencial Mariazinha" },
  { label: "Residencial Monumental" },
  { label: "Residencial Mónaco" },
  { label: "Residencial O Farol" },
  { label: "Residencial Santo António" },
  { label: "Residencial do Vale" },
  { label: "Ribeiro Frio Trout Farm" },
  { label: "Roca Mar Hotel" },
  { label: "Royal Savoy" },
  { label: "Résidence Palheiro Village" },
  { label: "Résidence Pierre & Vacances Travellers Pearl" },
  { label: "Santa Cruz Village Hotel" },
  { label: "Santana in Nature Bed & Breakfast" },
  { label: "Savoy Gardens" },
  { label: "Savoy Palace" },
  { label: "Savoy Saccharum Resort & Spa" },
  { label: "Sentido Galomar" },
  { label: "Sentido Galosol" },
  { label: "Solar Do Ribeiro" },
  { label: "Solar da Bica" },
  { label: "Solar de Boaventura" },
  { label: "Stay Inn Funchal" },
  { label: "Suite Hotel Eden Mar" },
  { label: "Sé Boutique Hotel" },
  { label: "Terrace Mar Suite Hotel" },
  { label: "The Flag House Madeira" },
  { label: "The Marketplace Apartments" },
  { label: "The Rum Inn" },
  { label: "The View Guesthouse" },
  { label: "The Vine Hotel" },
  { label: "The Wine Lodges" },
  { label: "Three House Hotel & Restaurant" },
  { label: "Tivoli Madeira" },
  { label: "Turim Santa Maria Hotel" },
  { label: "VidaMar Resort Hotel Madeira" },
  { label: "Vila Baleira Funchal" },
  { label: "Vila Baleira Resort" },
  { label: "Vila Flor do Campo" },
  { label: "Vila Lusitânia" },
  { label: "Vila Ventura" },
  { label: "Vila Vicência" },
  { label: "Villas Quinta da Lapa" },
  { label: "Vitorina Corte Guesthouse" },
  { label: "White Waters Hotel" },
];
