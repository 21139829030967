import React from "react";
import { connect } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import ListItem from "./ListItem";
import SearchBar from "./SearchItem";
import { Typography, Grid } from "@mui/material";
import { sortBookings } from "../States/Actions/Bookings";

export class List extends React.PureComponent {
  generate_list_items = (index) => {
    if (index === 0)
      return <SearchBar filters={this.props.bookings.filters}></SearchBar>;

    return (
      <ListItem
        key={this.props.bookings.displayed_bookings[index - 1].booking_info.ID}
        id={this.props.bookings.displayed_bookings[index - 1].booking_info.ID}
        booking={this.props.bookings.displayed_bookings[index - 1].booking_info}
        expanded={this.props.bookings.displayed_bookings[index - 1].expanded}
        edit_bookings={this.props.bookings.edit_bookings}
        snackbar={this.props.snackbar}
      />
    );
  };

  render() {
    return (
      <div>
        <Virtuoso
          key="bookings-list"
          totalCount={this.props.bookings.displayed_bookings.length}
          // itemsRendered={(x) => console.log(x[0].index, x[x.length - 1].index)}
          //overscan={{ main: 100 }}
          topItemCount={1}
          itemContent={this.generate_list_items}
          data={this.props.bookings.displayed_bookings.concat({
            expanded: false,
            booking_info: {
              Client_Name: "",
              Client_Email: "",
              Client_Phone: "",
              Client_Address: "",
              Start_Date: "",
              Start_Time: "",
              Start_Local: "",
              End_Date: "",
              End_Time: "",
              End_Local: "",
              Num_Days: "",
              Car_Brand: "",
              Car_Model: "",
              Car_Plate: "",
              Car_Group: "",
              Price: "",
              Deposit: "",
              Insurance: "",
              Observations: "",
              Added_Date: "",
              Modified_Date: "",
              ID: 0,
            },
          })}
          style={{
            height: this.props.height,
            boxShadow: "0px 0px 1px 1px #eeeeee",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
          components={{
            Header: () => {
              return (
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{
                    color: "#eeeeee",
                    textAlign: "center",
                    backgroundColor: "#2e3192",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    paddingTop: "5px",
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    xs={2.2}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Client_Name")}
                    >
                      {this.props.bookings.sort_by !== "Client_Name"
                        ? "Nome"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Nome ▲"
                        : "Nome ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.08}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Start_Date")}
                    >
                      {this.props.bookings.sort_by !== "Start_Date"
                        ? "Data Inicial"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Data Inicial ▲"
                        : "Data Inicial ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.08}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Start_Time")}
                    >
                      {this.props.bookings.sort_by !== "Start_Time"
                        ? "Hora Inicial"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Hora Inicial ▲"
                        : "Hora Inicial ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.56}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Start_Local")}
                    >
                      {this.props.bookings.sort_by !== "Start_Local"
                        ? "Local Inicial"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Local Inicial ▲"
                        : "Local Inicial ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.08}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("End_Date")}
                    >
                      {this.props.bookings.sort_by !== "End_Date"
                        ? "Data Final"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Data Final ▲"
                        : "Data Final ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.08}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("End_Time")}
                    >
                      {this.props.bookings.sort_by !== "End_Time"
                        ? "Hora Final"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Hora Final ▲"
                        : "Hora Final ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.56}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("End_Local")}
                    >
                      {this.props.bookings.sort_by !== "End_Local"
                        ? "Local Final"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Local Final ▲"
                        : "Local Final ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0.6}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Num_Days")}
                    >
                      {this.props.bookings.sort_by !== "Num_Days"
                        ? "Dias"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Dias ▲"
                        : "Dias ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0.9}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("Car_Plate")}
                    >
                      {this.props.bookings.sort_by !== "Car_Plate"
                        ? "Matrícula"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "Matrícula ▲"
                        : "Matrícula ▼ "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0.38}
                    style={{
                      borderRight: "1px solid #70719c",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 6,
                        },
                      }}
                      onClick={(e) => sortBookings("ID")}
                    >
                      {this.props.bookings.sort_by !== "ID"
                        ? "ID"
                        : this.props.bookings.sort_direction === "ASCENDING"
                        ? "ID ▲"
                        : this.props.bookings.sort_direction === "DESCENDING"
                        ? "ID ▼ "
                        : "ID"}
                    </Typography>
                  </Grid>
                </Grid>
              );
            },
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings,
  };
}

export default connect(mapStateToProps)(List);
