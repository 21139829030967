import cloneDeep from "lodash.clonedeep";

const initialState = {
  booking_requests: [],
  displayed_booking_requests: [],

  // Sort booking requests
  sort_by: "ID",
  sort_direction: null,

  // Filter booking requests
  filters: {
    text: "",
    new: 0,
    confirmed: 0,
    details: 0,
    ongoing: 0,
    commissioned: 0,
    problem: 0,
    cancelled: 0,
    archived: 0
  },
};

const filterBookingRequests = (state, filters) => {
  if (!filters)
    filters = {
      text: "",
      new: 0,
      confirmed: 0,
      details: 0,
      ongoing: 0,
      commissioned: 0,
      problem: 0,
      cancelled: 0,
      archived: 0
    }

  state.filters = filters;
  let filtered_booking_requests = cloneDeep(state.booking_requests);
  let content = filters.text.toLowerCase();
  for (var i = filtered_booking_requests.length - 1; i > -1; i--) {
    let req = filtered_booking_requests[i]

    if (
      content !== "" &&
      !req["Client_Name"].toLowerCase().includes(content) &&
      !req["Client_Age"].toLowerCase().includes(content) &&
      !req["Client_Email"].toLowerCase().includes(content) &&
      !req["Client_Phone"].toLowerCase().includes(content) &&
      !req["Language"].toLowerCase().includes(content) &&
      !req["Start_Date"].toLowerCase().includes(content) &&
      !req["Start_Time"].toLowerCase().includes(content) &&
      !req["Start_Local"].toLowerCase().includes(content) &&
      !req["End_Date"].toLowerCase().includes(content) &&
      !req["End_Time"].toLowerCase().includes(content) &&
      !req["End_Local"].toLowerCase().includes(content) &&
      !req["Submitted_Price"].toString().includes(content) &&
      !req["Submitted_Deposit"].toString().includes(content) &&
      !("Grupo " + req["Car_Group"]).toLowerCase().includes(content) &&
      !("id-" + req["ID"]).includes(content)
    ) filtered_booking_requests.splice(i, 1);
    else if (
      filters.new === 1 && !req.Status.split(";").includes("new") ||
      filters.new === -1 && req.Status.split(";").includes("new") ||
      filters.confirmed === 1 && !req.Status.split(";").includes("confirmed") ||
      filters.confirmed === -1 && req.Status.split(";").includes("confirmed") ||
      filters.details === 1 && !req.Status.split(";").includes("details") ||
      filters.details === -1 && req.Status.split(";").includes("details") ||
      filters.ongoing === 1 && !req.Status.split(";").includes("ongoing") ||
      filters.ongoing === -1 && req.Status.split(";").includes("ongoing") ||
      filters.commissioned === 1 && !req.Status.split(";").includes("commissioned") ||
      filters.commissioned === -1 && req.Status.split(";").includes("commissioned") ||
      filters.problem === 1 && !req.Status.split(";").includes("problem") ||
      filters.problem === -1 && req.Status.split(";").includes("problem") ||
      filters.cancelled === 1 && !req.Status.split(";").includes("cancelled") ||
      filters.cancelled === -1 && req.Status.split(";").includes("cancelled") ||
      filters.archived === 1 && !req.Status.split(";").includes("archived") ||
      filters.archived === -1 && req.Status.split(";").includes("archived")
    )
      filtered_booking_requests.splice(i, 1);
  }

  state.displayed_booking_requests = filtered_booking_requests;
}

const applySorting = (state, sort_by) => {

}

const BookingRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BOOKING_REQUESTS":
      state.booking_requests = action.payload
      filterBookingRequests(state, state.filters)
      return {
        ...state,
        booking_requests: state.booking_requests,
        displayed_booking_requests: state.displayed_booking_requests
      };
    case "SORT_BOOKING_REQUESTS":
      return {
        ...state,
        booking_requests: action.payload,
      };
    case "FILTER_BOOKING_REQUESTS":
      filterBookingRequests(state, action.payload);
      applySorting(state, state.sort_by);
      return {
        ...state,
        filters: state.filters,
        displayed_booking_requests: state.displayed_booking_requests,
      };
    case "CHANGE_STATUS_BOOKING_REQUESTS":
      return {
        ...state,
        booking_requests: action.payload,
      };

    default:
      return state;
  }
};

export default BookingRequestsReducer;
