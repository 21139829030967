const initialState = {
  seasons: [],
};

const SeasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SEASONS":
      return {
        ...state,
        seasons: action.payload,
      };

    default:
      return state;
  }
};

export default SeasonsReducer;
