import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import style from "./Simulator.module.css";
import { getPassword, isAuthenticated } from "../../States/Actions/Auth";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { TextField } from "@mui/material";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { systembackend_ip } from "../../Utils/Network.js";
import cloneDeep from "lodash.clonedeep";
import { titleCase } from "title-case";
import { templates } from "./templates.js";
import normal_email_en from "./templates/normal/normal_email_en.js";
import normal_email_es from "./templates/normal/normal_email_es.js";
import normal_email_de from "./templates/normal/normal_email_de.js";
import normal_email_fr from "./templates/normal/normal_email_fr.js";
import normal_email_pt from "./templates/normal/normal_email_pt.js";
import normal_email_it from "./templates/normal/normal_email_it.js";
import confirmation_email_en from "./templates/confirmation/confirmation_email_en.js";
import confirmation_email_es from "./templates/confirmation/confirmation_email_es.js";
import confirmation_email_de from "./templates/confirmation/confirmation_email_de.js";
import confirmation_email_fr from "./templates/confirmation/confirmation_email_fr.js";
import confirmation_email_pt from "./templates/confirmation/confirmation_email_pt.js";
import confirmation_email_it from "./templates/confirmation/confirmation_email_it.js";
const axios = require("axios").default;
const moment = extendMoment(Moment);

const html_templates = {
  "en": {
    "normal": normal_email_en,
    "confirmation": confirmation_email_en,
  },
  "es": {
    "normal": normal_email_es,
    "confirmation": confirmation_email_es,
  },
  "de": {
    "normal": normal_email_de,
    "confirmation": confirmation_email_de,
  },
  "fr": {
    "normal": normal_email_fr,
    "confirmation": confirmation_email_fr,
  },
  "pt": {
    "normal": normal_email_pt,
    "confirmation": confirmation_email_pt,
  },
  "it": {
    "normal": normal_email_it,
    "confirmation": confirmation_email_it,
  }
}

const rates = {
  groups: {
    normal_season: {
      a: {
        "1_2": 24.0,
        "3_6": 21.0,
        "7+": 18.0,
      },
      b: {
        "1_2": 29.0,
        "3_6": 26.0,
        "7+": 23.0,
      },
      c: {
        "1_2": 34.0,
        "3_6": 31.0,
        "7+": 28.0,
      },
      "c+": {
        "1_2": 37.0,
        "3_6": 34.0,
        "7+": 31.0,
      },
      d: {
        "1_2": 42.0,
        "3_6": 39.0,
        "7+": 36.0,
      },
      e: {
        "1_2": 45.0,
        "3_6": 42.0,
        "7+": 39.0,
      },
      m: {
        "1_2": 37.0,
        "3_6": 34.0,
        "7+": 31.0,
      },
    },
    special_season: {
      a: {
        "1_2": 34.0,
        "3_6": 31.0,
        "7+": 28.0,
      },
      b: {
        "1_2": 39.0,
        "3_6": 36.0,
        "7+": 33.0,
      },
      c: {
        "1_2": 44.0,
        "3_6": 41.0,
        "7+": 38.0,
      },
      "c+": {
        "1_2": 49.0,
        "3_6": 46.0,
        "7+": 43.0,
      },
      d: {
        "1_2": 59.0,
        "3_6": 56.0,
        "7+": 53.0,
      },
      e: {
        "1_2": 64.0,
        "3_6": 61.0,
        "7+": 58.0,
      },
      m: {
        "1_2": 49.0,
        "3_6": 46.0,
        "7+": 43.0,
      },
    },
  },
  insurances: {
    normal_season: {
      cdw: {
        a: 10.65,
        b: 10.65,
        c: 10.65,
        "c+": 10.65,
        m: 10.65,
        d: 14.75,
        e: 14.75,
      },
      scdw: {
        a: 18.85,
        b: 18.85,
        c: 18.85,
        "c+": 18.85,
        m: 18.85,
        d: 22.95,
        e: 22.95,
      },
    },
    special_season: {
      cdw: {
        a: 18.85,
        b: 18.85,
        c: 18.85,
        "c+": 18.85,
        m: 18.85,
        d: 22.95,
        e: 22.95,
      },
      scdw: {
        a: 27.05,
        b: 27.05,
        c: 27.05,
        "c+": 27.05,
        m: 27.05,
        d: 31.15,
        e: 31.15,
      },
    },
  },
  airport_fee: 16.39,
  ship_port_fee: 14.75,
  night_time_fee: 24.59,
  extra_driver_fee: 8.19,
  child_seat_fee: 4.91,
};

const currentYear = moment().year();

const discount_periods = [
  { start: `29/04/2024 00:00`, end: `31/05/2024 23:59` },
  { start: `01/06/2024 00:00`, end: `30/06/2024 23:59` },
  { start: `01/07/2024 00:00`, end: `31/07/2024 23:59` },
  { start: `01/08/2024 00:00`, end: `31/08/2024 23:59` },
  { start: `01/09/2024 00:00`, end: `30/09/2024 23:59` },
];

let specialSeasons = [];

for (var i = moment().year(); i < moment().year() + 10; i++) {
  specialSeasons.push(
    moment.range([
      moment(`24/12/${i - 1} 00:00`, "DD/MM/YYYY HH:mm"),
      moment(`01/01/${i} 23:59`, "DD/MM/YYYY HH:mm")
    ]))
  specialSeasons.push(
    moment.range([
      moment(`23/03/${i} 00:00`, "DD/MM/YYYY HH:mm"),
      moment(`07/04/${i} 23:59`, "DD/MM/YYYY HH:mm")
    ]))
  specialSeasons.push(
    moment.range([
      moment(`29/04/${i} 00:00`, "DD/MM/YYYY HH:mm"),
      moment(`30/09/${i} 23:59`, "DD/MM/YYYY HH:mm")
    ]))
}

const langKeywords = {
  en: "Transmission Type(s)",
  pt: "Tipo(s) de transmissão",
  es: "Tipo(s) de transmisión",
  fr: "Type(s) de transmission",
  de: "Getriebeart(en)",
};

const detailsKeywords = {
  en: {
    name: "Name",
    start_date: "Pick-up Date",
    start_time: "Pick-up Hour",
    start_local: "Pick-up place",
    end_date: "Return Date",
    end_time: "Return Hour",
    end_local: "Return Place",
    car_group: "Car Type",
    transmission: "Transmission Type(s)",
    num_cars: "Number of Cars",
    num_extra_drivers: "Number of Extra Drivers",
    num_child_seats_0_3: "Toddler Chair (0-3 years)",
    num_child_seats_4_7: "Infant Chair (4-7 years)",
    num_child_seats_8_12: "Booster Seat (8-12 years)",
    insurance: "Insurance",
    birthdate: "Birth Date",
  },
  pt: {
    name: "Nome",
    start_date: "Data de Levantamento",
    start_time: "Hora de Levantamento",
    start_local: "Local de Levantamento",
    end_date: "Data de Devolução",
    end_time: "Hora de Devolução",
    end_local: "Local de Devolução",
    car_group: "Tipo de Carro",
    transmission: "Tipo(s) de transmissão",
    num_cars: "Número de Carros",
    num_extra_drivers: "Número de Condutores Extra",
    num_child_seats_0_3: "Cadeira Bebé (0-3 anos)",
    num_child_seats_4_7: "Cadeira Criança (4-7 anos)",
    num_child_seats_8_12: "Assento Criança (8-12 anos)",
    insurance: "Seguro",
    birthdate: "Data de Nascimento",
  },
  es: {
    name: "Nombre",
    start_date: "Fecha de recepcion",
    start_time: "Hora de Recepcion",
    start_local: "Local de Recepcion",
    end_date: "Fecha de devolucion",
    end_time: "Hora de devolucion",
    end_local: "Local de Devolucion",
    car_group: "Tipo de Coche",
    transmission: "Tipo(s) de transmisión",
    num_cars: "Número de Coches",
    num_extra_drivers: "Número de Conductores Extra",
    num_child_seats_0_3: "Sillas para Bebé (0-3 años)",
    num_child_seats_4_7: "Sillas para Niños (4-7 años)",
    num_child_seats_8_12: "Asiento para Adolecentes (8-12 años)",
    insurance: "Seguro",
    birthdate: "Fecha de Nacimiento",
  },
  fr: {
    name: "Nom",
    start_date: "Date de livráison",
    start_time: "Heur de livráison",
    start_local: "Lieu de livráison",
    end_date: "Date de rétour",
    end_time: "Heur de rétour",
    end_local: "Lieu de rétour",
    car_group: "Type de voiture",
    transmission: "Type(s) de transmission",
    num_cars: "Nombre de Voitures",
    num_extra_drivers: "Nombre de Conducteurs Extra",
    num_child_seats_0_3: "Siége pour bébé (0-3 ans)",
    num_child_seats_4_7: "Siége pour enfant (4-7 ans)",
    num_child_seats_8_12: "Siége pour enfant (8-12 ans)",
    insurance: "Insurance",
    birthdate: "Date de Naissance",
  },
  de: {
    name: "Name",
    start_date: "Abholdatum",
    start_time: "Abholzeit",
    start_local: "Abholstelle",
    end_date: "Rückgabedatum",
    end_time: "Rückgabezeit",
    end_local: "Rückgabestelle",
    car_group: "Fahrzeugklasse",
    transmission: "Getriebeart(en)",
    num_cars: "Anzahl der Fahrzeuge",
    num_extra_drivers: "Anzahl der zusätzlichen Fahrer",
    num_child_seats_0_3: "Babysitz (0-3 Jahre)",
    num_child_seats_4_7: "Kindersitz (4-7 Jahre)",
    num_child_seats_8_12: "Kindersitz (8-12 Jahre)",
    insurance: "Versicherung",
    birthdate: "Geburtsdatum",
  },
};

function toTitleCase(text) {
  try {
    return text
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  } catch {
    return text;
  }
}

function Simulator(props) {
  const [input, setInput] = useState("");
  const [parsedDetails, setParsedDetails] = useState("");
  const [output, setOutput] = useState("");
  const [reply, setReply] = useState("");
  const [signature, setSignature] = useState("");
  const [discounts, setDiscounts] = useState({});

  const onInputChanged = (input) => {
    input = input.target.value;
    changeInput(input);

    if (input.length <= 0) return;

    // Find language that the input is in
    let lang = "";
    for (var key of Object.keys(langKeywords)) {
      if (input.toLowerCase().includes(langKeywords[key].toLowerCase())) {
        lang = key;
        break;
      }
    }

    // Parse options
    let splits = input.split("\n");
    let options = structuredClone(detailsKeywords[lang]);

    for (var option of Object.keys(options)) {
      let found = false;
      for (var split of splits) {
        if (
          split
            .toLowerCase()
            .trim()
            .includes(options[option].toLowerCase().trim())
        ) {
          options[option] = split.split(":")[1].trim();

          // Clean start and end locals
          if (option === "start_local" || option === "end_local") {
            if (options[option].toLowerCase().includes("rua da casa branca"))
              options[option] = "Escritorio";
            else if (
              (options[option].toLowerCase().includes("funchal") &&
                options[option].toLowerCase().includes("free")) ||
              options[option].toLowerCase().includes("hotel") ||
              options[option].toLowerCase().includes("hôtel") ||
              options[option].toLowerCase().includes("hotéis")
            )
              options[option] = "Hotel";
            else if (options[option].toLowerCase().includes("funchal"))
              options[option] = "Pontinha";
            else if (options[option].includes("€16.39"))
              options[option] = "Aeroporto";
            else options[option] = "Outro";
          }
          // Clean car group
          else if (option === "car_group") {
            options[option] = options[option].split("(")[0].trim();
          }
          // Clean num extra drivers
          else if (option === "num_extra_drivers") {
            if (!isNaN(parseInt(options[option])))
              options[option] = parseInt(options[option]);
            else options[option] = 0;
          }
          // Clean child seats
          else if (
            option.includes("0_3") ||
            option.includes("4_7") ||
            option.includes("8_12")
          ) {
            options[option] = options[option].replaceAll("-", "");
            if (
              options[option].toLowerCase().startsWith("n") ||
              options[option] === "" ||
              isNaN(options[option])
            )
              options[option] = 0;
            else options[option] = parseInt(options[option]);
          }
          // Clean insurance
          else if (option === "insurance") {
            if (options[option].toLowerCase().includes("scdw"))
              options[option] = "SCDW";
            else options[option] = "CDW";
          }

          found = true;
          break;
        }
      }
      if (!found) options[option] = "";
    }

    for (var option of Object.keys(options)) {
      if (option.includes("num_") && options[option] === "")
        options[option] = 0;
    }

    options["lang"] = lang;

    let start_date = moment(
      options["start_date"] + "T" + options["start_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let end_date = moment(
      options["end_date"] + "T" + options["end_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );

    // Prepare parsed details
    let parsedDetails = "";
    for (var option of Object.keys(options)) {
      parsedDetails =
        parsedDetails +
        toTitleCase(option.replaceAll("_", " ").toLowerCase().split(" ")) +
        ":         " +
        toTitleCase(options[option]) +
        "\n";
    }
    parsedDetails = parsedDetails.trim();

    // Update
    changeParsedDetails(parsedDetails);
  };

  const calc_quote = (options) => {
    let start_date = moment(
      options["start_date"] + "T" + options["start_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let end_date = moment(
      options["end_date"] + "T" + options["end_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );

    // Find season information
    let num_normal_days = 0;
    let num_special_days = 0;
    for (var i = 0; i < options["num_days"]; i++) {
      let firstDay = start_date
        .clone()
        .add(i, "days");
      let secondDay = firstDay.clone().add(1, "days");
      if (i + 1 === options["num_days"])
        secondDay = end_date.clone();

      let day_range = moment.range(firstDay, secondDay);

      let found = false;
      for (var season of specialSeasons) {
        if (day_range.overlaps(season)) {
          num_special_days += 1;
          found = true;
          break;
        }
      }
      if (found) continue;
      num_normal_days += 1;
    }

    // Prepare days information
    let rentalDays = [];

    for (var i = 0; i < options["num_days"]; i++) {
      // Check discount
      let rentalDay = start_date.clone().add(i, "days");
      let info = { day: rentalDay, isSpecialSeason: false, discount: 0 };

      for (var j = 0; j < discount_periods.length; j++) {
        let discount_period = moment.range(
          moment(discount_periods[j]["start"], "DD-MM-YYYY HH:mm"),
          moment(discount_periods[j]["end"], "DD-MM-YYYY HH:mm")
        );
        if (discount_period.contains(rentalDay)) {
          info.discount =
            parseInt(
              discounts[
              discount_periods[j]["start"] + "_" + discount_periods[j]["end"]
              ]
            ) || 0;
        }
      }

      // Check season
      let firstDay = start_date
        .clone()
        .add(i, "days");
      let secondDay = firstDay.clone().add(1, "days");
      if (i + 1 === options["num_days"])
        secondDay = end_date.clone();

      let dayRange = moment.range(firstDay, secondDay);
      for (var season of specialSeasons) {
        if (dayRange.overlaps(season)) {
          info.isSpecialSeason = true;
          break;
        }
      }

      // Add to list
      rentalDays.push(info);
    }

    // Prepare number of days string
    let days = "";
    if (options["num_days"] < 3) days = "1_2";
    else if (options["num_days"] < 7) days = "3_6";
    else days = "7+";

    // Check child seats
    let child_seats_ppd = 0;
    if (options["num_child_seats_0_3"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_0_3"];
    if (options["num_child_seats_4_7"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_4_7"];
    if (options["num_child_seats_8_12"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_8_12"];

    // Calculate normal season days cost
    let ns_group_ppd =
      rates["groups"]["normal_season"][
      options["car_group"].split(" ")[1].toLowerCase()
      ][days];
    let ns_insurance_ppd =
      rates["insurances"]["normal_season"][options["insurance"].toLowerCase()][
      options["car_group"].split(" ")[1].toLowerCase()
      ];

    let ns_total_without_fees = 0;
    for (var i = 0; i < rentalDays.length; i++) {
      let day = rentalDays[i];
      if (!day.isSpecialSeason) {
        ns_total_without_fees +=
          ns_group_ppd * (1 - rentalDays[i].discount / 100) +
          ns_insurance_ppd +
          child_seats_ppd;
      }
    }

    // Calculate special season days cost
    let ss_group_ppd =
      rates["groups"]["special_season"][
      options["car_group"].split(" ")[1].toLowerCase()
      ][days];
    let ss_insurance_ppd =
      rates["insurances"]["special_season"][options["insurance"].toLowerCase()][
      options["car_group"].split(" ")[1].toLowerCase()
      ];

    let ss_total_without_fees = 0;
    for (var i = 0; i < rentalDays.length; i++) {
      let day = rentalDays[i];
      if (day.isSpecialSeason) {
        ss_total_without_fees +=
          ss_group_ppd * (1 - rentalDays[i].discount / 100) +
          ss_insurance_ppd +
          child_seats_ppd;
      }
    }

    // Add totals without fees
    let total_without_fees = ns_total_without_fees + ss_total_without_fees;
    let total_without_fees_discount =
      (ns_group_ppd + ns_insurance_ppd + child_seats_ppd) * num_normal_days +
      (ss_group_ppd + ss_insurance_ppd + child_seats_ppd) * num_special_days;

    // Calculate fees
    let total_fees = 0;
    if (options["start_local"].toLowerCase().includes("aeroporto"))
      total_fees += rates["airport_fee"];
    if (options["end_local"].toLowerCase().includes("aeroporto"))
      total_fees += rates["airport_fee"];

    let extra_drivers_ppd = rates["extra_driver_fee"];
    total_fees += extra_drivers_ppd * options["num_extra_drivers"];

    if (
      moment(start_date.format("HH:mm"), "HH:mm").isAfter(
        moment("20:00", "HH:mm"),
        "minutes"
      ) ||
      moment(start_date.format("HH:mm"), "HH:mm").isBefore(
        moment("08:00", "HH:mm"),
        "minutes"
      )
    ) {
      total_fees += rates["night_time_fee"];
    }
    if (
      moment(end_date.format("HH:mm"), "HH:mm").isAfter(
        moment("20:00", "HH:mm"),
        "minutes"
      ) ||
      moment(end_date.format("HH:mm"), "HH:mm").isBefore(
        moment("08:00", "HH:mm"),
        "minutes"
      )
    ) {
      total_fees += rates["night_time_fee"];
    }

    // Add ship port fee (if applicable)
    if (
      options["start_local"].toLowerCase().includes("pontinha") ||
      options["end_local"].toLowerCase().includes("pontinha")
    )
      total_fees += rates["ship_port_fee"];

    let avg_group_ppd =
      (total_without_fees -
        ns_insurance_ppd * num_normal_days -
        ss_insurance_ppd * num_special_days) /
      options["num_days"];
    let avg_insurance_ppd =
      (ns_insurance_ppd * num_normal_days +
        ss_insurance_ppd * num_special_days) /
      options["num_days"];

    let total = total_without_fees + total_fees;
    let total_without_discount = total_without_fees_discount + total_fees;
    let output = `Avg. Group PPD:   ${avg_group_ppd.toFixed(2)}\n`;
    output += `Avg. Insurance PPD:   ${avg_insurance_ppd.toFixed(2)}\n`;
    output += `Child Seats PPD:   ${child_seats_ppd.toFixed(2)}\n`;
    output += `Num Normal Days:   ${num_normal_days}\n`;
    output += `Num Special Days:   ${num_special_days}\n`;
    output += `Total without Fees:   ${total_without_fees.toFixed(2)}\n`;
    output += `Fees:   ${total_fees.toFixed(2)}\n`;
    output += `Total PPD w/o Discount:   ${(
      (total_without_discount / options["num_days"]) *
      1.22
    ).toFixed(2)}\n`;
    output += `Total w/o Discount:   ${(total_without_discount * 1.22).toFixed(
      2
    )}\n`;
    output += `Total PPD:   ${((total / options["num_days"]) * 1.22).toFixed(
      2
    )}\n`;
    output += `Total:   ${(total * 1.22).toFixed(2)}`;

    return output;
  };

  const calc_cost = (options, insurance) => {
    let start_date = moment(
      options["start_date"] + "T" + options["start_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let end_date = moment(
      options["end_date"] + "T" + options["end_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );

    // Prepare days information
    let rentalDays = [];

    for (var i = 0; i < options["num_days"]; i++) {
      // Check discount
      let rentalDay = start_date.clone().add(i, "days");
      let info = { day: rentalDay, isSpecialSeason: false, discount: 0 };

      for (var j = 0; j < discount_periods.length; j++) {
        let discount_period = moment.range(
          moment(discount_periods[j]["start"], "DD-MM-YYYY HH:mm"),
          moment(discount_periods[j]["end"], "DD-MM-YYYY HH:mm")
        );
        if (discount_period.contains(rentalDay)) {
          info.discount =
            parseInt(
              discounts[
              discount_periods[j]["start"] + "_" + discount_periods[j]["end"]
              ]
            ) || 0;
        }
      }

      // Check season
      let firstDay = start_date
        .clone()
        .add(i, "days");
      let secondDay = firstDay.clone().add(1, "days");
      if (i + 1 === options["num_days"])
        secondDay = end_date.clone();

      let dayRange = moment.range(firstDay, secondDay);
      for (var season of specialSeasons) {
        if (dayRange.overlaps(season)) {
          info.isSpecialSeason = true;
          break;
        }
      }

      // Add to list
      rentalDays.push(info);
    }

    // Prepare number of days string
    let days = "";
    if (options["num_days"] < 3) days = "1_2";
    else if (options["num_days"] < 7) days = "3_6";
    else days = "7+";

    // Check child seats
    let child_seats_ppd = 0;
    if (options["num_child_seats_0_3"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_0_3"];
    if (options["num_child_seats_4_7"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_4_7"];
    if (options["num_child_seats_8_12"] !== 0)
      child_seats_ppd +=
        rates["child_seat_fee"] * options["num_child_seats_8_12"];

    // Calculate normal season days cost
    let ns_group_ppd =
      rates["groups"]["normal_season"][
      options["car_group"].split(" ")[1].toLowerCase()
      ][days];
    let ns_insurance_ppd =
      rates["insurances"]["normal_season"][insurance.toLowerCase()][
      options["car_group"].split(" ")[1].toLowerCase()
      ];

    let ns_total_without_fees = 0;
    for (var i = 0; i < rentalDays.length; i++) {
      let day = rentalDays[i];
      if (!day.isSpecialSeason) {
        ns_total_without_fees +=
          ns_group_ppd * (1 - rentalDays[i].discount / 100) +
          ns_insurance_ppd +
          child_seats_ppd;
      }
    }

    // Calculate special season days cost
    let ss_group_ppd =
      rates["groups"]["special_season"][
      options["car_group"].split(" ")[1].toLowerCase()
      ][days];
    let ss_insurance_ppd =
      rates["insurances"]["special_season"][insurance.toLowerCase()][
      options["car_group"].split(" ")[1].toLowerCase()
      ];

    let ss_total_without_fees = 0;
    for (var i = 0; i < rentalDays.length; i++) {
      let day = rentalDays[i];
      if (day.isSpecialSeason) {
        ss_total_without_fees +=
          ss_group_ppd * (1 - rentalDays[i].discount / 100) +
          ss_insurance_ppd +
          child_seats_ppd;
      }
    }

    // Add totals without fees
    let total_without_fees = ns_total_without_fees + ss_total_without_fees;

    // Calculate fees
    let total_fees = 0;
    if (options["start_local"].toLowerCase().includes("aeroporto"))
      total_fees += rates["airport_fee"];
    if (options["end_local"].toLowerCase().includes("aeroporto"))
      total_fees += rates["airport_fee"];

    let extra_drivers_ppd = rates["extra_driver_fee"];
    total_fees += extra_drivers_ppd * options["num_extra_drivers"];

    if (
      moment(start_date.format("HH:mm"), "HH:mm").isAfter(
        moment("20:00", "HH:mm"),
        "minutes"
      ) ||
      moment(start_date.format("HH:mm"), "HH:mm").isBefore(
        moment("08:00", "HH:mm"),
        "minutes"
      )
    ) {
      total_fees += rates["night_time_fee"];
    }
    if (
      moment(end_date.format("HH:mm"), "HH:mm").isAfter(
        moment("20:00", "HH:mm"),
        "minutes"
      ) ||
      moment(end_date.format("HH:mm"), "HH:mm").isBefore(
        moment("08:00", "HH:mm"),
        "minutes"
      )
    ) {
      total_fees += rates["night_time_fee"];
    }

    // Add ship port fee (if applicable)
    if (
      options["start_local"].toLowerCase().includes("pontinha") ||
      options["end_local"].toLowerCase().includes("pontinha")
    )
      total_fees += rates["ship_port_fee"];

    let total = total_without_fees + total_fees;
    return (total * 1.22).toFixed(2);
  };

  const changeInput = (input) => {
    setInput(input);
  };

  const calculateReply = (parsedDetails) => {
    let lines = parsedDetails.split("\n");
    let options = {};
    for (var line of lines) {
      try {
        let key = line.split(":")[0].toLowerCase().replaceAll(" ", "_");
        let value = line.split(":")[1].trim();
        options[key] = value;

        if (key.includes("num_")) {
          if (!isNaN(parseInt(options[key])))
            options[key] = parseInt(options[key]);
          else options[key] = 0;
        }
      } catch { }
    }

    let start_date = moment(
      options["start_date"] + "T" + options["start_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let end_date = moment(
      options["end_date"] + "T" + options["end_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let date_range = moment.range(start_date, end_date);

    let num_minutes = end_date.diff(start_date, "minutes");
    let num_days = 0;
    let max_margin = 60;

    if (num_minutes % (24 * 60) > max_margin)
      num_days = parseInt(num_minutes / (24 * 60)) + 1;
    else num_days = parseInt(num_minutes / (24 * 60));
    options["num_days"] = num_days;

    // Build reply
    let lang = options["lang"];

    // If the lang is invalid then skip
    if (!["en", "pt", "es", "fr", "de"].includes(lang.toLowerCase()))
      return

    // If the group is invalid then skip
    if (options["car_group"].split(" ").length < 2)
      return

    let template = "";

    // If the dates are invalid then build invalid_dates template
    if (
      end_date.diff(start_date, "minutes") < 120 ||
      (start_date.isBefore(moment()) && end_date.isBefore(moment()))
    ) {
      template = templates[lang]["invalid_dates"];
    }

    // If the group asked is A or B then check availability for groups C and C+
    else if (
      ["a", "b"].includes(options["car_group"].split(" ")[1].toLowerCase()) &&
      (isAvailable("C", moment.range(start_date, end_date)) ||
        isAvailable("C+", moment.range(start_date, end_date)))
    ) {
      if (isAvailable("C", moment.range(start_date, end_date))) {
        if (start_date.diff(moment(), "days") > 60)
          template = templates[lang]["only_c"]["far"];
        else template = templates[lang]["only_c"]["near"];
      } else if (isAvailable("C+", moment.range(start_date, end_date))) {
        if (start_date.diff(moment(), "days") > 60)
          template = templates[lang]["only_c+"]["far"];
        else template = templates[lang]["only_c+"]["near"];
      }
    }
    // If the group C is asked and there is no availability then check group C+
    else if (
      ["c"].includes(options["car_group"].split(" ")[1].toLowerCase()) &&
      !isAvailable("C", moment.range(start_date, end_date)) &&
      isAvailable("C+", moment.range(start_date, end_date))
    ) {
      if (start_date.diff(moment(), "days") > 60)
        template = templates[lang]["only_c+"]["far"];
      else template = templates[lang]["only_c+"]["near"];
    }

    // If a car is not available
    else if (
      !isAvailable(
        options["car_group"].split(" ")[1].toUpperCase(),
        moment.range(start_date, end_date)
      )
    ) {
      let infinity = moment(1e15);

      let groups = cleanMargins(getGaps(), 90);

      if (
        ["M", "E"].includes(options["car_group"].split(" ")[1].toUpperCase())
      ) {
        delete groups["A"];
        delete groups["B"];
        delete groups["C"];
        delete groups["C+"];
        delete groups["D"];
      } else if (
        ["A", "B", "C", "C+", "D"].includes(
          options["car_group"].split(" ")[1].toUpperCase()
        )
      ) {
        delete groups["M"];
        delete groups["E"];
      }

      // Remove far away gaps
      for (var group in groups) {
        for (var i = groups[group].length - 1; i >= 0; i--) {
          if (!groups[group][i].range.overlaps(date_range))
            groups[group].splice(i, 1);
        }
      }

      let noAvailability = true;

      for (var group in groups) {
        if (groups[group].length > 0) {
          noAvailability = false;
          break;
        }
      }

      let text = "";

      if (noAvailability) {
        text = `Unfortunately, at the moment we don't have availability for the period that you asked for, from any group.
If we have any cancellations, we can send you an email to let you know.
Alternatively, we can try to help find a similar car with similar conditions in another company.`;

        // text += "\n" + signature;
        text = text.trim();
        changeReply(text);
        return;
      }

      text = `Unfortunately, at the moment we don't have availability for the whole period and car group that you asked for. These are our options:

`;
      let ava = "";

      for (var group in groups) {
        if (groups[group].length <= 0) continue;

        ava += "\n\nGroup " + group;

        if (["M", "E"].includes(group)) ava += " (Automatic)";
        else ava += " (Manual)";

        for (var gap of groups[group]) {
          if (gap.range.contains(date_range, true)) {
            ava += "\n- whole period";
          } else {
            ava += "\n- from " + gap.start_time.format("DD/MM [at] HH:mm");

            if (gap.end_time.isSame(infinity)) ava += " onwards";
            else ava += " until " + gap.end_time.format("DD/MM [at] HH:mm");
          }
        }
      }

      ava = ava.trim();
      text += ava;
      text += `

Please let us know in case you'd be interested in any of them.`;

      // text += "\n" + signature;
      text = text.trim();
      changeReply(text);
      return;
    }

    // If the car is to be picked up and returned in the ship port
    else if (
      options["start_local"].toLowerCase().includes("pontinha") &&
      options["end_local"].toLowerCase().includes("pontinha")
    ) {
      template = templates[lang]["price_scdw"];
      if (
        ["a", "b", "c", "c+", "m"].includes(
          options["car_group"].split(" ")[1].toLowerCase()
        )
      )
        template = template["groups_abcc+m"];
      else template = template["groups_de"];

      let included = "";

      switch (lang) {
        case "en":
          included += "pick up/return at the Funchal's ship port, ";
          break;
        case "pt":
          included += "levantamento/devolução no porto do Funchal, ";
          break;
        case "es":
          included += "recogida/devolución en el puerto de Funchal, ";
          break;
        case "fr":
          included += "ramassage/retour à le port maritime de Funchal, ";
          break;
        case "de":
          included += "Abholung/Rückgabe am Schiffshafen von Funchal, ";
          break;
      }

      if (options["num_extra_drivers"] > 0) {
        switch (lang) {
          case "en":
            included += `${options["num_extra_drivers"]} extra driver(s), `;
            break;
          case "pt":
            included += `${options["num_extra_drivers"]} condutor(es) extra, `;
            break;
          case "es":
            included += `${options["num_extra_drivers"]} conductor(es) adicional(es), `;
            break;
          case "fr":
            included += `${options["num_extra_drivers"]} conducteur(s) supplémentaire(s), `;
            break;
          case "de":
            included += `${options["num_extra_drivers"]} zusätzliche(r) Fahrer, `;
            break;
        }
      }

      let num_child_seats =
        options["num_child_seats_0_3"] +
        options["num_child_seats_4_7"] +
        options["num_child_seats_8_12"];
      if (num_child_seats > 0) {
        switch (lang) {
          case "en":
            included += `${num_child_seats} child seat(s), `;
            break;
          case "pt":
            included += `${num_child_seats} cadeira(s) de criança, `;
            break;
          case "es":
            included += `${num_child_seats} asiento(s) para niños, `;
            break;
          case "fr":
            included += `${num_child_seats} siège(s) pour enfant, `;
            break;
          case "de":
            included += `${num_child_seats} Kindersitz(e), `;
            break;
        }
      }

      switch (lang) {
        case "en":
          included += `SCDW insurance and VAT`;
          break;
        case "pt":
          included += `seguro SCDW e IVA`;
          break;
        case "es":
          included += `seguro SCDW e IVA`;
          break;
        case "fr":
          included += `assurance SCDW et TVA`;
          break;
        case "de":
          included += `SCDW-Versicherung und Mehrwertsteuer`;
          break;
      }

      const values = [
        options["car_group"].split(" ")[1].toUpperCase(),
        "€" + parseInt(calc_cost(options, "scdw")),
        options["num_days"],
        included,
      ];

      for (var i = 0; i < values.length; i++)
        template = template.replace("XXXX", values[i]);
    }

    // Else build template price_cdw_scdw
    else {
      template = templates[lang]["price_cdw_scdw"];
      if (
        ["a", "b", "c", "c+", "m"].includes(
          options["car_group"].split(" ")[1].toLowerCase()
        )
      )
        template = template["groups_abcc+m"];
      else template = template["groups_de"];

      let included = "";

      if (
        options["start_local"].toLowerCase() ===
        options["end_local"].toLowerCase()
      ) {
        if (options["start_local"].toLowerCase() === "aeroporto") {
          switch (lang) {
            case "en":
              included += "pick up/return at the airport, ";
              break;
            case "pt":
              included += "levantamento/devolução no aeroporto, ";
              break;
            case "es":
              included += "recogida/devolución en el aeropuerto, ";
              break;
            case "fr":
              included += "ramassage/retour à l'aéroport, ";
              break;
            case "de":
              included += "Abholung/Rückgabe am Flughafen, ";
              break;
          }
        } else if (options["start_local"].toLowerCase() === "pontinha") {
          switch (lang) {
            case "en":
              included += "pick up/return at the Funchal's ship port, ";
              break;
            case "pt":
              included += "levantamento/devolução no porto do Funchal, ";
              break;
            case "es":
              included += "recogida/devolución en el puerto de Funchal, ";
              break;
            case "fr":
              included += "ramassage/retour à le port maritime de Funchal, ";
              break;
            case "de":
              included += "Abholung/Rückgabe am Schiffshafen von Funchal, ";
              break;
          }
        } else {
          switch (lang) {
            case "en":
              included += "pick up/return in Funchal, ";
              break;
            case "pt":
              included += "levantamento/devolução no Funchal, ";
              break;
            case "es":
              included += "recogida/devolución en Funchal, ";
              break;
            case "fr":
              included += "ramassage/retour à Funchal, ";
              break;
            case "de":
              included += "Abholung/Rückgabe in Funchal, ";
              break;
          }
        }
      } else {
        if (options["start_local"].toLowerCase() === "aeroporto") {
          switch (lang) {
            case "en":
              included += "pick up at the airport, ";
              break;
            case "pt":
              included += "levantamento no aeroporto, ";
              break;
            case "es":
              included += "recogida en el aeropuerto, ";
              break;
            case "fr":
              included += "ramassage à l'aéroport, ";
              break;
            case "de":
              included += "Abholung am Flughafen, ";
              break;
          }
        } else if (options["start_local"].toLowerCase() === "pontinha") {
          switch (lang) {
            case "en":
              included += "pick up at the Funchal's ship port, ";
              break;
            case "pt":
              included += "levantamento no porto do Funchal, ";
              break;
            case "es":
              included += "recogida en el puerto de Funchal, ";
              break;
            case "fr":
              included += "ramassage à le port maritime de Funchal, ";
              break;
            case "de":
              included += "Abholung am Schiffshafen von Funchal, ";
              break;
          }
        } else {
          switch (lang) {
            case "en":
              included += "pick up in Funchal, ";
              break;
            case "pt":
              included += "levantamento no Funchal, ";
              break;
            case "es":
              included += "recogida en Funchal, ";
              break;
            case "fr":
              included += "ramassage à Funchal, ";
              break;
            case "de":
              included += "Abholung in Funchal, ";
              break;
          }
        }

        if (options["end_local"].toLowerCase() === "aeroporto") {
          switch (lang) {
            case "en":
              included += "return at the airport, ";
              break;
            case "pt":
              included += "devolução no aeroporto, ";
              break;
            case "es":
              included += "devolución en el aeropuerto, ";
              break;
            case "fr":
              included += "retour à l'aéroport, ";
              break;
            case "de":
              included += "Rückgabe am Flughafen, ";
              break;
          }
        } else if (options["end_local"].toLowerCase() === "pontinha") {
          switch (lang) {
            case "en":
              included += "return at the Funchal's ship port, ";
              break;
            case "pt":
              included += "devolução no porto do Funchal, ";
              break;
            case "es":
              included += "devolución en el puerto de Funchal, ";
              break;
            case "fr":
              included += "retour à le port maritime de Funchal, ";
              break;
            case "de":
              included += "Rückgabe am Schiffshafen von Funchal, ";
              break;
          }
        } else {
          switch (lang) {
            case "en":
              included += "return in Funchal, ";
              break;
            case "pt":
              included += "devolução no Funchal, ";
              break;
            case "es":
              included += "devolución en Funchal, ";
              break;
            case "fr":
              included += "retour à Funchal, ";
              break;
            case "de":
              included += "Rückgabe in Funchal, ";
              break;
          }
        }
      }

      if (options["num_extra_drivers"] > 0) {
        switch (lang) {
          case "en":
            included += `${options["num_extra_drivers"]} extra driver(s), `;
            break;
          case "pt":
            included += `${options["num_extra_drivers"]} condutor(es) extra, `;
            break;
          case "es":
            included += `${options["num_extra_drivers"]} conductor(es) adicional(es), `;
            break;
          case "fr":
            included += `${options["num_extra_drivers"]} conducteur(s) supplémentaire(s), `;
            break;
          case "de":
            included += `${options["num_extra_drivers"]} zusätzliche(r) Fahrer, `;
            break;
        }
      }

      let num_child_seats =
        options["num_child_seats_0_3"] +
        options["num_child_seats_4_7"] +
        options["num_child_seats_8_12"];
      if (num_child_seats > 0) {
        switch (lang) {
          case "en":
            included += `${num_child_seats} child seat(s), `;
            break;
          case "pt":
            included += `${num_child_seats} cadeira(s) de criança, `;
            break;
          case "es":
            included += `${num_child_seats} asiento(s) para niños, `;
            break;
          case "fr":
            included += `${num_child_seats} siège(s) pour enfant, `;
            break;
          case "de":
            included += `${num_child_seats} Kindersitz(e), `;
            break;
        }
      }

      switch (lang) {
        case "en":
          included += `mandatory insurance and VAT`;
          break;
        case "pt":
          included += `seguro obrigatório e IVA`;
          break;
        case "es":
          included += `seguro obligatorio e IVA`;
          break;
        case "fr":
          included += `l'assurance obligatoire et la TVA`;
          break;
        case "de":
          included += `Obligatorische Versicherung und MwSt`;
          break;
      }

      const values = [
        options["car_group"].split(" ")[1].toUpperCase(),
        "€" + parseInt(calc_cost(options, "cdw")),
        options["num_days"],
        included,
        "€" + parseInt(calc_cost(options, "scdw")),
      ];

      for (var i = 0; i < values.length; i++)
        template = template.replace("XXXX", values[i]);
    }

    let output = calc_quote(options);
    changeOutput(output);

    template = template.trim();
    // template += "\n" + signature;
    changeReply(template);
  };

  const prepareConfirmation = (parsedDetails) => {
    let options = getOptions(parsedDetails);

    // Build reply
    let lang = options["lang"];
    let template = templates[lang]["confirmation"];
    let nextid = null;

    axios
      .post(systembackend_ip + "/nextid", { password: getPassword() })
      .then((resp) => {
        if (resp.status === 200) {
          nextid = resp.data;

          let insurance = options["insurance"];

          if (
            options["start_local"].toLowerCase().includes("pontinha") &&
            options["end_local"].toLowerCase().includes("pontinha")
          )
            insurance = "scdw";

          let cost = calc_cost(options, insurance).split(".")[0] + ".00";
          let car_group = null;
          try {
            car_group = options["car_group"].split(" ")[1];
          } catch {
            return;
          }
          let deposit = 0;

          if (["a", "b", "c", "c+", "m"].includes(car_group.toLowerCase())) {
            if (insurance.toLowerCase() === "cdw") deposit = "300.00";
            else if (insurance.toLowerCase() === "scdw") deposit = "80.00";
          } else if (["d", "e"].includes(car_group.toLowerCase())) {
            if (insurance.toLowerCase() === "cdw") deposit = "500.00";
            else if (insurance.toLowerCase() === "scdw") deposit = "200.00";
          }

          let start_local = options["start_local"];
          let end_local = options["end_local"];
          let methods = "(cash)";

          if (start_local.toLowerCase().includes("pontinha")) {
            if (lang === "en") methods = "(cash)";
            if (lang === "es") methods = "(efectivo)";
            if (lang === "pt") methods = "(numerário)";
            if (lang === "fr") methods = "(espèces)";
            if (lang === "de") methods = "(bar)";
          } else {
            if (lang === "en") methods = "(credit/debit card or cash)";
            if (lang === "es")
              methods = "(tarjeta de crédito/débito o efectivo)";
            if (lang === "pt")
              methods = "(cartão de crédito/débito ou numerário)";
            if (lang === "fr") methods = "(carte de crédit/débit ou espèces)";
            if (lang === "de") methods = "(Kredit-/Debitkarte oder bar)";
          }

          if (start_local === "Aeroporto") {
            if (lang === "en") start_local = "Airport";
            if (lang === "es") start_local = "Aeropuerto";
            if (lang === "pt") start_local = "Aeroporto";
            if (lang === "fr") start_local = "Aéroport";
            if (lang === "de") start_local = "Flughafen";
          } else if (start_local === "Hotel") {
            if (lang === "en") start_local = "Hotel";
            if (lang === "es") start_local = "Hotel";
            if (lang === "pt") start_local = "Hotel";
            if (lang === "fr") start_local = "Hôtel";
            if (lang === "de") start_local = "Hotel";
          } else if (start_local === "Pontinha") {
            if (lang === "en") start_local = "Funchal's Ship Port";
            if (lang === "es") start_local = "Puerto de Funchal";
            if (lang === "pt") start_local = "Porto do Funchal";
            if (lang === "fr") start_local = "Le port maritime de Funchal";
            if (lang === "de") start_local = "Der Schiffshafen von Funchal";
          }

          if (end_local === "Aeroporto") {
            if (lang === "en") end_local = "Airport";
            if (lang === "es") end_local = "Aeropuerto";
            if (lang === "pt") end_local = "Aeroporto";
            if (lang === "fr") end_local = "Aéroport";
            if (lang === "de") end_local = "Flughafen";
          } else if (end_local === "Hotel") {
            if (lang === "en") end_local = "Hotel";
            if (lang === "es") end_local = "Hotel";
            if (lang === "pt") end_local = "Hotel";
            if (lang === "fr") end_local = "Hôtel";
            if (lang === "de") end_local = "Hotel";
          } else if (end_local === "Pontinha") {
            if (lang === "en") end_local = "Funchal's Ship Port";
            if (lang === "es") end_local = "Puerto de Funchal";
            if (lang === "pt") end_local = "Porto do Funchal";
            if (lang === "fr") end_local = "Le port maritime de Funchal";
            if (lang === "de") end_local = "Der Schiffshafen von Funchal";
          }

          let separator = "at";

          if (lang === "en") separator = "at";
          if (lang === "es") separator = "a las";
          if (lang === "pt") separator = "às";
          if (lang === "fr") separator = "à";
          if (lang === "de") separator = "um";

          const values = [
            nextid,

            options["start_date"] +
            " " +
            separator +
            " " +
            options["start_time"],
            start_local,

            options["end_date"] + " " + separator + " " + options["end_time"],
            end_local,
            car_group,
            insurance.toUpperCase(),
            options["num_extra_drivers"],
            options["num_child_seats_0_3"],
            options["num_child_seats_4_7"],
            options["num_child_seats_8_12"],
            cost,
            methods,
            deposit,
          ];

          for (var value of values) {
            template = template.replace("XXXX", value);
          }

          template = template.trim();
          template += "\n" + signature;

          changeReply(template);
        } else {
          return;
        }
      })
      .catch((reason) => {
        return;
      });
  };

  const getHTMLConfirmation = (event, parsedDetails) => {
    let options = getOptions(parsedDetails);

    // Build reply
    let lang = options["lang"];
    let html_template = html_templates[lang]["confirmation"];

    let nextid = null;

    axios
      .post(systembackend_ip + "/nextid", { password: getPassword() })
      .then((resp) => {
        if (resp.status === 200) {
          nextid = resp.data;

          let insurance = options["insurance"];

          if (
            options["start_local"].toLowerCase().includes("pontinha") &&
            options["end_local"].toLowerCase().includes("pontinha")
          )
            insurance = "scdw";

          let cost = calc_cost(options, insurance).split(".")[0] + ".00";
          let car_group = null;
          let car_transmission = null;
          let car_models = null;

          try {
            car_group = options["car_group"].split(" ")[1].toUpperCase();

            if (car_group === "M" || car_group === "E") {
              if (lang === "en") car_transmission = "Automatic";
              if (lang === "es") car_transmission = "Automático";
              if (lang === "fr") car_transmission = "Automatique";
              if (lang === "de") car_transmission = "Automatisch";
              if (lang === "pt") car_transmission = "Automático";
            }
            else {
              if (lang === "en") car_transmission = "Manual";
              if (lang === "es") car_transmission = "Manual";
              if (lang === "fr") car_transmission = "Manuel";
              if (lang === "de") car_transmission = "Manuell";
              if (lang === "pt") car_transmission = "Manual";
            }

            if (car_group === "A") car_models = "Fiat Panda";
            if (car_group === "B") car_models = "Mitsubishi Space Star";
            if (car_group === "C") car_models = "Renault Clio / Seat Ibiza";
            if (car_group === "C+") car_models = "Seat Ibiza FR / Skoda Fabia";
            if (car_group === "M") car_models = "Nissan Micra / Skoda Fabia";
            if (car_group === "D") car_models = "Renault Captur";
            if (car_group === "E") car_models = "Renault Captur";

          } catch {
            return null;
          }
          let deposit = 0;

          if (["a", "b", "c", "c+", "m"].includes(car_group.toLowerCase())) {
            if (insurance.toLowerCase() === "cdw") deposit = "300.00";
            else if (insurance.toLowerCase() === "scdw") deposit = "80.00";
          } else if (["d", "e"].includes(car_group.toLowerCase())) {
            if (insurance.toLowerCase() === "cdw") deposit = "500.00";
            else if (insurance.toLowerCase() === "scdw") deposit = "200.00";
          }

          let start_local = options["start_local"];
          let end_local = options["end_local"];
          let methods = "(cash)";
          let deposit_method = "(cash)";

          if (start_local.toLowerCase().includes("pontinha")) {
            if (lang === "en") methods = "(cash)";
            if (lang === "es") methods = "(efectivo)";
            if (lang === "pt") methods = "(numerário)";
            if (lang === "fr") methods = "(espèces)";
            if (lang === "de") methods = "(bar)";
          } else {
            if (lang === "en") methods = "(credit/debit card or cash)";
            if (lang === "es")
              methods = "(tarjeta de crédito/débito o efectivo)";
            if (lang === "pt")
              methods = "(cartão de crédito/débito ou numerário)";
            if (lang === "fr") methods = "(carte de crédit/débit ou espèces)";
            if (lang === "de") methods = "(Kredit-/Debitkarte oder bar)";
          }

          if (lang === "en") deposit_method = "(cash)";
          if (lang === "es") deposit_method = "(efectivo)";
          if (lang === "pt") deposit_method = "(numerário)";
          if (lang === "fr") deposit_method = "(espèces)";
          if (lang === "de") deposit_method = "(bar)";

          if (start_local === "Aeroporto") {
            if (lang === "en") start_local = "Airport arrivals";
            if (lang === "es") start_local = "Llegadas del aeropuerto";
            if (lang === "pt") start_local = "Chegadas do aeroporto";
            if (lang === "fr") start_local = "Arrivées de l'aéroport";
            if (lang === "de") start_local = "Flughafenankünfte";
          } else if (start_local === "Hotel") {
            if (lang === "en") start_local = "Accommodation located in Funchal";
            if (lang === "es") start_local = "Alojamiento ubicado en Funchal";
            if (lang === "pt") start_local = "Alojamento localizado no Funchal";
            if (lang === "fr") start_local = "Hébergement situé à Funchal";
            if (lang === "de") start_local = "Unterkunft in Funchal";
          } else if (start_local === "Pontinha") {
            if (lang === "en") start_local = "Funchal's Ship Port";
            if (lang === "es") start_local = "Puerto de cruceros de Funchal";
            if (lang === "pt") start_local = "Porto de cruzeiros do Funchal";
            if (lang === "fr") start_local = "Port de croisière de Funchal";
            if (lang === "de") start_local = "Hafen von Funchal";
          } else if (start_local === "Escritorio") {
            if (lang === "en") start_local = "Funchal Office - Rua da Casa Branca, 33";
            if (lang === "es") start_local = "Oficina de Funchal - Rua da Casa Branca, 33";
            if (lang === "pt") start_local = "Escritório do Funchal - Rua da Casa Branca, 33";
            if (lang === "fr") start_local = "Bureau de Funchal - Rua da Casa Branca, 33";
            if (lang === "de") start_local = "Funchal Büro - Rua da Casa Branca, 33";
          }

          if (end_local === "Aeroporto") {
            if (lang === "en") end_local = "Airport departures";
            if (lang === "es") end_local = "Salidas del aeropuerto";
            if (lang === "pt") end_local = "Partidas do aeroporto";
            if (lang === "fr") end_local = "Départs de l'aéroport";
            if (lang === "de") end_local = "Flughafenabflüge";
          } else if (end_local === "Hotel") {
            if (lang === "en") end_local = "Accommodation located in Funchal";
            if (lang === "es") end_local = "Alojamiento ubicado en Funchal";
            if (lang === "pt") end_local = "Alojamento localizado no Funchal";
            if (lang === "fr") end_local = "Hébergement situé à Funchal";
            if (lang === "de") end_local = "Unterkunft in Funchal";
          } else if (end_local === "Pontinha") {
            if (lang === "en") end_local = "Funchal's Ship Port";
            if (lang === "es") end_local = "Puerto de cruceros de Funchal";
            if (lang === "pt") end_local = "Porto de cruzeiros do Funchal";
            if (lang === "fr") end_local = "Port de croisière de Funchal";
            if (lang === "de") end_local = "Hafen von Funchal";
          } else if (end_local === "Escritorio") {
            if (lang === "en") end_local = "Funchal Office - Rua da Casa Branca, 33";
            if (lang === "es") end_local = "Oficina de Funchal - Rua da Casa Branca, 33";
            if (lang === "pt") end_local = "Escritório do Funchal - Rua da Casa Branca, 33";
            if (lang === "fr") end_local = "Bureau de Funchal - Rua da Casa Branca, 33";
            if (lang === "de") end_local = "Funchal Büro - Rua da Casa Branca, 33";
          }

          let separator = "at";

          if (lang === "en") separator = "at";
          if (lang === "es") separator = "a las";
          if (lang === "pt") separator = "às";
          if (lang === "fr") separator = "à";
          if (lang === "de") separator = "um";

          const values = [
            nextid,
            titleCase(options["name"].split(" ")[0]),
            options["start_date"] +
            " " +
            separator +
            " " +
            options["start_time"],
            start_local,
            options["end_date"] + " " + separator + " " + options["end_time"],
            end_local,
            options["num_cars"],
            car_group,
            car_transmission,
            car_models,
            options["num_extra_drivers"],
            options["num_child_seats_0_3"],
            options["num_child_seats_4_7"],
            options["num_child_seats_8_12"],
            insurance.toUpperCase(),
            "€" + cost + " " + methods,
            "€" + deposit + " " + deposit_method,
            signature
          ];

          for (var value of values) {
            html_template = html_template.replace("XXX", value);
          }

          navigator.clipboard.writeText(html_template);
          let aux = event.target.innerHTML;
          event.target.innerHTML = "Copiado!";
          setTimeout(() => {
            event.target.innerHTML = aux;
          }, 1000);
        } else {
          return null;
        }
      })
      .catch((reason) => {
        return null;
      });
  };

  const getOptions = (parsedDetails) => {
    let lines = parsedDetails.split("\n");
    let options = {};
    for (var line of lines) {
      try {
        let key = line.split(":")[0].toLowerCase().replaceAll(" ", "_");
        let value = line.split(":")[1].trim();
        options[key] = value;

        if (key.includes("num_")) {
          if (!isNaN(parseInt(options[key])))
            options[key] = parseInt(options[key]);
          else options[key] = 0;
        }
      } catch { }
    }

    let start_date = moment(
      options["start_date"] + "T" + options["start_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );
    let end_date = moment(
      options["end_date"] + "T" + options["end_time"].replace("h", ":"),
      "DD-MM-YYYYTHH:mm"
    );

    let num_minutes = end_date.diff(start_date, "minutes");
    let num_days = 0;
    let max_margin = 60;

    if (num_minutes % (24 * 60) > max_margin)
      num_days = parseInt(num_minutes / (24 * 60)) + 1;
    else num_days = parseInt(num_minutes / (24 * 60));
    options["num_days"] = num_days;

    return options;
  };

  const onParsedDetailsChanged = (parsedDetails) => {
    changeParsedDetails(parsedDetails.target.value);
  };

  const changeParsedDetails = (parsedDetails) => {
    setParsedDetails(parsedDetails);
    calculateReply(parsedDetails);
  };

  const onOutputChanged = (output) => {
    //
  };

  const changeOutput = (output) => {
    setOutput(output);
  };

  const changeReply = (reply) => {
    setReply(reply);
  };

  const changeSignature = (signature) => {
    setSignature(signature);
  };

  const changeDiscount = (period, discount) => {
    let aux = cloneDeep(discounts);
    aux[period] = discount;
    setDiscounts(aux);
  };

  const onChangeSignatureChanged = (input) => {
    changeSignature(input.target.value);
  };

  const onChangeDiscountChanged = (input) => {
    changeDiscount(input.target.id, input.target.value);
  };

  const getGaps = (car_groups) => {
    let groups = {};

    if (car_groups === undefined) {
      groups = { A: [], B: [], C: [], "C+": [], M: [], D: [], E: [] };
    } else {
      for (var group of car_groups) groups[group.toUpperCase()] = [];
    }

    let infinity = moment(1e15);

    // Check each group's cars
    for (var group in groups) {
      for (var car of props.cars) {
        if (car.Group.toUpperCase() === group) {
          // Get car bookings and gaps
          let car_bookings = props.bookings.filter((booking, index) => {
            if (booking.booking_info.Car_Plate === car.Plate) return true;
            else return false;
          });

          if (car_bookings.length <= 0)
            continue

          // Sort bookings by date
          car_bookings = car_bookings.sort((a, b) => {
            if (
              moment(
                a.booking_info.Start_Date + "T" + a.booking_info.Start_Time,
                "DD-MM-YYYYTHH:mm"
              ).isBefore(
                moment(
                  b.booking_info.End_Date + "T" + b.booking_info.End_Time,
                  "DD-MM-YYYYTHH:mm"
                )
              )
            )
              return -1;
            else return 1;
          });

          for (var j = 0; j < car_bookings.length - 1; j++) {
            let start_datetime = moment(
              car_bookings[j].booking_info.End_Date +
              "T" +
              car_bookings[j].booking_info.End_Time,
              "DD-MM-YYYYTHH:mm"
            );

            let end_datetime = moment(
              car_bookings[j + 1].booking_info.Start_Date +
              "T" +
              car_bookings[j + 1].booking_info.Start_Time,
              "DD-MM-YYYYTHH:mm"
            );

            if (
              end_datetime.diff(start_datetime, "minute") < 1 ||
              end_datetime.isSameOrBefore(moment(), "minute")
            )
              continue;

            groups[car.Group.toUpperCase()].push({
              start_time: start_datetime,
              end_time: end_datetime,
              range: moment.range(start_datetime, end_datetime),
              prev_booking: car_bookings[j],
              next_booking: car_bookings[j + 1],
            });
          }

          let start_datetime = moment(
            car_bookings[car_bookings.length - 1].booking_info.End_Date +
            "T" +
            car_bookings[car_bookings.length - 1].booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          );

          groups[car.Group.toUpperCase()].push({
            start_time: start_datetime,
            end_time: infinity,
            range: moment.range(start_datetime, infinity),
            prev_booking: car_bookings[car_bookings.length - 1],
            next_booking: undefined,
          });
        }
      }
    }

    for (var group in groups) {
      for (var i = 0; i < groups[group].length; i++) {
        for (var j = 0; j < groups[group].length; j++) {
          if (i === j) continue;

          let gap_a = groups[group][i];
          let gap_b = groups[group][j];

          if (gap_a.range.overlaps(gap_b.range)) {
            // Remover buracos sobrepostos
            // Talvez sem isto para ordenar os carros
            if (
              gap_a.start_time.isSameOrBefore(gap_b.start_time) &&
              gap_a.end_time.isSameOrAfter(gap_b.end_time)
            ) {
              groups[group].splice(j, 1);

              if (j <= i && i > 0) i -= 1;
              if (j >= 0) j -= 1;
              continue;
            }
            if (
              gap_b.start_time.isSameOrBefore(gap_a.start_time) &&
              gap_b.end_time.isSameOrAfter(gap_a.end_time)
            ) {
              groups[group].splice(i, 1);
              if (i > 0) i -= 1;
              if (j >= 0) j -= 1;
              continue;
            }

            let gap = {};

            if (gap_a.start_time.isSameOrBefore(gap_b.start_time, "minute")) {
              gap.start_time = gap_a.start_time;
              gap.prev_booking = gap_a.prev_booking;
            } else {
              gap.start_time = gap_b.start_time;
              gap.prev_booking = gap_b.prev_booking;
            }

            if (gap_a.end_time.isSameOrAfter(gap_b.end_time, "minute")) {
              gap.end_time = gap_a.end_time;
              gap.next_booking = gap_a.next_booking;
            } else {
              gap.end_time = gap_b.end_time;
              gap.next_booking = gap_b.next_booking;
            }

            gap.range = moment.range(gap.start_time, gap.end_time);

            groups[group].splice(groups[group].indexOf(gap_a), 1);
            groups[group].splice(groups[group].indexOf(gap_b), 1);
            groups[group].unshift(gap);
            i = 0;
            j = -1;
          }
        }
      }

      groups[group] = groups[group].sort((a, b) => {
        if (a.start_time.isBefore(b.start_time)) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    return groups;
  };

  const cleanMargins = (groups, margin) => {
    let infinity = moment(1e15);

    for (var group in groups) {
      if (groups[group].length <= 0) continue;

      for (var i = groups[group].length - 1; i >= 0; i--) {
        // Add margin to the beginning and end of the gap
        // If it's a final gap, just add to the beginning
        if (groups[group][i].end_time.isSame(infinity)) {
          groups[group][i].start_time = groups[group][i].start_time.add(
            margin,
            "minutes"
          );
          groups[group][i].range = moment.range(
            groups[group][i].start_time,
            groups[group][i].end_time
          );
          continue;
        }

        // If the gap started before the present moment, change the gap start to the present moment plus 90 minutes
        // Round it to the next tens and add the margins
        let addedMargin = false;

        if (groups[group][i].start_time.isBefore(moment(), "minute")) {
          groups[group][i].start_time = moment().add(margin, "minutes");
          groups[group][i].start_time.minute(
            Math.ceil((groups[group][i].start_time.minute() + 1) / 10) * 10
          );
          if (
            groups[group][i].end_time.diff(
              groups[group][i].start_time,
              "minutes"
            ) > margin
          ) {
            groups[group][i].end_time = groups[group][i].end_time.subtract(
              margin,
              "minutes"
            );
            groups[group][i].range = moment.range(
              groups[group][i].start_time,
              groups[group][i].end_time
            );
            addedMargin = true;
          } else {
            groups[group].splice(i, 1);
            continue;
          }
        }

        if (addedMargin === false) {
          if (
            groups[group][i].end_time.diff(
              groups[group][i].start_time,
              "minutes"
            ) <=
            margin * 2
          ) {
            groups[group].splice(i, 1);
            continue;
          }
          groups[group][i].start_time = groups[group][i].start_time.add(
            margin,
            "minutes"
          );
          groups[group][i].end_time = groups[group][i].end_time.subtract(
            margin,
            "minutes"
          );
          groups[group][i].range = moment.range(
            groups[group][i].start_time,
            groups[group][i].end_time
          );
        }

        // Remove gaps smaller than 5 hours
        if (
          groups[group][i].end_time.diff(
            groups[group][i].start_time,
            "minutes"
          ) <
          5 * 60
        ) {
          groups[group].splice(i, 1);
          continue;
        }

        // Remove gaps overnight or with not enough consecutive day hours
        if (
          groups[group][i].start_time.hour() >= 14 &&
          groups[group][i].end_time.diff(
            groups[group][i].start_time,
            "minutes"
          ) <=
          21 * 60
        ) {
          groups[group].splice(i, 1);
          continue;
        }
        if (
          groups[group][i].start_time.hour() >= 12 &&
          groups[group][i].end_time.diff(
            groups[group][i].start_time,
            "minutes"
          ) <=
          6 * 60
        ) {
          groups[group].splice(i, 1);
          continue;
        }
      }
    }

    return groups;
  };

  const isAvailable = (group, period) => {
    let gaps = cleanMargins(getGaps([group.toUpperCase()]), 90);
    for (var gap of gaps[group.toUpperCase()]) {
      if (gap.range.contains(period, true)) return true;
    }
    return false;
  };

  if (!isAuthenticated()) return <Navigate to="/" />;

  return (
    <div>
      <Navbar />
      <div className={style.box}>
        <div>
          <div style={{ float: "left", marginBottom: "0px" }}>
            <TextField
              id="filled-multiline-flexible"
              label="Input"
              multiline
              maxRows={1}
              size="small"
              variant="outlined"
              style={{ width: "150px" }}
              InputProps={{
                style: { fontSize: "1.3vh" },
              }}
              onChange={onInputChanged}
              value={input}
            />
          </div>
          <div>
            <button
              style={{
                padding: "2px",
                border: "1px solid #aaa",
                width: "50px",
                marginLeft: "20px",
                marginRight: "5px",
              }}
              onClick={async (event) => {
                calculateReply(parsedDetails);
              }}
            >
              Simular
            </button>
            <button
              style={{
                padding: "2px",
                border: "1px solid #aaa",
                width: "70px",
                marginRight: "5px",
              }}
              onClick={async (event) => {
                prepareConfirmation(parsedDetails);
              }}
              onDoubleClick={async (event) => {
                console.log("Added booking");
              }}
            >
              Confirmar
            </button>
            <button
              style={{
                padding: "2px",
                border: "1px solid #aaa",
                width: "60px",
                marginRight: "5px",
              }}
              onClick={async (event) => {

                let options = getOptions(parsedDetails)
                let html_template = html_templates[options["lang"]]["normal"]

                let _reply = reply.replace(/(?:\r\n|\r|\n)/g, '<br>')

                html_template = html_template.replace("XXX", titleCase(options["name"].split(" ")[0])).replace("XXX", _reply).replace("XXX", signature)

                navigator.clipboard.writeText(html_template);
                let aux = event.target.innerHTML;
                event.target.innerHTML = "Copiado!";
                setTimeout(() => {
                  event.target.innerHTML = aux;
                }, 1000);
              }}
            >
              Copiar Resposta
            </button>
            <button
              style={{
                padding: "2px",
                border: "1px solid #aaa",
                width: "80px",
                marginRight: "20px"
              }}
              onClick={async (event) => {
                getHTMLConfirmation(event, parsedDetails)
              }}
            >
              Copiar Confirmacao
            </button>
            <TextField
              id="filled-multiline-flexible"
              label="Nome"
              size="small"
              variant="outlined"
              style={{ width: "100px" }}
              InputProps={{
                style: { fontSize: "1.3vh" },
              }}
              onChange={onChangeSignatureChanged}
              value={signature}
            />
          </div>
          <br></br>
          <div>
            {discount_periods.map((period) => (
              <TextField
                id={period.start + "_" + period.end}
                label={
                  period.start.split("/202")[0] +
                  " to " +
                  period.end.split("/202")[0]
                }
                variant="outlined"
                size="small"
                style={{ width: "100px", height: "50px", marginRight: "5px" }}
                InputProps={{
                  style: { fontSize: "1.3vh" },
                }}
                sx={{
                  '& .MuiFormLabel-root': {
                    fontSize: '1.1vh'
                  },
                }}
                onChange={onChangeDiscountChanged}
                value={discounts[period.start + "_" + period.end]}
              />
            ))}
          </div>
        </div>
        <br></br>
        <div style={{ height: "450px" }}>
          <div style={{ marginBottom: "5px", float: "left" }}>
            <TextField
              id="filled-multiline-flexible"
              label="Parsed"
              multiline
              maxRows={25}
              variant="filled"
              style={{ width: "300px" }}
              InputProps={{
                style: { fontSize: "1.3vh", backgroundColor: "#fafafa" },
              }}
              onChange={onParsedDetailsChanged}
              value={parsedDetails}
            />
            <TextField
              id="filled-multiline-flexible"
              label="Output"
              multiline
              maxRows={25}
              size="small"
              variant="filled"
              style={{ width: "300px" }}
              InputProps={{
                style: { fontSize: "1.3vh", backgroundColor: "#fafafa" },
              }}
              onChange={onOutputChanged}
              value={output}
            />
          </div>
        </div>
        <br></br><br></br>
        <div>
          <TextField
            id="filled-multiline-flexible"
            label="Reply"
            multiline
            maxRows={26}
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
            InputProps={{
              style: { fontSize: "1.3vh" },
            }}
            onChange={onOutputChanged}
            value={reply}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
  };
}

export default connect(mapStateToProps)(Simulator);
