import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar.js";
import style from "./Commissions.module.css";
import { isAuthenticated } from "../../States/Actions/Auth.js";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";


function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};


const Commissions = (props) => {

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    box_height: window.innerHeight - 80 - 150,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        box_height: window.innerHeight - 80 - 150,
      });
    }, 1);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  // useEffect(() => {
  //   disableEditBookingsMode();
  //   closeEditBookingPopup();
  //   updateAddBookingSection();
  // }, []);

  if (!isAuthenticated()) return <Navigate to="/" />;

  return (
    <div>
      <Navbar />
      <div className={style.box}>

      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
  };
}

export default connect(mapStateToProps)(Commissions);
