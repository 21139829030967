// Data
export const updateBookings = (bookings) => {
  return {
    type: "UPDATE_BOOKINGS",
    payload: bookings,
  };
};

export const updateGaps = () => {
  return {
    type: "UPDATE_GAPS",
  };
};

export const updateCars = (cars) => {
  return {
    type: "UPDATE_CARS",
    payload: cars,
  };
};

export const updateRates = (rates) => {
  return {
    type: "UPDATE_RATES",
    payload: rates,
  };
};

export const updateSeasons = (seasons) => {
  return {
    type: "UPDATE_SEASONS",
    payload: seasons,
  };
};

export const updateBookingRequests = (booking_requests) => {
  return {
    type: "UPDATE_BOOKING_REQUESTS",
    payload: booking_requests,
  };
};

export const sortBookingRequests = (booking_requests) => {
  return {
    type: "SORT_BOOKING_REQUESTS",
    payload: booking_requests,
  };
};

export const filterBookingRequests = (booking_requests) => {
  return {
    type: "FILTER_BOOKING_REQUESTS",
    payload: booking_requests,
  };
};

export const updateAddBookingSection = (info) => {
  return {
    type: "UPDATE_ADD_BOOKING_SECTION",
    payload: info,
  };
};

export const updateEditBookingSection = (info) => {
  return {
    type: "UPDATE_EDIT_BOOKING_SECTION",
    payload: info,
  };
};

// UI
export const expandAddBooking = () => {
  return {
    type: "EXPAND_ADD_BOOKING",
  };
};

export const expandBooking = (ID) => {
  return {
    type: "EXPAND_BOOKING",
    payload: ID,
  };
};

export const expandAllBookings = () => {
  return {
    type: "EXPAND_ALL_BOOKINGS",
  };
};

export const collapseAllBookings = () => {
  return {
    type: "COLLAPSE_ALL_BOOKINGS",
  };
};

export const displayBookings = (bookings) => {
  return {
    type: "DISPLAY_BOOKINGS",
    payload: bookings,
  };
};

export const sortBookings = (sort_by) => {
  return {
    type: "SORT_BOOKINGS",
    payload: sort_by,
  };
};

export const filterBookings = (filters) => {
  return {
    type: "FILTER_BOOKINGS",
    payload: filters,
  };
};

export const openEditBookingPopup = (booking_id) => {
  return {
    type: "OPEN_EDIT_BOOKING_POPUP",
    payload: booking_id,
  };
};

export const closeEditBookingPopup = () => {
  return {
    type: "CLOSE_EDIT_BOOKING_POPUP",
  };
};

export const openDeleteBookingPopup = (booking_id) => {
  return {
    type: "OPEN_DELETE_BOOKING_POPUP",
    payload: booking_id,
  };
};

export const closeDeleteBookingPopup = () => {
  return {
    type: "CLOSE_DELETE_BOOKING_POPUP",
  };
};

export const toggleEditBookingsMode = () => {
  return {
    type: "TOGGLE_EDIT_BOOKINGS_MODE",
  };
};

export const disableEditBookingsMode = () => {
  return {
    type: "DISABLE_EDIT_BOOKINGS_MODE",
  };
};

// Board
export const changeCalendarMode = (mode) => {
  return { type: "CHANGE_CALENDAR_MODE", payload: mode };
};

export const openEventPopup = (booking_id) => {
  return { type: "OPEN_EVENT_POPUP", payload: booking_id };
};

export const closeEventPopup = (booking_id) => {
  return { type: "CLOSE_EVENT_POPUP", payload: booking_id };
};

export const changeInterval = (interval) => {
  return { type: "CHANGE_INTERVAL", payload: interval };
};

// Auth
export const loggedIn = (password) => {
  return {
    type: "LOGGED_IN",
    payload: password,
  };
};

export const loggedOut = () => {
  return {
    type: "LOGGED_OUT",
  };
};
