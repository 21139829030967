import React, { useState, useContext, useEffect } from "react";
import Navbar from "../../Components/Navbar.js";
import style from "./Rates.module.css";
import { getPassword, isAuthenticated } from "../../States/Actions/Auth.js";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { BookingTextField } from "../../Components/BookingTextField.js";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Checkbox, FormControlLabel } from "@mui/material";
import SocketContext from "../../Utils/SocketContext";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { systembackend_ip, websitebackend_ip } from "../../Utils/Network.js";
const moment = extendMoment(Moment);


function Group(props) {

  return <Grid item>
    <Grid container direction="column" rowGap={1.4}>
      <Grid item style={{ marginLeft: "12px", marginBottom: "15px" }}>
        <Typography
          style={{
            fontFamily: "sf pro text regular",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#2e3192",
          }}
        >
          Grupo {props.group.name}
        </Typography>
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              1-2 Baixa
            </Typography>
          }
          value={props.group.low12}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "low12", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              3-6 Baixa
            </Typography>
          }
          value={props.group.low36}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "low36", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              7+ Baixa
            </Typography>
          }
          value={props.group.low7plus}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "low7plus", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              1-2 Normal
            </Typography>
          }
          value={props.group.normal12}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "normal12", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              3-6 Normal
            </Typography>
          }
          value={props.group.normal36}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "normal36", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              7+ Normal
            </Typography>
          }
          value={props.group.normal7plus}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "normal7plus", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              1-2 Alta
            </Typography>
          }
          value={props.group.high12}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "high12", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              3-6 Alta
            </Typography>
          }
          value={props.group.high36}
          disabled={props.locked}
          onChange={(event) => {
            props.setGroup(props.group.name, "high36", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
      <Grid item>
        <BookingTextField
          label={
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              7+ Alta
            </Typography>
          }
          value={props.group.high7plus}
          disabled={props.locked}
          onChange={async (event) => {
            props.setGroup(props.group.name, "high7plus", event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            transform: "translate(0px, -15px)",
            input: {
              width: "50px",
              height: "0px",
              fontSize: 12,
              textAlign: "center",
              fontFamily: "sf pro text regular",
            },
          }}
        />
      </Grid>
    </Grid>
  </Grid>
}

function Rates(props) {
  // Misc
  const [locked, setLocked] = useState(true);
  const [repeatingSeason, setRepeatingSeason] = useState(true);
  const [seasonStartDate, setSeasonStartDate] = useState("");
  const [seasonEndDate, setSeasonEndDate] = useState("");
  const [seasonType, setSeasonType] = useState("");

  const socket = useContext(SocketContext);

  // Rates and seasons
  let currentRates = props.rates[props.rates.length - 1]
  let currentSeasons = props.seasons.filter((season) => !season.Valid_Until)

  currentSeasons = currentSeasons.sort((a, b) => {

    let seasonA;
    let seasonB;

    if (a.Start.split(" ")[0].split("/").length <= 2)
      seasonA = moment(a.Start, "DD/MM HH:mm").set("year", moment().year());
    else
      seasonA = moment(a.Start, "DD/MM/YYYY HH:mm");

    if (b.Start.split(" ")[0].split("/").length <= 2)
      seasonB = moment(b.Start, "DD/MM HH:mm").set("year", moment().year());
    else
      seasonB = moment(b.Start, "DD/MM/YYYY HH:mm");

    if (seasonA.isBefore(seasonB)) return -1
    else return 1
  })

  // Group A
  const [groupALow12, setGroupALow12] = useState(currentRates.A_Low_12);
  const [groupALow36, setGroupALow36] = useState(currentRates.A_Low_36);
  const [groupALow7plus, setGroupALow7plus] = useState(currentRates.A_Low_7plus);
  const [groupANormal12, setGroupANormal12] = useState(currentRates.A_Normal_12);
  const [groupANormal36, setGroupANormal36] = useState(currentRates.A_Normal_36);
  const [groupANormal7plus, setGroupANormal7plus] = useState(currentRates.A_Normal_7plus);
  const [groupAHigh12, setGroupAHigh12] = useState(currentRates.A_High_12);
  const [groupAHigh36, setGroupAHigh36] = useState(currentRates.A_High_36);
  const [groupAHigh7plus, setGroupAHigh7plus] = useState(currentRates.A_High_7plus);

  // Group B
  const [groupBLow12, setGroupBLow12] = useState(currentRates.B_Low_12);
  const [groupBLow36, setGroupBLow36] = useState(currentRates.B_Low_36);
  const [groupBLow7plus, setGroupBLow7plus] = useState(currentRates.B_Low_7plus);
  const [groupBNormal12, setGroupBNormal12] = useState(currentRates.B_Normal_12);
  const [groupBNormal36, setGroupBNormal36] = useState(currentRates.B_Normal_36);
  const [groupBNormal7plus, setGroupBNormal7plus] = useState(currentRates.B_Normal_7plus);
  const [groupBHigh12, setGroupBHigh12] = useState(currentRates.B_High_12);
  const [groupBHigh36, setGroupBHigh36] = useState(currentRates.B_High_36);
  const [groupBHigh7plus, setGroupBHigh7plus] = useState(currentRates.B_High_7plus);

  // Group C
  const [groupCLow12, setGroupCLow12] = useState(currentRates.C_Low_12);
  const [groupCLow36, setGroupCLow36] = useState(currentRates.C_Low_36);
  const [groupCLow7plus, setGroupCLow7plus] = useState(currentRates.C_Low_7plus);
  const [groupCNormal12, setGroupCNormal12] = useState(currentRates.C_Normal_12);
  const [groupCNormal36, setGroupCNormal36] = useState(currentRates.C_Normal_36);
  const [groupCNormal7plus, setGroupCNormal7plus] = useState(currentRates.C_Normal_7plus);
  const [groupCHigh12, setGroupCHigh12] = useState(currentRates.C_High_12);
  const [groupCHigh36, setGroupCHigh36] = useState(currentRates.C_High_36);
  const [groupCHigh7plus, setGroupCHigh7plus] = useState(currentRates.C_High_7plus);

  // Group M
  const [groupMLow12, setGroupMLow12] = useState(currentRates.M_Low_12);
  const [groupMLow36, setGroupMLow36] = useState(currentRates.M_Low_36);
  const [groupMLow7plus, setGroupMLow7plus] = useState(currentRates.M_Low_7plus);
  const [groupMNormal12, setGroupMNormal12] = useState(currentRates.M_Normal_12);
  const [groupMNormal36, setGroupMNormal36] = useState(currentRates.M_Normal_36);
  const [groupMNormal7plus, setGroupMNormal7plus] = useState(currentRates.M_Normal_7plus);
  const [groupMHigh12, setGroupMHigh12] = useState(currentRates.M_High_12);
  const [groupMHigh36, setGroupMHigh36] = useState(currentRates.M_High_36);
  const [groupMHigh7plus, setGroupMHigh7plus] = useState(currentRates.M_High_7plus);

  // Group D
  const [groupDLow12, setGroupDLow12] = useState(currentRates.D_Low_12);
  const [groupDLow36, setGroupDLow36] = useState(currentRates.D_Low_36);
  const [groupDLow7plus, setGroupDLow7plus] = useState(currentRates.D_Low_7plus);
  const [groupDNormal12, setGroupDNormal12] = useState(currentRates.D_Normal_12);
  const [groupDNormal36, setGroupDNormal36] = useState(currentRates.D_Normal_36);
  const [groupDNormal7plus, setGroupDNormal7plus] = useState(currentRates.D_Normal_7plus);
  const [groupDHigh12, setGroupDHigh12] = useState(currentRates.D_High_12);
  const [groupDHigh36, setGroupDHigh36] = useState(currentRates.D_High_36);
  const [groupDHigh7plus, setGroupDHigh7plus] = useState(currentRates.D_High_7plus);

  // Group E
  const [groupELow12, setGroupELow12] = useState(currentRates.E_Low_12);
  const [groupELow36, setGroupELow36] = useState(currentRates.E_Low_36);
  const [groupELow7plus, setGroupELow7plus] = useState(currentRates.E_Low_7plus);
  const [groupENormal12, setGroupENormal12] = useState(currentRates.E_Normal_12);
  const [groupENormal36, setGroupENormal36] = useState(currentRates.E_Normal_36);
  const [groupENormal7plus, setGroupENormal7plus] = useState(currentRates.E_Normal_7plus);
  const [groupEHigh12, setGroupEHigh12] = useState(currentRates.E_High_12);
  const [groupEHigh36, setGroupEHigh36] = useState(currentRates.E_High_36);
  const [groupEHigh7plus, setGroupEHigh7plus] = useState(currentRates.E_High_7plus);

  // Insurance and extras
  const [SCDWLow, setSCDWLow] = useState(currentRates.SCDW_Low);
  const [SCDWNormal, setSCDWNormal] = useState(currentRates.SCDW_Normal);
  const [SCDWHigh, setSCDWHigh] = useState(currentRates.SCDW_High);
  const [airportFee, setAirportFee] = useState(currentRates.Airport_Fee);
  const [shipPortFee, setShipPortFee] = useState(currentRates.Ship_Port_Fee);
  const [nightTimeFee, setNightTimeFee] = useState(currentRates.Night_Time_Fee);
  const [extraDriverFee, setExtraDriverFee] = useState(currentRates.Extra_Driver_Fee);
  const [chairFee, setChairFee] = useState(currentRates.Chair_Fee);

  // Dynamic adjustment
  const [lowMonthOffset, setLowMonthOffset] = useState(currentRates.Low_Month_Offset);
  const [normalMonthOffset, setNormalMonthOffset] = useState(currentRates.Normal_Month_Offset);
  const [highMonthOffset, setHighMonthOffset] = useState(currentRates.High_Month_Offset);

  const [percGroupAOccupancy, setPercGroupAOccupancy] = useState(currentRates.Perc_Group_A_Occupancy);
  const [percGroupBOccupancy, setPercGroupBOccupancy] = useState(currentRates.Perc_Group_B_Occupancy);
  const [percGroupCOccupancy, setPercGroupCOccupancy] = useState(currentRates.Perc_Group_C_Occupancy);
  const [percGroupMOccupancy, setPercGroupMOccupancy] = useState(currentRates.Perc_Group_M_Occupancy);
  const [percGroupDOccupancy, setPercGroupDOccupancy] = useState(currentRates.Perc_Group_D_Occupancy);
  const [percGroupEOccupancy, setPercGroupEOccupancy] = useState(currentRates.Perc_Group_E_Occupancy);

  const [discountThresholdLow, setDiscountThresholdLow] = useState(currentRates.Perc_Max_For_Discount_Low);
  const [discountThresholdNormal, setDiscountThresholdNormal] = useState(currentRates.Perc_Max_For_Discount_Normal);
  const [discountThresholdHigh, setDiscountThresholdHigh] = useState(currentRates.Perc_Max_For_Discount_High);
  const [surchargeThresholdLow, setSurchargeThresholdLow] = useState(currentRates.Perc_Min_For_Surcharge_Low);
  const [surchargeThresholdNormal, setSurchargeThresholdNormal] = useState(currentRates.Perc_Min_For_Surcharge_Normal);
  const [surchargeThresholdHigh, setSurchargeThresholdHigh] = useState(currentRates.Perc_Min_For_Surcharge_High);

  const [maxPercDiscountAdjustmentLow, setMaxPercDiscountAdjustmentLow] = useState(currentRates.Perc_Max_Discount_Adjustment_Low);
  const [maxPercDiscountAdjustmentNormal, setMaxPercDiscountAdjustmentNormal] = useState(currentRates.Perc_Max_Discount_Adjustment_Normal);
  const [maxPercDiscountAdjustmentHigh, setMaxPercDiscountAdjustmentHigh] = useState(currentRates.Perc_Max_Discount_Adjustment_High);
  const [maxPercExtraAdjustmentLow, setMaxPercExtraAdjustmentLow] = useState(currentRates.Perc_Max_Extra_Adjustment_Low);
  const [maxPercExtraAdjustmentNormal, setMaxPercExtraAdjustmentNormal] = useState(currentRates.Perc_Max_Extra_Adjustment_Normal);
  const [maxPercExtraAdjustmentHigh, setMaxPercExtraAdjustmentHigh] = useState(currentRates.Perc_Max_Extra_Adjustment_High);

  const [discountGrowthLow, setDiscountGrowthLow] = useState(currentRates.Discount_Growth_Low);
  const [discountGrowthNormal, setDiscountGrowthNormal] = useState(currentRates.Discount_Growth_Normal);
  const [discountGrowthHigh, setDiscountGrowthHigh] = useState(currentRates.Discount_Growth_High);
  const [surchargeGrowthLow, setSurchargeGrowthLow] = useState(currentRates.Surcharge_Growth_Low);
  const [surchargeGrowthNormal, setSurchargeGrowthNormal] = useState(currentRates.Surcharge_Growth_Normal);
  const [surchargeGrowthHigh, setSurchargeGrowthHigh] = useState(currentRates.Surcharge_Growth_High);

  // Simulator
  const [simStartDate, setSimStartDate] = useState("");
  const [simEndDate, setSimEndDate] = useState("");
  const [simStartLocal, setSimStartLocal] = useState("");
  const [simEndLocal, setSimEndLocal] = useState("");
  const [simGroup, setSimGroup] = useState("");
  const [simChairs03, setSimChairs03] = useState(0);
  const [simChairs47, setSimChairs47] = useState(0);
  const [simChairs812, setSimChairs812] = useState(0);
  const [simExtraDrivers, setSimExtraDrivers] = useState(0);
  const [simInsurance, setSimInsurance] = useState("");
  const [simBaseResult, setSimBaseResult] = useState("Base:  €___");
  const [simAdjustment, setSimAdjustment] = useState("Ajuste:  €___");
  const [simResult, setSimResult] = useState("Total:  €___ + €___ Excess");
  const [simTotalOccupancy, setSimTotalOccupancy] = useState("Ocupação total:  ");
  const [simGroupOccupancy, setSimGroupOccupancy] = useState("Ocupação do grupo:  ");
  const [simWeightedOccupancy, setSimWeightedOccupancy] = useState("Ocupação pesada:  ");
  const [simNumDays, setSimNumDays] = useState("# Days:  ");
  const [simNumLowDays, setSimNumLowDays] = useState("# Low Days:  ");
  const [simNumNormalDays, setSimNumNormalDays] = useState("# Normal Days:  ");
  const [simNumHighDays, setSimNumHighDays] = useState("# High Days:  ");
  const [simTotalFees, setSimTotalFees] = useState("Taxas (s/ajuste):  ");
  const [simBasePPD, setSimBasePPD] = useState("PPD (s/ajuste):  ");
  const [simTotalPPD, setSimTotalPPD] = useState("PPD (c/ajuste):  ");

  useEffect(() => {
    setGroupALow12(currentRates.A_Low_12);
    setGroupALow36(currentRates.A_Low_36);
    setGroupALow7plus(currentRates.A_Low_7plus);
    setGroupANormal12(currentRates.A_Normal_12);
    setGroupANormal36(currentRates.A_Normal_36);
    setGroupANormal7plus(currentRates.A_Normal_7plus);
    setGroupAHigh12(currentRates.A_High_12);
    setGroupAHigh36(currentRates.A_High_36);
    setGroupAHigh7plus(currentRates.A_High_7plus);
    setGroupBLow12(currentRates.B_Low_12);
    setGroupBLow36(currentRates.B_Low_36);
    setGroupBLow7plus(currentRates.B_Low_7plus);
    setGroupBNormal12(currentRates.B_Normal_12);
    setGroupBNormal36(currentRates.B_Normal_36);
    setGroupBNormal7plus(currentRates.B_Normal_7plus);
    setGroupBHigh12(currentRates.B_High_12);
    setGroupBHigh36(currentRates.B_High_36);
    setGroupBHigh7plus(currentRates.B_High_7plus);
    setGroupCLow12(currentRates.C_Low_12);
    setGroupCLow36(currentRates.C_Low_36);
    setGroupCLow7plus(currentRates.C_Low_7plus);
    setGroupCNormal12(currentRates.C_Normal_12);
    setGroupCNormal36(currentRates.C_Normal_36);
    setGroupCNormal7plus(currentRates.C_Normal_7plus);
    setGroupCHigh12(currentRates.C_High_12);
    setGroupCHigh36(currentRates.C_High_36);
    setGroupCHigh7plus(currentRates.C_High_7plus);
    setGroupMLow12(currentRates.M_Low_12);
    setGroupMLow36(currentRates.M_Low_36);
    setGroupMLow7plus(currentRates.M_Low_7plus);
    setGroupMNormal12(currentRates.M_Normal_12);
    setGroupMNormal36(currentRates.M_Normal_36);
    setGroupMNormal7plus(currentRates.M_Normal_7plus);
    setGroupMHigh12(currentRates.M_High_12);
    setGroupMHigh36(currentRates.M_High_36);
    setGroupMHigh7plus(currentRates.M_High_7plus);
    setGroupDLow12(currentRates.D_Low_12);
    setGroupDLow36(currentRates.D_Low_36);
    setGroupDLow7plus(currentRates.D_Low_7plus);
    setGroupDNormal12(currentRates.D_Normal_12);
    setGroupDNormal36(currentRates.D_Normal_36);
    setGroupDNormal7plus(currentRates.D_Normal_7plus);
    setGroupDHigh12(currentRates.D_High_12);
    setGroupDHigh36(currentRates.D_High_36);
    setGroupDHigh7plus(currentRates.D_High_7plus);
    setGroupELow12(currentRates.E_Low_12);
    setGroupELow36(currentRates.E_Low_36);
    setGroupELow7plus(currentRates.E_Low_7plus);
    setGroupENormal12(currentRates.E_Normal_12);
    setGroupENormal36(currentRates.E_Normal_36);
    setGroupENormal7plus(currentRates.E_Normal_7plus);
    setGroupEHigh12(currentRates.E_High_12);
    setGroupEHigh36(currentRates.E_High_36);
    setGroupEHigh7plus(currentRates.E_High_7plus);
    setSCDWLow(currentRates.SCDW_Low);
    setSCDWNormal(currentRates.SCDW_Normal);
    setSCDWHigh(currentRates.SCDW_High);
    setAirportFee(currentRates.Airport_Fee);
    setShipPortFee(currentRates.Ship_Port_Fee);
    setNightTimeFee(currentRates.Night_Time_Fee);
    setExtraDriverFee(currentRates.Extra_Driver_Fee);
    setChairFee(currentRates.Chair_Fee);
    setLowMonthOffset(currentRates.Low_Month_Offset);
    setNormalMonthOffset(currentRates.Normal_Month_Offset);
    setHighMonthOffset(currentRates.High_Month_Offset);
    setPercGroupAOccupancy(currentRates.Perc_Group_A_Occupancy);
    setPercGroupBOccupancy(currentRates.Perc_Group_B_Occupancy);
    setPercGroupCOccupancy(currentRates.Perc_Group_C_Occupancy);
    setPercGroupMOccupancy(currentRates.Perc_Group_M_Occupancy);
    setPercGroupDOccupancy(currentRates.Perc_Group_D_Occupancy);
    setPercGroupEOccupancy(currentRates.Perc_Group_E_Occupancy);
    setDiscountThresholdLow(currentRates.Perc_Max_For_Discount_Low);
    setDiscountThresholdNormal(currentRates.Perc_Max_For_Discount_Normal);
    setDiscountThresholdHigh(currentRates.Perc_Max_For_Discount_High);
    setSurchargeThresholdLow(currentRates.Perc_Min_For_Surcharge_Low);
    setSurchargeThresholdNormal(currentRates.Perc_Min_For_Surcharge_Normal);
    setSurchargeThresholdHigh(currentRates.Perc_Min_For_Surcharge_High);
    setMaxPercDiscountAdjustmentLow(currentRates.Perc_Max_Discount_Adjustment_Low);
    setMaxPercDiscountAdjustmentNormal(currentRates.Perc_Max_Discount_Adjustment_Normal);
    setMaxPercDiscountAdjustmentHigh(currentRates.Perc_Max_Discount_Adjustment_High);
    setMaxPercExtraAdjustmentLow(currentRates.Perc_Max_Extra_Adjustment_Low);
    setMaxPercExtraAdjustmentNormal(currentRates.Perc_Max_Extra_Adjustment_Normal);
    setMaxPercExtraAdjustmentHigh(currentRates.Perc_Max_Extra_Adjustment_High);
    setDiscountGrowthLow(currentRates.Discount_Growth_Low);
    setDiscountGrowthNormal(currentRates.Discount_Growth_Normal);
    setDiscountGrowthHigh(currentRates.Discount_Growth_High);
    setSurchargeGrowthLow(currentRates.Surcharge_Growth_Low);
    setSurchargeGrowthNormal(currentRates.Surcharge_Growth_Normal);
    setSurchargeGrowthHigh(currentRates.Surcharge_Growth_High);
  }, [currentRates]);


  function Season(props) {
    return <Grid item>
      <Grid container direction="row" gap={2}>
        <Grid item>
          <Typography>
            {props.season.Start} → {props.season.End} - {props.season.Type.charAt(0).toUpperCase() + props.season.Type.substr(1, props.season.Type.length)}
          </Typography>
        </Grid>
        <Grid item>
          <button hidden={locked} onClick={(event) => {
            socket.emit("remove_season", {
              password: getPassword(),
              time: moment().unix(),
              id: props.season.ID,
            });
          }}>🗑️</button>
        </Grid>
      </Grid>
    </Grid>
  }

  const setGroup = (group, tariff, value) => {
    if (group === "A") {
      if (tariff === "low12")
        setGroupALow12(value)
      else if (tariff === "low36")
        setGroupALow36(value)
      else if (tariff === "low7plus")
        setGroupALow7plus(value)
      else if (tariff === "normal12")
        setGroupANormal12(value)
      else if (tariff === "normal36")
        setGroupANormal36(value)
      else if (tariff === "normal7plus")
        setGroupANormal7plus(value)
      else if (tariff === "high12")
        setGroupAHigh12(value)
      else if (tariff === "high36")
        setGroupAHigh36(value)
      else if (tariff === "high7plus")
        setGroupAHigh7plus(value)
    }
    else if (group === "B") {
      if (tariff === "low12")
        setGroupBLow12(value)
      else if (tariff === "low36")
        setGroupBLow36(value)
      else if (tariff === "low7plus")
        setGroupBLow7plus(value)
      else if (tariff === "normal12")
        setGroupBNormal12(value)
      else if (tariff === "normal36")
        setGroupBNormal36(value)
      else if (tariff === "normal7plus")
        setGroupBNormal7plus(value)
      else if (tariff === "high12")
        setGroupBHigh12(value)
      else if (tariff === "high36")
        setGroupBHigh36(value)
      else if (tariff === "high7plus")
        setGroupBHigh7plus(value)
    }
    else if (group === "C") {
      if (tariff === "low12")
        setGroupCLow12(value)
      else if (tariff === "low36")
        setGroupCLow36(value)
      else if (tariff === "low7plus")
        setGroupCLow7plus(value)
      else if (tariff === "normal12")
        setGroupCNormal12(value)
      else if (tariff === "normal36")
        setGroupCNormal36(value)
      else if (tariff === "normal7plus")
        setGroupCNormal7plus(value)
      else if (tariff === "high12")
        setGroupCHigh12(value)
      else if (tariff === "high36")
        setGroupCHigh36(value)
      else if (tariff === "high7plus")
        setGroupCHigh7plus(value)
    }
    else if (group === "M") {
      if (tariff === "low12")
        setGroupMLow12(value)
      else if (tariff === "low36")
        setGroupMLow36(value)
      else if (tariff === "low7plus")
        setGroupMLow7plus(value)
      else if (tariff === "normal12")
        setGroupMNormal12(value)
      else if (tariff === "normal36")
        setGroupMNormal36(value)
      else if (tariff === "normal7plus")
        setGroupMNormal7plus(value)
      else if (tariff === "high12")
        setGroupMHigh12(value)
      else if (tariff === "high36")
        setGroupMHigh36(value)
      else if (tariff === "high7plus")
        setGroupMHigh7plus(value)
    }
    else if (group === "D") {
      if (tariff === "low12")
        setGroupDLow12(value)
      else if (tariff === "low36")
        setGroupDLow36(value)
      else if (tariff === "low7plus")
        setGroupDLow7plus(value)
      else if (tariff === "normal12")
        setGroupDNormal12(value)
      else if (tariff === "normal36")
        setGroupDNormal36(value)
      else if (tariff === "normal7plus")
        setGroupDNormal7plus(value)
      else if (tariff === "high12")
        setGroupDHigh12(value)
      else if (tariff === "high36")
        setGroupDHigh36(value)
      else if (tariff === "high7plus")
        setGroupDHigh7plus(value)
    }
    else if (group === "E") {
      if (tariff === "low12")
        setGroupELow12(value)
      else if (tariff === "low36")
        setGroupELow36(value)
      else if (tariff === "low7plus")
        setGroupELow7plus(value)
      else if (tariff === "normal12")
        setGroupENormal12(value)
      else if (tariff === "normal36")
        setGroupENormal36(value)
      else if (tariff === "normal7plus")
        setGroupENormal7plus(value)
      else if (tariff === "high12")
        setGroupEHigh12(value)
      else if (tariff === "high36")
        setGroupEHigh36(value)
      else if (tariff === "high7plus")
        setGroupEHigh7plus(value)
    }
  }

  function resetStats() {
    setSimBaseResult("Base: €___");
    setSimAdjustment("Ajuste: €___");
    setSimResult("Total: €___ + €___ Excess");
    setSimGroupOccupancy("Ocupação do grupo: ");
    setSimTotalOccupancy("Ocupação total: ");
    setSimWeightedOccupancy("Ocupação pesada: ");
    setSimNumDays("# Days: ")
    setSimNumLowDays("# Low Days: ")
    setSimNumNormalDays("# Normal Days: ")
    setSimNumHighDays("# High Days: ")
    setSimTotalFees("Taxas (s/ajuste): ")
    setSimBasePPD("PPD (s/ajuste): ")
    setSimTotalPPD("PPD (c/ajuste): ")
  }

  function getQuote(data) {
    if (Object.keys(data).every((element) => data[element] !== "")) {
      data.password = getPassword()
      fetch(websitebackend_ip + '/sysquote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          if (!response.ok)
            throw new Error('Response was not OK');
          return response.json();
        })
        .then(result => {
          let excess = ""
          if (["A", "B", "C", "C+", "M"].includes(data.carCategory.split(" ")[1])) {
            if (data.insuranceOption === "SCDW")
              excess = "€80.00 Excess"
            else
              excess = "€300.00 Excess"
          }
          else if (["D", "E"].includes(data.carCategory.split(" ")[1])) {
            if (data.insuranceOption === "SCDW")
              excess = "€200.00 Excess"
            else
              excess = "€500.00 Excess"
          }

          let baseResult = ""

          if (data.amountOfCars > 1)
            baseResult = data.amountOfCars + " x (" + "€" + Math.floor(result.quote.baseTotal) + ".00 + " + excess + ")"
          else
            baseResult = "€" + Math.floor(result.quote.baseTotal) + ".00 + " + excess

          let totalResult = ""

          if (data.amountOfCars > 1)
            totalResult = data.amountOfCars + " x (" + "€" + Math.floor(result.quote.total) + ".00 + " + excess + ")"
          else
            totalResult = "€" + Math.floor(result.quote.total) + ".00 + " + excess

          setSimBaseResult("Base: " + baseResult)
          setSimAdjustment("Ajuste: €" + (result.quote.adjustment * result.quote.baseTotal).toFixed(2) + " (" + (result.quote.adjustment * 100).toFixed(2) + "%)")
          setSimResult("Total: " + totalResult);
          setSimGroupOccupancy("Ocupação grupo: " + result.quote.groupOccupancy.toFixed(2) + "%")
          setSimTotalOccupancy("Ocupação total: " + result.quote.totalOccupancy.toFixed(2) + "%")
          setSimWeightedOccupancy("Ocupação final: " + result.quote.weightedOccupancy.toFixed(2) + "%")
          setSimNumDays("# Days: " + result.quote.numDays)
          setSimNumLowDays("# Low Days: " + result.quote.numLowDays)
          setSimNumNormalDays("# Normal Days: " + result.quote.numNormalDays)
          setSimNumHighDays("# High Days: " + result.quote.numHighDays)
          setSimTotalFees("Taxas (s/ajuste): " + result.quote.totalFees.toFixed(2))
          setSimBasePPD("PPD (s/ajuste): " + result.quote.basePPD.toFixed(2))
          setSimTotalPPD("PPD (c/ajuste): " + result.quote.totalPPD.toFixed(2))
        })
        .catch(error => {
          resetStats()
        });
    }
    else
      resetStats()
  }


  if (!isAuthenticated()) return <Navigate to="/" />;

  const groups = [
    { name: "A", low12: groupALow12, low36: groupALow36, low7plus: groupALow7plus, normal12: groupANormal12, normal36: groupANormal36, normal7plus: groupANormal7plus, high12: groupAHigh12, high36: groupAHigh36, high7plus: groupAHigh7plus },
    { name: "B", low12: groupBLow12, low36: groupBLow36, low7plus: groupBLow7plus, normal12: groupBNormal12, normal36: groupBNormal36, normal7plus: groupBNormal7plus, high12: groupBHigh12, high36: groupBHigh36, high7plus: groupBHigh7plus },
    { name: "C", low12: groupCLow12, low36: groupCLow36, low7plus: groupCLow7plus, normal12: groupCNormal12, normal36: groupCNormal36, normal7plus: groupCNormal7plus, high12: groupCHigh12, high36: groupCHigh36, high7plus: groupCHigh7plus },
    { name: "M", low12: groupMLow12, low36: groupMLow36, low7plus: groupMLow7plus, normal12: groupMNormal12, normal36: groupMNormal36, normal7plus: groupMNormal7plus, high12: groupMHigh12, high36: groupMHigh36, high7plus: groupMHigh7plus },
    { name: "D", low12: groupDLow12, low36: groupDLow36, low7plus: groupDLow7plus, normal12: groupDNormal12, normal36: groupDNormal36, normal7plus: groupDNormal7plus, high12: groupDHigh12, high36: groupDHigh36, high7plus: groupDHigh7plus },
    { name: "E", low12: groupELow12, low36: groupELow36, low7plus: groupELow7plus, normal12: groupENormal12, normal36: groupENormal36, normal7plus: groupENormal7plus, high12: groupEHigh12, high36: groupEHigh36, high7plus: groupEHigh7plus },
  ]

  return (
    <div>
      <Navbar />
      <div className={style.box}>
        <div>
          {window.innerWidth < 500 ? (<div><Grid item style={{ marginLeft: "40px" }}>
            <Typography
              style={{
                fontFamily: "sf pro text regular",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#2e3192",
              }}
            >
              Simulador
            </Typography>
            <br></br><br></br>
            <Grid container direction="row" gap={4}>
              <Grid item>
                <Grid container direction="column" gap={3}>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <BookingTextField
                        label="Data Inicial"
                        type="datetime-local"
                        inputFormat="dd-mm-yyyy"
                        value={simStartDate}
                        onChange={(event) => {
                          setSimStartDate(event.target.value); getQuote({
                            pickupDate: moment(event.target.value, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                            dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                            pickupLocation: simStartLocal,
                            dropoffLocation: simEndLocal,
                            carCategory: "Group " + simGroup,
                            amountOfCars: 1,
                            extraDriver: simExtraDrivers,
                            chair03Years: simChairs03,
                            chair47Years: simChairs47,
                            chair812Years: simChairs812,
                            insuranceOption: simInsurance ? "SCDW" : "CDW",
                          })
                        }}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: "170px",
                          input: {
                            fontSize: 12,
                            fontFamily: "sf pro text regular",
                            textAlign: "center",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <BookingTextField
                        label="Data Final"
                        type="datetime-local"
                        inputFormat="dd/mm/yyyy"
                        value={simEndDate}
                        onChange={(event) => {
                          setSimEndDate(event.target.value); getQuote({
                            pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                            dropoffDate: moment(event.target.value, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                            pickupLocation: simStartLocal,
                            dropoffLocation: simEndLocal,
                            carCategory: "Group " + simGroup,
                            amountOfCars: 1,
                            extraDriver: simExtraDrivers,
                            chair03Years: simChairs03,
                            chair47Years: simChairs47,
                            chair812Years: simChairs812,
                            insuranceOption: simInsurance ? "SCDW" : "CDW",
                          })
                        }}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: "170px",
                          input: {
                            fontSize: 12,
                            fontFamily: "sf pro text regular",
                            textAlign: "center",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      freeSolo={true}
                      options={["Aeroporto", "Funchal", "Pontinha"]}
                      value={simStartLocal}
                      onInputChange={(event, value) => {
                        setSimStartLocal(value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: value,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: simChairs03,
                          chair47Years: simChairs47,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Local Inicial
                            </Typography>
                          }
                          InputLabelProps={{ shrink: true }}
                          style={{ width: "100%", maxWidth: "170px" }}
                          sx={{
                            input: {
                              fontSize: {
                                lg: 14,
                                md: 12,
                                sm: 12,
                                xs: 8,
                              },
                              fontFamily: "sf pro text regular",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      freeSolo={true}
                      options={["Aeroporto", "Funchal", "Pontinha"]}
                      value={simEndLocal}
                      onInputChange={(event, value) => {
                        setSimEndLocal(value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: value,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: simChairs03,
                          chair47Years: simChairs47,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Local Final
                            </Typography>
                          }
                          InputLabelProps={{ shrink: true }}
                          style={{ width: "100%", maxWidth: "170px" }}
                          sx={{
                            input: {
                              fontSize: {
                                lg: 14,
                                md: 12,
                                sm: 12,
                                xs: 8,
                              },
                              fontFamily: "sf pro text regular",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      size="small"
                      freeSolo={true}
                      options={["A", "B", "C", "M", "D", "E"]}
                      value={simGroup}
                      onInputChange={(event, value) => {
                        setSimGroup(value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + value,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: simChairs03,
                          chair47Years: simChairs47,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Grupo
                            </Typography>
                          }
                          InputLabelProps={{ shrink: true }}
                          style={{ width: "100%", maxWidth: "170px" }}
                          sx={{
                            input: {
                              fontSize: {
                                lg: 14,
                                md: 12,
                                sm: 12,
                                xs: 8,
                              },
                              fontFamily: "sf pro text regular",
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" gap={3}>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cadeiras 0-3
                        </Typography>
                      }
                      value={simChairs03}
                      onChange={(event) => {
                        setSimChairs03(event.target.value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: event.target.value,
                          chair47Years: simChairs47,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "60px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cadeiras 4-7
                        </Typography>
                      }
                      value={simChairs47}
                      onChange={(event) => {
                        setSimChairs47(event.target.value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: simChairs03,
                          chair47Years: event.target.value,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "60px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cadeiras 8-12
                        </Typography>
                      }
                      value={simChairs812}
                      onChange={(event) => {
                        setSimChairs812(event.target.value); getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: simExtraDrivers,
                          chair03Years: simChairs03,
                          chair47Years: simChairs47,
                          chair812Years: event.target.value,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "60px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cond Extra
                        </Typography>
                      }
                      value={simExtraDrivers}
                      onChange={(event) => {
                        setSimExtraDrivers(event.target.value);
                        getQuote({
                          pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                          pickupLocation: simStartLocal,
                          dropoffLocation: simEndLocal,
                          carCategory: "Group " + simGroup,
                          amountOfCars: 1,
                          extraDriver: event.target.value,
                          chair03Years: simChairs03,
                          chair47Years: simChairs47,
                          chair812Years: simChairs812,
                          insuranceOption: simInsurance ? "SCDW" : "CDW",
                        })
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "60px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          id="yearly-season"
                          checked={simInsurance}
                          onChange={(event) => {
                            setSimInsurance(!simInsurance); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: !simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          sx={{
                            color: "#eeeeee",
                            transform: "translate(0px, -7px)",
                            "&.Mui-checked": {
                              color: "#2e3192",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "9px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          SCDW
                        </Typography>
                      }
                      labelPlacement="top"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simBaseResult}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simAdjustment}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simResult}
                </Typography>
                <br></br>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simGroupOccupancy}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simTotalOccupancy}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simWeightedOccupancy}
                </Typography>
                <br></br>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simNumDays}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simNumLowDays}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simNumNormalDays}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simNumHighDays}
                </Typography>
                <br></br>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simTotalFees}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simBasePPD}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  {simTotalPPD}
                </Typography>
              </Grid>
            </Grid>
          </Grid></div>) : (<div><Grid container direction="row" columnGap={2}>
            <Grid item>
              <Typography
                style={{
                  fontFamily: "sf pro text regular",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#2e3192",
                }}
              >
                Tarifas
              </Typography>
            </Grid>
            <Grid item style={{ transform: "translate(-10px, 4px)" }}>
              {
                locked ? <FaLock style={{ color: "#2e3192" }} onClick={() => { setLocked(!locked) }}></FaLock> : <FaLockOpen style={{ color: "#2e3192" }} onClick={() => {
                  setLocked(!locked); socket.emit("update_rates", {
                    password: getPassword(),
                    data: {
                      A_Low_12: parseFloat(groupALow12),
                      A_Low_36: parseFloat(groupALow36),
                      A_Low_7plus: parseFloat(groupALow7plus),
                      A_Normal_12: parseFloat(groupANormal12),
                      A_Normal_36: parseFloat(groupANormal36),
                      A_Normal_7plus: parseFloat(groupANormal7plus),
                      A_High_12: parseFloat(groupAHigh12),
                      A_High_36: parseFloat(groupAHigh36),
                      A_High_7plus: parseFloat(groupAHigh7plus),
                      B_Low_12: parseFloat(groupBLow12),
                      B_Low_36: parseFloat(groupBLow36),
                      B_Low_7plus: parseFloat(groupBLow7plus),
                      B_Normal_12: parseFloat(groupBNormal12),
                      B_Normal_36: parseFloat(groupBNormal36),
                      B_Normal_7plus: parseFloat(groupBNormal7plus),
                      B_High_12: parseFloat(groupBHigh12),
                      B_High_36: parseFloat(groupBHigh36),
                      B_High_7plus: parseFloat(groupBHigh7plus),
                      C_Low_12: parseFloat(groupCLow12),
                      C_Low_36: parseFloat(groupCLow36),
                      C_Low_7plus: parseFloat(groupCLow7plus),
                      C_Normal_12: parseFloat(groupCNormal12),
                      C_Normal_36: parseFloat(groupCNormal36),
                      C_Normal_7plus: parseFloat(groupCNormal7plus),
                      C_High_12: parseFloat(groupCHigh12),
                      C_High_36: parseFloat(groupCHigh36),
                      C_High_7plus: parseFloat(groupCHigh7plus),
                      M_Low_12: parseFloat(groupMLow12),
                      M_Low_36: parseFloat(groupMLow36),
                      M_Low_7plus: parseFloat(groupMLow7plus),
                      M_Normal_12: parseFloat(groupMNormal12),
                      M_Normal_36: parseFloat(groupMNormal36),
                      M_Normal_7plus: parseFloat(groupMNormal7plus),
                      M_High_12: parseFloat(groupMHigh12),
                      M_High_36: parseFloat(groupMHigh36),
                      M_High_7plus: parseFloat(groupMHigh7plus),
                      D_Low_12: parseFloat(groupDLow12),
                      D_Low_36: parseFloat(groupDLow36),
                      D_Low_7plus: parseFloat(groupDLow7plus),
                      D_Normal_12: parseFloat(groupDNormal12),
                      D_Normal_36: parseFloat(groupDNormal36),
                      D_Normal_7plus: parseFloat(groupDNormal7plus),
                      D_High_12: parseFloat(groupDHigh12),
                      D_High_36: parseFloat(groupDHigh36),
                      D_High_7plus: parseFloat(groupDHigh7plus),
                      E_Low_12: parseFloat(groupELow12),
                      E_Low_36: parseFloat(groupELow36),
                      E_Low_7plus: parseFloat(groupELow7plus),
                      E_Normal_12: parseFloat(groupENormal12),
                      E_Normal_36: parseFloat(groupENormal36),
                      E_Normal_7plus: parseFloat(groupENormal7plus),
                      E_High_12: parseFloat(groupEHigh12),
                      E_High_36: parseFloat(groupEHigh36),
                      E_High_7plus: parseFloat(groupEHigh7plus),
                      Airport_Fee: parseFloat(airportFee),
                      Ship_Port_Fee: parseFloat(shipPortFee),
                      Night_Time_Fee: parseFloat(nightTimeFee),
                      Extra_Driver_Fee: parseFloat(extraDriverFee),
                      Chair_Fee: parseFloat(chairFee),
                      SCDW_Low: parseFloat(SCDWLow),
                      SCDW_Normal: parseFloat(SCDWNormal),
                      SCDW_High: parseFloat(SCDWHigh),
                      Low_Month_Offset: parseFloat(lowMonthOffset),
                      Normal_Month_Offset: parseFloat(normalMonthOffset),
                      High_Month_Offset: parseFloat(highMonthOffset),
                      Perc_Group_A_Occupancy: parseFloat(percGroupAOccupancy),
                      Perc_Group_B_Occupancy: parseFloat(percGroupBOccupancy),
                      Perc_Group_C_Occupancy: parseFloat(percGroupCOccupancy),
                      Perc_Group_M_Occupancy: parseFloat(percGroupMOccupancy),
                      Perc_Group_D_Occupancy: parseFloat(percGroupDOccupancy),
                      Perc_Group_E_Occupancy: parseFloat(percGroupEOccupancy),
                      Perc_Max_For_Discount_Low: parseFloat(discountThresholdLow),
                      Perc_Max_For_Discount_Normal: parseFloat(discountThresholdNormal),
                      Perc_Max_For_Discount_High: parseFloat(discountThresholdHigh),
                      Perc_Min_For_Surcharge_Low: parseFloat(surchargeThresholdLow),
                      Perc_Min_For_Surcharge_Normal: parseFloat(surchargeThresholdNormal),
                      Perc_Min_For_Surcharge_High: parseFloat(surchargeThresholdHigh),
                      Perc_Max_Discount_Adjustment_Low: parseFloat(maxPercDiscountAdjustmentLow),
                      Perc_Max_Discount_Adjustment_Normal: parseFloat(maxPercDiscountAdjustmentNormal),
                      Perc_Max_Discount_Adjustment_High: parseFloat(maxPercDiscountAdjustmentHigh),
                      Perc_Max_Extra_Adjustment_Low: parseFloat(maxPercExtraAdjustmentLow),
                      Perc_Max_Extra_Adjustment_Normal: parseFloat(maxPercExtraAdjustmentNormal),
                      Perc_Max_Extra_Adjustment_High: parseFloat(maxPercExtraAdjustmentHigh),
                      Discount_Growth_Low: parseFloat(discountGrowthLow),
                      Discount_Growth_Normal: parseFloat(discountGrowthNormal),
                      Discount_Growth_High: parseFloat(discountGrowthHigh),
                      Surcharge_Growth_Low: parseFloat(surchargeGrowthLow),
                      Surcharge_Growth_Normal: parseFloat(surchargeGrowthNormal),
                      Surcharge_Growth_High: parseFloat(surchargeGrowthHigh),
                      Valid_From: moment().unix()
                    },
                  });
                }}></FaLockOpen>
              }
            </Grid>
          </Grid>
            <Grid container direction="row" columnGap={8} rowGap={4}>
              <Grid item style={{ marginLeft: "40px" }}>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  Simulador
                </Typography>
                <br></br><br></br>
                <Grid container direction="row" gap={4}>
                  <Grid item>
                    <Grid container direction="column" gap={3}>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <BookingTextField
                            label="Data Inicial"
                            type="datetime-local"
                            inputFormat="dd-mm-yyyy"
                            value={simStartDate}
                            onChange={(event) => {
                              setSimStartDate(event.target.value); getQuote({
                                pickupDate: moment(event.target.value, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                pickupLocation: simStartLocal,
                                dropoffLocation: simEndLocal,
                                carCategory: "Group " + simGroup,
                                amountOfCars: 1,
                                extraDriver: simExtraDrivers,
                                chair03Years: simChairs03,
                                chair47Years: simChairs47,
                                chair812Years: simChairs812,
                                insuranceOption: simInsurance ? "SCDW" : "CDW",
                              })
                            }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              width: "170px",
                              input: {
                                fontSize: 12,
                                fontFamily: "sf pro text regular",
                                textAlign: "center",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <BookingTextField
                            label="Data Final"
                            type="datetime-local"
                            inputFormat="dd/mm/yyyy"
                            value={simEndDate}
                            onChange={(event) => {
                              setSimEndDate(event.target.value); getQuote({
                                pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                dropoffDate: moment(event.target.value, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                pickupLocation: simStartLocal,
                                dropoffLocation: simEndLocal,
                                carCategory: "Group " + simGroup,
                                amountOfCars: 1,
                                extraDriver: simExtraDrivers,
                                chair03Years: simChairs03,
                                chair47Years: simChairs47,
                                chair812Years: simChairs812,
                                insuranceOption: simInsurance ? "SCDW" : "CDW",
                              })
                            }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              width: "170px",
                              input: {
                                fontSize: 12,
                                fontFamily: "sf pro text regular",
                                textAlign: "center",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item>
                        <Autocomplete
                          size="small"
                          freeSolo={true}
                          options={["Aeroporto", "Funchal", "Pontinha"]}
                          value={simStartLocal}
                          onInputChange={(event, value) => {
                            setSimStartLocal(value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: value,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          renderInput={(params) => (
                            <BookingTextField
                              {...params}
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Local Inicial
                                </Typography>
                              }
                              InputLabelProps={{ shrink: true }}
                              style={{ width: "100%", maxWidth: "170px" }}
                              sx={{
                                input: {
                                  fontSize: {
                                    lg: 14,
                                    md: 12,
                                    sm: 12,
                                    xs: 8,
                                  },
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Autocomplete
                          size="small"
                          freeSolo={true}
                          options={["Aeroporto", "Funchal", "Pontinha"]}
                          value={simEndLocal}
                          onInputChange={(event, value) => {
                            setSimEndLocal(value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: value,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          renderInput={(params) => (
                            <BookingTextField
                              {...params}
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Local Final
                                </Typography>
                              }
                              InputLabelProps={{ shrink: true }}
                              style={{ width: "100%", maxWidth: "170px" }}
                              sx={{
                                input: {
                                  fontSize: {
                                    lg: 14,
                                    md: 12,
                                    sm: 12,
                                    xs: 8,
                                  },
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Autocomplete
                          size="small"
                          freeSolo={true}
                          options={["A", "B", "C", "M", "D", "E"]}
                          value={simGroup}
                          onInputChange={(event, value) => {
                            setSimGroup(value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + value,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          renderInput={(params) => (
                            <BookingTextField
                              {...params}
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Grupo
                                </Typography>
                              }
                              InputLabelProps={{ shrink: true }}
                              style={{ width: "100%", maxWidth: "170px" }}
                              sx={{
                                input: {
                                  fontSize: {
                                    lg: 14,
                                    md: 12,
                                    sm: 12,
                                    xs: 8,
                                  },
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" gap={3}>
                      <Grid item>
                        <BookingTextField
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Cadeiras 0-3
                            </Typography>
                          }
                          value={simChairs03}
                          onChange={(event) => {
                            setSimChairs03(event.target.value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: event.target.value,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            input: {
                              width: "60px",
                              height: "0px",
                              fontSize: 12,
                              textAlign: "center",
                              fontFamily: "sf pro text regular",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <BookingTextField
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Cadeiras 4-7
                            </Typography>
                          }
                          value={simChairs47}
                          onChange={(event) => {
                            setSimChairs47(event.target.value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: event.target.value,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            input: {
                              width: "60px",
                              height: "0px",
                              fontSize: 12,
                              textAlign: "center",
                              fontFamily: "sf pro text regular",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <BookingTextField
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Cadeiras 8-12
                            </Typography>
                          }
                          value={simChairs812}
                          onChange={(event) => {
                            setSimChairs812(event.target.value); getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: simExtraDrivers,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: event.target.value,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            input: {
                              width: "60px",
                              height: "0px",
                              fontSize: 12,
                              textAlign: "center",
                              fontFamily: "sf pro text regular",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <BookingTextField
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              Cond Extra
                            </Typography>
                          }
                          value={simExtraDrivers}
                          onChange={(event) => {
                            setSimExtraDrivers(event.target.value);
                            getQuote({
                              pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                              pickupLocation: simStartLocal,
                              dropoffLocation: simEndLocal,
                              carCategory: "Group " + simGroup,
                              amountOfCars: 1,
                              extraDriver: event.target.value,
                              chair03Years: simChairs03,
                              chair47Years: simChairs47,
                              chair812Years: simChairs812,
                              insuranceOption: simInsurance ? "SCDW" : "CDW",
                            })
                          }}
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            input: {
                              width: "60px",
                              height: "0px",
                              fontSize: 12,
                              textAlign: "center",
                              fontFamily: "sf pro text regular",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                              id="yearly-season"
                              checked={simInsurance}
                              onChange={(event) => {
                                setSimInsurance(!simInsurance); getQuote({
                                  pickupDate: moment(simStartDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                  dropoffDate: moment(simEndDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                  pickupLocation: simStartLocal,
                                  dropoffLocation: simEndLocal,
                                  carCategory: "Group " + simGroup,
                                  amountOfCars: 1,
                                  extraDriver: simExtraDrivers,
                                  chair03Years: simChairs03,
                                  chair47Years: simChairs47,
                                  chair812Years: simChairs812,
                                  insuranceOption: !simInsurance ? "SCDW" : "CDW",
                                })
                              }}
                              sx={{
                                color: "#eeeeee",
                                transform: "translate(0px, -7px)",
                                "&.Mui-checked": {
                                  color: "#2e3192",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontFamily: "sf pro text regular",
                                fontSize: "9px",
                                fontWeight: "bold",
                                color: "#2e3192",
                              }}
                            >
                              SCDW
                            </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simBaseResult}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simAdjustment}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simResult}
                    </Typography>
                    <br></br>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simGroupOccupancy}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simTotalOccupancy}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simWeightedOccupancy}
                    </Typography>
                    <br></br>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simNumDays}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simNumLowDays}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simNumNormalDays}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simNumHighDays}
                    </Typography>
                    <br></br>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simTotalFees}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simBasePPD}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      {simTotalPPD}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" gap={2}>
                  <Grid item>
                    <Grid container direction="row" columnGap={1}>
                      {groups.map(group => {
                        return <Group group={group} locked={locked} setGroup={setGroup}></Group>
                      })}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" gap={2}>
                      <Grid item>
                        <Grid container direction="column" gap={2}>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Meses Baixa
                                </Typography>
                              }
                              value={lowMonthOffset}
                              disabled={locked}
                              onChange={(event) => {
                                setLowMonthOffset(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Meses Normal
                                </Typography>
                              }
                              value={normalMonthOffset}
                              disabled={locked}
                              onChange={(event) => {
                                setNormalMonthOffset(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Meses Alta
                                </Typography>
                              }
                              value={highMonthOffset}
                              disabled={locked}
                              onChange={(event) => {
                                setHighMonthOffset(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" gap={2}>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo A
                                </Typography>
                              }
                              value={percGroupAOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupAOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo B
                                </Typography>
                              }
                              value={percGroupBOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupBOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo C
                                </Typography>
                              }
                              value={percGroupCOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupCOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo M
                                </Typography>
                              }
                              value={percGroupMOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupMOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo D
                                </Typography>
                              }
                              value={percGroupDOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupDOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Grupo E
                                </Typography>
                              }
                              value={percGroupEOccupancy}
                              disabled={locked}
                              onChange={(event) => {
                                setPercGroupEOccupancy(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "50px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" gap={2}>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Desc. Baixa
                                </Typography>
                              }
                              value={discountThresholdLow}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountThresholdLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Desc Normal
                                </Typography>
                              }
                              value={discountThresholdNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountThresholdNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Desc. Alta
                                </Typography>
                              }
                              value={discountThresholdHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountThresholdHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Desc. Baixa
                                </Typography>
                              }
                              value={discountGrowthLow}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountGrowthLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Desc Normal
                                </Typography>
                              }
                              value={discountGrowthNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountGrowthNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Desc. Alta
                                </Typography>
                              }
                              value={discountGrowthHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setDiscountGrowthHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Desc. Baixa
                                </Typography>
                              }
                              value={maxPercDiscountAdjustmentLow}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercDiscountAdjustmentLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Desc Normal
                                </Typography>
                              }
                              value={maxPercDiscountAdjustmentNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercDiscountAdjustmentNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Desc. Alta
                                </Typography>
                              }
                              value={maxPercDiscountAdjustmentHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercDiscountAdjustmentHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" gap={2}>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Extra Baixa
                                </Typography>
                              }
                              value={surchargeThresholdLow}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeThresholdLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Extra Normal
                                </Typography>
                              }
                              value={surchargeThresholdNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeThresholdNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Limite Extra Alta
                                </Typography>
                              }
                              value={surchargeThresholdHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeThresholdHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Extra Baixa
                                </Typography>
                              }
                              value={surchargeGrowthLow}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeGrowthLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Extra Normal
                                </Typography>
                              }
                              value={surchargeGrowthNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeGrowthNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Rácio Extra Alta
                                </Typography>
                              }
                              value={surchargeGrowthHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setSurchargeGrowthHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Extra Baixa
                                </Typography>
                              }
                              value={maxPercExtraAdjustmentLow}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercExtraAdjustmentLow(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Extra Normal
                                </Typography>
                              }
                              value={maxPercExtraAdjustmentNormal}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercExtraAdjustmentNormal(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BookingTextField
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  % Ajuste Extra Alta
                                </Typography>
                              }
                              value={maxPercExtraAdjustmentHigh}
                              disabled={locked}
                              onChange={(event) => {
                                setMaxPercExtraAdjustmentHigh(event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                input: {
                                  width: "90px",
                                  height: "0px",
                                  fontSize: 12,
                                  textAlign: "center",
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" rowGap={2}>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#2e3192",
                      }}
                    >
                      Extras
                    </Typography>
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          SCDW Low
                        </Typography>
                      }
                      value={SCDWLow}
                      disabled={locked}
                      onChange={(event) => {
                        setSCDWLow(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "50px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          SCDW Normal
                        </Typography>
                      }
                      value={SCDWNormal}
                      disabled={locked}
                      onChange={(event) => {
                        setSCDWNormal(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "50px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          SCDW High
                        </Typography>
                      }
                      value={SCDWHigh}
                      disabled={locked}
                      onChange={(event) => {
                        setSCDWHigh(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "50px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Aeroporto
                        </Typography>
                      }
                      value={airportFee}
                      disabled={locked}
                      onChange={(event) => {
                        setAirportFee(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "35px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Pontinha
                        </Typography>
                      }
                      value={shipPortFee}
                      disabled={locked}
                      onChange={(event) => {
                        setShipPortFee(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "35px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Noite
                        </Typography>
                      }
                      value={nightTimeFee}
                      disabled={locked}
                      onChange={(event) => {
                        setNightTimeFee(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "35px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cond Extra
                        </Typography>
                      }
                      value={extraDriverFee}
                      disabled={locked}
                      onChange={(event) => {
                        setExtraDriverFee(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "35px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label={
                        <Typography
                          style={{
                            fontFamily: "sf pro text regular",
                            fontSize: "10px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Cadeira
                        </Typography>
                      }
                      value={chairFee}
                      disabled={locked}
                      onChange={(event) => {
                        setChairFee(event.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        input: {
                          width: "35px",
                          height: "0px",
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "sf pro text regular",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ transform: "translate(0px, -100px)" }}>
              <Grid item style={{ marginLeft: "12px", marginBottom: "15px" }}>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#2e3192",
                  }}
                >
                  Épocas
                </Typography>
              </Grid>
              <Grid container direction="row" gap={4}>
                <Grid item>
                  < Grid container direction="column" rowGap={2} >
                    <Grid item style={{ transform: "translate(0px, 0px)" }}>
                      <FormControlLabel
                        value="top"
                        control={
                          <Checkbox
                            id="yearly-season"
                            checked={repeatingSeason}
                            onChange={(event) => { setRepeatingSeason(!repeatingSeason) }}
                            sx={{
                              color: "#eeeeee",
                              transform: "translate(0px, -7px)",
                              "&.Mui-checked": {
                                color: "#2e3192",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontFamily: "sf pro text regular",
                              fontSize: "9px",
                              fontWeight: "bold",
                              color: "#2e3192",
                            }}
                          >
                            Repete
                          </Typography>
                        }
                        labelPlacement="top"
                      />
                    </Grid>
                    <Grid item style={{ transform: "translate(0px, -15px)" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BookingTextField
                          id="add-booking-start-date"
                          label="Data Inicial"
                          type="datetime-local"
                          inputFormat="dd-mm-yyyy"
                          value={seasonStartDate}
                          onChange={(event) => { setSeasonStartDate(event.target.value) }}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "205px",
                            input: {
                              fontSize: 12,
                              fontFamily: "sf pro text regular",
                              textAlign: "center",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item style={{ transform: "translate(0px, -15px)" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <BookingTextField
                          id="add-booking-start-date"
                          label="Data Final"
                          type="datetime-local"
                          inputFormat="dd/mm/yyyy"
                          value={seasonEndDate}
                          onChange={(event) => { setSeasonEndDate(event.target.value) }}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "205px",
                            input: {
                              fontSize: 12,
                              fontFamily: "sf pro text regular",
                              textAlign: "center",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item>
                      <FormControl style={{ width: "200px", transform: "translate(0px, -15px)" }}>
                        <InputLabel>Tipo</InputLabel>
                        <Select
                          value={seasonType}
                          onChange={(event) => { setSeasonType(event.target.value) }}
                        >
                          <MenuItem value="baixa">Baixa</MenuItem>
                          <MenuItem value="normal">Normal</MenuItem>
                          <MenuItem value="alta">Alta</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <button disabled={locked} style={{ transform: "translate(0px, -15px)", border: locked ? "1px solid #eee" : "1px solid #2e3192", borderRadius: "5px", padding: "5px", color: locked ? "#eee" : "#2e3192", fontSize: "16px" }}
                        onClick={(event) => {
                          if (locked) return;
                          else {
                            let startDay = seasonStartDate.split("T")[0]
                            let startTime = seasonStartDate.split("T")[1]

                            let endDay = seasonEndDate.split("T")[0]
                            let endTime = seasonEndDate.split("T")[1]

                            if (repeatingSeason) {
                              let startDaySplits = startDay.split("-")
                              if (startDaySplits.length > 2)
                                startDay = startDaySplits[2] + "/" + startDaySplits[1]
                              else
                                startDay = startDaySplits[0]

                              let endDaySplits = endDay.split("-")
                              if (endDaySplits.length > 2)
                                endDay = endDaySplits[2] + "/" + endDaySplits[1]
                              else
                                endDay = endDaySplits[0]
                            }
                            else {
                              let startDaySplits = startDay.split("-")
                              startDay = startDaySplits[2] + "/" + startDaySplits[1] + "/" + startDaySplits[0]

                              let endDaySplits = endDay.split("-")
                              endDay = endDaySplits[2] + "/" + endDaySplits[1] + "/" + endDaySplits[0]
                            }

                            let data = { start: startDay + " " + startTime, end: endDay + " " + endTime, type: seasonType, valid_from: moment().unix() }

                            socket.emit("add_season", {
                              password: getPassword(),
                              data: data
                            })
                          }
                        }}>adicionar</button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" gap={1}>
                    {currentSeasons.map(season => {
                      return <Season season={season}></Season>
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid></div>)}

        </div>
      </div >
    </div >
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
    rates: state.rates.rates,
    seasons: state.seasons.seasons
  };
}

export default connect(mapStateToProps)(Rates);
