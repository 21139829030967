import { store } from "../../index.js";
import * as actions from "./index.js";

export const changeCalendarMode = (mode) => {
  store.dispatch(actions.changeCalendarMode(mode));
};

export const openEventPopup = (booking_id) => {
  store.dispatch(actions.openEventPopup(booking_id));
};

export const closeEventPopup = () => {
  store.dispatch(actions.closeEventPopup());
};

export const changeInterval = (interval) => {
  store.dispatch(actions.changeInterval(interval));
};
