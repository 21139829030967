import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import {
  expandBooking,
  openDeleteBookingPopup,
  openEditBookingPopup,
  updateEditBookingSection,
} from "../States/Actions/Bookings.js";
import moment from "moment";
import 'moment/locale/pt'

class ListItem extends React.PureComponent {
  handle_expand = () => {
    expandBooking(this.props.booking.ID);
  };

  render() {
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={11.52}>
            <Accordion
              expanded={this.props.expanded}
              onClick={this.handle_expand}
              disableGutters={true}
              style={{
                background: "white",
                //boxShadow: "-5px 0px 5px -1px #dddddd",
                boxShadow: "0px 0px 0px 0px #ffffff",
                borderTop: "1px solid #f1f1f1",
                borderRadius: 0,
                borderBottom: "0px",
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                style={{
                  padding: 0,
                  border: "0px",
                }}
              >
                <Grid
                  container
                  id={"booking-row-" + this.props.booking.ID}
                  direction="row"
                  justify="space-between"
                  style={{
                    color: "#2e3192",
                    textAlign: "center",
                    fontFamily: "sf pro text regular",
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  sx={{
                    fontSize: {
                      lg: 14,
                      md: 12,
                      sm: 10,
                      xs: 8,
                    },
                    fontWeight: {
                      lg: "bold",
                      md: "normal",
                      sm: "normal",
                      xs: "normal",
                    },
                  }}
                  wrap="nowrap"
                >
                  <Grid
                    item
                    xs={2.2902}
                    paddingLeft={"16px"}
                    paddingRight={"10px"}
                    textAlign={"left"}
                  >
                    {this.props.booking.Client_Name}
                  </Grid>
                  <Grid item xs={1.12428}>
                    {this.props.booking.Start_Date}
                  </Grid>
                  <Grid item xs={1.12428}>
                    {this.props.booking.Start_Time}
                  </Grid>
                  <Grid item xs={1.62396}>
                    {this.props.booking.Start_Local}
                  </Grid>
                  <Grid item xs={1.12428}>
                    {this.props.booking.End_Date}
                  </Grid>
                  <Grid item xs={1.12428}>
                    {this.props.booking.End_Time}
                  </Grid>
                  <Grid item xs={1.62396}>
                    {this.props.booking.End_Local}
                  </Grid>
                  <Grid item xs={0.6246}>
                    {this.props.booking.Num_Days}
                  </Grid>

                  <Grid item xs={0.9369}>
                    {this.props.booking.Car_Plate !== "" ? (
                      <Box
                        style={{ height: "100%", maxHeight: "20px" }}
                        display="flex"
                        justifyContent="center"
                      >
                        <div
                          style={{
                            width: "6px",
                            height: "100%",
                            background: "#2c55ce",
                            borderTopLeftRadius: "3px",
                            borderBottomLeftRadius: "3px",
                            border: "1px solid black",
                            borderRight: "0px",
                            marginLeft: "0 auto",
                            padding: "0px",
                            float: "left",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "auto",
                            height: "100%",
                            border: "1px solid black",
                            borderLeft: "0px",
                            borderTopRightRadius: "3px",
                            borderBottomRightRadius: "3px",
                            background: "white",
                            padding: "0px 4px 0px 4px",
                            float: "left",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              flexWrap: "wrap",
                              wordWrap: "break-word",
                              fontWeight: "bold",
                              color: "#000000",
                              fontFamily: "monospace",
                            }}
                            sx={{
                              fontSize: {
                                lg: 14,
                                md: 12,
                                sm: 10,
                                xs: 8,
                              },
                            }}
                          >
                            {this.props.booking.Car_Plate}
                          </Typography>
                        </div>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={0.39558}>
                    {this.props.booking.ID}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "#f9f9f9",
                  boxShadow: "0px -2px 10px 2px #f9f9f9",
                  border: "0px",
                }}
              >
                <Grid
                  container
                  direction="row"
                  rowSpacing={1}
                  columnSpacing={0}
                  color={"#171a61"}
                  whiteSpace={"pre"}
                >
                  <Grid item xs={5}>
                    <Grid container direction="row" marginTop={1}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Email:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Client_Email !== ""
                                ? this.props.booking.Client_Email
                                : " -"}
                            </Typography>
                          </Grid>
                          <Grid item marginTop={"10px"}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Telefone:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Client_Phone !== ""
                                ? this.props.booking.Client_Phone
                                : " -"}
                            </Typography>
                          </Grid>
                          <Grid item marginTop={"10px"}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Morada:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Client_Address !== ""
                                ? this.props.booking.Client_Address
                                : " -"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container direction="row" marginTop={1}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Marca:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Car_Brand !== ""
                                ? this.props.booking.Car_Brand
                                : " -"}
                            </Typography>
                          </Grid>
                          <Grid item marginTop={"10px"}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Modelo:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Car_Model !== ""
                                ? this.props.booking.Car_Model
                                : " -"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container direction="row" marginTop={1}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Preço:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Price !== ""
                                ? !this.props.booking.Price.includes(".") &&
                                  !this.props.booking.Price.includes(",")
                                  ? "€" + this.props.booking.Price + ",00"
                                  : "€" +
                                    this.props.booking.Price.replace(".", ",")
                                : " -"}
                            </Typography>
                          </Grid>
                          <Grid item marginTop={"10px"}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              wrap="nowrap"
                            >
                              Depósito:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Deposit !== ""
                                ? !this.props.booking.Deposit.includes(".") &&
                                  !this.props.booking.Deposit.includes(",")
                                  ? "€" + this.props.booking.Deposit + ",00"
                                  : "€" +
                                    this.props.booking.Deposit.replace(".", ",")
                                : " -"}
                            </Typography>
                          </Grid>
                          <Grid item marginTop={"10px"}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              wrap="nowrap"
                            >
                              Seguro:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                  fontFamily: "sf pro text regular",
                                },
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Insurance === ""
                                ? " -"
                                : this.props.booking.Insurance === 0
                                ? "Não"
                                : "Sim"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container direction="column" marginTop={1}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              Observações:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 10,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Observations !== ""
                                ? this.props.booking.Observations
                                : " -"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item marginTop={"66px"}>
                        <Grid container direction="column">
                          <Grid item>
                          <Typography
                              variant="subtitle2"
                              fontStyle={"italic"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 12,
                                  md: 10,
                                  sm: 8,
                                  xs: 6,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Added_Date !== ""
                                ? `Adicionada ${moment(parseInt(this.props.booking.Added_Date)).locale('pt').calendar().toLowerCase()}`
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              fontStyle={"italic"}
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 12,
                                  md: 10,
                                  sm: 8,
                                  xs: 6,
                                },
                                fontFamily: "sf pro text regular",
                              }}
                              wrap="nowrap"
                            >
                              {this.props.booking.Modified_Date !== ""
                                ? `Modificada ${moment(parseInt(this.props.booking.Modified_Date)).locale('pt').calendar().toLowerCase()}`
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={0.46}>
            <Grid
              container
              style={{
                borderTop: "1px solid #f1f1f1",
              }}
            >
              <Grid
                item
                className="booking-row-actions"
                visibility={this.props.edit_bookings ? "visible" : "hidden"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 14,
                      sm: 12,
                      xs: 6,
                    },
                  }}
                  style={{ paddingTop: "10px" }}
                  onClick={() => {
                    openDeleteBookingPopup(this.props.booking.ID);
                  }}
                >
                  🗑️
                </Typography>
              </Grid>
              <Grid
                item
                className="booking-row-actions"
                visibility={this.props.edit_bookings ? "visible" : "hidden"}
              >
                <Typography
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 14,
                      sm: 12,
                      xs: 6,
                    },
                  }}
                  style={{ paddingTop: "10px" }}
                  onClick={() => {
                    let edit_booking_section = {
                      client_name: this.props.booking.Client_Name,
                      client_email: this.props.booking.Client_Email,
                      client_phone: this.props.booking.Client_Phone,
                      client_address: this.props.booking.Client_Address,
                      start_date: this.props.booking.Start_Date,
                      start_time: this.props.booking.Start_Time,
                      start_local: this.props.booking.Start_Local,
                      end_date: this.props.booking.End_Date,
                      end_time: this.props.booking.End_Time,
                      end_local: this.props.booking.End_Local,
                      num_days: this.props.booking.Num_Days,
                      available_same_day: this.props.booking.Available_Same_Day,
                      car_brand: this.props.booking.Car_Brand,
                      car_model: this.props.booking.Car_Model,
                      car_plate: this.props.booking.Car_Plate,
                      price: this.props.booking.Price,
                      deposit: this.props.booking.Deposit,
                      insurance: this.props.booking.Insurance,
                      observations: this.props.booking.Observations,
                      id: this.props.booking.ID,
                    };
                    updateEditBookingSection(edit_booking_section);
                    openEditBookingPopup(this.props.booking.ID);
                  }}
                >
                  🖋️
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ListItem;
