import React from "react";
import style from "./Navbar.module.css";
import { isAuthenticated, logout } from "../States/Actions/Auth";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const tryLogout = () => {
    logout(() => {
      if (!isAuthenticated()) {
        navigate("/");
      }
    });
  };

  const goDashboard = () => {
    navigate("/inicio");
  };
  const goBookings = () => {
    navigate("/reservas");
  };
  const goBoard = () => {
    navigate("/quadro");
  };
  const goRequests = () => {
    navigate("/pedidos");
  };
  const goSimulator = () => {
    navigate("/simulador");
  };
  const goCommissions = () => {
    navigate("/comissoes");
  };
  const goRates = () => {
    navigate("/tarifas");
  };

  return (
    <div className={style.navbar}>
      <img
        alt="logo"
        src="./Logos/logo_transparent.png"
        className={style.logo}
        onClick={tryLogout}
      ></img>
      <div className={style.buttondiv}>
        <button className={style.button} onClick={goDashboard}>
          Início
        </button>
        <button className={style.button} onClick={goBookings}>
          Reservas
        </button>
        <button className={style.button} onClick={goBoard}>
          Quadro
        </button>
        <button className={style.button} onClick={goRequests}>
          Pedidos
        </button>
        <button className={style.button} onClick={goSimulator}>
          Simulador
        </button>
        <button className={style.button} onClick={goRates}>
          Tarifas
        </button>
      </div>
    </div>
  );
}

export default Navbar;
