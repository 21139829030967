const initialState = {
  rates: [],
};

const RatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RATES":
      state.rates = action.payload
      return {
        ...state,
        rates: state.rates,
      };

    default:
      return state;
  }
};

export default RatesReducer;
