import { store } from "../../index.js";
import * as actions from "./index.js";
import { systembackend_ip } from "../../Utils/Network.js";
const axios = require("axios").default;

export const login = (password, callback) => {
  axios
    .post(systembackend_ip + "/login", { password: password })
    .then((resp) => {
      if (resp.status === 200 && resp.data === true) {
        console.log("worked");
        store.dispatch(actions.loggedIn(password));
        callback(true);
      } else {
        console.log("failed");
        console.log(resp);
        callback(false);
      }
    })
    .catch((reason) => {
      console.log("caught");
      console.log(reason);
      callback(false);
    });
};

export const logout = (callback) => {
  store.dispatch(actions.loggedOut());
  callback();
};

export const isAuthenticated = () => {
  return store.getState().auth.logged_in;
};

export const getPassword = () => {
  return store.getState().auth.password;
};
