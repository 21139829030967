import React from "react";
import { Typography, Grid } from "@mui/material";
import { AddBookingSearchField } from "./AddBookingSection/AddBookingSearchField.js";
import {
  filterBookings,
  toggleEditBookingsMode,
} from "../States/Actions/Bookings.js";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { store } from "../index.js";
import { cloneDeep } from "lodash";

const searchFieldStyle = {
  input: {
    "&::placeholder": {
      fontSize: {
        lg: 12,
        md: 10,
        sm: 8,
        xs: 6,
      },
      fontStyle: "italic",
    },
    fontSize: {
      lg: 14,
      md: 12,
      sm: 10,
      xs: 8,
    },
    fontStyle: "italic",
    fontFamily: "sf pro text regular",
    textAlign: "center",
  },
};

const handle_filter_changed = (filter, data) => {
  let filters = cloneDeep(store.getState().bookings.filters);
  filters[filter] = data;
  filterBookings(filters);
};

const handle_clear_filter = () => {
  filterBookings({
    client_info: "",
    start_date: null,
    start_time: null,
    start_local: "",
    end_date: null,
    end_time: null,
    end_local: "",
    num_days: "",
    car_info: "",
    id: "",
  });
};

export function SearchItem(props) {
  const [startDateOpen, setStartDateOpen] = React.useState(false);
  const [startTimeOpen, setStartTimeOpen] = React.useState(false);
  const [endDateOpen, setEndDateOpen] = React.useState(false);
  const [endTimeOpen, setEndTimeOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Grid
        container
        style={{ background: "#ffffff", borderBottom: "1px solid #dddddd" }}
      >
        <Grid item xs={11.52}>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{
              color: "#2e3192",
              textAlign: "center",
              fontFamily: "Arial",
              flexWrap: "wrap",
              wordWrap: "break-word",
            }}
            sx={{
              fontSize: {
                lg: 14,
                md: 12,
                sm: 10,
                xs: 8,
              },
              fontWeight: {
                lg: "bold",
                md: "normal",
                sm: "normal",
                xs: "normal",
              },
            }}
            wrap="nowrap"
          >
            <Grid item xs={2.2902} backgroundColor="#ffffff">
              <AddBookingSearchField
                id="search-client-info"
                placeholder="nome, email, ..."
                value={props.filters.client_info}
                onChange={(event) => {
                  handle_filter_changed("client_info", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>
            <Grid item xs={1.12428}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  id="search-start-date"
                  clearable={true}
                  value={props.filters.start_date}
                  inputFormat="dd-MM-yyyy"
                  mask=""
                  open={startDateOpen}
                  onOpen={() => setStartDateOpen(true)}
                  onClose={() => setStartDateOpen(false)}
                  onChange={(newDate) => {
                    handle_filter_changed("start_date", newDate);
                  }}
                  OpenPickerButtonProps={{
                    disable: "true",
                    style: {
                      display: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <AddBookingSearchField
                      sx={searchFieldStyle}
                      onClick={() => setStartDateOpen(true)}
                      style={{ transform: "translate(10px, 0px)" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.12428}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  id="search-start-time"
                  clearable={true}
                  value={props.filters.start_time}
                  inputFormat="HH:mm"
                  ampm={false}
                  mask=""
                  open={startTimeOpen}
                  onOpen={() => setStartTimeOpen(true)}
                  onClose={() => setStartTimeOpen(false)}
                  onChange={(newTime) => {
                    handle_filter_changed("start_time", newTime);
                  }}
                  OpenPickerButtonProps={{
                    disable: "true",
                    style: {
                      display: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <AddBookingSearchField
                      sx={searchFieldStyle}
                      onClick={() => setStartTimeOpen(true)}
                      style={{ transform: "translate(10px, 0px)" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.62396}>
              <AddBookingSearchField
                id="search-start-local"
                value={props.filters.start_local}
                placeholder="local inicial..."
                onChange={(event) => {
                  handle_filter_changed("start_local", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>
            <Grid item xs={1.12428}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  id="search-end-date"
                  clearable={true}
                  value={props.filters.end_date}
                  inputFormat="dd-MM-yyyy"
                  mask=""
                  open={endDateOpen}
                  onOpen={() => setEndDateOpen(true)}
                  onClose={() => setEndDateOpen(false)}
                  onChange={(newDate) => {
                    handle_filter_changed("end_date", newDate);
                  }}
                  OpenPickerButtonProps={{
                    disable: "true",
                    style: {
                      display: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <AddBookingSearchField
                      sx={searchFieldStyle}
                      onClick={() => setEndDateOpen(true)}
                      style={{ transform: "translate(10px, 0px)" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.12428}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  id="search-end-time"
                  clearable={true}
                  value={props.filters.end_time}
                  inputFormat="HH:mm"
                  ampm={false}
                  mask=""
                  open={endTimeOpen}
                  onOpen={() => setEndTimeOpen(true)}
                  onClose={() => setEndTimeOpen(false)}
                  onChange={(newTime) => {
                    handle_filter_changed("end_time", newTime);
                  }}
                  OpenPickerButtonProps={{
                    disable: "true",
                    style: {
                      display: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <AddBookingSearchField
                      sx={searchFieldStyle}
                      onClick={() => setEndTimeOpen(true)}
                      style={{ transform: "translate(10px, 0px)" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.62396}>
              <AddBookingSearchField
                id="search-end-local"
                value={props.filters.end_local}
                placeholder="local final..."
                onChange={(event) => {
                  handle_filter_changed("end_local", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>
            <Grid item xs={0.6246}>
              <AddBookingSearchField
                id="search-num-days"
                value={props.filters.num_days}
                placeholder="# dias..."
                onChange={(event) => {
                  handle_filter_changed("num_days", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>

            <Grid item xs={0.9369}>
              <AddBookingSearchField
                id="search-car-info"
                value={props.filters.car_info}
                placeholder="marca, matricula..."
                onChange={(event) => {
                  handle_filter_changed("car_info", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>

            <Grid item xs={0.39558}>
              <AddBookingSearchField
                id="search-id"
                value={props.filters.id}
                placeholder="id..."
                onChange={(event) => {
                  handle_filter_changed("id", event.target.value);
                }}
                sx={searchFieldStyle}
              ></AddBookingSearchField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0.46}>
          <Grid
            container
            direction="row"
            style={{
              //boxShadow: "0px -1px 2px -1px #dddddd",
              borderTop: "1px solid #f1f1f1",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: {
                    lg: 16,
                    md: 14,
                    sm: 12,
                    xs: 6,
                  },
                }}
                style={{ paddingTop: "10px" }}
                onClick={() => {
                  handle_clear_filter();
                }}
              >
                🗑️
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: {
                    lg: 16,
                    md: 14,
                    sm: 12,
                    xs: 6,
                  },
                  color: "#2e3192",
                }}
                style={{ paddingTop: "10px" }}
                onClick={() => {
                  toggleEditBookingsMode();
                }}
              >
                🖋️
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SearchItem;
