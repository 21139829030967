import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar";
import style from "./Dashboard.module.css";
import { getPassword, isAuthenticated } from "../../States/Actions/Auth";
import { Navigate } from "react-router-dom";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Grid, Typography } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import Calendar from "react-awesome-calendar";
import "./Calendar.css";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoAirplaneSharp } from "react-icons/io5";
import { RiShip2Fill } from "react-icons/ri";
import { FaCheck, FaRoute } from "react-icons/fa";
import { openEventPopup } from "../../States/Actions/Board";
import EventPopup from "../Board/EventPopup";
import HeatMap from "@uiw/react-heat-map";
import axios from "axios";
import { websitebackend_ip } from "../../Utils/Network";

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ArcElement, Legend);
ChartJS.register(ChartDataLabels);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const onEventClicked = (event, props) => {
  let id = parseInt(event.split("_")[1]);
  openEventPopup(id);
};

const translateMonthsNames = () => {
  let header_month_element = document.querySelector(
    ".calendarHeader > h1 > span:nth-child(2)"
  );

  let prev_day_element = document.querySelector(
    ".calendarHeaderButtons > button:nth-child(1)"
  );

  let next_day_element = document.querySelector(
    ".calendarHeaderButtons > button:nth-child(2)"
  );

  let months = [
    ["January", "Janeiro"],
    ["February", "Fevereiro"],
    ["March", "Março"],
    ["April", "Abril"],
    ["May", "Maio"],
    ["June", "Junho"],
    ["July", "Julho"],
    ["August", "Agosto"],
    ["September", "Setembro"],
    ["October", "Outubro"],
    ["November", "Novembro"],
    ["December", "Dezembro"],
  ];

  for (var i = 0; i < months.length; i++) {
    header_month_element.textContent = header_month_element.textContent.replace(
      months[i][0],
      months[i][1]
    );

    prev_day_element.textContent = prev_day_element.textContent.replace(
      months[i][0],
      months[i][1]
    );

    next_day_element.textContent = next_day_element.textContent.replace(
      months[i][0],
      months[i][1]
    );
  }
};

const Dashboard = (props) => {
  useEffect(async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 10));
      translateMonthsNames();
    } catch (e) { }
    try {
      await new Promise((resolve) => setTimeout(resolve, 300));
      document
        .querySelector("#dailyTimeLine > div.dailyTimeLine > div:nth-child(17)")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    } catch (e) { }
  }, []);

  const [quoteStats, setQuoteStats] = useState(0);
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      axios
        .post(websitebackend_ip + "/sysquotecount", { password: getPassword() })
        .then((resp) => {
          if (resp.status === 200) {
            setQuoteStats(resp.data.count + " pedidos de preço desde " + moment.unix(resp.data.start).locale("pt").calendar().toLowerCase())
          } else {
          }
        })
        .catch((reason) => {
          let a = reason
        });

    }, 5000);
    //Clearing the interval
    return () => clearInterval(interval);
  }, [quoteStats]);

  if (!isAuthenticated()) return <Navigate to="/" />;

  //#region Capacity
  let capacity = {};
  for (var i = 0; i < props.bookings.length; i++) {
    let currentDate = moment(
      props.bookings[i].booking_info.Start_Date +
      " " +
      props.bookings[i].booking_info.Start_Time,
      "DD-MM-YYYY HH:mm"
    );
    let end_date = moment(
      props.bookings[i].booking_info.End_Date +
      " " +
      props.bookings[i].booking_info.End_Time,
      "DD-MM-YYYY HH:mm"
    );
    let now = moment();

    if (props.bookings[i].booking_info.Car_Plate === "") {
      continue;
    }

    // if (currentDate.isBefore(now, "days") && end_date.isBefore(now, "days"))
    //   continue;

    let a = currentDate.format("YYYY/MM/DD");

    currentDate = currentDate.startOf("day");
    end_date = end_date.startOf("day");

    if (!Object.keys(capacity).includes(currentDate.format("YYYY/MM/DD")))
      capacity[currentDate.format("YYYY/MM/DD")] = [];

    if (
      !capacity[currentDate.format("YYYY/MM/DD")].includes(
        props.bookings[i].booking_info.Car_Plate
      )
    )
      capacity[currentDate.format("YYYY/MM/DD")].push(
        props.bookings[i].booking_info.Car_Plate
      );

    while (currentDate.add(1, "days").diff(end_date) <= 0) {
      if (!Object.keys(capacity).includes(currentDate.format("YYYY/MM/DD")))
        capacity[currentDate.format("YYYY/MM/DD")] = [];

      if (
        !capacity[currentDate.format("YYYY/MM/DD")].includes(
          props.bookings[i].booking_info.Car_Plate
        )
      )
        capacity[currentDate.format("YYYY/MM/DD")].push(
          props.bookings[i].booking_info.Car_Plate
        );
    }
  }

  // for (var day of Object.keys(capacity)) {
  //   capacity[day] = Math.round((capacity[day].length / 28) * 100);
  // }

  let aux = [];
  for (var day of Object.keys(capacity)) {
    aux.push({
      date: day,
      count: Math.round((capacity[day].length / 35) * 100),
    });
  }
  capacity = aux;

  //#region Deposits
  let deposits = {};

  for (var i = 0; i < props.bookings.length; i++) {
    if (
      !(
        moment(
          props.bookings[i].booking_info.Start_Date +
          " " +
          props.bookings[i].booking_info.Start_Time,
          "DD-MM-YYYY HH:mm"
        ).isSameOrBefore(moment(), "minutes") &&
        moment(
          props.bookings[i].booking_info.End_Date +
          " " +
          props.bookings[i].booking_info.End_Time,
          "DD-MM-YYYY HH:mm"
        ).isSameOrAfter(moment(), "minutes")
      )
    )
      continue;

    let value = props.bookings[i].booking_info.Deposit.trim()
      .replace(",00", "")
      .replace(".00", "")
      .replace(";00", "");
    if (value === "") value = "Indeterminado";
    if (!(value in deposits)) deposits[value] = 1;
    else deposits[value] += 1;
  }

  deposits = {
    labels: Object.keys(deposits),
    datasets: [
      {
        label: "Deposits",
        data: Object.values(deposits),
        backgroundColor: [
          "rgba(255, 99, 132, 0.3)",
          "rgba(54, 162, 235, 0.3)",
          "rgba(255, 206, 86, 0.3)",
          "rgba(75, 192, 192, 0.3)",
          "rgba(153, 102, 255, 0.3)",
          "rgba(255, 159, 64, 0.3)",
          "rgba(255, 99, 219, 0.3)",
          "rgba(109, 255, 99, 0.3)",
          "rgba(99, 226, 255, 0.3)",
          "rgba(247, 255, 99, 0.3)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 219, 1)",
          "rgba(109, 255, 99, 1)",
          "rgba(99, 226, 255, 1)",
          "rgba(247, 255, 99, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnut_options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  const doughnut_plugins = [
    {
      beforeDraw: function (chart) {
        var ctx = chart.ctx;
        ctx.restore();
        var fontSize = (chart.height / 150).toFixed(2);
        ctx.font = fontSize + "em sf pro text regular";
        ctx.textBaseline = "top";

        var total = 0;
        for (var i = 0; i < deposits.labels.length; i++) {
          if (
            !isNaN(parseFloat(deposits.labels[i])) &&
            isFinite(deposits.labels[i])
          )
            total +=
              parseFloat(deposits.labels[i]) * deposits.datasets[0].data[i];
        }
        var text = "€" + total;
        let metrics = ctx.measureText(text);
        var textX = Math.round(
          (chart.width - chart.legend.width) / 2 - metrics.width / 2
        ),
          textY = Math.round(
            chart.height / 2 -
            (metrics.actualBoundingBoxAscent +
              metrics.actualBoundingBoxDescent) /
            2
          );
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  //#endregion

  //#region Schedule
  // const [isDayEventOpen, setIsDayEventOpen] = useState(-1);

  // const onDayEventOpen = (booking_id) => {
  //   setIsDayEventOpen(
  //     parseInt(booking_id.replace("start_", "").replace("end_", ""))
  //   );
  // };
  // const onDayEventClose = (booking_id) => {
  //   setIsDayEventOpen(-1);
  // };

  const events = [];
  const today = moment();
  let income = {};
  let labels = [];

  for (var i = -1; i < 2; i++) {
    for (var j = 1; j < 13; j++) {
      income[`${j}/${today.year() + i}`] = 0
      labels.push(`${j}/${today.year() + i}`)
    }
  }

  for (var i = 0; i < props.bookings.length; i++) {
    if (props.bookings[i].booking_info.Car_Plate === "") continue;

    let booking_start = moment(
      props.bookings[i].booking_info.Start_Date +
      " " +
      props.bookings[i].booking_info.Start_Time,
      "DD-MM-YYYY HH:mm"
    );

    let booking_end = moment(
      props.bookings[i].booking_info.End_Date +
      " " +
      props.bookings[i].booking_info.End_Time,
      "DD-MM-YYYY HH:mm"
    );

    var title = "";
    var Start_Local = props.bookings[i].booking_info.Start_Local.toLowerCase()
      .replace("-", "/")
      .replace("ó", "o")
      .replace("ç", "c");
    // if (Start_Local.includes("aeroporto")) title = title + "✈ ";
    // if (Start_Local.includes("/escritorio")) title = title + "✔";

    var icon = null;
    if (Start_Local.includes("aeroporto/escritorio"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "2px 0px 1px 0px",
          }}
        >
          <IoAirplaneSharp size={14} />
          <FaCheck size={10} />
        </span>
      );
    else if (Start_Local.includes("aeroporto"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <IoAirplaneSharp size={14} />
        </span>
      );
    else if (Start_Local.includes("/escritorio"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "2px 0px 1px 0px",
          }}
        >
          <HiOfficeBuilding size={14} />
          <FaCheck size={10} />
        </span>
      );
    else if (Start_Local.includes("escritorio"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <HiOfficeBuilding size={14} />
        </span>
      );
    else if (Start_Local.includes("pontinha/"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "2px 0px 1px 0px",
          }}
        >
          <RiShip2Fill size={14} />
          <FaCheck size={10} />
        </span>
      );
    else if (Start_Local.includes("pontinha"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <RiShip2Fill size={14} />
        </span>
      );
    else if (
      Start_Local.includes("garajau") ||
      Start_Local.includes("canico") ||
      Start_Local.includes("gaula") ||
      Start_Local.includes("machico") ||
      Start_Local.includes("calheta") ||
      Start_Local.includes("ribeira brava") ||
      Start_Local.includes("ponta do sol") ||
      Start_Local.includes("saccharum") ||
      Start_Local.includes("sunset bay") ||
      Start_Local.includes("churchill")
    )
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <FaRoute size={14} />
        </span>
      );

    events.push({
      id: "start_" + props.bookings[i].booking_info.ID,
      color: "#00db4b",
      from: booking_start.format("YYYY-MM-DDTHH:mm:00+00:00"),
      to: booking_start.format("YYYY-MM-DDTHH:mm:00+00:30"),
      title: title.trim(),
      icon: icon,
    });

    title = "";
    var end_local = props.bookings[i].booking_info.End_Local.toLowerCase()
      .replace("-", "/")
      .replace("ó", "o")
      .replace("ç", "c");
    // if (end_local.includes("/")) title = title + "✔ ";
    // if (end_local.includes("aeroporto")) title = title + "✈";

    var icon = null;
    if (end_local.includes("escritorio/aeroporto"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "3px 0px 2px 0px",
          }}
        >
          <FaCheck size={10} />
          <IoAirplaneSharp size={14} />
        </span>
      );
    else if (end_local.includes("aeroporto"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <IoAirplaneSharp size={14} />
        </span>
      );
    else if (end_local.includes("escritorio/"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "2px 0px 1px 0px",
          }}
        >
          <FaCheck size={10} />
          <HiOfficeBuilding size={14} />
        </span>
      );
    else if (end_local.includes("escritorio"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <HiOfficeBuilding size={14} />
        </span>
      );
    else if (end_local.includes("pontinha"))
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <RiShip2Fill size={14} />
        </span>
      );
    else if (
      end_local.includes("garajau") ||
      end_local.includes("canico") ||
      end_local.includes("gaula") ||
      end_local.includes("machico") ||
      end_local.includes("calheta") ||
      end_local.includes("ribeira brava") ||
      end_local.includes("ponta do sol") ||
      end_local.includes("saccharum") ||
      end_local.includes("sunset bay") ||
      end_local.includes("churchill")
    )
      icon = (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "Center",
            alignItems: "Center",
            padding: "7px 0px 6px 0px",
          }}
        >
          <FaRoute size={14} />
        </span>
      );

    events.push({
      id: "end_" + props.bookings[i].booking_info.ID,
      color: "#ff022c",
      from: booking_end.format("YYYY-MM-DDTHH:mm:00+00:00"),
      to: booking_end.format("YYYY-MM-DDTHH:mm:00+00:30"),
      title: title.trim(),
      icon: icon,
    });

    if (booking_start.year() !== moment().year() - 1 && booking_start.year() !== moment().year() && booking_start.year() !== moment().year() + 1) continue;

    // if (booking_start.year() !== 2022) continue;

    let p = parseFloat(props.bookings[i].booking_info.Price) || 0;

    income[booking_start.month() + 1 + "/" + booking_start.year()] = income[booking_start.month() + 1 + "/" + booking_start.year()] + p;
  }
  //#endregion

  //Bar Chart
  let income_data = [];
  for (const [key, value] of Object.entries(income)) {
    income_data.push(value.toFixed(0));
  }

  let colors = [];
  for (const [key, value] of Object.entries(income)) {
    if (key === `${moment().month() + 1}/${moment().year()}`)
      colors.push("rgba(54, 162, 235, 0.6)")
    else if (key.split("/")[1] === (moment().year() - 1).toString())
      colors.push("rgba(54, 162, 235, 0.1)")
    else if (key.split("/")[1] === (moment().year()).toString())
      colors.push("rgba(54, 162, 235, 0.3)")
    else if (key.split("/")[1] === (moment().year() + 1).toString())
      colors.push("rgba(54, 162, 235, 0.1)")
  }

  const barData = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: income_data,
        backgroundColor: colors,
      },
    ],
  };

  const changeToDailyMode = (calendar) => {
    try {
      calendar.onClickMode("dailyMode");
    } catch (e) { }
  };

  //#region New Bookings
  let sorted_bookings = props.bookings.sort((a, b) => { moment(a).isSameOrBefore(moment(b)) })
  let new_bookings = {};
  let total_bookings = 0
  let avg_bookings = 0
  let avg_days = 0
  let offset = 60

  for (var i = 0; i < sorted_bookings.length; i++) {

    // Calculate avg days
    let booking_start = moment(
      sorted_bookings[i].booking_info.Start_Date +
      " " +
      sorted_bookings[i].booking_info.Start_Time,
      "DD-MM-YYYY HH:mm"
    );
    let start = moment().subtract(offset, 'days')
    let added_date = sorted_bookings[i].booking_info.Added_Date

    if (added_date === '')
      continue

    added_date = moment.unix(Math.floor(parseInt(added_date) / 1000))

    if (added_date.isSameOrAfter(start) && sorted_bookings[i].booking_info.Car_Plate !== "") {

      added_date = added_date.format("DD/MM/YYYY")

      if (!Object.keys(new_bookings).includes(added_date))
        new_bookings[added_date] = 0
      new_bookings[added_date] += 1
      total_bookings += 1
      avg_days += parseInt(sorted_bookings[i].booking_info.Num_Days) || 0
    }
  }

  avg_bookings = total_bookings / Object.keys(new_bookings).length
  avg_days /= total_bookings

  let r = avg_days / 30 * 30

  let sorted_days = Object.keys(new_bookings).sort((a, b) => { moment(a).isSameOrBefore(moment(b)) })
  let sorted_values = []

  for (var i = 0; i < sorted_days.length; i++) {
    sorted_values.push(new_bookings[sorted_days[i]])
  }

  let minNewBookings = Math.min(...sorted_values)
  let maxNewBookings = Math.max(...sorted_values)

  let newBookingsColors = [];

  const lerp = (a, b, t) => (b - a) * t + a;
  const unlerp = (a, b, t) => (t - a) / (b - a);
  const map = (a1, b1, a2, b2, t) => lerp(a2, b2, unlerp(a1, b1, t));

  for (let value of sorted_values) {
    let alpha = map(0, maxNewBookings, 0.1, 0.9, value)
    newBookingsColors.push(`rgba(54, 162, 235, ${alpha}`)
  }

  const newBookingsData = {
    labels: sorted_days,
    datasets: [
      {
        label: "",
        data: sorted_values,
        backgroundColor: newBookingsColors,
      },
    ],
  };

  return (
    <div>
      <Navbar />

      {window.innerWidth < 500 ? (
        <div style={{ width: "100vw", paddingTop: "50px" }}>
          <div>
            <Calendar
              events={events}
              onChange={translateMonthsNames}
              onClickEvent={(event) => {
                onEventClicked(event, props);
              }}
              ref={changeToDailyMode}
            // className="test"
            // onClickEvent={onDayEventOpen}
            // onClickTimeLine={onDayEventClose}
            />
          </div><Typography style={{ textAlign: "center", fontSize: "14px", margin: "0px", padding: "0px" }}>{quoteStats}</Typography>
        </div>
      ) : (
        <div className={style.main}>
          <section className={style.grid}>
            <div>
              <HeatMap
                value={capacity}
                width={950}
                height={400}
                rectSize={34}
                style={{ color: "#ad001d" }}
                startDate={
                  new Date(moment().subtract(0, "days").toISOString())
                }
                panelColors={{
                  0: "#f9f9f9",
                  10: "#fffe82",
                  20: "#ffec74",
                  30: "#ffda69",
                  40: "#ffc762",
                  50: "#ffb55d",
                  60: "#ffa35a",
                  70: "#fa925a",
                  80: "#f3815b",
                  90: "#eb705d",
                  100: "#e06060",
                }}
                rectProps={{
                  rx: 4,
                }}
                rectRender={(props, data) => {
                  // if (!data.count) return <rect {...props} />;
                  return (
                    <React.Fragment>
                      <rect {...props}></rect>
                      <text
                        position="relative"
                        x={props.x + 3}
                        y={props.y + 9}
                        font-family="sf pro text regular"
                        fontSize="8px"
                        fill="black"
                        stroke-width="0.05"
                        stroke="#000000"
                      >
                        {data.date.split("/")[2]}
                      </text>
                      <text
                        position="relative"
                        x={props.x + 6}
                        y={props.y + 23}
                        font-family="sf pro text regular"
                        font-size="10px"
                        fill="black"
                        strokeWidth="0.4"
                        stroke="#000000"
                      >
                        {Object.keys(data).includes("count") ? data.count : 0}
                        %
                      </text>
                    </React.Fragment>
                  );
                }}
              />
              <div style={{ transform: "translate(0px, -100px)" }}>
                <Typography style={{ textAlign: "center", fontSize: "14px", margin: "0px", padding: "0px" }}>{quoteStats}</Typography>
                <Bar
                  style={{ maxWidth: "1000px", maxHeight: "350px" }}
                  options={{
                    scales: {
                      // to remove the labels
                      x: {
                        ticks: {
                          display: true,
                        },

                        // to remove the x-axis grid
                        grid: {
                          drawBorder: true,
                          display: false,
                        },
                      },
                      // to remove the y-axis labels
                      y: {
                        ticks: {
                          display: false,
                          beginAtZero: true,
                        },
                        // to remove the y-axis grid
                        grid: {
                          drawBorder: false,
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                      title: {
                        display: false,
                        text: "",
                      },
                      datalabels: { display: true },
                    },
                  }}
                  data={barData}
                ></Bar>
                <Bar
                  style={{ maxWidth: "1000px", maxHeight: "150px" }}
                  options={{
                    scales: {
                      // to remove the labels
                      x: {
                        ticks: {
                          display: false,
                        },

                        // to remove the x-axis grid
                        grid: {
                          drawBorder: true,
                          display: false,
                        },
                      },
                      // to remove the y-axis labels
                      y: {
                        ticks: {
                          display: false,
                          beginAtZero: true,
                        },
                        // to remove the y-axis grid
                        grid: {
                          drawBorder: false,
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                        position: "top",
                      },
                      title: {
                        display: false,
                        text: "",
                      },
                      datalabels: { display: true },
                    },
                  }}
                  data={newBookingsData}
                ></Bar>
              </div>
            </div>
            <div>
              <div className={style.card}>
                <Doughnut
                  data={deposits}
                  options={doughnut_options}
                  plugins={doughnut_plugins}
                />
              </div>
              <div
                className={style.card}
              // style={{ overflowY: "hidden" }}
              >
                <Calendar
                  events={events}
                  onChange={translateMonthsNames}
                  onClickEvent={(event) => {
                    onEventClicked(event, props);
                  }}
                  ref={changeToDailyMode}
                  className="test"
                // onClickEvent={onDayEventOpen}
                // onClickTimeLine={onDayEventClose}
                />
              </div>
            </div>
          </section>
        </div >
      )}
      {/* {isEventOpen !== -1 && <DayEventPopup></DayEventPopup>} */}
      <EventPopup></EventPopup>
    </div >
  );
};

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    event_popup_open: state.board.event_popup_open,
  };
}

export default connect(mapStateToProps)(Dashboard);
