import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  closeDeleteBookingPopup,
  closeEditBookingPopup,
} from "../States/Actions/Bookings";
import { Button, DialogActions, Typography } from "@mui/material";
import { useContext } from "react";
import SocketContext from "../Utils/SocketContext";
import { getPassword } from "../States/Actions/Auth";

export const DeleteBookingPopup = (props) => {
  const socket = useContext(SocketContext);

  const booking = props.bookingsState.all_bookings.filter((booking) => {
    return (
      booking.booking_info.ID === props.bookingsState.delete_booking_popup_open
    );
  })[0];

  if (!booking) return "";

  return (
    <div>
      <Dialog
        open={
          props.bookingsState.delete_booking_popup_open === -1 ? false : true
        }
        onClose={closeEditBookingPopup}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
      >
        <DialogContent>
          <Typography
            color="#2e3192"
            fontSize="20px"
            fontWeight="bold"
            fontFamily="sf pro text regular"
            textAlign="center"
          >
            Apagar a reserva {booking.booking_info.ID}?
          </Typography>
          <br></br>
        </DialogContent>
        <DialogActions>
          <div style={{ margin: "0 auto", display: "block" }}>
            <Button
              onClick={() => {
                socket.emit("remove_booking", {
                  password: getPassword(),
                  data: booking.booking_info.ID,
                });
              }}
              style={{
                color: "#ea1d24",
                background: "#ffffff",
                fontFamily: "sf pro text regular",
                fontSize: "14px",
                fontWeight: "bold",
                marginRight: "10px",
                maxHeight: "30px",
                border: "1px solid #ea1d24",
                textTransform: "none",
              }}
            >
              Sim
            </Button>
            <Button
              onClick={() => {
                closeDeleteBookingPopup();
              }}
              style={{
                color: "#2e3192",
                background: "#eeeeee",
                fontFamily: "sf pro text regular",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "10px",
                maxHeight: "30px",
                textTransform: "none",
              }}
            >
              Não
            </Button>
            <br></br>
            <br></br>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    bookingsState: state.bookings,
  };
}

export default connect(mapStateToProps)(DeleteBookingPopup);
