const confirmation_email_it = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Booking Confirmation</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    @media screen and (max-width: 600px) {
      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
</head>
<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <center><h2>Booking Confirmation - Reservation ID-XXX</h2></center>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <p>
              Salve XXX,<br><br>
              Grazie per averci scelto per le vostre esigenze di noleggio auto a Madeira! Siamo lieti di informarla che le date e il veicolo da lei richiesti sono disponibili. La sua prenotazione con Auto Rent-a-Car è confermata e i suoi dettagli sono i seguenti:
            </p>
            <!-- Reservation Details -->
            <p>
              <strong>Dettagli della riserva:</strong><br>
              - Data di ritiro: XXX<br>
              - Località di ritiro: XXX<br>
              - Data di riconsegna: XXX<br>
              - Località di riconsegna: XXX
            </p>
            <!-- Car details -->
            <p>
              <strong>Dettagli dell'auto:</strong><br>
              - Quantità di auto: XXX<br>
              - Categoria auto: Gruppo XXX (Trasmissione XXX - XXX)<br>
              - Numero di conducente extra: XXX<br>
              - Seggiolino (0-3 anni): XXX<br>
              - Seggiolino (4-7 anni): XXX<br>
              - Seggiolino (8-12 anni): XXX<br>
              - Opzione assicurativa: XXX<br>
            </p>
            </p>
            <!-- Pricing and Deposit -->
            <p>
              <strong>Prezzo di noleggio completo (Contanti/carta di debito o credito):</strong> XXX<br>
              <strong>Franchigia (Contanti/Carta di credito):</strong> XXX
            </p>
            </p>
            <!-- Additional Information -->
            <br>
            <p style="text-align: justify">
              Il pagamento viene effettuato solo al momento del ritiro. Accettiamo pagamenti in contanti (Euro) e con le principali carte di credito e di debito (tranne American Express).
              Chiediamo che il deposito sia in contanti, in quanto è più facile restituirlo alla fine del periodo di noleggio. In ultima istanza, è possibile pagare con carta di credito VISA.
              <br></br>
              Vi chiediamo ora di fornirci ulteriori informazioni, come il numero di volo, il numero di persone in arrivo e i dati relativi alla vostra sistemazione a Madeira. Vi ringraziamo in anticipo per la vostra collaborazione!
              <br></br>
              Ora vi chiediamo gentilmente di comunicarci il nome della vostra nave da crociera. Saremo pronti ad accogliervi al terminal delle navi da crociera, identificabili dal nostro logo e da un cartello con il vostro nome. Grazie in anticipo per la vostra collaborazione!
              <br></br>
              Abbiamo tutte le informazioni richieste. Vi preghiamo di confermare se tutto vi sembra corretto. Se avete ulteriori domande o bisogno di assistenza, non esitate a contattarci. Grazie e ci auguriamo di potervi incontrare presto!
            </p>
            <br>
            <!-- Closing Part -->
            <p>
              Saluti,<br>
              XXX<br><br>
              Auto Rent-a-Car<br>
              +351 918888307 (also on WhatsApp)<br>
              www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. All rights reserved.
          </p>
        </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default confirmation_email_it;