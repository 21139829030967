const templates = {
  en: {
    price_cdw: { "groups_abcc+m": ``, groups_de: `` },
    price_scdw: {
      "groups_abcc+m": `We have a group XXXX car available for XXXX.00 for XXXX day(s), with XXXX included.
We ask for a €80.00 fuel deposit in cash when you pick up the car. This deposit will be returned to you at the end of the rental period, if you return the car with the same amount of fuel (full tank). This deposit is unrelated to the insurance option chosen.

Note that the client is always responsible for all damages caused by negligence or misuse, including damages to the windows, tires, clutch, bottom of the car, and car key.


Please let us know if you have any questions or if you would like to book the car!`,
      groups_de: ``,
    },
    price_cdw_scdw: {
      "groups_abcc+m": `We have a group XXXX car available for XXXX.00 for XXXX day(s), with XXXX included.
With the basic insurance option, we ask for a €300.00 cash deposit when you pick up the car. The deposit will be returned to you at the end of the rental period, if the car is returned in the same condition as delivered and with the same amount of fuel (full tank).

If you choose the SCDW insurance option instead, the price is XXXX.00, for the same dates and conditions.
We ask for a €80.00 fuel deposit in cash when you pick up the car. This deposit will be returned to you at the end of the rental period, if you return the car with the same amount of fuel (full tank). This deposit is unrelated to the insurance option chosen.

Note that the client is always responsible for all damages caused by negligence or misuse, including damages to the windows, tires, clutch, bottom of the car, and car key.


Please let us know if you have any questions, or if you would like to book the car for any of the options above. Thank you!`,
      groups_de: `We have a group XXXX car available for XXXX.00 for XXXX day(s), with XXXX included.
With the basic insurance option, we ask for a €500.00 cash deposit when you pick up the car. The deposit will be returned to you at the end of the rental period, if the car is returned in the same condition as delivered and with the same amount of fuel (full tank).

If you choose the SCDW insurance option instead, the price is XXXX.00, for the same dates and conditions.
In this case, the deposit would be reduced to €200.00 in cash.

Note that the client is always responsible for all damages caused by negligence or misuse, including damages to the windows, tires, clutch, bottom of the car, and car key.


Please let us know if you have any questions, or if you would like to book the car for any of the options above. Thank you!`,
    },
    only_c: {
      near: `Unfortunately, for these dates we only have availability from group C (Renault Clio / Seat Ibiza). These cars have 5 seats/5 doors, manual transmission and a turbo petrol engine.
      Would you be interested in a price quotation? Please let me know, and if so I would then send an email with all the details. Thank you!`,
      far: `At the moment, for these dates in advance we can only confirm availability for cars starting from group C (Renault Clio / Seat Ibiza). These cars have 5 seats/5 doors, manual transmission and a turbo petrol engine.
      Would you be interested in a price quotation? Please let me know, and if so I would then send an email with all the details. Thank you!`,
    },
    "only_c+": {
      near: `Unfortunately, for these dates we only have availability from group C+ (Seat Ibiza FR / Skoda Fabia). These cars have 5 seats/5 doors, manual transmission and a turbo petrol engine.
      Would you be interested in a price quotation? Please let me know, and if so I would then send an email with all the details. Thank you!`,
      far: `At the moment, for these dates in advance we can only confirm availability for cars starting from group C+ (Seat Ibiza FR / Skoda Fabia). These cars have 5 seats/5 doors, manual transmission and a turbo petrol engine.
      Would you be interested in a price quotation? Please let me know, and if so I would then send an email with all the details. Thank you!`,
    },
    invalid_dates: `Could you please verify the dates that you selected? There may have been an error.
Thank you for your help!`,
    confirmation: `Hello,

We are very pleased to inform you that your booking ID-XXXX is confirmed!

Reservation details:
Pick-up date:  XXXX
Pick-up place:  XXXX

Return date:  XXXX
Return place:  XXXX

Car type:  Group XXXX
Insurance:  XXXX insurance

Number of extra drivers:  XXXX
Toddler chair (0-3 years):  XXXX
Infant chair (4-7 years):  XXXX
Booster seat (8-12 years):  XXXX

Full rental price:  €XXXX XXXX
Deposit:  €XXXX (cash)


We now need you to tell us your flight number, how many people are arriving, and where you will be staying during your time in Madeira.
Thanks in advance!

We have all the information needed for now. 
Thank you and see you soon!


Best regards,`,
  },
  pt: {
    price_cdw: { "groups_abcc+m": ``, groups_de: `` },
    price_scdw: {
      "groups_abcc+m": `Temos um carro do grupo XXXX disponível por XXXX.00 para XXXX dia(s), com XXXX incluído.
Solicitamos um depósito de €80.00 em numerário para o combustível, no momento em que recolhe o carro. Este depósito é devolvido no final do período de aluguer, se devolver o carro com a mesma quantidade de combustível (tanque cheio). Este depósito não está relacionado com a opção de seguro escolhida.

Passo a informar também que o cliente é sempre responsável por todos os danos causados por negligência ou má utilização, incluindo danos nos vidros, pneus, parte inferior do carro, embreagem ou chave do carro. 


Por favor, diga-nos se tiver alguma dúvida ou se deseja reservar o carro!`,
      groups_de: ``,
    },
    price_cdw_scdw: {
      "groups_abcc+m": `Temos um carro do grupo XXXX disponível por XXXX.00 para XXXX dia(s), com XXXX incluído.
Com a opção de seguro básico, solicitamos um depósito em numerário de €300.00 no momento em que recolhe o carro. Este depósito é-lhe devolvido no final do período de aluguer, se devolver o carro na mesma condição em que o recebeu, e com a mesma quantidade de combustível (tanque cheio).

Se decidir optar pelo seguro SCDW, o preço é de XXXX.00, para as mesmas datas e condições.
Solicitamos um depósito de €80.00 em numerário para o combustível, no momento em que recolhe o carro. Este depósito é devolvido no final do período de aluguer, se devolver o carro com a mesma quantidade de combustível. Este depósito não está relacionado com a opção de seguro escolhida.

Passo a informar também que o cliente é sempre responsável por todos os danos causados por negligência ou má utilização, incluindo danos nos vidros, pneus, parte inferior do carro, embreagem ou chave do carro. 


Por favor, diga-nos se tiver alguma dúvida, ou se deseja reservar o carro para alguma das opções. Obrigado!`,
      groups_de: `Temos um carro do grupo XXXX disponível por XXXX.00 para XXXX dia(s), com XXXX incluído.
Com a opção de seguro básico, solicitamos um depósito em numerário de €500.00 no momento em que recolhe o carro. Este depósito é-lhe devolvido no final do período de aluguer, se devolver o carro na mesma condição em que o recebeu, e com a mesma quantidade de combustível (tanque cheio).

Se decidir optar pelo seguro SCDW, o preço é de XXXX.00, para as mesmas datas e condições.
Neste caso, o depósito seria reduzido para €200.00 em numerário.

Passo a informar também que o cliente é sempre responsável por todos os danos causados por negligência ou má utilização, incluindo danos nos vidros, pneus, parte inferior do carro, embreagem ou chave do carro. 


Por favor, diga-nos se tiver alguma dúvida, ou se deseja reservar o carro para alguma das opções. Obrigado!`,
    },
    only_c: {
      near: `Infelizmente, para estas datas, só temos disponibilidade do grupo C (Renault Clio / Seat Ibiza). Estes carros têm 5 lugares/5 portas, caixa manual e um motor turbo a gasolina.
      Está interessado numa proposta de preço? Se for o caso, enviarei então um e-mail com todos os detalhes. Obrigado.`,
      far: `De momento, para estas datas, só podemos confirmar a disponibilidade de viaturas a partir do grupo C (Renault Clio / Seat Ibiza). Estes carros têm 5 lugares/5 portas, caixa manual e um motor turbo a gasolina.
      Está interessado numa proposta de preço? Se for o caso, enviarei então um e-mail com todos os detalhes. Obrigado.`,
    },
    "only_c+": {
      near: `Infelizmente, para estas datas, só temos disponibilidade do grupo C+ (Seat Ibiza FR / Skoda Fabia). Estes carros têm 5 lugares/5 portas, caixa manual e um motor turbo a gasolina.
      Está interessado numa proposta de preço? Por favor, informe-me e, em caso afirmativo, enviarei um e-mail com todos os pormenores. Obrigado.
      `,
      far: `De momento, para estas datas, só podemos confirmar a disponibilidade de viaturas a partir do grupo C+ (Seat Ibiza FR / Skoda Fabia). Estes carros têm 5 lugares/5 portas, caixa manual e um motor turbo a gasolina.
      Está interessado numa proposta de preço? Por favor, informe-me e, em caso afirmativo, enviarei um e-mail com todos os pormenores. Obrigado.`,
    },
    invalid_dates: `Poderia verificar as datas selecionadas? Parece ter havido algum erro. 
Obrigado desde já pela sua ajuda.`,
    confirmation: `Olá,

Temos o prazer de lhe informar que a sua reserva ID-XXXX está confirmada!

Detalhes da reserva:
Data do levantamento: XXXX
Lugar do levantamento: XXXX

Data da devolução: XXXX
Local da devolução: XXXX

Tipo de carro: Grupo XXXX
Tipo de seguro: Seguro XXXX

Número de condutores extra: XXXX
Cadeira de bebé (0-3 anos): XXXX
Cadeira de criança (4-7 anos): XXXX
Assento booster (8-12 anos): XXXX


Preço de aluguer completo: €XXXX XXXX
Depósito: €XXXX (numerário)


Precisamos agora que nos diga o seu número de voo, quantas pessoas irão viajar, e onde irá se hospedar durante a sua estadia na Madeira.
Obrigado desde já!

Por agora, temos toda a informação necessária.
Obrigado e até breve!


Atenciosamente,`,
  },
  es: {
    price_cdw: { "groups_abcc+m": ``, groups_de: `` },
    price_scdw: {
      "groups_abcc+m": `Tenemos un coche del grupo XXXX disponible por XXXX.00 para XXXX día(s), con XXXX incluido.
Solicitamos un depósito de combustible en efectivo de €80.00  cuando recoges el coche. Este depósito le será devuelto al final del periodo de alquiler, si devuelve el coche con la misma cantidad de combustible (depósito lleno). Este depósito no está relacionado con la opción de seguro elegida.

También debo informarle de que el cliente es siempre responsable de todos los daños causados por negligencia o uso indebido, incluidos los daños a las lunas, los neumáticos, los bajos del coche, el embrague o la llave del coche. 


Si tiene alguna pregunta o desea reservar el coche, no dude en ponerse en contacto con nosotros!`,
      groups_de: ``,
    },
    price_cdw_scdw: {
      "groups_abcc+m": `Tenemos un coche del grupo XXXX disponible por XXXX.00 para XXXX día(s), con XXXX incluido.
Con la opción de seguro básico pedimos un depósito en efectivo de €300.00 al recoger el coche. El depósito le será devuelto al final del período de alquiler, si el coche se devuelve en las mismas condiciones en que se entregó, y con la misma cantidad de combustible (depósito lleno).

Si elige en su lugar la opción de seguro SCDW, el precio es de XXXX.00, para las mismas fechas y condiciones.
Solicitamos un depósito de combustible en efectivo de €80.00  cuando recoges el coche. Este depósito le será devuelto al final del periodo de alquiler, si devuelve el coche con la misma cantidad de combustible (depósito lleno). Este depósito no está relacionado con la opción de seguro elegida.

También debo informarle de que el cliente es siempre responsable de todos los daños causados por negligencia o uso indebido, incluidos los daños a las lunas, los neumáticos, los bajos del coche, el embrague o la llave del coche. 


Por favor, háganos saber si tiene alguna pregunta, o si desea reservar el coche para cualquiera de las opciones anteriores. Gracias!`,
      groups_de: `Tenemos un coche del grupo XXXX disponible por XXXX.00 euros para XXXX día(s), con XXXX incluido.
Con la opción de seguro básico pedimos un depósito en efectivo de €500.00 al recoger el coche. El depósito le será devuelto al final del período de alquiler, si el coche se devuelve en las mismas condiciones en que se entregó, y con la misma cantidad de combustible (depósito lleno).

Si elige en su lugar la opción de seguro SCDW, el precio es de XXXX.00, para las mismas fechas y condiciones.
En este caso, el depósito se reduciría a €200.00 en efectivo.

También debo informarle de que el cliente es siempre responsable de todos los daños causados por negligencia o uso indebido, incluidos los daños a las lunas, los neumáticos, los bajos del coche, el embrague o la llave del coche. 


Por favor, háganos saber si tiene alguna pregunta, o si desea reservar el coche para cualquiera de las opciones anteriores. Gracias!`,
    },
    only_c: {
      near: `Lamentablemente, para estas fechas sólo tenemos disponibilidad del grupo C (Renault Clio / Seat Ibiza). Estos coches tienen 5 plazas/5 puertas, transmisión manual y motor turbo de gasolina.
      Estaría interesado en un presupuesto? Por favor, hágamelo saber, y si es así le enviaría un correo electrónico con todos los detalles. Gracias.
      `,
      far: `Por el momento, para estas fechas por adelantado sólo podemos confirmar disponibilidad para coches a partir del grupo C (Renault Clio / Seat Ibiza). Estos coches tienen 5 plazas/5 puertas, transmisión manual y motor turbo de gasolina.
      Estaría interesado en un presupuesto? Por favor, hágamelo saber, y si es así le enviaría un correo electrónico con todos los detalles. Gracias.`,
    },
    "only_c+": {
      near: `Lamentablemente, para estas fechas sólo tenemos disponibilidad del grupo C+ (Seat Ibiza FR / Skoda Fabia). Estos coches tienen 5 plazas/5 puertas, transmisión manual y motor turbo de gasolina.
      Estaría interesado en un presupuesto? Por favor, hágamelo saber, y si es así le enviaría un correo electrónico con todos los detalles. Gracias.`,
      far: `Por el momento, para estas fechas por adelantado sólo podemos confirmar disponibilidad para coches a partir del grupo C+ (Seat Ibiza FR / Skoda Fabia). Estos coches tienen 5 plazas/5 puertas, transmisión manual y motor turbo de gasolina.
      Estaría interesado en un presupuesto? Por favor, hágamelo saber, y si es así le enviaría un correo electrónico con todos los detalles. Gracias.`,
    },
    invalid_dates: `Podría verificar las fechas que ha seleccionado? Parece que puede haber un error. 
Gracias por su ayuda!`,
    confirmation: `Hola,

Nos complace informarle de que su reserva ID-XXXX ha sido confirmada.

Detalles de la reserva:
Fecha de recogida: XXXX
Lugar de recogida: XXXX

Fecha de devolución: XXXX
Lugar de devolución: XXXX

Tipo de coche: Grupo XXXX
Seguros: Seguro XXXX

Número de conductores adicionales: XXXX
Silla para bebé (0-3 años): XXXX
Silla para niños (4-7 años): XXXX
Asiento para adolecentes (8-12 años): XXXX


Precio del alquiler completo: €XXXX XXXX
Fianza: €XXXX (en efectivo)


Ahora necesitamos que nos indique su número de vuelo, cuántas personas llegan y dónde se alojarán durante su estancia en Madeira.
Gracias de antemano.

Por ahora tenemos toda la información necesaria.
Gracias y hasta pronto.


Un saludo,`,
  },
  fr: {
    price_cdw: { "groups_abcc+m": ``, groups_de: `` },
    price_scdw: {
      "groups_abcc+m": `Nous avons une voiture du groupe XXXX disponible pour XXXX.00 pour XXXX jour(s), avec XXXX incluse.
Nous demandons une caution de €80.00 en espèces lors de la prise en charge de la voiture. Cette caution vous sera restituée à la fin de la période de location, si vous rendez la voiture avec la même quantité de carburant (réservoir plein). Cette caution n'est pas liée à l'option d'assurance choisie.

Je dois également vous informer que le client est toujours responsable de tous les dommages causés par une négligence ou une mauvaise utilisation, y compris les dommages aux fenêtres, aux pneus, au-dessous de la voiture, à l'embrayage ou à la clé de voiture. 


N'hésitez pas à nous contacter si vous avez des questions ou si vous souhaitez réserver la voiture!`,
      groups_de: ``,
    },
    price_cdw_scdw: {
      "groups_abcc+m": `Nous avons une voiture du groupe XXXX disponible pour XXXX.00 pour XXXX jour(s), avec XXXX incluse.
Avec l'option d'assurance de base, nous demandons une caution de €300.00 en espèces lors de la prise en charge de la voiture. La caution vous sera restituée à la fin de la période de location, si la voiture est rendue dans le même état qu'à la livraison et avec la même quantité de carburant (réservoir plein).

Si vous choisissez l'option d'assurance SCDW à la place, le prix est de XXXX.00, pour les mêmes dates et conditions.
Nous demandons une caution de €80.00 en espèces lors de la prise en charge de la voiture. Cette caution vous sera restituée à la fin de la période de location, si vous rendez la voiture avec la même quantité de carburant (réservoir plein). Cette caution n'est pas liée à l'option d'assurance choisie.

Je dois également vous informer que le client est toujours responsable de tous les dommages causés par une négligence ou une mauvaise utilisation, y compris les dommages aux fenêtres, aux pneus, au-dessous de la voiture, à l'embrayage ou à la clé de voiture. 


N'hésitez pas à nous faire savoir si vous avez des questions, ou si vous souhaitez réserver la voiture pour l'une des options ci-dessus. Merci !`,
      groups_de: `Nous avons une voiture du groupe XXXX disponible pour XXXX.00 pour XXXX jour(s), avec XXXX incluse.
Avec l'option d'assurance de base, nous demandons une caution de €500.00 en espèces lors de la prise en charge de la voiture. La caution vous sera restituée à la fin de la période de location, si la voiture est rendue dans le même état qu'à la livraison et avec la même quantité de carburant (réservoir plein).

Si vous choisissez l'option d'assurance SCDW à la place, le prix est de XXXX.00, pour les mêmes dates et conditions.
Dans ce cas, la caution sera réduite à €200.00 en espèces.

Je dois également vous informer que le client est toujours responsable de tous les dommages causés par une négligence ou une mauvaise utilisation, y compris les dommages aux fenêtres, aux pneus, au-dessous de la voiture, à l'embrayage ou à la clé de voiture. 


N'hésitez pas à nous faire savoir si vous avez des questions, ou si vous souhaitez réserver la voiture pour l'une des options ci-dessus. Merci !`,
    },
    only_c: {
      near: `Malheureusement, pour ces dates, nous n'avons de disponibilité que pour le groupe C (Renault Clio / Seat Ibiza). Ces voitures ont 5 places/5 portes, une transmission manuelle et un moteur turbo essence.
      Seriez-vous intéressé par une offre de prix ? Veuillez me le faire savoir, et si oui, je vous enverrai un e-mail avec tous les détails. Merci.`,
      far: `Actuellement, pour ces dates à l'avance, nous ne pouvons confirmer la disponibilité que pour les voitures à partir du groupe C (Renault Clio / Seat Ibiza). Ces voitures ont 5 places/5 portes, une transmission manuelle et un moteur turbo essence.
      Seriez-vous intéressé par une offre de prix ? Veuillez me le faire savoir, et si oui, je vous enverrai un e-mail avec tous les détails. Merci.`,
    },
    "only_c+": {
      near: `Malheureusement, pour ces dates, nous n'avons de disponibilité que pour le groupe C+ (Seat Ibiza FR / Skoda Fabia). Ces voitures ont 5 places/5 portes, une transmission manuelle et un moteur turbo essence.
      Seriez-vous intéressé par une offre de prix ? Veuillez me le faire savoir, et si oui, je vous enverrai un e-mail avec tous les détails. Merci.`,
      far: `Actuellement, pour ces dates à l'avance, nous ne pouvons confirmer la disponibilité que pour les voitures à partir du groupe C+ (Seat Ibiza FR / Skoda Fabia). Ces voitures ont 5 places/5 portes, une transmission manuelle et un moteur turbo essence.
      Seriez-vous intéressé par une offre de prix ? Veuillez me le faire savoir, et si oui, je vous enverrai un e-mail avec tous les détails. Merci.`,
    },
    invalid_dates: `Pourriez-vous vérifier les dates que vous avez sélectionnées? Il semble qu'il y ait eu une erreur. 
Merci de votre aide!`,
    confirmation: `Bonjour,

Nous avons le plaisir de vous informer que votre réservation ID-XXXX est confirmée!

Détails de la réservation:
Date de livráison: XXXX
Lieu de livráison: XXXX

Date de retour: XXXX
Lieu de retour: XXXX

Type de voiture: Groupe XXXX
Assurance: Assurance XXXX

Nombre de conducteurs supplémentaires: XXXX
Siége pour bébé (0-3 ans): XXXX
Siége pour enfant (4-7 ans): XXXX
Siége pour enfant (8-12 ans): XXXX


Prix total de la location: €XXXX XXXX
Dépôt: €XXXX (en espèces)


Nous avons maintenant besoin que vous nous indiquiez le numéro de votre vol, le nombre de personnes qui arrivent et l'endroit où vous séjournerez pendant votre séjour à Madère.
Merci d'avance!

Nous avons toutes les informations nécessaires pour le moment.
Merci et à bientôt!


Cordialement,`,
  },
  de: {
    price_cdw: { "groups_abcc+m": ``, groups_de: `` },
    price_scdw: {
      "groups_abcc+m": `Wir haben ein Auto der Gruppe XXXX zu mieten für XXXX.00 für die XXXX Tag(e), mit XXXX enthalten.
Wir bitten um eine Kaution in Höhe von €80.00 in bar, wenn Sie das Auto abholen. Diese Kaution wird Ihnen am Ende der Mietzeit zurückerstattet, wenn Sie das Fahrzeug mit der gleichen Menge Kraftstoff (vollgetankt) zurückgeben. Diese Kaution ist unabhängig von der gewählten Versicherungsoption.

Ich muss Sie auch darauf hinweisen, dass der Kunde immer für alle Schäden verantwortlich ist, die durch Fahrlässigkeit oder Missbrauch verursacht werden, einschließlich Schäden an Fenstern, Reifen, Unterseite des Autos, Kupplung oder Autoschlüssel. 


Bitte lassen Sie uns wissen, wenn Sie Fragen haben oder das Auto buchen möchten!`,
      groups_de: ``,
    },
    price_cdw_scdw: {
      "groups_abcc+m": `Wir haben ein Auto der Gruppe XXXX für XXXX.00 für XXXX Tag(e) zur Verfügung, mit XXXX enthalten.
Bei der Grundversicherung bitten wir um eine Kaution von €300.00 in bar bei der Abholung des Fahrzeugs. Die Kaution wird Ihnen am Ende des Mietzeitraums zurückerstattet, wenn das Auto im gleichen Zustand wie bei der Übergabe und mit der gleichen Menge an Kraftstoff (voller Tank) zurückgegeben wird.

Wenn Sie stattdessen die SCDW-Versicherungsoption wählen, beträgt der Preis XXXX.00 für dieselben Daten und Bedingungen.
Wir bitten um eine Kaution in Höhe von €80.00 in bar, wenn Sie das Auto abholen. Diese Kaution wird Ihnen am Ende der Mietzeit zurückerstattet, wenn Sie das Fahrzeug mit der gleichen Menge Kraftstoff (vollgetankt) zurückgeben. Diese Kaution ist unabhängig von der gewählten Versicherungsoption.

Ich muss Sie auch darauf hinweisen, dass der Kunde immer für alle Schäden verantwortlich ist, die durch Fahrlässigkeit oder Missbrauch verursacht werden, einschließlich Schäden an Fenstern, Reifen, Unterseite des Autos, Kupplung oder Autoschlüssel. 


Bitte lassen Sie uns wissen, wenn Sie Fragen haben, oder den Wagen für eine der oben genannten Optionen buchen möchten. Vielen Dank!`,
      groups_de: `Wir haben ein Auto der Gruppe XXXX für XXXX.00 für XXXX Tag(e) zur Verfügung, mit XXXX enthalten.
Bei der Grundversicherung bitten wir um eine Kaution von €500.00 in bar bei der Abholung des Fahrzeugs. Die Kaution wird Ihnen am Ende des Mietzeitraums zurückerstattet, wenn das Auto im gleichen Zustand wie bei der Übergabe und mit der gleichen Menge an Kraftstoff (voller Tank) zurückgegeben wird.

Wenn Sie stattdessen die SCDW-Versicherungsoption wählen, beträgt der Preis XXXX.00 für dieselben Daten und Bedingungen.
In diesem Fall würde die Kaution auf €200.00 in bar reduziert werden.

Ich muss Sie auch darauf hinweisen, dass der Kunde immer für alle Schäden verantwortlich ist, die durch Fahrlässigkeit oder Missbrauch verursacht werden, einschließlich Schäden an Fenstern, Reifen, Unterseite des Autos, Kupplung oder Autoschlüssel. 


Bitte lassen Sie uns wissen, wenn Sie Fragen haben, oder den Wagen für eine der oben genannten Optionen buchen möchten. Vielen Dank!`,
    },
    only_c: {
      near: `Leider haben wir zu diesen Terminen nur Angebote der Gruppe C (Renault Clio / Seat Ibiza). Diese Autos haben 5 Sitze/5 Türen, Schaltgetriebe und einen Turbo-Benzinmotor.
      Wären Sie an einem Preisangebot interessiert? Bitte lassen Sie es mich wissen, und wenn ja, würde ich Ihnen eine E-Mail mit allen Details schicken. Ich danke Ihnen.`,
      far: `Im Moment können wir für diese Termine im Voraus nur die Verfügbarkeit von Fahrzeugen ab Gruppe C (Renault Clio / Seat Ibiza). Diese Autos haben 5 Sitze/5 Türen, Schaltgetriebe und einen Turbo-Benzinmotor.
      Wären Sie an einem Preisangebot interessiert? Bitte lassen Sie es mich wissen, und wenn ja, würde ich Ihnen eine E-Mail mit allen Details schicken. Ich danke Ihnen.`,
    },
    "only_c+": {
      near: `Leider haben wir zu diesen Terminen nur Angebote der Gruppe C+ (Seat Ibiza FR / Skoda Fabia). Diese Autos haben 5 Sitze/5 Türen, Schaltgetriebe und einen Turbo-Benzinmotor.
      Wären Sie an einem Preisangebot interessiert? Bitte lassen Sie es mich wissen, und wenn ja, würde ich Ihnen eine E-Mail mit allen Details schicken. Ich danke Ihnen!`,
      far: `Im Moment können wir für diese Termine im Voraus nur die Verfügbarkeit von Fahrzeugen ab Gruppe C+ (Seat Ibiza FR / Skoda Fabia). Diese Autos haben 5 Sitze/5 Türen, Schaltgetriebe und einen Turbo-Benzinmotor.
      Wären Sie an einem Preisangebot interessiert? Bitte lassen Sie es mich wissen, und wenn ja, würde ich Ihnen eine E-Mail mit allen Details schicken. Ich danke Ihnen!`,
    },
    invalid_dates: `Könnten Sie bitte die von Ihnen gewählten Daten überprüfen? Es scheint, dass es einen Fehler gegeben hat. 
Ich danke Ihnen für Ihre Hilfe!`,
    confirmation: `Hallo,

Wir freuen uns, Ihnen mitteilen zu können, dass Ihre Buchung ID-XXXX bestätigt ist!

Details zur Reservierung:
Abholdatum: XXXX
Abholstelle: XXXX

Rückgabedatum: XXXX
Rückgabestelle: XXXX

Fahrzeugklasse: Gruppe XXXX
Versicherung: XXXX Versicherung

Anzahl der zusätzlichen Fahrer: XXXX
Babysitz (0-3 Jahre): XXXX
Kindersitz (4-7 Jahre): XXXX
Kindersitz (8-12 Jahre): XXXX


Vollständiger Mietpreis: €XXXX XXXX
Kaution: €XXXX (bar)


Bitte teilen Sie uns nun Ihre Flugnummer mit, wie viele Personen anreisen und wo Sie während Ihres Aufenthalts auf Madeira übernachten werden.
Vielen Dank im Voraus!

Wir haben alle Informationen, die wir im Moment brauchen.
Vielen Dank und bis bald!


Mit freundlichen Grüßen,`,
  },
};

export { templates };
