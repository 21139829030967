import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { ReactComponent as RenaultLogo } from "../../Resources/Images/Cars/renault.svg";
import { ReactComponent as SeatLogo } from "../../Resources/Images/Cars/seat.svg";
import { ReactComponent as SmartLogo } from "../../Resources/Images/Cars/smart.svg";
import { ReactComponent as NissanLogo } from "../../Resources/Images/Cars/nissan.svg";
import { ReactComponent as SkodaLogo } from "../../Resources/Images/Cars/skoda.svg";
import { Box, Grid, Typography } from "@mui/material";
import { closeEventPopup } from "../../States/Actions/Board";

const EventPopup = (props) => {
  let booking = props.all_bookings.filter((booking) => {
    return booking.booking_info.ID === props.event_popup_open;
  })[0];

  if (!booking) return "";

  booking = booking.booking_info;

  return (
    <Dialog
      open={props.event_popup_open === -1 ? false : true}
      onClose={closeEventPopup}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: { borderRadius: "15px" },
      }}
    >
      <br></br>
      <DialogTitle
        color="#2e3192"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="sf pro text regular"
      >
        Reserva {booking.ID}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" rowGap={5}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1}>
                {booking.Car_Brand === "Renault" ? (
                  <RenaultLogo
                    style={{
                      maxWidth: "28px",
                      maxHeight: "28px",
                      textAlign: "center",
                    }}
                  />
                ) : booking.Car_Brand === "Seat" ? (
                  <SeatLogo
                    style={{
                      maxWidth: "24px",
                      maxHeight: "24px",
                      textAlign: "center",
                    }}
                  />
                ) : booking.Car_Brand === "Smart" ? (
                  <SmartLogo
                    style={{
                      maxWidth: "28px",
                      maxHeight: "28px",
                      textAlign: "center",
                    }}
                  />
                ) : booking.Car_Brand === "Nissan" ? (
                  <NissanLogo
                    style={{
                      maxWidth: "28px",
                      maxHeight: "28px",
                      textAlign: "center",
                    }}
                  />
                ) : booking.Car_Brand === "Skoda" ? (
                  <SkodaLogo
                    style={{
                      maxWidth: "28px",
                      maxHeight: "28px",
                      textAlign: "center",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontFamily: "sf pro text regular",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {booking.Car_Model}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ marginLeft: "30px", marginRight: "0px" }}
              >
                <Box
                  style={{
                    maxHeight: "30px",
                  }}
                  display="flex"
                  justifyContent="center"
                >
                  <div
                    style={{
                      width: "8px",
                      background: "#2c55ce",
                      borderTopLeftRadius: "3px",
                      borderBottomLeftRadius: "3px",
                      border: "1px solid black",
                      borderRight: "0px",
                      marginLeft: "0 auto",
                      padding: "0px",
                      float: "left",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "auto",
                      height: "100%",
                      border: "1px solid black",
                      borderLeft: "0px",
                      borderTopRightRadius: "3px",
                      borderBottomRightRadius: "3px",
                      background: "white",
                      padding: "0px 4px 0px 4px",
                      float: "left",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontWeight: "bold",
                        color: "#000000",
                        fontFamily: "monospace",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                      sx={{
                        fontSize: {
                          lg: 16,
                          md: 14,
                          sm: 12,
                          xs: 10,
                        },
                      }}
                    >
                      {booking.Car_Plate}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      textAlign="center"
                      fontSize="16px"
                      fontWeight="bold"
                      fontFamily="sf pro text regular"
                      color="#2e3192"
                    >{`${booking.Start_Date}, ${booking.Start_Time}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="#2e3192"
                      textAlign="center"
                      fontSize="18px"
                    >
                      ↓
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      textAlign="center"
                      fontSize="16px"
                      fontWeight="bold"
                      fontFamily="sf pro text regular"
                      color="#2e3192"
                    >{`${booking.End_Date}, ${booking.End_Time}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12}>
            <Grid item xs={5.5}>
              <Grid container direction="column" rowGap={2}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                    >
                      Nome:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                      }}
                    >
                      {booking.Client_Name !== "" ? booking.Client_Name : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                    >
                      Email:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                        marginRight: "10px",
                        wordBreak: "break-word",
                      }}
                    >
                      {booking.Client_Email !== "" ? booking.Client_Email : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                    >
                      Telefone:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                      }}
                    >
                      {booking.Client_Phone !== "" ? booking.Client_Phone : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                    >
                      Morada:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                        marginRight: "10px",
                      }}
                    >
                      {booking.Client_Address !== ""
                        ? booking.Client_Address
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6.5}>
              <Grid container direction="column" rowGap={2}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      Local Inicial:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      {booking.Start_Local !== "" ? booking.Start_Local : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      Local Final:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      {booking.End_Local !== "" ? booking.End_Local : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" columnGap={1.5}>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "sf pro text regular",
                            color: "#2e3192",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          Preço:&nbsp;&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontFamily: "sf pro text regular",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          {booking.Price !== ""
                            ? !booking.Price.includes(".") &&
                              !booking.Price.includes(",")
                              ? "€" + booking.Price + ",00"
                              : "€" + booking.Price.replace(".", ",")
                            : " -"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontFamily: "sf pro text regular",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          {booking.Price !== ""
                            ? !booking.Price.includes(".") &&
                              !booking.Price.includes(",")
                              ? "€" + Math.floor(parseFloat(booking.Price) / parseFloat(booking.Num_Days)) + "/dia"
                              : "€" + Math.floor((parseFloat(booking.Price) / parseFloat(booking.Num_Days))).toString().replace(".", ",") + "/dia"
                            : " -"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "sf pro text regular",
                            color: "#2e3192",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          Depósito:&nbsp;&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontFamily: "sf pro text regular",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          {booking.Deposit !== ""
                            ? !booking.Deposit.includes(".") &&
                              !booking.Deposit.includes(",")
                              ? "€" + booking.Deposit + ",00"
                              : "€" + booking.Deposit.replace(".", ",")
                            : " -"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "sf pro text regular",
                            color: "#2e3192",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          Seguro:&nbsp;&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontFamily: "sf pro text regular",
                          }}
                          sx={{
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                          }}
                          wrap="nowrap"
                        >
                          {booking.Insurance === 0
                            ? "Não"
                            : booking.Insurance === 1
                              ? "Sim"
                              : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "sf pro text regular",
                        color: "#2e3192",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      Observações:&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        flexWrap: "wrap",
                        wordWrap: "break-word",
                        fontSize: "14px",
                        fontFamily: "sf pro text regular",
                      }}
                      sx={{
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 10,
                          xs: 8,
                        },
                      }}
                      wrap="nowrap"
                    >
                      {booking.Observations !== "" ? booking.Observations : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    all_bookings: state.bookings.all_bookings,
    event_popup_open: state.board.event_popup_open,
  };
}

export default connect(mapStateToProps)(EventPopup);
