const group_c_email_fr = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width"/>
  <link rel="stylesheet" href="assets/css/foundation-emails.css">
  <title>Autorentacar - Kostenvoranschlag</title>
  <style>
    @font-face {
      font-family: "SF Pro Text Regular";
      src: url(https://www.autorentacar.net/assets/fonts/sfprotextregular.ttf) format("truetype");
    }
    
    body {
      font-family: "SF Pro Text Regular", sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f7f7f9;
      height: 100%;
    }
    html, body {
      height: 100%;
      overflow: hidden;
    }
    h2 {
        color: #262626;
        font-size: min(4vw, 22px);
    }
    p {
        font-size: min(3vw, 16px);
        line-height: 1.7em;
        color: #404040;
    }
    .footerp {
        font-size: min(2vw, 10px);
        color: #fff;
        padding: 18px;
        background-color: #324681;
        text-align: center;
    }
    .additional-info-box {
        font-size: min(3vw, 16px);
        background-color: #dbe0f0;
        padding: 8px;
        border-radius: 6px;
        margin: 50px auto;
        text-align: center;
        width: 90%;
    }
    
    @media screen and (max-width: 600px) {
      /* Adjust styles for smaller screens */

      .container {
          width: 100%;
      }
      header {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      footer {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
      }
      h2 {
        font-size: min(4vw, 22px);
      }
      p {
        font-size: min(3vw, 16px);
      }
      .additional-info-box {
        font-size: min(3vw, 16px);
      }
    }
  </style>
  
</head>

<body>
  <table class="body" style="background-color: #f8f8f8; border-spacing: 0px;" data-made-with-foundation>
    <tr>
      <td valign="top">
        <center>
          <div style="background-color: #fff;">
            <img style="width: min(150px, 15%); padding-top: min(0.9em, 9px); padding-bottom: min(0.5em, 5px);" src="https://www.autorentacar.net/images/content/logo_emails.png" download></img>
          </div>
        </center>
        <div>
          <div style="padding-left: min(5vw, 50px); padding-right: min(5vw, 50px);">
            <br><br><br>
            <p>
                Bonjour XXX,
            </p>
            <br>
            <p style="text-align: justify">
            Malheureusement, pour ces dates, nous n'avons des disponibilités qu'à partir du groupe C (Seat Ibiza FR / Skoda Fabia). Ces voitures sont équipées de 5 places/5 portes, d'une transmission manuelle et d'un moteur turbo essence 1.0L de 110 ch. Cela vous intéresse-t-il toujours ? Si c'est le cas, il vous suffit de remplir à nouveau le formulaire de réservation, mais en choisissant une voiture du groupe C. Soumettez une nouvelle demande et nous vous enverrons une confirmation de réservation. Nous vous remercions de votre attention.
            </p>
            <br>
            <p>
                Cordialement,<br>
                XXX
            </p>
            <br><br>
            <p>
                Auto Rent-a-Car<br>
                +351 918888307 (WhatsApp verfügbar)<br>
                www.autorentacar.net
            </p>
          </div>
        </div>
        <center>
          <br>
          <p class="footerp">
            © 2023 Auto Rent-a-Car. Alle Rechte vorbehalten.
          </p>
      </center>
      </td>
    </tr>
  </table>
</body>
</html>`

export default group_c_email_fr;