import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar.js";
import style from "./BookingRequests.module.css";
import { getPassword, isAuthenticated } from "../../States/Actions/Auth.js";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaHandHoldingMedical, FaCheck, FaRegListAlt } from "react-icons/fa";
import SocketContext from "../../Utils/SocketContext.js";
import cloneDeep from "lodash.clonedeep";
import { BookingTextField } from "../../Components/BookingTextField.js";
import { filterBookingRequests } from "../../States/Actions/BookingRequests.js";
import Moment from "moment";
import { extendMoment } from 'moment-range';
import confirmation_email_en from "../Simulator/templates/confirmation/confirmation_email_en.js";
import confirmation_email_es from "../Simulator/templates/confirmation/confirmation_email_es.js";
import confirmation_email_fr from "../Simulator/templates/confirmation/confirmation_email_fr.js";
import confirmation_email_de from "../Simulator/templates/confirmation/confirmation_email_de.js";
import confirmation_email_pt from "../Simulator/templates/confirmation/confirmation_email_pt.js";
import confirmation_email_it from "../Simulator/templates/confirmation/confirmation_email_it.js";
import normal_email_en from "../Simulator/templates/normal/normal_email_en.js";
import normal_email_es from "../Simulator/templates/normal/normal_email_es.js";
import normal_email_de from "../Simulator/templates/normal/normal_email_de.js";
import normal_email_fr from "../Simulator/templates/normal/normal_email_fr.js";
import normal_email_pt from "../Simulator/templates/normal/normal_email_pt.js";
import normal_email_it from "../Simulator/templates/normal/normal_email_it.js";
import group_a_email_en from "../Simulator/templates/options/group_a_email_en.js";
import group_a_email_es from "../Simulator/templates/options/group_a_email_es.js";
import group_a_email_fr from "../Simulator/templates/options/group_a_email_fr.js";
import group_a_email_de from "../Simulator/templates/options/group_a_email_de.js";
import group_a_email_pt from "../Simulator/templates/options/group_a_email_pt.js";
import group_a_email_it from "../Simulator/templates/options/group_a_email_it.js";
import group_b_email_en from "../Simulator/templates/options/group_b_email_en.js";
import group_b_email_es from "../Simulator/templates/options/group_b_email_es.js";
import group_b_email_fr from "../Simulator/templates/options/group_b_email_fr.js";
import group_b_email_de from "../Simulator/templates/options/group_b_email_de.js";
import group_b_email_pt from "../Simulator/templates/options/group_b_email_pt.js";
import group_b_email_it from "../Simulator/templates/options/group_b_email_it.js";
import group_c_email_en from "../Simulator/templates/options/group_c_email_en.js";
import group_c_email_es from "../Simulator/templates/options/group_c_email_es.js";
import group_c_email_fr from "../Simulator/templates/options/group_c_email_fr.js";
import group_c_email_de from "../Simulator/templates/options/group_c_email_de.js";
import group_c_email_pt from "../Simulator/templates/options/group_c_email_pt.js";
import group_c_email_it from "../Simulator/templates/options/group_c_email_it.js";
import group_m_email_en from "../Simulator/templates/options/group_m_email_en.js";
import group_m_email_es from "../Simulator/templates/options/group_m_email_es.js";
import group_m_email_fr from "../Simulator/templates/options/group_m_email_fr.js";
import group_m_email_de from "../Simulator/templates/options/group_m_email_de.js";
import group_m_email_pt from "../Simulator/templates/options/group_m_email_pt.js";
import group_m_email_it from "../Simulator/templates/options/group_m_email_it.js";
import group_d_email_en from "../Simulator/templates/options/group_d_email_en.js";
import group_d_email_es from "../Simulator/templates/options/group_d_email_es.js";
import group_d_email_fr from "../Simulator/templates/options/group_d_email_fr.js";
import group_d_email_de from "../Simulator/templates/options/group_d_email_de.js";
import group_d_email_pt from "../Simulator/templates/options/group_d_email_pt.js";
import group_d_email_it from "../Simulator/templates/options/group_d_email_it.js";
import group_e_email_en from "../Simulator/templates/options/group_e_email_en.js";
import group_e_email_es from "../Simulator/templates/options/group_e_email_es.js";
import group_e_email_fr from "../Simulator/templates/options/group_e_email_fr.js";
import group_e_email_de from "../Simulator/templates/options/group_e_email_de.js";
import group_e_email_pt from "../Simulator/templates/options/group_e_email_pt.js";
import group_e_email_it from "../Simulator/templates/options/group_e_email_it.js";
import options_email_en from "../Simulator/templates/options/options_email_en.js";
import options_email_es from "../Simulator/templates/options/options_email_es.js";
import options_email_fr from "../Simulator/templates/options/options_email_fr.js";
import options_email_de from "../Simulator/templates/options/options_email_de.js";
import options_email_pt from "../Simulator/templates/options/options_email_pt.js";
import options_email_it from "../Simulator/templates/options/options_email_it.js";
import commission_email_en from "../Simulator/templates/commission/commission_email_en.js";
import commission_email_es from "../Simulator/templates/commission/commission_email_es.js";
import commission_email_fr from "../Simulator/templates/commission/commission_email_fr.js";
import commission_email_de from "../Simulator/templates/commission/commission_email_de.js";
import commission_email_pt from "../Simulator/templates/commission/commission_email_pt.js";
import commission_email_it from "../Simulator/templates/commission/commission_email_it.js";
import reject_email_en from "../Simulator/templates/reject/reject_email_en.js";
import reject_email_es from "../Simulator/templates/reject/reject_email_es.js";
import reject_email_fr from "../Simulator/templates/reject/reject_email_fr.js";
import reject_email_de from "../Simulator/templates/reject/reject_email_de.js";
import reject_email_pt from "../Simulator/templates/reject/reject_email_pt.js";
import reject_email_it from "../Simulator/templates/reject/reject_email_it.js";
import { systembackend_ip, websitebackend_ip } from "../../Utils/Network.js";
import { titleCase } from "title-case";
import axios from "axios";
import { Virtuoso } from "react-virtuoso";
import { store } from "../../index.js";
const moment = extendMoment(Moment);


const html_templates = {
  "Inglês": {
    "normal": normal_email_en,
    "confirmation": confirmation_email_en,
  },
  "Espanhol": {
    "normal": normal_email_es,
    "confirmation": confirmation_email_es,
  },
  "Alemão": {
    "normal": normal_email_de,
    "confirmation": confirmation_email_de,
  },
  "Francês": {
    "normal": normal_email_fr,
    "confirmation": confirmation_email_fr,
  },
  "Português": {
    "normal": normal_email_pt,
    "confirmation": confirmation_email_pt,
  },
  "Italiano": {
    "normal": normal_email_it,
    "confirmation": confirmation_email_it,
  }
}

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

const isAvailable = (group, period, gaps) => {
  for (var gap of gaps[group.toUpperCase()]) {
    let range = moment.range(moment(gap.range.start), moment(gap.range.end))
    try {
      if (range.contains(period, true)) return true;
    }
    catch (e) {
      let a = e
    }
  }
  return false;
};

// const setTag = (request, tag, state, socket) => {
//   let _request = cloneDeep(request)
//   if (!state)
//     _request.Status = _request.Status.replace(";" + tag, "").replace(tag + ";", "").replace(tag, "")
//   else
//     _request.Status = _request.Status + ";" + tag
//   socket.emit("update_booking_request", { password: getPassword(), data: _request })
//   return _request
// }

const toggleTag = (request, tag, socket) => {
  let _request = cloneDeep(request)
  if (_request.Status.includes(tag))
    _request.Status = _request.Status.replace(";" + tag, "").replace(tag + ";", "").replace(tag, "")
  else
    _request.Status = _request.Status + ";" + tag

  // _request.Status = _request.Status.replace(";new", "").replace("new;", "").replace("new", "")

  socket.emit("update_booking_request", { password: getPassword(), data: _request })
}

const getConfirmationTemplate = (request) => {

  let e = document.getElementById("req_" + request.ID + "_langs")
  let lang = e.options[e.selectedIndex].text

  if (lang === "")
    lang = request.Language

  let html_template = html_templates[lang]["confirmation"];
  let nextid = null;

  axios
    .post(systembackend_ip + "/nextid", { password: getPassword() })
    .then((resp) => {
      if (resp.status === 200) {
        nextid = resp.data;

        let insurance = request.Insurance === 1 ? "scdw" : "cdw";

        let cost = request.Submitted_Price + ".00";
        let car_transmission = null;
        let car_models = null;

        try {
          if (request.Car_Group === "M" || request.Car_Group === "E") {
            if (lang === "Inglês") car_transmission = "Automatic";
            else if (lang === "Espanhol") car_transmission = "Automático";
            else if (lang === "Francês") car_transmission = "Automatique";
            else if (lang === "Alemão") car_transmission = "Automatisch";
            else if (lang === "Português") car_transmission = "Automático";
            else if (lang === "Italiano") car_transmission = "Automatico";
          }
          else {
            if (lang === "Inglês") car_transmission = "Manual";
            else if (lang === "Espanhol") car_transmission = "Manual";
            else if (lang === "Francês") car_transmission = "Manuel";
            else if (lang === "Alemão") car_transmission = "Manuell";
            else if (lang === "Português") car_transmission = "Manual";
            else if (lang === "Italiano") car_transmission = "Manuale";
          }

          if (request.Car_Group === "A") car_models = "Fiat Panda Hybrid";
          else if (request.Car_Group === "B") car_models = "Renault Clio / Seat Ibiza";
          else if (request.Car_Group === "C") car_models = "Seat Ibiza FR / Skoda Fabia";
          else if (request.Car_Group === "M") car_models = "Nissan Micra / Skoda Fabia";
          else if (request.Car_Group === "D") car_models = "Renault Captur";
          else if (request.Car_Group === "E") car_models = "Renault Captur/Seat Arona";

        } catch {
          return null;
        }

        let deposit = request.Submitted_Deposit + ".00";

        let start_local = request.Start_Local
        let end_local = request.End_Local

        if (start_local === "Madeira Airport") {
          if (lang === "Inglês") start_local = "Airport arrivals";
          else if (lang === "Espanhol") start_local = "Llegadas del aeropuerto";
          else if (lang === "Português") start_local = "Chegadas do aeroporto";
          else if (lang === "Francês") start_local = "Arrivées de l'aéroport";
          else if (lang === "Alemão") start_local = "Flughafenankünfte";
          else if (lang === "Italiano") start_local = "Arrivi in aeroporto";
        } else if (start_local === "Accommodation in Funchal") {
          if (lang === "Inglês") start_local = "Accommodation located in Funchal";
          else if (lang === "Espanhol") start_local = "Alojamiento ubicado en Funchal";
          else if (lang === "Português") start_local = "Alojamento localizado no Funchal";
          else if (lang === "Francês") start_local = "Hébergement situé à Funchal";
          else if (lang === "Alemão") start_local = "Unterkunft in Funchal";
          else if (lang === "Italiano") start_local = "Alloggio situato a Funchal";
        } else if (start_local === "Funchal Ship Port") {
          if (lang === "Inglês") start_local = "Funchal's Ship Port";
          else if (lang === "Espanhol") start_local = "Puerto de cruceros de Funchal";
          else if (lang === "Português") start_local = "Porto de cruzeiros do Funchal";
          else if (lang === "Francês") start_local = "Port de croisière de Funchal";
          else if (lang === "Alemão") start_local = "Hafen von Funchal";
          else if (lang === "Italiano") start_local = "Il porto di Funchal";
        } else if (start_local === "Funchal Office (Rua da Casa Branca 33)") {
          if (lang === "Inglês") start_local = "Funchal Office - Rua da Casa Branca, 33";
          else if (lang === "Espanhol") start_local = "Oficina de Funchal - Rua da Casa Branca, 33";
          else if (lang === "Português") start_local = "Escritório do Funchal - Rua da Casa Branca, 33";
          else if (lang === "Francês") start_local = "Bureau de Funchal - Rua da Casa Branca, 33";
          else if (lang === "Alemão") start_local = "Funchal Büro - Rua da Casa Branca, 33";
          else if (lang === "Italiano") start_local = "Ufficio di Funchal - Rua da Casa Branca, 33";
        }

        if (end_local === "Madeira Airport") {
          if (lang === "Inglês") end_local = "Airport departures";
          else if (lang === "Espanhol") end_local = "Salidas del aeropuerto";
          else if (lang === "Português") end_local = "Partidas do aeroporto";
          else if (lang === "Francês") end_local = "Départs de l'aéroport";
          else if (lang === "Alemão") end_local = "Flughafenabflüge";
          else if (lang === "Italiano") end_local = "Partenze dall'aeroporto";
        } else if (end_local === "Accommodation in Funchal") {
          if (lang === "Inglês") end_local = "Accommodation located in Funchal";
          else if (lang === "Espanhol") end_local = "Alojamiento ubicado en Funchal";
          else if (lang === "Português") end_local = "Alojamento localizado no Funchal";
          else if (lang === "Francês") end_local = "Hébergement situé à Funchal";
          else if (lang === "Alemão") end_local = "Unterkunft in Funchal";
          else if (lang === "Italiano") end_local = "Alloggio situato a Funchal";
        } else if (end_local === "Funchal Ship Port") {
          if (lang === "Inglês") end_local = "Funchal's Ship Port";
          else if (lang === "Espanhol") end_local = "Puerto de cruceros de Funchal";
          else if (lang === "Português") end_local = "Porto de cruzeiros do Funchal";
          else if (lang === "Francês") end_local = "Port de croisière de Funchal";
          else if (lang === "Alemão") end_local = "Hafen von Funchal";
          else if (lang === "Italiano") end_local = "Il porto di Funchal";
        } else if (end_local === "Funchal Office (Rua da Casa Branca 33)") {
          if (lang === "Inglês") end_local = "Funchal Office - Rua da Casa Branca, 33";
          else if (lang === "Espanhol") end_local = "Oficina de Funchal - Rua da Casa Branca, 33";
          else if (lang === "Português") end_local = "Escritório do Funchal - Rua da Casa Branca, 33";
          else if (lang === "Francês") end_local = "Bureau de Funchal - Rua da Casa Branca, 33";
          else if (lang === "Alemão") end_local = "Funchal Büro - Rua da Casa Branca, 33";
          else if (lang === "Italiano") end_local = "Ufficio di Funchal - Rua da Casa Branca, 33";
        }

        let separator = "at";

        if (lang === "Inglês") separator = "at";
        else if (lang === "Espanhol") separator = "a las";
        else if (lang === "Português") separator = "às";
        else if (lang === "Francês") separator = "à";
        else if (lang === "Alemão") separator = "um";
        else if (lang === "Italiano") separator = "alle";

        const values = [
          nextid - 1,
          titleCase(request.Client_Name.split(" ")[0]),
          request.Start_Date +
          " " +
          separator +
          " " +
          request.Start_Time,
          start_local,
          request.End_Date + " " + separator + " " + request.End_Time,
          end_local,
          request.Num_Cars,
          request.Car_Group,
          car_transmission,
          car_models,
          request.Num_Extra_Drivers,
          request.Num_Child_Seats_0_3,
          request.Num_Child_Seats_4_7,
          request.Num_Child_Seats_8_12,
          insurance.toUpperCase(),
          "€" + cost,
          "€" + deposit,
          "XXX"
        ];

        for (var value of values) {
          html_template = html_template.replace("XXX", value);
        }
        var container = document.createElement('div')
        container.innerHTML = html_template
        container.style.position = 'fixed'
        container.style.pointerEvents = 'none'
        container.style.opacity = 0
        document.body.appendChild(container)
        window.getSelection().removeAllRanges()
        var range = document.createRange()
        range.selectNode(container)
        window.getSelection().addRange(range)
        document.execCommand('copy')
        document.body.removeChild(container);
        window.open("mailto:" + request.Client_Email + "?subject=Autorentacar Booking Request ID-" + request.ID + "&body=" + "")
      } else {

      }
    })
    .catch((reason) => {
      let a = reason
    });
}

const getOptions = (req, gaps) => {
  let recommendation = ""
  if (req.Car_Group === "A") {
    if (isAvailable("B", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "B"
    else if (isAvailable("C", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "C"
    else if (isAvailable("M", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "M"
    else if (isAvailable("D", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "D"
    else if (isAvailable("E", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "E"
    else
      recommendation = ""
  }
  if (req.Car_Group === "B") {
    if (isAvailable("C", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "C"
    else if (isAvailable("A", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "A"
    else if (isAvailable("M", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "M"
    else if (isAvailable("D", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "D"
    else if (isAvailable("E", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "E"
    else
      recommendation = ""
  }
  if (req.Car_Group === "C") {
    if (isAvailable("B", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "B"
    else if (isAvailable("D", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "D"
    else if (isAvailable("M", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "M"
    else if (isAvailable("A", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "A"
    else if (isAvailable("E", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "E"
    else
      recommendation = ""
  }
  if (req.Car_Group === "M") {
    if (isAvailable("E", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "E"
    else
      recommendation = ""
  }
  if (req.Car_Group === "D") {
    if (isAvailable("E", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "E"
    else if (isAvailable("C", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "C"
    else if (isAvailable("M", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "M"
    else if (isAvailable("B", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "B"
    else if (isAvailable("A", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "A"
    else
      recommendation = ""
  }
  if (req.Car_Group === "E") {
    if (isAvailable("M", moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
      recommendation = "M"
    else
      recommendation = ""
  }
  return recommendation
}

const getOptionsTemplate = (req, gaps) => {
  let dic = {
    "Inglês": {
      a: group_a_email_en,
      b: group_b_email_en,
      c: group_c_email_en,
      m: group_m_email_en,
      d: group_d_email_en,
      e: group_e_email_en,
      gaps: options_email_en
    },
    "Espanhol": {
      a: group_a_email_es,
      b: group_b_email_es,
      c: group_c_email_es,
      m: group_m_email_es,
      d: group_d_email_es,
      e: group_e_email_es,
      gaps: options_email_es
    },
    "Francês": {
      a: group_a_email_fr,
      b: group_b_email_fr,
      c: group_c_email_fr,
      m: group_m_email_fr,
      d: group_d_email_fr,
      e: group_e_email_fr,
      gaps: options_email_fr
    },
    "Alemão": {
      a: group_a_email_de,
      b: group_b_email_de,
      c: group_c_email_de,
      m: group_m_email_de,
      d: group_d_email_de,
      e: group_e_email_de,
      gaps: options_email_de
    },
    "Português": {
      a: group_a_email_pt,
      b: group_b_email_pt,
      c: group_c_email_pt,
      m: group_m_email_pt,
      d: group_d_email_pt,
      e: group_e_email_pt,
      gaps: options_email_pt
    },
    "Italiano": {
      a: group_a_email_it,
      b: group_b_email_it,
      c: group_c_email_it,
      m: group_m_email_it,
      d: group_d_email_it,
      e: group_e_email_it,
      gaps: options_email_it
    },
  }
  let recommendation = getOptions(req, gaps)
  let htmlCode = ""
  let e = document.getElementById("req_" + req.ID + "_langs")
  let lang = e.options[e.selectedIndex].text

  if (lang === "")
    lang = req.Language

  if (recommendation !== "") {
    htmlCode = dic[lang][recommendation.toLowerCase()]
  }
  else {
    htmlCode = dic[lang]["gaps"]
  }

  htmlCode = htmlCode.replace("XXX", titleCase(req.Client_Name.split(" ")[0]))
  var container = document.createElement('div')
  container.innerHTML = htmlCode
  container.style.position = 'fixed'
  container.style.pointerEvents = 'none'
  container.style.opacity = 0
  document.body.appendChild(container)
  window.getSelection().removeAllRanges()
  var range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)
  document.execCommand('copy')
  document.body.removeChild(container);
  window.open("mailto:" + req.Client_Email + "?subject=Autorentacar Booking Request ID-" + req.ID + "&body=" + "")
}

const getCommissionTemplate = (req) => {
  let htmlCode = ""
  let e = document.getElementById("req_" + req.ID + "_langs")
  let lang = e.options[e.selectedIndex].text

  if (lang === "")
    lang = req.Language

  if (lang === "Inglês")
    htmlCode = commission_email_en
  else if (lang === "Espanhol")
    htmlCode = commission_email_es
  else if (lang === "Francês")
    htmlCode = commission_email_fr
  else if (lang === "Alemão")
    htmlCode = commission_email_de
  else if (lang === "Português")
    htmlCode = commission_email_pt
  else if (lang === "Italiano")
    htmlCode = commission_email_it

  htmlCode = htmlCode.replace("XXX", titleCase(req.Client_Name.split(" ")[0]))

  var container = document.createElement('div')
  container.innerHTML = htmlCode
  container.style.position = 'fixed'
  container.style.pointerEvents = 'none'
  container.style.opacity = 0
  document.body.appendChild(container)
  window.getSelection().removeAllRanges()
  var range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)
  document.execCommand('copy')
  document.body.removeChild(container);
  window.open("mailto:" + req.Client_Email + "?subject=Autorentacar Booking Request ID-" + req.ID + "&body=" + "")
}

const getRejectionTemplate = (req) => {
  let htmlCode = ""
  let e = document.getElementById("req_" + req.ID + "_langs")
  let lang = e.options[e.selectedIndex].text

  if (lang === "")
    lang = req.Language

  if (lang === "Inglês")
    htmlCode = reject_email_en
  else if (lang === "Espanhol")
    htmlCode = reject_email_es
  else if (lang === "Francês")
    htmlCode = reject_email_fr
  else if (lang === "Alemão")
    htmlCode = reject_email_de
  else if (lang === "Português")
    htmlCode = reject_email_pt
  else if (lang === "Italiano")
    htmlCode = reject_email_it

  htmlCode = htmlCode.replace("XXX", titleCase(req.Client_Name.split(" ")[0]))
  var container = document.createElement('div')
  container.innerHTML = htmlCode
  container.style.position = 'fixed'
  container.style.pointerEvents = 'none'
  container.style.opacity = 0
  document.body.appendChild(container)
  window.getSelection().removeAllRanges()
  var range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)
  document.execCommand('copy')
  document.body.removeChild(container);
  window.open("mailto:" + req.Client_Email + "?subject=Autorentacar Booking Request ID-" + req.ID + "&body=" + "")
}

const getAutoTemplate = (req, gaps) => {
  if (isAvailable(req.Car_Group, moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps))
    return getConfirmationTemplate(req)
  else if (getOptions(req, gaps) != "")
    return getOptionsTemplate(req, gaps)
  else return getCommissionTemplate(req)
}

const bookRequest = (socket, snackbar, req, bookings, gaps) => {
  // Check if a car is available
  if (!isAvailable(req.Car_Group, moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), gaps)) {
    snackbar("Nenhum carro disponível", { variant: "error" });
    return;
  }

  // Check if the dates are valid
  let start_date = moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm")
  let end_date = moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")

  if (!start_date.isValid() || !end_date.isValid() || start_date.isSameOrAfter(end_date, "minutes")) {
    snackbar("Datas inválidas", { variant: "error" });
    return;
  }

  // Clean locations
  let startLocal = ""
  let endLocal = ""
  if (req.Start_Local.toLowerCase().includes("office"))
    startLocal = "Escritório"
  else if (req.Start_Local.toLowerCase().includes("airport"))
    startLocal = "Aeroporto"
  else if (req.Start_Local.toLowerCase().includes("accommodation"))
    startLocal = "Funchal"
  else if (req.Start_Local.toLowerCase().includes("ship"))
    startLocal = "Pontinha"
  else if (req.Start_Local.toLowerCase().includes("other"))
    startLocal = ""

  if (req.End_Local.toLowerCase().includes("office"))
    endLocal = "Escritório"
  else if (req.End_Local.toLowerCase().includes("airport"))
    endLocal = "Aeroporto"
  else if (req.End_Local.toLowerCase().includes("accommodation"))
    endLocal = "Funchal"
  else if (req.End_Local.toLowerCase().includes("ship"))
    endLocal = "Pontinha"
  else if (req.End_Local.toLowerCase().includes("other"))
    endLocal = ""

  // Select car
  let carBrand = ""
  let carModel = ""
  let carPlate = ""
  let _gaps = gaps[req.Car_Group]
  let cars = store.getState().bookings.cars.filter((car) => { return car.Group === req.Car_Group })
  let edgeBookings = []

  for (var car of cars) {
    let carBookings = bookings.filter((booking) => { return booking.booking_info.Car_Plate === car.Plate })

    let prevBooking = undefined
    let prevBookingTimeDiff = Infinity
    let nextBooking = undefined
    let nextBookingTimeDiff = Infinity
    for (var i = carBookings.length - 1; i >= 0; i--) {
      let booking = carBookings[i]
      let bookingStart = moment(booking.booking_info.Start_Date + "T" + booking.booking_info.Start_Time, "DD-MM-YYYYTHH:mm")
      let bookingEnd = moment(booking.booking_info.End_Date + "T" + booking.booking_info.End_Time, "DD-MM-YYYYTHH:mm")

      if (moment.range(bookingStart, bookingEnd).overlaps(moment.range(start_date, end_date)) && !edgeBookings.includes(booking))
        edgeBookings.push(booking)

      if (start_date.diff(bookingEnd, "day") > 0 && start_date.diff(bookingEnd, "day") < prevBookingTimeDiff)//start_date.diff(moment(prevBooking.booking_info.End_Date + "T" + prevBooking.booking_info.End_Time, "DD-MM-YYYYTHH:mm"), "day"))
      {
        prevBooking = booking
        prevBookingTimeDiff = start_date.diff(bookingEnd, "day")
      }
      if (bookingStart.diff(end_date, "day") > 0 && bookingStart.diff(end_date, "day") < nextBookingTimeDiff)//moment(nextBooking.booking_info.Start_Date + "T" + nextBooking.booking_info.Start_Time, "DD-MM-YYYYTHH:mm").diff(end_date, "day"))
      {
        nextBooking = booking
        nextBookingTimeDiff = bookingStart.diff(end_date, "day")
      }
    }

    if (prevBooking !== undefined && !edgeBookings.includes(prevBooking))
      edgeBookings.push(prevBooking)

    if (nextBooking !== undefined && !edgeBookings.includes(nextBooking))
      edgeBookings.push(nextBooking)

    let a = 1
  }

  // Clean inputs
  let num_days = req.Num_Days
  if (isNaN(num_days)) num_days = "";
  else num_days = num_days.toString();

  let price = parseFloat(req.Submitted_Price);
  if (isNaN(price)) price = "";
  else price = price.toFixed(2);

  let deposit = parseFloat(req.Submitted_Deposit);
  if (isNaN(deposit)) deposit = "";
  else deposit = deposit.toFixed(2);

  // Add booking
  const booking = {
    client_name: titleCase(
      req.Client_Name.trim()
    ),
    client_email: req.Client_Email.trim(),
    client_phone: req.Client_Phone.trim(),
    client_address: "",
    start_date: req.Start_Date.replaceAll("/", "-"),
    start_time: req.Start_Time,
    start_local: titleCase(
      startLocal.trim()
    ),
    end_date: req.End_Date.replaceAll("/", "-"),
    end_time: req.End_Time,
    end_local: titleCase(
      endLocal.trim()
    ),
    num_days: num_days,
    car_brand: titleCase(
      "".trim()
    ),
    car_model: titleCase(
      "".trim()
    ),
    car_plate: "",
    car_group: req.Car_Group,
    price: req.Submitted_Price,
    deposit: req.Submitted_Deposit,
    insurance: req.Insurance === 1,
    observations: "".trim(),
    added_date: Date.now(),
    modified_date: Date.now(),
  };

  socket.emit("add_booking", { password: getPassword(), data: booking });
}

const BookingRequests = (props) => {

  const socket = useContext(SocketContext);

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    box_height: window.innerHeight - 80 - 150,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        box_height: window.innerHeight - 80 - 150,
      });
    }, 1);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  // useEffect(() => {
  //   disableEditBookingsMode();
  //   closeEditBookingPopup();
  //   updateAddBookingSection();
  // }, []);

  const [quoteStats, setQuoteStats] = useState(0);
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      axios
        .post(websitebackend_ip + "/sysquotecount", { password: getPassword() })
        .then((resp) => {
          if (resp.status === 200) {
            setQuoteStats(resp.data.count + " pedidos de preço desde " + moment.unix(resp.data.start).locale("pt").calendar().toLowerCase())
          } else {
          }
        })
        .catch((reason) => {
          let a = reason
        });

    }, 5000);
    //Clearing the interval
    return () => clearInterval(interval);
  }, [quoteStats]);

  if (!isAuthenticated()) return <Navigate to="/" />;

  let all_requests = props.displayed_booking_requests
  let new_requests = []
  let confirmed_requests = []
  let details_requests = []
  let ongoing_requests = []
  let commissioned_requests = []
  let problem_requests = []
  let cancelled_requests = []
  let archived_requests = []

  for (var req of all_requests) {
    if (req.Status.includes("new"))
      new_requests.push(req)
    if (req.Status.includes("confirmed"))
      confirmed_requests.push(req)
    if (req.Status.includes("details"))
      details_requests.push(req)
    if (req.Status.includes("ongoing"))
      ongoing_requests.push(req)
    if (req.Status.includes("commissioned"))
      commissioned_requests.push(req)
    if (req.Status.includes("problem"))
      problem_requests.push(req)
    if (req.Status.includes("cancelled"))
      cancelled_requests.push(req)
    if (req.Status.includes("archived"))
      archived_requests.push(req)
  }

  // let new_requests = all_requests.filter(req => req.Status.includes("new"))
  // let ongoing_requests = all_requests.filter(req => !req.Status.includes("new"))
  // let confirmed_requests = all_requests.filter(req => !req.Status.includes("new"))
  // let details_requests = all_requests.filter(req => !req.Status.includes("new"))
  // let commissioned_requests = all_requests.filter(req => !req.Status.includes("new"))
  // let archived_requests = all_requests.filter(req => !req.Status.includes("new"))

  // new_requests = new_requests.toSorted((a, b) => {
  //   if (parseInt(a.Added_Date) > parseInt(b.Added_Date)) return -1; else return 1
  // })

  // ongoing_requests = ongoing_requests.toSorted((a, b) => {
  //   if (parseInt(a.Added_Date) > parseInt(b.Added_Date)) return -1; else return 1
  // })

  // let sorted_requests = new_requests.concat(ongoing_requests)

  let sorted_requests = all_requests.toSorted((a, b) => {
    if (parseInt(a.Added_Date) > parseInt(b.Added_Date)) return -1; else return 1
  })

  document.title = new_requests.length + " pedidos novos"

  return (
    <div>
      <Navbar />
      <div className={style.box}>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" gap={2}>
              <Grid item marginRight="10px">
                <div style={{ height: '6vh', overflow: 'auto' }}>
                  <BookingTextField
                    onChange={(event) => {
                      let _filters = cloneDeep(props.filters)
                      _filters.text = event.target.value
                      filterBookingRequests(_filters)
                    }}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: "200px" }}
                    sx={{
                      input: {
                        fontSize: {
                          lg: 14,
                          md: 12,
                          sm: 12,
                          xs: 8,
                        },
                        fontFamily: "sf pro text regular",
                        padding: "10px"
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.new += 1
                    if (_filters.new > 1)
                      _filters.new = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.new === 1 ? "#57fad4" : "#fff",
                    color: props.filters.new === 1 ? "#fff" : props.filters.new === -1 ? "#57fad4" : "#ddd",
                    border: props.filters.new === 0 ? "1px solid #ddd" : "1px solid #57fad4",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Novo
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.confirmed += 1
                    if (_filters.confirmed > 1)
                      _filters.confirmed = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.confirmed === 1 ? "#6de639" : "#fff",
                    color: props.filters.confirmed === 1 ? "#fff" : props.filters.confirmed === -1 ? "#6de639" : "#ddd",
                    border: props.filters.confirmed === 0 ? "1px solid #ddd" : "1px solid #6de639",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Confirmado
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.details += 1
                    if (_filters.details > 1)
                      _filters.details = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.details === 1 ? "#ffdd33" : "#fff",
                    color: props.filters.details === 1 ? "#fff" : props.filters.details === -1 ? "#ffdd33" : "#ddd",
                    border: props.filters.details === 0 ? "1px solid #ddd" : "1px solid #ffdd33",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  + Detalhes
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.ongoing += 1
                    if (_filters.ongoing > 1)
                      _filters.ongoing = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.ongoing === 1 ? "#fc8c03" : "#fff",
                    color: props.filters.ongoing === 1 ? "#fff" : props.filters.ongoing === -1 ? "#fc8c03" : "#ddd",
                    border: props.filters.ongoing === 0 ? "1px solid #ddd" : "1px solid #fc8c03",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Em curso
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.commissioned += 1
                    if (_filters.commissioned > 1)
                      _filters.commissioned = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.commissioned === 1 ? "#33aaff" : "#fff",
                    color: props.filters.commissioned === 1 ? "#fff" : props.filters.commissioned === -1 ? "#33aaff" : "#ddd",
                    border: props.filters.commissioned === 0 ? "1px solid #ddd" : "1px solid #33aaff",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Dado
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.problem += 1
                    if (_filters.problem > 1)
                      _filters.problem = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.problem === 1 ? "#fc0303" : "#fff",
                    color: props.filters.problem === 1 ? "#fff" : props.filters.problem === -1 ? "#fc0303" : "#ddd",
                    border: props.filters.problem === 0 ? "1px solid #ddd" : "1px solid #fc0303",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Problema
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.cancelled += 1
                    if (_filters.cancelled > 1)
                      _filters.cancelled = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.cancelled === 1 ? "#a503fc" : "#fff",
                    color: props.filters.cancelled === 1 ? "#fff" : props.filters.cancelled === -1 ? "#a503fc" : "#ddd",
                    border: props.filters.cancelled === 0 ? "1px solid #ddd" : "1px solid #a503fc",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Cancelado
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    let _filters = cloneDeep(props.filters)
                    _filters.archived += 1
                    if (_filters.archived > 1)
                      _filters.archived = -1
                    filterBookingRequests(_filters)
                  }}
                  style={{
                    minWidth: "110px",
                    height: "25px",
                    backgroundColor: props.filters.archived === 1 ? "#616161" : "#fff",
                    color: props.filters.archived === 1 ? "#fff" : props.filters.archived === -1 ? "#616161" : "#ddd",
                    border: props.filters.archived === 0 ? "1px solid #ddd" : "1px solid #616161",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  Arquivado
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div style={{ height: '82vh' }}>
              <Grid container direction="column" gap={2}>
                <Virtuoso
                  style={{ height: "82vh" }}
                  data={sorted_requests}
                  itemContent={(_, req) => <Grid item style={{ border: "2px solid #f9f9f9", borderRadius: "10px", marginBottom: "15px" }}>
                    <Grid container direction="row">
                      <Grid item >
                        {
                          req.Status.includes("new") ? <div style={{ width: "6px", height: "100%", background: "#57fad4", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}></div> :
                            <div style={{ width: "6px", height: "100%", background: "#fff", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}></div>
                        }
                      </Grid>
                      <Grid item style={{ width: "250px", padding: "20px 0px 0px 20px", borderRight: "1px solid #ebebeb" }}>
                        <Grid container direction="column" gap={0.5}>
                          <Grid item>
                            <Typography style={{ fontSize: "18px" }}>
                              {req.Client_Name}, {req.Client_Age}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: "14px", textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                              const htmlCode = normal_email_en
                              var container = document.createElement('div')
                              container.innerHTML = htmlCode
                              container.style.position = 'fixed'
                              container.style.pointerEvents = 'none'
                              container.style.opacity = 0
                              document.body.appendChild(container)
                              window.getSelection().removeAllRanges()
                              var range = document.createRange()
                              range.selectNode(container)
                              window.getSelection().addRange(range)
                              document.execCommand('copy')
                              document.body.removeChild(container);
                              window.open("mailto:" + req.Client_Email + "?subject=Autorentacar Booking Request ID-" + req.ID + "&body=" + "")

                            }}>
                              {req.Client_Email}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: "14px" }}>
                              <a href={"tel:" + req.Client_Phone} style={{ color: "#000" }}>{req.Client_Phone}</a>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: "14px" }}>
                              {req.Language}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <select name="langs" id={"req_" + req.ID + "_langs"}>
                              <option value=""></option>
                              <option value="Inglês">Inglês</option>
                              <option value="Francês">Francês</option>
                              <option value="Espanhol">Espanhol</option>
                              <option value="Português">Português</option>
                              <option value="Alemão">Alemão</option>
                              <option value="Italiano">Italiano</option>
                            </select>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: "14px" }}>
                              ID-{req.ID}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ minWidth: "400px", padding: "20px 20px 0px 0px", borderRight: "1px solid #ebebeb", }}>
                        <Grid container direction="column">
                          <Grid item>
                            <Grid container direction="row">
                              <Grid item style={{ minWidth: "200px", padding: "0px 0px 0px 20px" }}>
                                <Grid container direction="column" gap={2}>
                                  <Grid item>
                                    <Typography fontSize={15}>
                                      {req.Start_Date} às {req.Start_Time}
                                    </Typography>
                                    <Typography fontSize={15}>
                                      {req.Start_Local.split("(")[0].replace("in Funchal", "").replace("Funchal", "")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography fontSize={15}>
                                      {req.End_Date} às {req.End_Time}
                                    </Typography>
                                    <Typography fontSize={15}>
                                      {req.End_Local.split("(")[0].replace("in Funchal", "").replace("Funchal", "")}
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ paddingTop: "5px" }}>
                                    <Grid container direction="row" gap={1.5}>
                                      {
                                        req.Num_Extra_Drivers > 0 ? (
                                          <Grid item>
                                            <Grid container direction="row" gap={0.4}>
                                              <Grid item>
                                                <Typography fontSize={14}>
                                                  {req.Num_Extra_Drivers}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <GiSteeringWheel style={{ fontSize: "22px", transform: "translate(0px, 0px)" }}></GiSteeringWheel>
                                              </Grid>
                                            </Grid>
                                          </Grid>) : ""
                                      }
                                      {
                                        req.Num_Child_Seats_0_3 > 0 ? (
                                          <Grid item>
                                            <Grid container direction="row" gap={0.4}>
                                              <Grid item>
                                                <Typography fontSize={14}>
                                                  {req.Num_Child_Seats_0_3}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <MdAirlineSeatReclineNormal style={{ fontSize: "16px", transform: "translate(0px, 2px)" }}></MdAirlineSeatReclineNormal>
                                              </Grid>
                                            </Grid>
                                          </Grid>) : ""
                                      }
                                      {
                                        req.Num_Child_Seats_4_7 > 0 ? (
                                          <Grid item>
                                            <Grid container direction="row" gap={0.4}>
                                              <Grid item>
                                                <Typography fontSize={14}>
                                                  {req.Num_Child_Seats_4_7}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <MdAirlineSeatReclineNormal style={{ fontSize: "22px", transform: "translate(0px, 0px)" }}></MdAirlineSeatReclineNormal>
                                              </Grid>
                                            </Grid>
                                          </Grid>) : ""
                                      }
                                      {
                                        req.Num_Child_Seats_8_12 > 0 ? (
                                          <Grid item>
                                            <Grid container direction="row" gap={0.4}>
                                              <Grid item>
                                                <Typography fontSize={14}>
                                                  {req.Num_Child_Seats_8_12}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <MdAirlineSeatReclineNormal style={{ fontSize: "28px", transform: "translate(0px, -3px)" }}></MdAirlineSeatReclineNormal>
                                              </Grid>
                                            </Grid>
                                          </Grid>) : ""
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container direction="column" gap={0.5}>
                                  <Grid item>
                                    {
                                      req.Displayed_Price === req.Submitted_Price && req.Displayed_Deposit === req.Submitted_Deposit ?
                                        <Typography>{req.Num_Cars > 1 ? req.Num_Cars + " x (€" + req.Submitted_Price.toFixed(2) + " + €" + req.Submitted_Deposit.toFixed(2) + ")" : "€" + req.Submitted_Price.toFixed(2) + " + €" + req.Submitted_Deposit.toFixed(2)}</Typography> :
                                        <div>
                                          <Typography>{"Submitted: €" + req.Submitted_Price.toFixed(2) + " + €" + req.Submitted_Deposit.toFixed(2)}</Typography>
                                          <Typography style={{ color: "red" }}>{"Displayed: €" + req.Displayed_Price.toFixed(2) + " + €" + req.Displayed_Deposit.toFixed(2)}</Typography>
                                        </div>
                                    }
                                  </Grid>
                                  <Grid item>
                                    <Typography>{
                                      moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm").utc(true).diff(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm").utc(true), "minutes") % (24 * 60) > 60 ? "€" + (parseFloat(req.Submitted_Price) / (Math.floor(moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm").utc(true).diff(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm").utc(true), "minutes") / (24 * 60)) + 1)).toFixed(2) + "/dia" : "€" + (parseFloat(req.Submitted_Price) / Math.floor(moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm").utc(true).diff(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm").utc(true), "minutes") / (24 * 60))).toFixed(2) + "/dia"
                                    }</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>{req.Insurance === 1 ? "SCDW" : "CDW"}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography fontSize={15}>
                                      {req.Num_Cars + " x Grupo " + req.Car_Group}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item >
                            <TextField
                              maxRows={4}
                              padding="0px"
                              multiline
                              value={req.Custom_Message}
                              sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                              fullWidth
                              size="small"
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                }
                              }}
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ padding: "20px 0px 0px 20px" }}>
                        <Grid container direction="row" gap={2}>
                          <Grid item>
                            <Grid container direction="column" gap={1.5}>
                              <Grid item>
                                <Button
                                  onClick={async () => { getConfirmationTemplate(req) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <FaCheck></FaCheck>
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => { getOptionsTemplate(req, props.gaps) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#ffffff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <FaRegListAlt></FaRegListAlt>
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => { getCommissionTemplate(req) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#ffffff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  <FaHandHoldingMedical></FaHandHoldingMedical>
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => { getRejectionTemplate(req) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  X
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => { getAutoTemplate(req, props.gaps) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  A
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => { bookRequest(socket, props.snackbar, req, props.bookings, props.gaps) }}
                                  style={{
                                    height: "25px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  R
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="column" gap={1.5}>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "new", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("new") ? "#57fad4" : "#fff",
                                    color: req.Status.includes("new") ? "#fff" : "#ddd",
                                    border: req.Status.includes("new") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Novo
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "confirmed", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("confirmed") ? "#6de639" : "#fff",
                                    color: req.Status.includes("confirmed") ? "#fff" : "#ddd",
                                    border: req.Status.includes("confirmed") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Confirmado
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "details", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("details") ? "#ffdd33" : "#fff",
                                    color: req.Status.includes("details") ? "#fff" : "#ddd",
                                    border: req.Status.includes("details") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  + Detalhes
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "ongoing", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("ongoing") ? "#fc8c03" : "#fff",
                                    color: req.Status.includes("ongoing") ? "#fff" : "#ddd",
                                    border: req.Status.includes("ongoing") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Em curso
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="column" gap={1.5}>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "commissioned", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("commissioned") ? "#33aaff" : "#fff",
                                    color: req.Status.includes("commissioned") ? "#fff" : "#ddd",
                                    border: req.Status.includes("commissioned") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Dado
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "problem", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("problem") ? "#fc0303" : "#fff",
                                    color: req.Status.includes("problem") ? "#fff" : "#ddd",
                                    border: req.Status.includes("problem") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Problema
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    toggleTag(req, "cancelled", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("cancelled") ? "#a503fc" : "#fff",
                                    color: req.Status.includes("cancelled") ? "#fff" : "#ddd",
                                    border: req.Status.includes("cancelled") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancelado
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() => {
                                    // if (!req.Status.includes("archived")) {
                                    //   let _req = setTag(req, "ongoing", false, socket)
                                    //   toggleTag(_req, "archived", socket)
                                    // }
                                    // else
                                    toggleTag(req, "archived", socket)
                                  }}
                                  style={{
                                    minWidth: "110px",
                                    height: "25px",
                                    backgroundColor: req.Status.includes("archived") ? "#616161" : "#fff",
                                    color: req.Status.includes("archived") ? "#fff" : "#ddd",
                                    border: req.Status.includes("archived") ? "none" : "1px solid #ddd",
                                    fontSize: "16px",
                                    textTransform: "none",
                                  }}
                                >
                                  Arquivado
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item marginLeft="20px">
                            <Grid container direction="column">
                              <Grid item>
                                <Typography fontSize="12px">Recebido {moment(parseInt(req.Added_Date) * 1000).format("DD/MM/YYYY \\à\\s HH:mm")}</Typography>
                              </Grid>
                              <Grid item>
                                {req.Modified_Date ? <Typography fontSize="12px">Modificado {moment(parseInt(req.Modified_Date) * 1000).format("DD/MM/YYYY \\à\\s HH:mm")}</Typography> : ""}
                              </Grid>
                              <Grid item marginTop="10px">
                                {
                                  isAvailable(req.Car_Group, moment.range(moment(req.Start_Date + "T" + req.Start_Time, "DD/MM/YYYYTHH:mm"), moment(req.End_Date + "T" + req.End_Time, "DD/MM/YYYYTHH:mm")), props.gaps) ?
                                    <Typography fontSize="14px" color="#60d42f">Disponível</Typography> :
                                    <Typography fontSize="14px" color="#d42f2f">Indisponível</Typography>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid></Grid>
                  }
                />
              </Grid>
            </div>
          </Grid>
          <Grid item style={{
            transform: "translate(0px, 20px)"
          }}>
            < Typography style={{ fontSize: "14px" }}> {all_requests.length} pedidos ({new_requests.length} novos, {confirmed_requests.length} confirmados, {details_requests.length} +detalhes,  {ongoing_requests.length} em curso, {commissioned_requests.length} dados, {problem_requests.length} problemas, {cancelled_requests.length} cancelados, {archived_requests.length} arquivados) - {quoteStats}</Typography>
          </Grid>
        </Grid>
      </div >
    </div >
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
    booking_requests: state.requests.booking_requests,
    displayed_booking_requests: state.requests.displayed_booking_requests,
    filters: state.requests.filters,
    gaps: state.bookings.gaps
  };
}

export default connect(mapStateToProps)(BookingRequests);
