import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar";
import style from "./Bookings.module.css";
import { Navigate } from "react-router-dom";
import List from "../../Components/List";
import { isAuthenticated } from "../../States/Actions/Auth";
import AddBooking from "../../Components/AddBookingSection/AddBooking";
import AddBookingSmall from "../../Components/AddBookingSection/AddBookingSmall";
import EditBookingPopup from "../../Components/EditBookingPopup";
import {
  closeEditBookingPopup,
  disableEditBookingsMode,
  updateAddBookingSection,
} from "../../States/Actions/Bookings";
import DeleteBookingPopup from "../../Components/DeleteBookingPopup";
import AddBookingMobile from "../../Components/AddBookingSection/AddBookingMobile";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const useBeforeRender = (callback, deps) => {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps);
};

const Bookings = (props) => {

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    box_height: window.innerHeight - 80 - 150,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        box_height: window.innerHeight - 80 - 150,
      });
    }, 1);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  useEffect(() => {
    disableEditBookingsMode();
    closeEditBookingPopup();
    updateAddBookingSection();
  }, []);

  if (!isAuthenticated()) return <Navigate to="/" />;

  let height = "";
  if (props.add_booking_expanded) {
    if (window.innerWidth >= 800) height = window.innerHeight - 80 - 480;
    else height = window.innerHeight - 80 - 745;
  } else height = window.innerHeight - 80 - 110;

  return (
    <React.Fragment>
      <Navbar />
      <div className={style.box} id="bookings-box">
        {window.innerWidth >= 800 ? (
          <AddBooking snackbar={props.snackbar}></AddBooking>
        ) : window.innerWidth >= 450 ? (
          <AddBookingSmall snackbar={props.snackbar}></AddBookingSmall>
        ) : (
          <AddBookingMobile snackbar={props.snackbar}></AddBookingMobile>
        )}
        <br></br>
        {height >= 100 && window.innerWidth >= 450 ? (
          <List height={height + "px"} snackbar={props.snackbar} />
        ) : (
          ""
        )}
      </div>
      <EditBookingPopup snackbar={props.snackbar}></EditBookingPopup>
      <DeleteBookingPopup snackbar={props.snackbar}></DeleteBookingPopup>
    </React.Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    add_booking_expanded: state.bookings.add_booking_expanded,
  };
}

export default connect(mapStateToProps)(Bookings);
