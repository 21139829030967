import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Timeline, { CustomMarker } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { changeInterval, openEventPopup } from "../../States/Actions/Board";
import "./GapsBoard.css";
import { ReactComponent as RenaultLogo } from "../../Resources/Images/Cars/renault.svg";
import { ReactComponent as SeatLogo } from "../../Resources/Images/Cars/seat.svg";
import { ReactComponent as SmartLogo } from "../../Resources/Images/Cars/smart.svg";
import { ReactComponent as NissanLogo } from "../../Resources/Images/Cars/nissan.svg";
import { ReactComponent as SkodaLogo } from "../../Resources/Images/Cars/skoda.svg";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { TbManualGearbox } from "react-icons/tb";
import { GrManual } from "react-icons/gr";


const itemRenderer = ({
  item,
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  return (
    <div
      {...getItemProps({
        style: {
          background: item.gap ? "#88888800" : item.bgColor,
          color: item.gap ? "#ffffff00" : "#000000ff",
          border:
            item.gap && itemContext.selected
              ? "1px dotted #000000ff"
              : item.gap
                ? "00000000"
                : itemContext.selected
                  ? "1px solid #000000ff"
                  : "1px solid #bbbbbb66",
          borderStyle: "solid",
          borderWidth: itemContext.selected ? 2 : 1,
          borderRadius: 4,
          fontFamily: "sf pro text regular",
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        style={{
          height: itemContext.dimensions.height,
          overflow: "hidden",
          paddingLeft: 3,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

const groupRenderer = (group, cars) => {
  const car = cars.find((c) => c.Plate === group.group.id);

  var mw = 100;

  if (window.innerWidth < 500) mw = 80;

  mw = mw + "px";

  return (
    <div className="sidebar-group" style={{ maxWidth: "100px" }}>
      {window.innerWidth < 500 ? (
        <Box
          style={{
            maxHeight: "30px",
            transform: "translate(2px, 6px)",
          }}
          display="flex"
        >
          <div
            style={{
              width: "6px",
              background: "#2c55ce",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              border: "1px solid black",
              borderRight: "0px",
              marginLeft: "0 auto",
              padding: "0px",
              float: "left",
            }}
          ></div>
          <div
            style={{
              width: "auto",
              height: "100%",
              border: "1px solid black",
              borderLeft: "0px",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
              background: "white",
              padding: "0px 4px 0px 4px",
              float: "left",
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
                fontWeight: "bold",
                color: "#000000",
                fontFamily: "monospace",
              }}
              sx={{
                fontSize: {
                  lg: 10,
                  md: 10,
                  sm: 10,
                  xs: 8,
                },
              }}
            >
              {group.id}
            </Typography>
          </div>
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          columnGap={1}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ transform: "translate(-2px, -5px)" }}
        >
          <Grid item xs={3}>
            <Grid container direction="row">
              <Grid item>
                <Grid container direction="column">
                  <Grid item style={{ transform: "translate(-2px, 6px)" }}>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      {car.Group}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {car.Transmission === "Manual" ? (
                      <TbManualGearbox
                        style={{ transform: "translate(-2px, -4px)" }}
                        size={14}
                      ></TbManualGearbox>
                    ) : (
                      <GrManual
                        style={{ transform: "translate(-2px, -4px)" }}
                        size={12}
                      ></GrManual>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ position: "absolute" }}>
                {car.Brand === "Renault" ? (
                  <RenaultLogo
                    style={{
                      maxWidth: "16px",
                      maxHeight: "16px",
                      textAlign: "center",
                      transform: "translate(16px, 8px)",
                    }}
                  />
                ) : car.Brand === "Seat" ? (
                  <SeatLogo
                    style={{
                      maxWidth: "14px",
                      maxHeight: "14px",
                      textAlign: "center",
                      transform: "translate(18px, 7px)",
                    }}
                  />
                ) : car.Brand === "Smart" ? (
                  <SmartLogo
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      textAlign: "center",
                      transform: "translate(16px, 6px)",
                    }}
                  />
                ) : car.Brand === "Nissan" ? (
                  <NissanLogo
                    style={{
                      maxWidth: "16px",
                      maxHeight: "16px",
                      textAlign: "center",
                      transform: "translate(16px, 6px)",
                    }}
                  />
                ) : car.Brand === "Skoda" ? (
                  <SkodaLogo
                    style={{
                      maxWidth: "14px",
                      maxHeight: "14px",
                      textAlign: "center",
                      transform: "translate(18px, 6px)",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ transform: "translate(6px, -5px)" }}>
            <Box
              style={{
                maxHeight: "30px",
              }}
              display="flex"
            >
              <div
                style={{
                  width: "6px",
                  background: "#2c55ce",
                  borderTopLeftRadius: "3px",
                  borderBottomLeftRadius: "3px",
                  border: "1px solid black",
                  borderRight: "0px",
                  marginLeft: "0 auto",
                  padding: "0px",
                  float: "left",
                }}
              ></div>
              <div
                style={{
                  width: "auto",
                  height: "100%",
                  border: "1px solid black",
                  borderLeft: "0px",
                  borderTopRightRadius: "3px",
                  borderBottomRightRadius: "3px",
                  background: "white",
                  padding: "0px 4px 0px 4px",
                  float: "left",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                    fontWeight: "bold",
                    color: "#000000",
                    fontFamily: "monospace",
                  }}
                  sx={{
                    fontSize: {
                      lg: 10,
                      md: 10,
                      sm: 10,
                      xs: 8,
                    },
                  }}
                >
                  {car.Plate}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const GapsCalendar = (props) => {
  const groups = [];
  const items = [];

  const car_info = {
    "AH-69-IR": { color: "#8cebf8", place: 1 },
    "AH-34-BA": { color: "#a9f58c", place: 2 },
    "BI-27-OF": { color: "#f2f592", place: 3 },
    "BI-31-OF": { color: "#a3f8dc", place: 4 },
    "BI-49-OF": { color: "#f1a9ca", place: 5 },
    "BI-76-OF": { color: "#cdffba", place: 6 },
    "AH-74-IC": { color: "#f2f592", place: 7 },
    "AH-93-IC": { color: "#a3f8dc", place: 8 },
    "AO-19-TM": { color: "#f1a9ca", place: 9 },
    "AO-31-TM": { color: "#f2f592", place: 10 },
    "AO-34-TM": { color: "#d2baf8", place: 11 },
    "AO-39-TM": { color: "#cdffba", place: 12 },
    "AO-41-TM": { color: "#97d3f3", place: 13 },
    "AO-48-UG": { color: "#faa8de", place: 14 },
    "AO-98-TL": { color: "#f3c18a", place: 15 },
    "AO-99-TL": { color: "#a2f7db", place: 16 },
    "AQ-05-LF": { color: "#f2f592", place: 17 },
    "AQ-11-LF": { color: "#94fca8", place: 18 },
    "AQ-71-LF": { color: "#f1a9ca", place: 19 },
    "AQ-94-JQ": { color: "#b3abf1", place: 20 },
    "AQ-97-JR": { color: "#dcef7d", place: 21 },
    "AQ-99-JR": { color: "#a2f7db", place: 22 },
    "AZ-46-OA": { color: "#8cebf8", place: 23 },
    "AZ-79-PB": { color: "#f2f592", place: 24 },
    "AZ-67-PC": { color: "#94fca8", place: 25 },
    "AZ-47-OA": { color: "#d2baf8", place: 26 },
    "AZ-63-TI": { color: "#f3c18a", place: 27 },
    "AZ-65-TI": { color: "#f2f592", place: 28 },
    "AZ-11-ZR": { color: "#a3f8dc", place: 29 },
    "BJ-49-OF": { color: "#f1a9ca", place: 30 },
    "BJ-33-OZ": { color: "#b3abf1", place: 31 },
    "AR-31-VC": { color: "#cdffba", place: 32 },
    "AX-58-FP": { color: "#97d3f3", place: 33 },
    "AX-96-PM": { color: "#faa8de", place: 34 },
  };

  let cars = props.cars;

  for (var i = cars.length - 1; i >= 0; i--) {
    if (!Object.keys(car_info).includes(cars[i].Plate)) cars.splice(i, 1);
  }

  cars = cars.sort((a, b) =>
    car_info[a.Plate].place < car_info[b.Plate].place ? -1 : 1
  );

  for (var i = 0; i < cars.length; i++)
    groups.push({
      id: cars[i].Plate,
      title: cars[i].Plate,
      //stackItems: true,
      height: props.h / cars.length,
    });

  for (var i = 0; i < props.bookings.length; i++) {
    if (!props.bookings[i].booking_info.Car_Plate) continue;

    if (
      !Object.keys(car_info).includes(props.bookings[i].booking_info.Car_Plate)
    )
      continue;

    try {
      items.push({
        id: props.bookings[i].booking_info.ID,
        group: props.bookings[i].booking_info.Car_Plate,
        title: props.bookings[i].booking_info.Client_Name,
        text:
          props.bookings[i].booking_info.Start_Date +
          ", " +
          props.bookings[i].booking_info.Start_Time +
          "   ➞   " +
          props.bookings[i].booking_info.End_Date +
          ", " +
          props.bookings[i].booking_info.End_Time,
        start_time: moment(
          props.bookings[i].booking_info.Start_Date +
          "T" +
          props.bookings[i].booking_info.Start_Time,
          "DD-MM-YYYYTHH:mm"
        ),
        end_time: moment(
          props.bookings[i].booking_info.End_Date +
          "T" +
          props.bookings[i].booking_info.End_Time,
          "DD-MM-YYYYTHH:mm"
        ),
        bgColor: car_info[props.bookings[i].booking_info.Car_Plate].color,
        selectedBgColor:
          car_info[props.bookings[i].booking_info.Car_Plate].color,
        color: "#bbbbbb",
        gap: false,
      }); //colors[props.bookings[i].booking_info.Car_Plate]
    } catch (e) {
      console.log(
        "ERROR: Invalid car plate (Booking " +
        props.bookings[i].booking_info.ID +
        ")"
      );
    }
  }

  for (var i = 0; i < cars.length; i++) {
    let car_bookings = props.bookings.filter((booking, index) => {
      if (booking.booking_info.Car_Plate === cars[i].Plate) return true;
      else return false;
    });

    car_bookings = car_bookings.sort((a, b) => {
      if (
        moment(
          a.booking_info.Start_Date + "T" + a.booking_info.Start_Time,
          "DD-MM-YYYYTHH:mm"
        ).isBefore(
          moment(
            b.booking_info.End_Date + "T" + b.booking_info.End_Time,
            "DD-MM-YYYYTHH:mm"
          )
        )
      )
        return -1;
      else return 1;
    });

    for (var j = 0; j < car_bookings.length - 1; j++) {
      let start_datetime = moment(
        car_bookings[j].booking_info.End_Date +
        "T" +
        car_bookings[j].booking_info.End_Time,
        "DD-MM-YYYYTHH:mm"
      );

      let end_datetime = moment(
        car_bookings[j + 1].booking_info.Start_Date +
        "T" +
        car_bookings[j + 1].booking_info.Start_Time,
        "DD-MM-YYYYTHH:mm"
      );

      if (end_datetime.diff(start_datetime, "minute") < 360) continue;

      let gap = {
        id: `gap_${car_bookings[j].booking_info.ID}_${car_bookings[j + 1].booking_info.ID
          }`,
        group: car_bookings[j].booking_info.Car_Plate,
        text:
          car_bookings[j].booking_info.End_Date +
          ", " +
          car_bookings[j].booking_info.End_Time +
          "   ➞   " +
          car_bookings[j + 1].booking_info.Start_Date +
          ", " +
          car_bookings[j + 1].booking_info.Start_Time,
        start_time: start_datetime,
        end_time: end_datetime,
        gap: true,
      };
      items.push(gap);
    }
  }

  return (
    <Timeline
      style={{
        color: "#000000",
        backgroundColor: "#ffffff",
        border: "1px",
        borderRadius: "15px",
        boxShadow: "0px 0px 20px 1px #dddddd",
        transform: "translate(0, 6px)",
      }}
      groups={groups}
      items={items}
      canMove={false}
      canResize
      itemHeightRatio={0.8}
      //itemsSorted
      //stackItems
      defaultTimeStart={moment().startOf("day").subtract(1, "day").toDate()}
      defaultTimeEnd={moment().startOf("day").add(30, "day").toDate()}
      itemRenderer={itemRenderer}
      groupRenderer={(group) => groupRenderer(group, props.cars)}
      onItemClick={(id, event) => {
        if (!id.toString().includes("gap")) openEventPopup(id);
      }}
      onItemSelect={(id) => {
        let booking = items.filter((item) => {
          if (item.id === id) return true;
          else return false;
        })[0];

        changeInterval(booking.text);
      }}
    ></Timeline>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
  };
}

export default connect(mapStateToProps)(GapsCalendar);
