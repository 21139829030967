import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

    html {
        height  : 100%;
        overflow: hidden;
        position: fixed;
        height: 100%;
    }

    body {
        background-color: #ffffff;
        margin: 0px;
        padding: 0px;
    }

    @media (prefers-color-scheme: dark) {
        body {
            background-color: #fafbfb;
        }
    }
`;
