const initialState = {
  logged_in: false,
  password: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_IN":
      return {
        logged_in: true,
        password: action.payload,
      };

    case "LOGGED_OUT":
      return { logged_in: false, password: "" };

    default:
      return state;
  }
};

export default authReducer;
