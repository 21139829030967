import React, { useContext } from "react";
import { connect } from "react-redux";
import { Typography, Grid, Autocomplete, Button } from "@mui/material";
import { BookingTextField } from "../BookingTextField.js";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { cloneDeep } from "lodash";
import Moment from "moment";
import { store } from "../../index";
import { extendMoment } from "moment-range";
import { Box } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SocketContext from "../../Utils/SocketContext.js";
import { ReactComponent as RenaultLogo } from "../../Resources/Images/Cars/renault.svg";
import { ReactComponent as SeatLogo } from "../../Resources/Images/Cars/seat.svg";
import { ReactComponent as SmartLogo } from "../../Resources/Images/Cars/smart.svg";
import { ReactComponent as NissanLogo } from "../../Resources/Images/Cars/nissan.svg";
import { ReactComponent as SkodaLogo } from "../../Resources/Images/Cars/skoda.svg";
import { updateAddBookingSection } from "../../States/Actions/Bookings.js";
import style from "./AddBooking.module.css";

const moment = extendMoment(Moment);

const handle_clear = () => {
  let info = {
    client_name: "",
    client_email: "",
    client_phone: "",
    client_address: "",
    start_date: null,
    start_local: "",
    end_date: null,
    end_local: "",
    num_days: "",
    car_brand: "",
    car_model: "",
    car_plate: "",
    car_group: "",
    price: "",
    deposit: "",
    insurance: false,
    observations: "",
    added_date: "",
    modified_date: "",
    id: "",
  };
  updateAddBookingSection(info);
};

const handle_add_booking_info_changed = (field, data) => {
  let info = cloneDeep(store.getState().bookings.add_booking_section);
  info[field] = data;
  updateAddBookingSection(info);
};

export const AddBookingMobile = (props) => {
  return (
    <React.Fragment>
      <Typography
        variant={"title"}
        className={style.title}
        style={{
          paddingLeft: "10px",
          transform: "translate(-10px, -0px)",
        }}
      >
        Reservas
      </Typography>
      <Grid
        container
        direction="column"
        justify="space-between"
        style={{
          flexWrap: "wrap",
          wordWrap: "break-word",
        }}
        wrap="nowrap"
        rowGap={5}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <br></br>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BookingTextField
              id="add-booking-start-date"
              label="Data Inicial"
              type="datetime-local"
              value={
                props.add_booking_section.start_date === null
                  ? "" //get_current_date()
                  : props.add_booking_section.start_date
              }
              onChange={(event) =>
                handle_add_booking_info_changed(
                  "start_date",
                  event.target.value
                )
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "170px",
                input: {
                  fontSize: {
                    lg: 14,
                    md: 14,
                    sm: 14,
                    xs: 14,
                  },
                  textAlign: "center",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BookingTextField
              id="add-booking-end-date"
              label="Data Final"
              type="datetime-local"
              value={
                props.add_booking_section.end_date === null
                  ? "" //get_current_date()
                  : props.add_booking_section.end_date
              }
              onChange={(event) =>
                handle_add_booking_info_changed("end_date", event.target.value)
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "170px",
                input: {
                  fontSize: {
                    lg: 14,
                    md: 14,
                    sm: 14,
                    xs: 14,
                  },
                  textAlign: "center",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Autocomplete
            id="add-booking-car-plate"
            size="small"
            freeSolo={false}
            options={props.car_plate_options}
            getOptionLabel={(option) => option.plate}
            onInputChange={(event, value) =>
              handle_add_booking_info_changed("car_plate", value)
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Grid
                  container
                  direction="column"
                  style={{ borderBottom: "1px solid #eeeeee" }}
                >
                  {option.in_gap !== "" ? (
                    <Grid item>
                      <Typography
                        style={{
                          fontFamily: "Arial",
                          fontSize: "12px",
                          color:
                            option["in_difference"] < 0
                              ? "#ff0000"
                              : option["in_difference"] <= 60
                              ? "#ff9900"
                              : "#000000",
                          textAlign: "center",
                        }}
                      >
                        {option.in_gap}
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {option.out_gap !== "" ? (
                    <Grid item>
                      <Typography
                        style={{
                          fontFamily: "Arial",
                          fontSize: "12px",
                          fontStyle: "normal",
                          color:
                            option["out_difference"] < 0
                              ? "#ff0000"
                              : option["out_difference"] <= 60
                              ? "#ff9900"
                              : "#000000",
                          textAlign: "center",
                        }}
                      >
                        {option.out_gap}
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid
                    item
                    marginTop={
                      option.in_gap !== "" || option.out_gap !== ""
                        ? "15px"
                        : ""
                    }
                    marginBottom="5px"
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            {option.brand === "Renault" ? (
                              <RenaultLogo
                                style={{
                                  maxWidth: "20px",
                                  maxHeight: "20px",
                                  textAlign: "center",
                                }}
                              />
                            ) : option.brand === "Seat" ? (
                              <SeatLogo
                                style={{
                                  maxWidth: "18px",
                                  maxHeight: "18px",
                                  textAlign: "center",
                                }}
                              />
                            ) : option.brand === "Smart" ? (
                              <SmartLogo
                                style={{
                                  maxWidth: "20px",
                                  maxHeight: "20px",
                                  textAlign: "center",
                                }}
                              />
                            ) : option.brand === "Nissan" ? (
                              <NissanLogo
                                style={{
                                  maxWidth: "20px",
                                  maxHeight: "20px",
                                  textAlign: "center",
                                }}
                              />
                            ) : option.brand === "Skoda" ? (
                              <SkodaLogo
                                style={{
                                  maxWidth: "20px",
                                  maxHeight: "20px",
                                  textAlign: "center",
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{
                                fontFamily: "Arial",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#000000",
                              }}
                            >
                              {option.model}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={8}>
                        <Box
                          style={{
                            maxHeight: "30px",
                          }}
                          display="flex"
                          justifyContent="center"
                        >
                          <div
                            style={{
                              width: "6px",
                              background: "#2c55ce",
                              borderTopLeftRadius: "3px",
                              borderBottomLeftRadius: "3px",
                              border: "1px solid black",
                              borderRight: "0px",
                              marginLeft: "0 auto",
                              padding: "0px",
                              float: "left",
                            }}
                          ></div>
                          <div
                            style={{
                              width: "auto",
                              height: "100%",
                              border: "1px solid black",
                              borderLeft: "0px",
                              borderTopRightRadius: "3px",
                              borderBottomRightRadius: "3px",
                              background: "white",
                              padding: "0px 4px 0px 4px",
                              float: "left",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                flexWrap: "wrap",
                                wordWrap: "break-word",
                                fontWeight: "bold",
                                color: "#000000",
                                fontFamily: "monospace",
                              }}
                              sx={{
                                fontSize: {
                                  lg: 14,
                                  md: 14,
                                  sm: 14,
                                  xs: 14,
                                },
                              }}
                            >
                              {option.plate}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <BookingTextField
                {...params}
                label="Matrícula"
                InputLabelProps={{ shrink: true }}
                //inputProps={{ style: { textAlign: "center" } }}
                style={{ width: "170px" }}
                sx={{
                  input: {
                    fontSize: {
                      lg: 14,
                      md: 14,
                      sm: 14,
                      xs: 14,
                    },
                  },
                }}
                size="small"
              />
            )}
          />
        </Grid>
        {props.add_booking_tbd_dates_num > 0 ||
        props.add_booking_tbd_cars_num > 0 ? (
          <Grid item style={{ transform: "translate(0px, -15px)" }}>
            <Box
              style={{
                width: "100%",
                maxWidth: "200px",
                paddingTop: "2px",
                paddingBottom: "2px",
                backgroundColor: "#ffdd00",
                border: "1px solid #ffdd00",
                borderRadius: "5px",
              }}
              alignItems="center"
              display="flex"
            >
              <WarningAmberIcon
                style={{
                  marginLeft: "10px",
                  marginRight: "6px",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Arial",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {props.add_booking_tbd_dates_num > 0
                  ? props.add_booking_tbd_dates_num + " reservas sem data"
                  : ""}
                {props.add_booking_tbd_dates_num > 0 &&
                props.add_booking_tbd_cars_num > 0 ? (
                  <br></br>
                ) : (
                  ""
                )}
                {props.add_booking_tbd_cars_num > 0
                  ? props.add_booking_tbd_cars_num + " reservas sem carro"
                  : ""}
              </Typography>
            </Box>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Button
            onClick={handle_clear}
            style={{
              height: "25px",
              textAlign: "center",
              backgroundColor: "#ffffff",
              color: "#2e3192",
              fontFamily: "Arial",
              fontSize: "14px",
              fontWeight: "bold",
              border: "1px solid #2e3192",
              borderRadius: "5px",
              boxShadow: "0px 0px 3px 1px #eeeeee",
              textTransform: "none",
            }}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    add_booking_section: state.bookings.add_booking_section,
    cars: state.bookings.cars,
    car_brand_options: state.bookings.add_booking_car_brand_options,
    car_model_options: state.bookings.add_booking_car_model_options,
    car_plate_options: state.bookings.add_booking_car_plate_options,
    add_booking_tbd_cars_num: state.bookings.add_booking_tbd_cars_num,
    add_booking_tbd_dates_num: state.bookings.add_booking_tbd_dates_num,
    all_bookings: state.bookings.all_bookings,
  };
}

export default connect(mapStateToProps)(AddBookingMobile);
