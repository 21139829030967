import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import reducers from "./States/Reducers";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { Collapse } from "@mui/material";
import "./index.css";

// export const store = createStore(reducers, compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(thunk)));
export const store = createStore(reducers, compose(applyMiddleware(thunk)));

export const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      onBeforeLift={() => {
        //startPolling();
      }}
      persistor={persistor}
    >
      <React.StrictMode>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Collapse}
          sx={{
            // "& .MuiCollapse-wrapperInner": {
            //   maxWidth: "200px",
            //   marginRight: "50px",
            // },
            "& .SnackbarItem-variantDefault": {
              color: "grey",
              backgroundColor: "#ffffff",
            },
            "& .SnackbarItem-variantSuccess": {
              color: "grey",
              backgroundColor: "#2e3192",
            },
            "& .SnackbarItem-variantError": {
              color: "grey",
              backgroundColor: "#ed1c24",
            },
            "& .SnackbarItem-variantWarning": {
              color: "grey",
              backgroundColor: "#fadd5f",
            },
            "& .SnackbarItem-variantInfo": {
              color: "grey",
              backgroundColor: "#ffffff",
            },
          }}
        >
          <App />
        </SnackbarProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
