import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AddBookingSearchField = styled(TextField)({
  input: {
    color: "#2e3192",
    fontFamily: "Arial",
    fontSize: {
      lg: 15,
      md: 12,
      sm: 11,
      xs: 10,
    },
    height: "10px",
    padding: "15px 5px 15px 5px",
  },
  label: {
    color: "#2e3192",
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    border: "0px",
  },
  "& .MuiInput-underline:after": {
    border: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "0px",
    },
    "&:hover fieldset": {
      border: "0px",
    },
    "&.Mui-focused fieldset": {
      border: "0px",
    },
  },
});
