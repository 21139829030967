import React, { useContext } from "react";
import style from "./Login.module.css";
import { login, isAuthenticated, getPassword } from "../../States/Actions/Auth";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SocketContext from "../../Utils/SocketContext";

function Login(props) {
  const socket = useContext(SocketContext);
  let navigate = useNavigate();

  const tryLogin = () => {
    let input = document.getElementById("login_password");
    let password = input.value;
    input.value = "";
    login(password, (success) => {
      if (success) {
        navigate("/inicio");
        socket.emit("request_cars", getPassword());
        socket.emit("request_bookings", getPassword());
      } else {
        props.snackbar("Login falhou", { variant: "error" });
        navigate("/");
      }
    });
  };

  if (isAuthenticated()) return <Navigate to="/inicio" />;

  return (
    <div>
      <div className={style.logodiv}>
        <img
          alt="logo"
          src="./logos/logo_transparent.png"
          className={style.logo}
        ></img>
      </div>
      <div className={style.box}>
        <p className={style.label}>Login</p>
        <input
          className={style.password}
          type="password"
          id="login_password"
          name="password"
          placeholder="password"
        ></input>
        <Button
          className={style.button}
          type="submit"
          value="Entrar"
          onClick={tryLogin}
          style={{
            top: "45%",
            color: "#2e3192",
            border: "1px solid #2e3192",
            fontFamily: "sf pro text regular",
            fontSize: "12px",
            fontWeight: "bold",
            textTransform: "none",
            height: "25px",
          }}
        >
          Entrar
        </Button>
      </div>
    </div>
  );
}

export default Login;
