import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BookingTextField = styled(TextField)({
  input: {
    color: "#2e3192",
    fontFamily: "Arial",
  },
  label: {
    color: "#2e3192",
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "bold",
  },
  "& label.Mui-focused": {
    borderColor: "#eeeeee",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#eeeeee",
    boxShadow: "0px 0px 3px 1px #fefefe",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#eeeeee",
      boxShadow: "0px 0px 3px 1px #fefefe",
    },
    "&:hover fieldset": {
      borderColor: "#eeeeee",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2e3192",
      border: "1px solid #2e3192",
      boxShadow: "0px 0px 3px 1px #fefefe",
    },
  },
});
