import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar";
import style from "./Board.module.css";
import { isAuthenticated } from "../../States/Actions/Auth";
import { Navigate } from "react-router-dom";
import GapsBoard from "./GapsBoard.js";
import moment from "moment";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { changeCalendarMode } from "../../States/Actions/Board";
import EventPopup from "./EventPopup";
import InOutBoard from "./InOutBoard";
import cloneDeep from "lodash.clonedeep";
import { updateBookings } from "../../States/Actions/Bookings";

// function sortGaps(props) {
//   let sortedBookings = cloneDeep(props.all_bookings);

//   sortedBookings = sortedBookings.filter((booking) => {
//     return (
//       moment(
//         booking.booking_info.Start_Date + " " + booking.booking_info.Start_Time,
//         "DD-MM-YYYY HH:mm"
//       ).diff(moment(), "minute") > 60
//     );
//   });

//   // Sort cars - WIP
//   let gaps = getGaps(props.all_bookings, sortedBookings, props.cars);
// }

// const swapCars = (starting_booking_a, starting_booking_b, bookings) => {
//   // Check if we have valid parameters
//   if (
//     starting_booking_a === null ||
//     starting_booking_a === undefined ||
//     starting_booking_b === null ||
//     starting_booking_b === undefined
//   )
//     return;

//   // Get the plates of the cars to swap
//   let car_a_plate = starting_booking_a.booking_info.Car_Plate;
//   let car_b_plate = starting_booking_b.booking_info.Car_Plate;

//   // Get the bookings to swap of each car
//   let car_a_bookings = bookings.filter((booking) => {
//     return (
//       booking.booking_info.Car_Plate === car_a_plate &&
//       moment(
//         booking.booking_info.Start_Date + " " + booking.booking_info.Start_Time,
//         "DD-MM-YYYY HH:mm"
//       ).isSameOrAfter(
//         moment(
//           starting_booking_a.booking_info.Start_Date +
//             " " +
//             starting_booking_a.booking_info.Start_Time,
//           "DD-MM-YYYY HH:mm"
//         )
//       )
//     );
//   });

//   let car_b_bookings = bookings.filter((booking) => {
//     return (
//       booking.booking_info.Car_Plate === car_b_plate &&
//       moment(
//         booking.booking_info.Start_Date + " " + booking.booking_info.Start_Time,
//         "DD-MM-YYYY HH:mm"
//       ).isSameOrAfter(
//         moment(
//           starting_booking_b.booking_info.Start_Date +
//             " " +
//             starting_booking_b.booking_info.Start_Time,
//           "DD-MM-YYYY HH:mm"
//         )
//       )
//     );
//   });

//   if (car_a_bookings.length <= 0 && car_b_bookings.length <= 0) return;
//   else if (car_a_bookings.length <= 0) {
//     car_a_bookings = cloneDeep(car_b_bookings);
//     car_b_bookings = [];
//     return;
//   } else if (car_b_bookings.length <= 0) {
//     car_b_bookings = cloneDeep(car_a_bookings);
//     car_a_bookings = [];
//     return;
//   }

//   for (var i = 0; i < car_a_bookings.length; i++) {
//     car_a_bookings[i].booking_info.Car_Plate = car_b_plate;
//   }

//   for (var i = 0; i < car_b_bookings.length; i++) {
//     car_b_bookings[i].booking_info.Car_Plate = car_a_plate;
//   }

//   return [car_a_bookings, car_b_bookings];
// };

// const getGaps = (all_bookings, bookings, cars) => {
//   let groups = { A: [], B: [], C: [], "C+": [], M: [], D: [], E: [] };
//   let infinity = moment(1e15);

//   // Check each group's cars
//   for (var group in groups) {
//     for (var car of cars) {
//       if (car.Group.toUpperCase() === group) {
//         // Get car bookings and gaps
//         let car_bookings = bookings.filter((booking, index) => {
//           if (booking.booking_info.Car_Plate === car.Plate) return true;
//           else return false;
//         });

//         // Sort bookings by date
//         car_bookings = car_bookings.sort((a, b) => {
//           if (
//             moment(
//               a.booking_info.Start_Date + "T" + a.booking_info.Start_Time,
//               "DD-MM-YYYYTHH:mm"
//             ).isBefore(
//               moment(
//                 b.booking_info.End_Date + "T" + b.booking_info.End_Time,
//                 "DD-MM-YYYYTHH:mm"
//               )
//             )
//           )
//             return -1;
//           else return 1;
//         });

//         for (var j = 0; j < car_bookings.length - 1; j++) {
//           let start_datetime = moment(
//             car_bookings[j].booking_info.End_Date +
//               "T" +
//               car_bookings[j].booking_info.End_Time,
//             "DD-MM-YYYYTHH:mm"
//           );

//           let end_datetime = moment(
//             car_bookings[j + 1].booking_info.Start_Date +
//               "T" +
//               car_bookings[j + 1].booking_info.Start_Time,
//             "DD-MM-YYYYTHH:mm"
//           );

//           if (
//             end_datetime.diff(start_datetime, "minute") < 1 ||
//             end_datetime.isSameOrBefore(moment(), "minute")
//           )
//             continue;

//           groups[car.Group.toUpperCase()].push({
//             start_time: start_datetime,
//             end_time: end_datetime,
//             range: moment.range(start_datetime, end_datetime),
//             prev_booking: car_bookings[j],
//             next_booking: car_bookings[j + 1],
//           });
//         }

//         let start_datetime = moment(
//           car_bookings[car_bookings.length - 1].booking_info.End_Date +
//             "T" +
//             car_bookings[car_bookings.length - 1].booking_info.End_Time,
//           "DD-MM-YYYYTHH:mm"
//         );

//         groups[car.Group.toUpperCase()].push({
//           start_time: start_datetime,
//           end_time: infinity,
//           range: moment.range(start_datetime, infinity),
//           prev_booking: car_bookings[car_bookings.length - 1],
//           next_booking: undefined,
//         });
//       }
//     }
//   }

//   for (var group in groups) {
//     for (var i = 0; i < groups[group].length; i++) {
//       for (var j = 0; j < groups[group].length; j++) {
//         if (i === j) continue;

//         let gap_a = groups[group][i];
//         let gap_b = groups[group][j];

//         if (gap_a.range.overlaps(gap_b.range)) {
//           // Remover buracos sobrepostos
//           // Talvez sem isto para ordenar os carros

//           // If gap_a contains gap_b then remove gap_b
//           if (
//             gap_a.start_time.isSameOrBefore(gap_b.start_time) &&
//             gap_a.end_time.isSameOrAfter(gap_b.end_time)
//           ) {
//             groups[group].splice(j, 1);

//             if (j <= i && i > 0) i -= 1;
//             if (j >= 0) j -= 1;
//             continue;
//           }

//           // If gap_b contains gap_a then remove gap_a
//           if (
//             gap_b.start_time.isSameOrBefore(gap_a.start_time) &&
//             gap_b.end_time.isSameOrAfter(gap_a.end_time)
//           ) {
//             groups[group].splice(i, 1);
//             if (i > 0) i -= 1;
//             if (j >= 0) j -= 1;
//             continue;
//           }

//           let gap = {};

//           if (gap_a.start_time.isSameOrBefore(gap_b.start_time, "minute")) {
//             gap.start_time = gap_a.start_time;
//             gap.prev_booking = gap_a.prev_booking;
//           } else {
//             gap.start_time = gap_b.start_time;
//             gap.prev_booking = gap_b.prev_booking;
//           }

//           if (gap_a.end_time.isSameOrAfter(gap_b.end_time, "minute")) {
//             gap.end_time = gap_a.end_time;
//             gap.next_booking = gap_a.next_booking;
//           } else {
//             gap.end_time = gap_b.end_time;
//             gap.next_booking = gap_b.next_booking;
//           }

//           // Swap cars - WIP
//           if (
//             gap_a.next_booking.booking_info.Car_Plate !==
//             gap_b.next_booking.booking_info.Car_Plate
//           ) {
//             let car_a_b_bookings = swapCars(
//               gap_a.next_booking,
//               gap_b.next_booking,
//               bookings
//             );
//             car_a_b_bookings = car_a_b_bookings[0].concat(car_a_b_bookings[1]);

//             for (var k = 0; k < car_a_b_bookings.length; k++) {
//               all_bookings.find(
//                 (booking) =>
//                   booking.booking_info.ID ===
//                   car_a_b_bookings[k].booking_info.ID
//               ).booking_info.Car_Plate =
//                 car_a_b_bookings[k].booking_info.Car_Plate;
//             }
//           }

//           gap.range = moment.range(gap.start_time, gap.end_time);

//           groups[group].splice(groups[group].indexOf(gap_a), 1);
//           groups[group].splice(groups[group].indexOf(gap_b), 1);
//           groups[group].unshift(gap);
//           i = 0;
//           j = -1;
//         }
//       }
//     }

//     groups[group] = groups[group].sort((a, b) => {
//       if (a.start_time.isBefore(b.start_time)) {
//         return -1;
//       } else {
//         return 1;
//       }
//     });
//   }

//   return groups;
// };

// const cleanMargins = (groups, margin) => {
//   let infinity = moment(1e15);

//   for (var group in groups) {
//     if (groups[group].length <= 0) continue;

//     for (var i = groups[group].length - 1; i >= 0; i--) {
//       // Add margin to the beginning and end of the gap
//       // If it's a final gap, just add to the beginning
//       if (groups[group][i].end_time.isSame(infinity)) {
//         groups[group][i].start_time = groups[group][i].start_time.add(
//           margin,
//           "minutes"
//         );
//         groups[group][i].range = moment.range(
//           groups[group][i].start_time,
//           groups[group][i].end_time
//         );
//         continue;
//       }

//       // If the gap started before the present moment, change the gap start to the present moment plus 90 minutes
//       // Round it to the next tens and add the margins
//       let addedMargin = false;

//       if (groups[group][i].start_time.isBefore(moment(), "minute")) {
//         groups[group][i].start_time = moment().add(margin, "minutes");
//         groups[group][i].start_time.minute(
//           Math.ceil((groups[group][i].start_time.minute() + 1) / 10) * 10
//         );
//         if (
//           groups[group][i].end_time.diff(
//             groups[group][i].start_time,
//             "minutes"
//           ) > margin
//         ) {
//           groups[group][i].end_time = groups[group][i].end_time.subtract(
//             margin,
//             "minutes"
//           );
//           groups[group][i].range = moment.range(
//             groups[group][i].start_time,
//             groups[group][i].end_time
//           );
//           addedMargin = true;
//         } else {
//           groups[group].splice(i, 1);
//           continue;
//         }
//       }

//       if (addedMargin === false) {
//         if (
//           groups[group][i].end_time.diff(
//             groups[group][i].start_time,
//             "minutes"
//           ) <=
//           margin * 2
//         ) {
//           groups[group].splice(i, 1);
//           continue;
//         }
//         groups[group][i].start_time = groups[group][i].start_time.add(
//           margin,
//           "minutes"
//         );
//         groups[group][i].end_time = groups[group][i].end_time.subtract(
//           margin,
//           "minutes"
//         );
//         groups[group][i].range = moment.range(
//           groups[group][i].start_time,
//           groups[group][i].end_time
//         );
//       }

//       // Remove gaps smaller than 5 hours
//       if (
//         groups[group][i].end_time.diff(groups[group][i].start_time, "minutes") <
//         5 * 60
//       ) {
//         groups[group].splice(i, 1);
//         continue;
//       }

//       // Remove gaps overnight or with not enough consecutive day hours
//       if (
//         groups[group][i].start_time.hour() >= 14 &&
//         groups[group][i].end_time.diff(
//           groups[group][i].start_time,
//           "minutes"
//         ) <=
//           21 * 60
//       ) {
//         groups[group].splice(i, 1);
//         continue;
//       }
//       if (
//         groups[group][i].start_time.hour() >= 12 &&
//         groups[group][i].end_time.diff(
//           groups[group][i].start_time,
//           "minutes"
//         ) <=
//           6 * 60
//       ) {
//         groups[group].splice(i, 1);
//         continue;
//       }
//     }
//   }

//   return groups;
// };

// const isAvailable = (group, period) => {
//   let gaps = cleanMargins(getGaps([group.toUpperCase()]), 90);
//   for (var gap of gaps[group.toUpperCase()]) {
//     if (gap.range.contains(period, true)) return true;
//   }
//   return false;
// };

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Calendars = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    box_height: window.innerHeight - 80 - 150,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        box_height: window.innerHeight - 80 - 150,
      });
    }, 1);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  if (!isAuthenticated()) return <Navigate to="/" />;

  let calendar_height = window.innerHeight - 80 - 120;

  return (
    <div>
      <Navbar />
      <div className={style.box}>
        <div>
          <Grid container direction="row" columnGap={10}>
            <Grid item xs={0.1}>
              <Typography
                variant={"title"}
                className={style.title}
                style={{
                  paddingLeft: "10px",
                  transform: "translate(-10px, -5px)",
                  fontFamily: "sf pro text regular",
                }}
              >
                Quadro
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                size="small"
                value={props.calendar_mode}
                onChange={(event, data) => {
                  if (event) changeCalendarMode(data.props.value);
                }}
                style={{
                  width: "167px",
                  height: "22px",
                  fontFamily: "sf pro text regular",
                  fontSize: "14px",
                  color: "#2e3192",
                  transform: "translate(-10px, -5px)",
                }}
              >
                <MenuItem
                  value={"gaps"}
                  style={{ fontFamily: "sf pro text regular" }}
                >
                  Buracos
                </MenuItem>
                <MenuItem
                  value={"in_out"}
                  style={{ fontFamily: "sf pro text regular" }}
                >
                  Entradas e Saidas
                </MenuItem>
              </Select>
            </Grid>
            {/* <Grid item>
              <button onClick={() => sortGaps(props)}>Organizar</button>
            </Grid> */}
            <Grid item>
              <Typography
                variant={"title"}
                className={style.subtitle}
                style={{
                  padding: "4px",
                  border: "1px solid #5053b4",
                  borderRadius: "5px",
                  transform: "translate(0px, -7px)",
                  fontFamily: "arial",
                }}
              >
                {props.interval.split("➞")[0]}
                &nbsp;&nbsp; {"➞"} &nbsp;&nbsp;
                {props.interval.split("➞")[1]}
              </Typography>
            </Grid>
          </Grid>

          {props.calendar_mode === "gaps" ? (
            <GapsBoard h={window.innerHeight - 80 - 110} />
          ) : props.calendar_mode === "in_out" ? (
            <InOutBoard calendar_height={calendar_height} />
          ) : (
            ""
          )}
        </div>
      </div>
      <EventPopup></EventPopup>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    all_bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
    calendar_mode: state.board.calendar_mode,
    interval: state.board.interval,
  };
}

export default connect(mapStateToProps)(Calendars);
