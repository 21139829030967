import { store } from "../../index.js";
import * as actions from "./index.js";

export const updateBookings = (bookings) => {
  store.dispatch(actions.updateBookings(bookings));
};

export const updateGaps = () => {
  store.dispatch(actions.updateGaps());
};

export const updateCars = (cars) => {
  store.dispatch(actions.updateCars(cars));
};

export const updateAddBookingSection = (info) => {
  store.dispatch(actions.updateAddBookingSection(info));
};

export const updateEditBookingSection = (info) => {
  store.dispatch(actions.updateEditBookingSection(info));
};

export const expandAddBooking = () => {
  store.dispatch(actions.expandAddBooking());
};

export const expandBooking = (id) => {
  store.dispatch(actions.expandBooking(id));
};

export const expandAllBookings = () => {
  store.dispatch(actions.expandAllBookings());
};

export const collapseAllBookings = () => {
  store.dispatch(actions.collapseAllBookings());
};

export const sortBookings = (sort_by) => {
  store.dispatch(actions.sortBookings(sort_by));
};

export const filterBookings = (filters) => {
  store.dispatch(actions.filterBookings(filters));
};

export const openEditBookingPopup = (booking_id) => {
  store.dispatch(actions.openEditBookingPopup(booking_id));
};

export const closeEditBookingPopup = () => {
  store.dispatch(actions.closeEditBookingPopup());
};

export const openDeleteBookingPopup = (booking_id) => {
  store.dispatch(actions.openDeleteBookingPopup(booking_id));
};

export const closeDeleteBookingPopup = () => {
  store.dispatch(actions.closeDeleteBookingPopup());
};

export const toggleEditBookingsMode = () => {
  store.dispatch(actions.toggleEditBookingsMode());
};

export const disableEditBookingsMode = () => {
  store.dispatch(actions.disableEditBookingsMode());
};
