import React from "react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as styles from "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { openEventPopup } from "../../States/Actions/Board";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function eventStyleGetter(event, start, end, isSelected) {
  var style = {
    backgroundColor: "#ffffff00",
    borderRadius: "0px",
    opacity: 1,
    color: "black",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
}

const EventComponent = (event) => {
  return (
    <div style={{ background: "ffffff00" }}>
      <Grid container direction="row">
        <Grid item style={{ marginLeft: "1px" }}>
          <Box
            style={{
              maxHeight: "30px",
            }}
            display="flex"
          >
            <div
              style={{
                width: "6px",
                background: event.event.color,
                borderTopLeftRadius: "3px",
                borderBottomLeftRadius: "3px",
                border: "1px solid black",
                borderRight: "0px",
                marginLeft: "0 auto",
                padding: "0px",
                float: "left",
              }}
            ></div>
            <div
              style={{
                width: "auto",
                height: "100%",
                border: "1px solid black",
                borderLeft: "0px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
                background: "white",
                padding: "0px 4px 0px 4px",
                float: "left",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  flexWrap: "wrap",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: "#000000",
                  fontFamily: "monospace",
                }}
                sx={{
                  fontSize: {
                    lg: 10,
                    md: 10,
                    sm: 10,
                    xs: 8,
                  },
                }}
              >
                {event.title}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const EventBackgroundComponent = (event) => {
  return "";
};

function InOutCalendar(props) {
  const events = [];
  for (var i = 0; i < props.bookings.length; i++) {
    if (!props.bookings[i].booking_info.Car_Plate) continue;
    events.push({
      id: props.bookings[i].booking_info.ID,
      booking: props.bookings[i].booking_info,
      title: props.bookings[i].booking_info.Car_Plate,
      allDay: false,
      start: moment(
        props.bookings[i].booking_info.Start_Date +
          "T" +
          props.bookings[i].booking_info.Start_Time,
        "DD-MM-YYYYTHH:mm"
      ).toDate(),
      end: moment(
        props.bookings[i].booking_info.Start_Date +
          "T" +
          props.bookings[i].booking_info.Start_Time,
        "DD-MM-YYYYTHH:mm"
      )
        .add(1, "hour")
        .toDate(),
      color: "#2afa38",
    });

    events.push({
      id: props.bookings[i].booking_info.ID,
      booking: props.bookings[i].booking_info,
      title: `${props.bookings[i].booking_info.Car_Plate}`,
      allDay: false,
      start: moment(
        props.bookings[i].booking_info.End_Date +
          "T" +
          props.bookings[i].booking_info.End_Time,
        "DD-MM-YYYYTHH:mm"
      ).toDate(),
      end: moment(
        props.bookings[i].booking_info.End_Date +
          "T" +
          props.bookings[i].booking_info.End_Time,
        "DD-MM-YYYYTHH:mm"
      )
        .add(1, "hour")
        .toDate(),
      color: "#fa2a2a",
    });
  }

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      defaultView="month"
      style={{
        transform: "translate(0px, 15px)",
        height: props.calendar_height + "px",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "15px",
      }}
      eventPropGetter={eventStyleGetter}
      views={["month", "week"]}
      popup={true}
      onSelectEvent={(e) => openEventPopup(e.id)}
      dayLayoutAlgorithm="overlap"
      components={{
        //eventWrapper: EventBackgroundComponent,
        event: EventComponent,
      }}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
  };
}

export default connect(mapStateToProps)(InOutCalendar);
