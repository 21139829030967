import React, { useContext } from "react";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { BookingTextField } from "../BookingTextField.js";
import { locations } from "../../Utils/Locations";
import { store } from "../../index";
import style from "./AddBookingSmall.module.css";
import {
  expandAddBooking,
  updateAddBookingSection,
} from "../../States/Actions/Bookings.js";
import { cloneDeep } from "lodash";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getPassword } from "../../States/Actions/Auth.js";
import SocketContext from "../../Utils/SocketContext.js";
import { Box } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { titleCase } from "title-case";

const handle_expand = () => {
  expandAddBooking();
};

const handle_submit = (socket, snackbar) => {
  // Check if the dates are valid
  let start_date = document
    .getElementById("add-booking-start-date")
    .value.split("T")[0]
    .split("-")
    .reverse()
    .join("-");

  let end_date = document
    .getElementById("add-booking-end-date")
    .value.split("T")[0]
    .split("-")
    .reverse()
    .join("-");

  let start_time = document.getElementById("add-booking-start-date").value;
  if (start_time.split("T").length > 1) start_time = start_time.split("T")[1];

  let end_time = document.getElementById("add-booking-end-date").value;
  if (end_time.split("T").length > 1) end_time = end_time.split("T")[1];

  let start_datetime = moment(
    start_date + "T" + start_time,
    "DD-MM-YYYYTHH:mm"
  );

  let end_datetime = moment(end_date + "T" + end_time, "DD-MM-YYYYTHH:mm");

  if (start_datetime.isSameOrAfter(end_datetime, "minutes")) {
    snackbar("Datas inválidas", { variant: "error" });
    return;
  }

  // Check if the car plate is valid
  let cars = store.getState().bookings.cars;
  let valid_car_plates = [];
  let car_plate = document
    .getElementById("add-booking-car-plate")
    .value.toUpperCase()
    .trim();
  let car_group = "";
  let car = {};

  if (car_plate !== "") {
    // for (var i = 0; i < cars.length; i++) valid_car_plates.push(cars[i].Plate);

    car = cars.filter((c) => c.Plate === car_plate)

    if (car.length <= 0) {
      snackbar("Matrícula inválida", { variant: "error" });
      return;
    }

    car = car[0]
    car_group = car.Group

    // if (!valid_car_plates.includes(car_plate)) {
    //   snackbar("Matrícula inválida", { variant: "error" });
    //   return;
    // }
  }

  // Clean inputs
  let num_days = parseFloat(
    document
      .getElementById("add-booking-num-days")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(num_days)) num_days = "";
  else num_days = num_days.toString();

  let price = parseFloat(
    document
      .getElementById("add-booking-price")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(price)) price = "";
  else price = price.toFixed(2);

  let deposit = parseFloat(
    document
      .getElementById("add-booking-deposit")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(deposit)) deposit = "";
  else deposit = deposit.toFixed(2);

  // Add booking
  let now = Date.now()
  
  const booking = {
    client_name: titleCase(
      document.getElementById("add-booking-client-name").value.trim()
    ),
    client_email: document
      .getElementById("add-booking-client-email")
      .value.trim(),
    client_phone: document
      .getElementById("add-booking-client-phone")
      .value.trim(),
    client_address: titleCase(
      document.getElementById("add-booking-client-address").value.trim()
    ),
    start_date: start_date,
    start_time: start_time,
    start_local: titleCase(
      document.getElementById("add-booking-start-local").value.trim()
    ),
    end_date: end_date,
    end_time: end_time,
    end_local: titleCase(
      document.getElementById("add-booking-end-local").value.trim()
    ),
    num_days: num_days,
    car_brand: titleCase(
      document.getElementById("add-booking-car-brand").value.trim()
    ),
    car_model: titleCase(
      document.getElementById("add-booking-car-model").value.trim()
    ),
    car_plate: car_plate,
    car_group: car_group,
    price: price,
    deposit: deposit,
    insurance: document.getElementById("add-booking-insurance").checked,
    observations: document.getElementById("add-booking-obs").value.trim(),
    added_date: now.toString(),
    modified_date: now.toString(),
  };

  socket.emit("add_booking", { password: getPassword(), data: booking });
  handle_clear();
};

const handle_clear = () => {
  let info = {
    client_name: "",
    client_email: "",
    client_phone: "",
    client_address: "",
    start_date: null,
    start_local: "",
    end_date: null,
    end_local: "",
    num_days: "",
    car_brand: "",
    car_model: "",
    car_plate: "",
    price: "",
    deposit: "",
    insurance: false,
    observations: "",
    id: "",
  };
  updateAddBookingSection(info);
};

const handle_add_booking_info_changed = (field, data) => {
  let info = cloneDeep(store.getState().bookings.add_booking_section);
  info[field] = data;
  updateAddBookingSection(info);
};

const get_current_date = () => {
  let now = new Date();
  return `${now.getFullYear().toString().padStart(2, "0")}-${(
    parseInt(now.getMonth()) + 1
  )
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}T${now
    .getHours()
    .toString()
    .padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
};

export const AddBookingSmall = (props) => {
  const socket = useContext(SocketContext);

  return (
    <Accordion
      expanded={props.add_booking_expanded}
      disableGutters={true}
      style={{
        background: "white",
        boxShadow: "0px 0px 5px 1px #eeeeee",
        borderRadius: "8px",
      }}
      TransitionProps={{ unmountOnExit: true }}
      id="add-bookings-accordion"
    >
      <AccordionSummary style={{ transform: "translate(0px, -10px)" }}>
        <Typography
          variant={"title"}
          className={style.title}
          onClick={handle_expand}
          style={{
            padding: "10px 10px 10px 10px",
            transform: "translate(-10px, -10px)",
          }}
        >
          {props.add_booking_expanded ? "Reservas ▲" : "Reservas ▼"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{
            flexWrap: "wrap",
            wordWrap: "break-word",
          }}
          wrap="nowrap"
          sx={{
            input: { fontSize: { lg: 14, md: 14, sm: 12, xs: 8.5 } },
          }}
          rowSpacing={1}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
              wrap="nowrap"
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <BookingTextField
                      label="Nome"
                      id="add-booking-client-name"
                      value={props.add_booking_section.client_name}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "client_name",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label="Email"
                      id="add-booking-client-email"
                      value={props.add_booking_section.client_email}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "client_email",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <BookingTextField
                      label="Telefone"
                      id="add-booking-client-phone"
                      value={props.add_booking_section.client_phone}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "client_phone",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ maxWidth: "400px" }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label="Morada"
                      id="add-booking-client-address"
                      value={props.add_booking_section.client_address}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "client_address",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ maxWidth: "400px" }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "16px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
              wrap="nowrap"
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <BookingTextField
                        id="add-booking-start-date"
                        label="Data Inicial"
                        type="datetime-local"
                        value={
                          props.add_booking_section.start_date === null
                            ? "" //get_current_date()
                            : props.add_booking_section.start_date
                        }
                        onChange={(event) =>
                          handle_add_booking_info_changed(
                            "start_date",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={style.datelocalfield}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="add-booking-start-local"
                      size="small"
                      freeSolo={true}
                      options={locations}
                      value={props.add_booking_section.start_local}
                      onInputChange={(event, value) =>
                        handle_add_booking_info_changed("start_local", value)
                      }
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label="Local Inicial"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={style.datelocalfield}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <BookingTextField
                        id="add-booking-end-date"
                        label="Data Final"
                        type="datetime-local"
                        value={
                          props.add_booking_section.end_date === null
                            ? "" //get_current_date()
                            : props.add_booking_section.end_date
                        }
                        onChange={(event) =>
                          handle_add_booking_info_changed(
                            "end_date",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={style.datelocalfield}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="add-booking-end-local"
                      size="small"
                      freeSolo={true}
                      options={locations}
                      value={props.add_booking_section.end_local}
                      onInputChange={(event, value) =>
                        handle_add_booking_info_changed("end_local", value)
                      }
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label="Local Final"
                          InputLabelProps={{ shrink: true }}
                          className={style.datelocalfield}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{
              transform: "translate(0px, 16px)",
              maxWidth: "250px",
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
              wrap="nowrap"
              columnGap={2}
            >
              <Grid item>
                <BookingTextField
                  label="Núm. Dias"
                  id="add-booking-num-days"
                  value={props.add_booking_section.num_days}
                  onChange={(event) =>
                    handle_add_booking_info_changed(
                      "num_days",
                      event.target.value
                    )
                  }
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  style={{ maxWidth: "90px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "4px" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
              wrap="nowrap"
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <Autocomplete
                      id="add-booking-car-brand"
                      size="small"
                      freeSolo={true}
                      autoSelect={true}
                      options={props.car_brand_options}
                      value={props.add_booking_section.car_brand}
                      onInputChange={(event, value) =>
                        handle_add_booking_info_changed("car_brand", value)
                      }
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label="Marca"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          fullWidth
                        />
                      )}
                      style={{ width: "100%", maxWidth: "200px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="add-booking-car-model"
                      size="small"
                      freeSolo={true}
                      options={props.car_model_options}
                      value={props.add_booking_section.car_model}
                      onInputChange={(event, value) =>
                        handle_add_booking_info_changed("car_model", value)
                      }
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label="Modelo"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                      style={{ width: "100%", maxWidth: "200px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="add-booking-car-plate"
                      size="small"
                      freeSolo={true}
                      options={props.car_plate_options}
                      value={props.add_booking_section.car_plate}
                      onInputChange={(event, value) =>
                        handle_add_booking_info_changed("car_plate", value)
                      }
                      renderInput={(params) => (
                        <BookingTextField
                          {...params}
                          label="Matrícula"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                      style={{ width: "100%", maxWidth: "200px" }}
                    />
                  </Grid>
                  {props.add_booking_tbd_cars_num > 0 ||
                  props.add_booking_tbd_dates_num > 0 ||
                  props.overlapped_bookings_num > 0 ? (
                    <Grid item style={{ transform: "translate(0px, -15px)" }}>
                      <Box
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          backgroundColor: "#ffdd00",
                          border: "1px solid #ffdd00",
                          borderRadius: "5px",
                        }}
                        alignItems="center"
                        display="flex"
                      >
                        <WarningAmberIcon
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                        <Typography
                          style={{
                            fontFamily: "Arial",
                            fontSize: "12px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {props.add_booking_tbd_dates_num > 0
                            ? props.add_booking_tbd_dates_num +
                              " reservas sem data"
                            : ""}
                          {props.add_booking_tbd_dates_num > 0 &&
                          props.add_booking_tbd_cars_num > 0 ? (
                            <br></br>
                          ) : (
                            ""
                          )}
                          {props.add_booking_tbd_cars_num > 0
                            ? props.add_booking_tbd_cars_num +
                              " reservas sem carro"
                            : ""}
                          {props.add_booking_tbd_cars_num > 0 &&
                          props.overlapped_bookings_num > 0 ? (
                            <br></br>
                          ) : (
                            ""
                          )}
                          {props.overlapped_bookings_num > 0
                            ? props.overlapped_bookings_num +
                              " reservas sobrepostas"
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                  }}
                  wrap="nowrap"
                  rowGap={3}
                >
                  <Grid item>
                    <BookingTextField
                      label="Preço"
                      id="add-booking-price"
                      value={props.add_booking_section.price}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "price",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ maxWidth: "90px" }}
                    />
                  </Grid>
                  <Grid item>
                    <BookingTextField
                      label="Depósito"
                      id="add-booking-deposit"
                      value={props.add_booking_section.deposit}
                      onChange={(event) =>
                        handle_add_booking_info_changed(
                          "deposit",
                          event.target.value
                        )
                      }
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ maxWidth: "90px" }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          id="add-booking-insurance"
                          checked={
                            props.add_booking_section.insurance ||
                            props.add_booking_section.insurance === 1
                          }
                          onChange={(event) => {
                            handle_add_booking_info_changed(
                              "insurance",
                              event.target.checked
                            );
                          }}
                          sx={{
                            color: "#eeeeee",
                            transform: "translate(0px, -11px)",
                            "&.Mui-checked": {
                              color: "#2e3192",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontFamily: "Arial",
                            fontSize: "11px",
                            fontWeight: "bold",
                            color: "#2e3192",
                          }}
                        >
                          Seguro
                        </Typography>
                      }
                      labelPlacement="top"
                      style={{ transform: "translate(-2px, -9px)" }}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      transform: "translate(0px, -30px)",
                    }}
                  >
                    <Grid container direction="column" rowGap={1.5}>
                      <Grid item>
                        <BookingTextField
                          id="add-booking-obs"
                          label="Observações"
                          value={props.add_booking_section.observations}
                          onChange={(event) =>
                            handle_add_booking_info_changed(
                              "observations",
                              event.target.value
                            )
                          }
                          size="small"
                          multiline
                          rows={2}
                          style={{
                            maxWidth: "300px",
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontFamily: "Arial",
                              fontSize: "15px",
                            },
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Arial",
                              fontSize: "15px",
                              color: "#2e3192",
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" columnGap={2}>
                          <Grid item>
                            <Button
                              onClick={() => {
                                handle_submit(socket, props.snackbar);
                              }}
                              style={{
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "#ffffff",
                                color: "#2e3192",
                                fontFamily: "Arial",
                                fontSize: "11px",
                                fontWeight: "bold",
                                border: "1px solid #2e3192",
                                borderRadius: "5px",
                                boxShadow: "0px 0px 3px 1px #eeeeee",
                                textTransform: "none",
                              }}
                            >
                              Adicionar
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={handle_clear}
                              style={{
                                height: "25px",
                                textAlign: "center",
                                backgroundColor: "#ffffff",
                                color: "#2e3192",
                                fontFamily: "Arial",
                                fontSize: "11px",
                                fontWeight: "bold",
                                border: "1px solid #2e3192",
                                borderRadius: "5px",
                                boxShadow: "0px 0px 3px 1px #eeeeee",
                                textTransform: "none",
                              }}
                            >
                              Limpar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    add_booking_expanded: state.bookings.add_booking_expanded,
    add_booking_section: state.bookings.add_booking_section,
    cars: state.bookings.cars,
    car_brand_options: state.bookings.add_booking_car_brand_options,
    car_model_options: state.bookings.add_booking_car_model_options,
    car_plate_options: state.bookings.add_booking_car_plate_options,
    add_booking_tbd_cars_num: state.bookings.add_booking_tbd_cars_num,
    add_booking_tbd_dates_num: state.bookings.add_booking_tbd_dates_num,
    overlapped_bookings_num: state.bookings.overlapped_bookings_num,
    all_bookings: state.bookings.all_bookings,
  };
}

export default connect(mapStateToProps)(AddBookingSmall);
