const initialState = {
  calendar_mode: "gaps",
  event_popup_open: -1,
  interval: "",
};

const CalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_CALENDAR_MODE":
      return {
        ...state,
        calendar_mode: action.payload,
      };

    case "OPEN_EVENT_POPUP":
      return {
        ...state,
        event_popup_open: action.payload,
      };

    case "CLOSE_EVENT_POPUP":
      return {
        ...state,
        event_popup_open: -1,
      };

    case "CHANGE_INTERVAL":
      return {
        ...state,
        interval: action.payload,
      };

    default:
      return state;
  }
};

export default CalendarReducer;
