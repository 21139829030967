import { store } from "../../index.js";
import * as actions from "./index.js";

export const updateBookingRequests = (booking_requests) => {
  store.dispatch(actions.updateBookingRequests(booking_requests));
};

export const sortBookingRequests = (booking_requests) => {
  store.dispatch(actions.sortBookingRequests(booking_requests));
};

export const filterBookingRequests = (filters) => {
  store.dispatch(actions.filterBookingRequests(filters));
};