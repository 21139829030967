import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  closeEditBookingPopup,
  updateEditBookingSection,
} from "../States/Actions/Bookings";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { BookingTextField } from "./BookingTextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { locations } from "../Utils/Locations";
import { store } from "..";
import cloneDeep from "lodash.clonedeep";
import Moment from "moment";
import { extendMoment } from "moment-range";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getPassword } from "../States/Actions/Auth";
import { useContext } from "react";
import SocketContext from "../Utils/SocketContext";
import { ReactComponent as RenaultLogo } from "../Resources/Images/Cars/renault.svg";
import { ReactComponent as SeatLogo } from "../Resources/Images/Cars/seat.svg";
import { ReactComponent as SmartLogo } from "../Resources/Images/Cars/smart.svg";
import { ReactComponent as NissanLogo } from "../Resources/Images/Cars/nissan.svg";
import { ReactComponent as SkodaLogo } from "../Resources/Images/Cars/skoda.svg";
import { titleCase } from "title-case";
import { TbManualGearbox } from "react-icons/tb";
import { GrManual } from "react-icons/gr";

const moment = extendMoment(Moment);

const handle_submit = (id, socket, snackbar) => {
  // Check if the dates are valid
  let start_date = document
    .getElementById("edit-booking-start-date")
    .value.split("T")[0]
    .split("-")
    .reverse()
    .join("-");

  let end_date = document
    .getElementById("edit-booking-end-date")
    .value.split("T")[0]
    .split("-")
    .reverse()
    .join("-");

  let start_time = document.getElementById("edit-booking-start-date").value;
  if (start_time.split("T").length > 1) start_time = start_time.split("T")[1];

  let end_time = document.getElementById("edit-booking-end-date").value;
  if (end_time.split("T").length > 1) end_time = end_time.split("T")[1];

  let start_datetime = moment(
    start_date + "T" + start_time,
    "DD-MM-YYYYTHH:mm"
  );

  let end_datetime = moment(end_date + "T" + end_time, "DD-MM-YYYYTHH:mm");

  if (start_datetime.isSameOrAfter(end_datetime, "minutes")) {
    snackbar("Datas inválidas", { variant: "error" });
    return;
  }

  // Check if the car plate is valid
  let cars = store.getState().bookings.cars;
  let valid_car_plates = [];
  let car_plate = document
    .getElementById("edit-booking-car-plate")
    .value.toUpperCase()
    .trim();
  let car = [];
  let car_group = ""

  if (car_plate !== "") {
    car = cars.filter((c) => c.Plate === car_plate)

    if (car.length <= 0) {
      // snackbar("Matrícula inválida", { variant: "error" });
      // return;
    }
    else
      car_group = car[0].Group;
  }

  // Clean inputs
  let num_days = parseFloat(
    document
      .getElementById("edit-booking-num-days")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(num_days)) num_days = "";
  else num_days = num_days.toString();

  let price = parseFloat(
    document
      .getElementById("edit-booking-price")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(price)) price = "";
  else price = price.toFixed(2);

  let deposit = parseFloat(
    document
      .getElementById("edit-booking-deposit")
      .value.replaceAll(" ", "")
      .replaceAll(",", ".")
      .replaceAll(";", ".")
  );
  if (isNaN(deposit)) deposit = "";
  else deposit = deposit.toFixed(2);

  // Edit booking
  let now = Date.now()

  const booking = {
    client_name: titleCase(
      document.getElementById("edit-booking-client-name").value.trim()
    ),
    client_email: document
      .getElementById("edit-booking-client-email")
      .value.trim(),
    client_phone: document
      .getElementById("edit-booking-client-phone")
      .value.trim(),
    client_address: titleCase(
      document.getElementById("edit-booking-client-address").value.trim()
    ),
    observations: document.getElementById("edit-booking-obs").value.trim(),
    start_date: start_date,
    start_time: start_time,
    start_local: titleCase(
      document.getElementById("edit-booking-start-local").value.trim()
    ),
    end_date: end_date,
    end_time: end_time,
    end_local: titleCase(
      document.getElementById("edit-booking-end-local").value.trim()
    ),
    num_days: num_days,
    car_brand: titleCase(
      document.getElementById("edit-booking-car-brand").value.trim()
    ),
    car_model: titleCase(
      document.getElementById("edit-booking-car-model").value.trim()
    ),
    car_plate: car_plate,
    car_group: car_group,
    price: price,
    deposit: deposit,
    insurance: document.getElementById("edit-booking-insurance").checked,
    added_date: now.toString(),
    modified_date: now.toString(),
    id: id,
  };

  socket.emit("update_booking", { password: getPassword(), data: booking });
};

const handle_edit_booking_info_changed = (field, data) => {
  let info = cloneDeep(store.getState().bookings.edit_booking_section);
  info[field] = data;
  updateEditBookingSection(info);
};

const handle_edit_booking_reset = (booking) => {
  let edit_booking_section = {
    client_name: booking.Client_Name,
    client_email: booking.Client_Email,
    client_phone: booking.Client_Phone,
    client_address: booking.Client_Address,
    start_date: booking.Start_Date,
    start_time: booking.Start_Time,
    start_local: booking.Start_Local,
    end_date: booking.End_Date,
    end_time: booking.End_Time,
    end_local: booking.End_Local,
    num_days: booking.Num_Days,
    car_brand: booking.Car_Brand,
    car_model: booking.Car_Model,
    car_plate: booking.Car_Plate,
    price: booking.Price,
    deposit: booking.Deposit,
    insurance: booking.Insurance,
    observations: booking.Observations,
    id: booking.ID,
  };
  updateEditBookingSection(edit_booking_section);
};

const convert_date = (date, time) => {
  if (date.includes("T")) return date;

  let splits = date.split("-");
  return splits[2] + "-" + splits[1] + "-" + splits[0] + "T" + time;
};

export const EditBookingPopup = (props) => {
  const socket = useContext(SocketContext);

  const booking = props.bookings.all_bookings.filter((booking) => {
    return booking.booking_info.ID === props.bookings.edit_booking_popup_open;
  })[0];

  if (!booking) return "";

  return (
    <div>
      <Dialog
        open={props.bookings.edit_booking_popup_open === -1 ? false : true}
        onClose={closeEditBookingPopup}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
      >
        <DialogTitle
          color="#2e3192"
          fontWeight="bold"
          fontFamily="sf pro text regular"
        >
          Editar Reserva {booking.booking_info.ID}
        </DialogTitle>
        <DialogContent>
          <br></br>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{
              flexWrap: "wrap",
              wordWrap: "break-word",
            }}
            wrap="nowrap"
            sx={{
              input: { fontSize: { lg: 15, md: 14, sm: 13, xs: 12 } },
            }}
            columnSpacing={4}
            rowSpacing={5}
          >
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                style={{
                  flexWrap: "wrap",
                  wordWrap: "break-word",
                }}
                wrap="nowrap"
                rowGap={4}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    style={{
                      flexWrap: "wrap",
                      wordWrap: "break-word",
                    }}
                    wrap="nowrap"
                    rowGap={3}
                  >
                    <Grid item>
                      <BookingTextField
                        label={
                          <Typography
                            style={{
                              fontFamily: "sf pro text regular",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#2e3192",
                            }}
                          >
                            Nome
                          </Typography>
                        }
                        id="edit-booking-client-name"
                        value={props.edit_booking_section.client_name}
                        onChange={(event) =>
                          handle_edit_booking_info_changed(
                            "client_name",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        style={{ maxWidth: "400px" }}
                        sx={{
                          input: {
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                            fontFamily: "sf pro text regular",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <BookingTextField
                        label={
                          <Typography
                            style={{
                              fontFamily: "sf pro text regular",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#2e3192",
                            }}
                          >
                            Email
                          </Typography>
                        }
                        id="edit-booking-client-email"
                        value={props.edit_booking_section.client_email}
                        onChange={(event) =>
                          handle_edit_booking_info_changed(
                            "client_email",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        style={{ maxWidth: "400px" }}
                        sx={{
                          input: {
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                            fontFamily: "sf pro text regular",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <BookingTextField
                        label={
                          <Typography
                            style={{
                              fontFamily: "sf pro text regular",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#2e3192",
                            }}
                          >
                            Telefone
                          </Typography>
                        }
                        id="edit-booking-client-phone"
                        value={props.edit_booking_section.client_phone}
                        onChange={(event) =>
                          handle_edit_booking_info_changed(
                            "client_phone",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        style={{ maxWidth: "400px" }}
                        sx={{
                          input: {
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                            fontFamily: "sf pro text regular",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <BookingTextField
                        label={
                          <Typography
                            style={{
                              fontFamily: "sf pro text regular",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#2e3192",
                            }}
                          >
                            Morada
                          </Typography>
                        }
                        id="edit-booking-client-address"
                        value={props.edit_booking_section.client_address}
                        onChange={(event) =>
                          handle_edit_booking_info_changed(
                            "client_address",
                            event.target.value
                          )
                        }
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        style={{ maxWidth: "400px" }}
                        sx={{
                          input: {
                            fontSize: {
                              lg: 14,
                              md: 12,
                              sm: 10,
                              xs: 8,
                            },
                            fontFamily: "sf pro text regular",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    rowGap={2}
                    style={{ transform: "translate(0px, -8px)" }}
                  >
                    <Grid item>
                      <BookingTextField
                        id="edit-booking-obs"
                        label="Observações"
                        value={props.edit_booking_section.observations}
                        onChange={(event) =>
                          handle_edit_booking_info_changed(
                            "observations",
                            event.target.value
                          )
                        }
                        size="small"
                        multiline
                        rows={4}
                        style={{
                          maxWidth: "400px",
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            fontFamily: "sf pro text regular",
                            fontSize: 14,
                          },
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "sf pro text regular",
                            fontSize: 12,
                            color: "#2e3192",
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" columnGap={2}>
                        <Grid item>
                          <Button
                            onClick={() => {
                              handle_submit(
                                booking.booking_info.ID,
                                socket,
                                props.snackbar
                              );
                            }}
                            style={{
                              height: "25px",
                              textAlign: "center",
                              backgroundColor: "#ffffff",
                              color: "#2e3192",
                              fontFamily: "sf pro text regular",
                              fontSize: "14px",
                              fontWeight: "bold",
                              border: "1px solid #2e3192",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 3px 1px #eeeeee",
                              textTransform: "none",
                            }}
                          >
                            Guardar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() =>
                              handle_edit_booking_reset(booking.booking_info)
                            }
                            style={{
                              height: "25px",
                              textAlign: "center",
                              backgroundColor: "#ffffff",
                              color: "#2e3192",
                              fontFamily: "sf pro text regular",
                              fontSize: "14px",
                              fontWeight: "bold",
                              border: "1px solid #2e3192",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 3px 1px #eeeeee",
                              textTransform: "none",
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} style={{ maxHeight: "350px" }}>
              <Grid
                container
                direction="column"
                justify="space-between"
                style={{
                  flexWrap: "wrap",
                  wordWrap: "break-word",
                }}
                wrap="nowrap"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    style={{
                      //flexWrap: "wrap",
                      wordWrap: "break-word",
                    }}
                    wrap="nowrap"
                    columnGap={3}
                  >
                    <Grid item xs={5}>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                        style={{
                          flexWrap: "wrap",
                          wordWrap: "break-word",
                        }}
                        wrap="nowrap"
                        rowGap={3}
                      >
                        <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <BookingTextField
                              id="edit-booking-start-date"
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Data Inicial
                                </Typography>
                              }
                              type="datetime-local"
                              value={
                                props.edit_booking_section.start_date === null
                                  ? "" //this.get_current_date()
                                  : convert_date(
                                    props.edit_booking_section.start_date,
                                    props.edit_booking_section.start_time
                                  )
                              }
                              onChange={(event) =>
                                handle_edit_booking_info_changed(
                                  "start_date",
                                  event.target.value
                                )
                              }
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                width: "205px",
                                input: {
                                  fontSize: {
                                    lg: 14,
                                    md: 12,
                                    sm: 12,
                                    xs: 8,
                                  },
                                  fontFamily: "sf pro text regular",
                                  textAlign: "center",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <BookingTextField
                              id="edit-booking-end-date"
                              label={
                                <Typography
                                  style={{
                                    fontFamily: "sf pro text regular",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color: "#2e3192",
                                  }}
                                >
                                  Data Final
                                </Typography>
                              }
                              type="datetime-local"
                              value={
                                props.edit_booking_section.end_date === null
                                  ? "" //this.get_current_date()
                                  : convert_date(
                                    props.edit_booking_section.end_date,
                                    props.edit_booking_section.end_time
                                  )
                              }
                              onChange={(event) =>
                                handle_edit_booking_info_changed(
                                  "end_date",
                                  event.target.value
                                )
                              }
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                width: "205px",
                                input: {
                                  fontSize: {
                                    lg: 14,
                                    md: 12,
                                    sm: 12,
                                    xs: 8,
                                  },
                                  fontFamily: "sf pro text regular",
                                  textAlign: "center",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            style={{
                              flexWrap: "wrap",
                              wordWrap: "break-word",
                            }}
                            wrap="nowrap"
                          >
                            <Grid item>
                              <BookingTextField
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Núm. Dias
                                  </Typography>
                                }
                                id="edit-booking-num-days"
                                value={props.edit_booking_section.num_days}
                                onChange={(event) =>
                                  handle_edit_booking_info_changed(
                                    "num_days",
                                    event.target.value
                                  )
                                }
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                style={{
                                  maxWidth: "80px",
                                }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                    textAlign: "center",
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          style={{
                            maxWidth: "80px",
                            transform: "translate(0px, 0px)",
                          }}
                        >
                          <BookingTextField
                            label={
                              <Typography
                                style={{
                                  fontFamily: "sf pro text regular",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "#2e3192",
                                }}
                              >
                                Preço
                              </Typography>
                            }
                            id="edit-booking-price"
                            value={props.edit_booking_section.price}
                            onChange={(event) =>
                              handle_edit_booking_info_changed(
                                "price",
                                event.target.value
                              )
                            }
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              input: {
                                fontSize: {
                                  lg: 14,
                                  md: 12,
                                  sm: 12,
                                  xs: 8,
                                },
                                fontFamily: "sf pro text regular",
                                textAlign: "center",
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            transform: "translate(0px, 0px)",
                          }}
                        >
                          <Grid container direction="row">
                            <Grid item>
                              <BookingTextField
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Depósito
                                  </Typography>
                                }
                                id="edit-booking-deposit"
                                value={props.edit_booking_section.deposit}
                                onChange={(event) =>
                                  handle_edit_booking_info_changed(
                                    "deposit",
                                    event.target.value
                                  )
                                }
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                style={{ maxWidth: "80px" }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                    textAlign: "center",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value="top"
                                control={
                                  <Checkbox
                                    id="edit-booking-insurance"
                                    checked={
                                      props.edit_booking_section.insurance ||
                                      props.edit_booking_section.insurance === 1
                                    }
                                    onChange={(event) => {
                                      handle_edit_booking_info_changed(
                                        "insurance",
                                        event.target.checked
                                      );
                                    }}
                                    sx={{
                                      color: "#eeeeee",
                                      transform: "translate(0px, -7px)",
                                      "&.Mui-checked": {
                                        color: "#2e3192",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "9px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Seguro
                                  </Typography>
                                }
                                labelPlacement="top"
                                style={{ transform: "translate(3px, -9px)" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                        style={{
                          flexWrap: "wrap",
                          wordWrap: "break-word",
                        }}
                        wrap="nowrap"
                        rowGap={3}
                      >
                        <Grid item>
                          <Autocomplete
                            id="edit-booking-start-local"
                            size="small"
                            freeSolo={true}
                            options={locations}
                            value={props.edit_booking_section.start_local}
                            onInputChange={(event, value) => {
                              if (event)
                                handle_edit_booking_info_changed(
                                  "start_local",
                                  value
                                );
                            }}
                            renderInput={(params) => (
                              <BookingTextField
                                {...params}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Local Inicial
                                  </Typography>
                                }
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                  },
                                }}
                                style={{ width: "100%", maxWidth: "350px" }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            id="edit-booking-end-local"
                            size="small"
                            freeSolo={true}
                            options={locations}
                            value={props.edit_booking_section.end_local}
                            onInputChange={(event, value) => {
                              if (event)
                                handle_edit_booking_info_changed(
                                  "end_local",
                                  value
                                );
                            }}
                            renderInput={(params) => (
                              <BookingTextField
                                {...params}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Local Final
                                  </Typography>
                                }
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%", maxWidth: "350px" }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                  },
                                }}
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            id="edit-booking-car-brand"
                            size="small"
                            freeSolo={true}
                            autoSelect={true}
                            //options={props.bookings.car_brand_options}
                            //defaultValue={booking.booking_info.Car_Brand}
                            options={props.car_brand_options}
                            value={props.edit_booking_section.car_brand}
                            onInputChange={(event, value) => {
                              if (event)
                                handle_edit_booking_info_changed(
                                  "car_brand",
                                  value
                                );
                            }}
                            renderInput={(params) => (
                              <BookingTextField
                                {...params}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Marca
                                  </Typography>
                                }
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%", maxWidth: "202px" }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                  },
                                }}
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            id="edit-booking-car-model"
                            size="small"
                            freeSolo={true}
                            // options={props.car_model_options}
                            // defaultValue={booking.booking_info.Car_Model}
                            options={props.car_model_options}
                            value={props.edit_booking_section.car_model}
                            onInputChange={(event, value) => {
                              if (event)
                                handle_edit_booking_info_changed(
                                  "car_model",
                                  value
                                );
                            }}
                            renderInput={(params) => (
                              <BookingTextField
                                {...params}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Modelo
                                  </Typography>
                                }
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%", maxWidth: "202px" }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                  },
                                }}
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Autocomplete
                            id="edit-booking-car-plate"
                            size="small"
                            freeSolo={true}
                            // options={props.car_plate_options}
                            defaultValue={{
                              plate: booking.booking_info.Car_Plate,
                              in_gap: "",
                              out_gap: "",
                            }}
                            options={props.car_plate_options}
                            getOptionLabel={(option) => option.plate}
                            onInputChange={(event, value) => {
                              if (event)
                                handle_edit_booking_info_changed(
                                  "car_plate",
                                  value
                                );
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Grid
                                  container
                                  direction="column"
                                  style={{ borderBottom: "1px solid #eeeeee" }}
                                >
                                  {option.in_gap !== "" ? (
                                    <Grid item>
                                      <Typography
                                        style={{
                                          fontFamily: "sf pro text regular",
                                          fontSize: "12px",
                                          color:
                                            option["in_difference"] < 0
                                              ? "#ff0000"
                                              : option["in_difference"] <= 60
                                                ? "#ff9900"
                                                : "#000000",
                                          textAlign: "center",
                                        }}
                                      >
                                        {option.in_gap}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  {option.out_gap !== "" ? (
                                    <Grid item>
                                      <Typography
                                        style={{
                                          fontFamily: "sf pro text regular",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          color:
                                            option["out_difference"] < 0
                                              ? "#ff0000"
                                              : option["out_difference"] <= 60
                                                ? "#ff9900"
                                                : "#000000",
                                          textAlign: "center",
                                        }}
                                      >
                                        {option.out_gap}
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                  <Grid
                                    item
                                    marginTop={
                                      option.in_gap !== "" ||
                                        option.out_gap !== ""
                                        ? "15px"
                                        : ""
                                    }
                                    marginBottom="5px"
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Grid container direction="row">
                                          <Grid
                                            item
                                            style={{
                                              transform: "translate(0px, 0px)",
                                            }}
                                          >
                                            <Grid container direction="column">
                                              <Grid item>
                                                <Typography
                                                  style={{
                                                    fontFamily:
                                                      "sf pro text regular",
                                                    fontSize: "12px",
                                                    color: "#000000",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {option.group}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                {option.transmission ===
                                                  "Manual" ? (
                                                  <TbManualGearbox
                                                    style={{
                                                      transform:
                                                        "translate(-2px, -4px)",
                                                    }}
                                                    size={14}
                                                  ></TbManualGearbox>
                                                ) : (
                                                  <GrManual
                                                    style={{
                                                      transform:
                                                        "translate(-2px, -4px)",
                                                    }}
                                                    size={12}
                                                  ></GrManual>
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item marginLeft={"10px"}>
                                            <Grid
                                              container
                                              direction="column"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              <Grid item>
                                                {option.brand === "Renault" ? (
                                                  <RenaultLogo
                                                    style={{
                                                      maxWidth: "20px",
                                                      maxHeight: "20px",
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                ) : option.brand === "Seat" ? (
                                                  <SeatLogo
                                                    style={{
                                                      maxWidth: "18px",
                                                      maxHeight: "18px",
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                ) : option.brand === "Smart" ? (
                                                  <SmartLogo
                                                    style={{
                                                      maxWidth: "20px",
                                                      maxHeight: "20px",
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                ) : option.brand ===
                                                  "Nissan" ? (
                                                  <NissanLogo
                                                    style={{
                                                      maxWidth: "20px",
                                                      maxHeight: "20px",
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                ) : option.brand === "Skoda" ? (
                                                  <SkodaLogo
                                                    style={{
                                                      maxWidth: "20px",
                                                      maxHeight: "20px",
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                              <Grid item>
                                                <Typography
                                                  style={{
                                                    fontFamily:
                                                      "sf pro text regular",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    color: "#000000",
                                                  }}
                                                >
                                                  {option.model}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item marginLeft={"10px"}>
                                        <Box
                                          style={{
                                            maxHeight: "30px",
                                          }}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <div
                                            style={{
                                              width: "6px",
                                              background: "#2c55ce",
                                              borderTopLeftRadius: "3px",
                                              borderBottomLeftRadius: "3px",
                                              border: "1px solid black",
                                              borderRight: "0px",
                                              marginLeft: "0 auto",
                                              padding: "0px",
                                              float: "left",
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              width: "auto",
                                              height: "100%",
                                              border: "1px solid black",
                                              borderLeft: "0px",
                                              borderTopRightRadius: "3px",
                                              borderBottomRightRadius: "3px",
                                              background: "white",
                                              padding: "0px 4px 0px 4px",
                                              float: "left",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                flexWrap: "wrap",
                                                wordWrap: "break-word",
                                                fontWeight: "bold",
                                                color: "#000000",
                                                fontFamily: "monospace",
                                              }}
                                              sx={{
                                                fontSize: {
                                                  lg: 14,
                                                  md: 12,
                                                  sm: 10,
                                                  xs: 8,
                                                },
                                              }}
                                            >
                                              {option.plate}
                                            </Typography>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </li>
                            )}
                            renderInput={(params) => (
                              <BookingTextField
                                {...params}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "sf pro text regular",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "#2e3192",
                                    }}
                                  >
                                    Matrícula
                                  </Typography>
                                }
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%", maxWidth: "202px" }}
                                sx={{
                                  input: {
                                    fontSize: {
                                      lg: 14,
                                      md: 12,
                                      sm: 12,
                                      xs: 8,
                                    },
                                    fontFamily: "sf pro text regular",
                                  },
                                }}
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        {props.edit_booking_tbd_cars_num > 0 ||
                          props.edit_booking_tbd_dates_num > 0 ||
                          props.overlapped_bookings_num > 0 ? (
                          <Grid
                            item
                            style={{ transform: "translate(0px, -15px)" }}
                          >
                            <Box
                              style={{
                                width: "100%",
                                maxWidth: "200px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                backgroundColor: "#ffdd00",
                                border: "1px solid #ffdd00",
                                borderRadius: "5px",
                              }}
                              alignItems="center"
                              display="flex"
                            >
                              <WarningAmberIcon
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                              <Typography
                                style={{
                                  fontFamily: "sf pro text regular",
                                  fontSize: "13px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                {props.edit_booking_tbd_dates_num > 0
                                  ? props.edit_booking_tbd_dates_num +
                                  " reservas sem data"
                                  : ""}
                                {props.edit_booking_tbd_dates_num > 0 &&
                                  props.edit_booking_tbd_cars_num > 0 ? (
                                  <br></br>
                                ) : (
                                  ""
                                )}
                                {props.edit_booking_tbd_cars_num > 0
                                  ? props.edit_booking_tbd_cars_num +
                                  " reservas sem carro"
                                  : ""}
                                {props.edit_booking_tbd_cars_num > 0 &&
                                  props.overlapped_bookings_num > 0 ? (
                                  <br></br>
                                ) : (
                                  ""
                                )}
                                {props.overlapped_bookings_num > 0
                                  ? props.overlapped_bookings_num +
                                  " reservas sobrepostas"
                                  : ""}
                              </Typography>
                            </Box>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings,
    edit_booking_section: state.bookings.edit_booking_section,
    car_brand_options: state.bookings.edit_booking_car_brand_options,
    car_model_options: state.bookings.edit_booking_car_model_options,
    car_plate_options: state.bookings.edit_booking_car_plate_options,
    edit_booking_tbd_cars_num: state.bookings.edit_booking_tbd_cars_num,
    edit_booking_tbd_dates_num: state.bookings.edit_booking_tbd_dates_num,
    overlapped_bookings_num: state.bookings.overlapped_bookings_num,
  };
}

export default connect(mapStateToProps)(EditBookingPopup);
